<div class="dialog-body">
  <div class="register-body">
    <form *ngIf="formRegister" [formGroup]="formRegister" novalidate>
      <div class="row">
        <!-- -->
        <label class="col-4 col-form-label">Codice Regionale</label>
        <div class="col-8 form-group">
          <kendo-multiselect [data]="data?.registerData.regionCodes"
                             formControlName="regionCodes"
                             textField="description"
                             valueField="identifier">
          </kendo-multiselect>
          <p class="error" 
             *ngIf="formRegister.get('regionCodes').errors && 
                    formRegister.get('regionCodes').touched &&
                    formRegister.get('regionCodes').hasError('required')">Inserire almeno un codice regionale (n° massimo: 3)</p>
          <p class="error" 
             *ngIf="formRegister.get('regionCodes').errors && 
                    formRegister.get('regionCodes').touched &&
                    formRegister.get('regionCodes').hasError('maxItems')">Inserire al massimo 3 codici regionali</p>
        </div>

        <!-- -->
        <label class="col-4 col-form-label">Gruppi</label>
        <div class="col-8 form-group">
          <kendo-multiselect [data]="data?.registerData.groups"
                             formControlName="groups"
                             textField="name"
                             valueField="id"
                             [valuePrimitive]="true">
          </kendo-multiselect>
        </div>

        <!-- -->
        <label class="col-4 col-form-label">Modalità Device</label>
        <div class="col-8 form-group">
          <kendo-combobox [data]="data?.registerData.deviceModes"
                          formControlName="deviceMode"
                          textField="description"
                          valueField="deviceMode"
                          [valuePrimitive]="true"
                          [filterable]="true"
                          (filterChange)="handleModeFilter($event)">
          </kendo-combobox>
        </div>

        <!-- -->
        <label class="col-4 col-form-label">Tipologia Utenza</label>
        <div class="col-8 form-group">
          <kendo-combobox [data]="data?.registerData.userTypes"
                          formControlName="userType"
                          textField="description"
                          valueField="userType"
                          [valuePrimitive]="true"
                          [filterable]="true"
                          (filterChange)="handleUserFilter($event)">
          </kendo-combobox>
        </div>

        <!-- -->
        <label class="col-4 col-form-label">Etichette</label>
        <div class="col-8">
          <kendo-multiselect [data]="data?.registerData.labels"
                             formControlName="labels"
                             textField="name"
                             valueField="id"
                             [valuePrimitive]="true">
          </kendo-multiselect>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="dialog-footer">
  <button type="button" class="btn btn-outline-secondary mt-2" (click)="backButtonClick()">
    <fa-icon icon="chevron-left"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_CANCEL | translate }}
  </button>
  <button type="button" class="btn btn-primary float-right mt-2" [disabled]="!formRegister.valid" (click)="saveButtonClick()">
    <fa-icon icon="save"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_SAVE | translate }}
  </button>
</div>
