import { AfterViewInit, Component, ElementRef, Input, ViewChild, Renderer2, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { ConstantHelper } from '../../helpers/constant.helper';
import { MapEntity, MapEntityType } from '../../models/map-entity';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {

  @ViewChild('map', { static: true }) mapElement: any;
  @ViewChild('searchBox', { static: true }) searchBox: ElementRef;
  @ViewChild('confirmSelection', { static: true }) confirmSelection: ElementRef;
  @ViewChild('infoWindowDumpsterTemplate', { static: true }) infoWindowDumpsterTemplate: ElementRef;
  @ViewChild('infoWindowDumpsterBtnTemplate', { static: true }) infoWindowDumpsterBtnTemplate: ElementRef;
  @ViewChild('infoWindowCollectionPointTemplate', { static: true }) infoWindowCollectionPointTemplate: ElementRef;

  private _entities: MapEntity[];
  private _selectedEntities: any[] = [];

  @Input() public mode: 'single' | 'multiple' = 'multiple';

  @Input() public onClickSetMarker: boolean = false;

  @Input() public set entities(val: MapEntity[]) {
    this._entities = val;
    if (this.mapInitialized) {
      this.draw();
    }
  };

  @Input() public set selectedEntities(val: MapEntity[]) {
    this._selectedEntities = val;
    this.drawSelectedEntities();
  };

  @Input() public zoom: number = 15;
  @Input() public useInfoWindowWithBtn: boolean = false;
  @Input() public markerColorByStatus: boolean = false;
  @Input() public getMarkerColorByStatus: Function;
  @Input() public context: any;
  @Input() public entityType: MapEntityType = MapEntityType.Dumpster;

  @Output() public selectedEntitiesChange = new EventEmitter();
  @Output() public selectedMarkerChange = new EventEmitter<google.maps.LatLng>();

  public get selectedEntities(): MapEntity[] {
    return this._selectedEntities;
  }

  private map: google.maps.Map;

  private mapInitialized: boolean = false;

  private markers: google.maps.Marker[] = [];
  private infoWindowTimer: any;
  private infoWindow: google.maps.InfoWindow;
  public infoWindowData: any;
  private bounds = new google.maps.LatLngBounds();

  private drawingManager: google.maps.drawing.DrawingManager;
  private drawingOverlays: (google.maps.Polygon | google.maps.Rectangle | google.maps.Circle | google.maps.Marker | google.maps.Polyline)[] = [];
  private drawingSelected: google.maps.Marker[] = [];

  constructor(private library: FaIconLibrary, private renderer: Renderer2, private router: Router, public constantHelper: ConstantHelper) {
    this.library.addIcons(faExpand, faPlusSquare);
    this.onDrawingComplete = this.onDrawingComplete.bind(this);
  }

  ngAfterViewInit() {

    const searchBox = new google.maps.places.SearchBox(this.searchBox.nativeElement, )

    var mapOptions: google.maps.MapOptions = {
      center: new google.maps.LatLng(45.4667664, 12.1858202),
      zoom: this.zoom,
      gestureHandling: 'cooperative',
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DEFAULT,
        position: google.maps.ControlPosition.RIGHT_TOP,
        mapTypeIds: ['roadmap', 'satellite']
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      fullscreenControl: false,
      streetViewControl: false
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.searchBox.nativeElement);

    const infowindow = new google.maps.InfoWindow();
    const infowindowContent = document.getElementById(
        "infowindow-content"
      ) as HTMLElement;

  infowindow.setContent(infowindowContent);

    const marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29),
    });

    // cambia i limiti della ricerca
    this.map.addListener("bounds_changed", () => {
      searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
    });

    // cambia indirizzo
    searchBox.addListener('places_changed', () => {
      infowindow.close();
      marker.setVisible(false);  

      const places = searchBox.getPlaces();
      if(places.length === 0)
        return;
      
      const bounds = new google.maps.LatLngBounds();

      places.forEach(place => {
        if(!place.geometry || !place.geometry.location)
          return;
        
        if(place.geometry.viewport){
          bounds.union(place.geometry.viewport)
        }
        else{
          bounds.extend(place.geometry.location)
        }
        this.map.fitBounds(bounds)

        marker.setPosition(place.geometry.location);
        marker.setVisible(true);

        infowindowContent.children["place-name"].textContent = place.name;
        infowindowContent.children["place-address"].textContent =
          place.formatted_address;
        infowindow.open(this.map, marker);
      })
    })

    this.infoWindow = new google.maps.InfoWindow({
      content: '',
      maxWidth: 400
    });

    //
    let externalThis = this;
    if (this.useInfoWindowWithBtn && this.entityType == MapEntityType.Dumpster) {
      google.maps.event.addListener(this.infoWindow, 'domready', () => {
        document.getElementById('sendToDetail').addEventListener('click', (val) => {
          // Go to detail
          externalThis.router.navigate(['dumpsters', 'list', externalThis.infoWindowData.id]);
        })
      });
    }

    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
        drawingModes: [google.maps.drawing.OverlayType.RECTANGLE, google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.POLYGON]
      },
      circleOptions: {
        strokeWeight: 1,
        fillOpacity: 0.2
      },
      polygonOptions: {
        strokeWeight: 1,
        fillOpacity: 0.2
      },
      rectangleOptions: {
        strokeWeight: 1,
        fillOpacity: 0.2
      }
    });

    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', this.onDrawingComplete);

    // On click set marker to return the position clicked
    this.map.addListener("click", (event) => {

      if (externalThis.onClickSetMarker) {

        var marker = new google.maps.Marker({ position: event.latLng, map: externalThis.map });
        externalThis.setMarkerIcon(marker);

        // Clear and set the created
        externalThis.clearMap();
        externalThis.markers = [marker];

        externalThis.bounds.extend(new google.maps.LatLng(marker.getPosition().lat(), marker.getPosition().lng()));

        // Emit the position selected
        externalThis.selectedMarkerChange.emit(event.latLng);
      }
    });

    if (this._entities && this._entities.length > 0) {
      this.draw();
    }

    this.mapInitialized = true;
  }

  private draw() {
    this.clearMap();
    if (this._entities) {
      this._entities.forEach(item => {
        var position = { lat: item.latitude, lng: item.longitude };
        var marker = new google.maps.Marker({ position: position, map: this.map });
        marker.set('id', item.id);
        marker.addListener('click', () => this.selectEntity(marker, item.entity));
        marker.addListener('mouseover', () => this.openInfoWindow(marker, item.entity));
        marker.addListener('mouseout', () => clearTimeout(this.infoWindowTimer));
        this.setMarkerIcon(marker);
        this.markers.push(marker);
        this.bounds.extend(new google.maps.LatLng(marker.getPosition().lat(), marker.getPosition().lng()));
      });
      this.centerAndZoomOnMarkers();
    }
  }

  private drawSelectedEntities() {
    // Update marker icons
    if (this.markers) {
      this.markers.forEach(m => {
        this.setMarkerIcon(m);
      });
    }
  }

  private setMarkerIcon(marker: google.maps.Marker) {
    let id = marker.get('id');
    let icon;

    if (this.markerColorByStatus) {

      if (this.getMarkerColorByStatus) {
        icon = this.getMarkerColorByStatus(this.context, id);
      }

    } else {
      if (this._selectedEntities.findIndex(d => d.id == id) > -1) {
        icon = `./assets/img/${this.constantHelper.IMG_GREEN_DOT}`;
      }
      else {
        icon = `./assets/img/${this.constantHelper.IMG_RED_DOT}`;
      }
    }
    marker.setIcon(icon);
  }

  private clearMap() {
    if (this.markers) {
      this.markers.forEach(m => m.setMap(null));
    }
    this.markers = [];
    this.bounds = new google.maps.LatLngBounds();
  }

  private openInfoWindow(marker: google.maps.Marker, data: any) {
    this.infoWindowData = data;

    this.infoWindowTimer = window.setTimeout(() => {

      let content;

      switch (this.entityType) {
        case MapEntityType.Dumpster:
          if (this.useInfoWindowWithBtn) {
            content = this.infoWindowDumpsterBtnTemplate.nativeElement.innerHTML;
          } else {
            content = this.infoWindowDumpsterTemplate.nativeElement.innerHTML;
          }
          break;

        case MapEntityType.CollectionPoint:
          content = this.infoWindowCollectionPointTemplate.nativeElement.innerHTML;
          break;
      }


      this.infoWindow.setContent(content);
      this.infoWindow.open(this.map, marker);
    }, 1000);
  }

  private selectEntity(marker: google.maps.Marker, entity: any) {
    const i = this._selectedEntities.findIndex(d => d.id == entity.id);
    if (i > -1) {
      this._selectedEntities.splice(i, 1);
    }
    else {
      this._selectedEntities.push(entity)
    }
    this.setMarkerIcon(marker);
    this.selectedEntitiesChange.emit(this._selectedEntities);
  }

  private onDrawingComplete(event: google.maps.drawing.OverlayCompleteEvent) {
    this.drawingOverlays.push(event.overlay);
    this.markers.forEach(m => {
      if (this.markerIsInside(m, event.overlay, event.type)) {
        let markerId = m.get('id');
        if (this._selectedEntities.findIndex(d => d.id == markerId) == -1 && this.drawingSelected.findIndex(m => m.get('id') == markerId) == -1) {
          m.setIcon(`./assets/img/${this.constantHelper.IMG_YELLOW_DOT}`);
          this.drawingSelected.push(m);
        }
      }
    });
  }

  private markerIsInside(marker: google.maps.Marker, overlay: any, overlayType: string): boolean {
    switch (overlayType) {
      case 'circle':
        return google.maps.geometry.spherical.computeDistanceBetween(marker.getPosition(), (<google.maps.Circle>overlay).getCenter()) <= (<google.maps.Circle>overlay).getRadius();
      case 'rectangle':
        return (<google.maps.Rectangle>overlay).getBounds().contains(marker.getPosition());
      case 'polygon':
        return google.maps.geometry.poly.containsLocation(marker.getPosition(), <google.maps.Polygon>overlay);
      default:
        return false;
    }
  }

  public centerAndZoomOnMarkers() {
    this.map.fitBounds(this.bounds); // Auto zoom
    this.map.panToBounds(this.bounds); // Auto center
  }

  public addSelection() {
    this.drawingManager.setMap(this.map);
    this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.confirmSelection.nativeElement);
    this.renderer.setStyle(this.confirmSelection.nativeElement, 'display', 'block');
  }

  public onConfirmSelection() {
    this.drawingSelected.forEach(s => {
      const markerId = s.get('id');
      if (this._selectedEntities.findIndex(d => d.id == markerId) == -1) {
        const entity = this._entities.find(d => d.id == markerId);
        this._selectedEntities.push(entity);
        this.setMarkerIcon(s);
      }
    });
    this.drawingSelected = [];
    this.drawingOverlays.forEach(o => o.setMap(null));
    this.drawingOverlays = [];
    this.drawingManager.setMap(null);
    this.map.controls[google.maps.ControlPosition.LEFT_TOP].clear();
    this.renderer.setStyle(this.confirmSelection.nativeElement, 'display', 'none');

    this.selectedEntitiesChange.emit(this._selectedEntities);
  }

  public onCancelSelection() {
    this.drawingOverlays.forEach(o => o.setMap(null));
    this.drawingOverlays = [];
    this.drawingSelected.forEach(m => this.setMarkerIcon(m));
    this.drawingSelected = [];
    this.drawingManager.setMap(null);
    this.map.controls[google.maps.ControlPosition.LEFT_TOP].clear();
  }
}
