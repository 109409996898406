<div class="row">
  <label 
    class="col-xl-3 col-md-4 col-12 col-form-label"
    title="{{ option?.text }}"
  >{{ option?.text }}</label>
  <div class="col-xl-9 col-md-8 col-12">
    <div class="w-100 d-flex input-action-wrapper">
      <ng-content select=".field-body"></ng-content>
      <button
        *ngIf="!option?.isActionHide"
        class="btn btn-primary"
        [disabled]="isDisable"
        (click)="setEmitClick()"
      >
        <i [ngClass]="option?.icon"></i>
      </button>
    </div>
  </div>
</div>