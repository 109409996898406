<h1 class="page-title" *ngIf="viewMode != ViewMode.New"><fa-icon icon="user-friends"></fa-icon>{{ role?.name }}</h1>
<h1 class="page-title" *ngIf="viewMode == ViewMode.New"><fa-icon icon="user-friends"></fa-icon>{{ constantHelper.LBL_PAGE_ROLE_NEW_TITLE | translate }}</h1>
<app-toolbar [formGroup]="formRole" [viewMode]="viewMode" (view)="onView()" (back)="onBack()" (edit)="onEdit()" (save)="onSave()" (delete)="onDelete()" (cancel)="onCancel()" [hideOperations]="!hasWritePermission"></app-toolbar>

<div class="page-content">

  <!-- -->
  <div class="mt-10">
    <app-group-box pageTitle="{{ 'page.RoleDetail.Section.General.Title' | translate }}">

      <form [formGroup]="formRole" class="box-standard-padding" novalidate>
        <div class="row">
          <label class="col-xl-2 col-md-2 col-sm-12 col-form-label">{{ constantHelper.LBL_COMMON_NAME | translate }}</label>
          <div class="col-xl-4 col-md-6 col-sm-12 form-group">
            <input type="text" class="form-control" formControlName="name" id="inputName">
          </div>
        </div>
        <div class="row">
          <label class="col-xl-2 col-md-2 col-sm-12 col-form-label">{{ constantHelper.LBL_COMMON_DESCRIPTION | translate }}</label>
          <div class="col-xl-10 col-md-10 col-sm-12 form-group">
            <input type="text" class="form-control" formControlName="description">
          </div>
        </div>

      </form>
    </app-group-box>
  </div>

  <!-- -->
  <div class="">
    <app-group-box pageTitle="{{ 'page.RoleDetail.Section.Permissions.Title' | translate }}">
      <div class="box-standard-padding">
        <kendo-treeview [nodes]="permissions"
                        [isDisabled]="isDisabled"
                        kendoTreeViewExpandable
                        kendoTreeViewHierarchyBinding
                        [hasChildren]="hasChildren"
                        [children]="fetchChildren"
                        [kendoTreeViewCheckable]="checkableSettings"
                        [isChecked]="isNodeChecked"
                        (checkedChange)="nodeChecking($event)"
                        [expandBy]="'indexKey'"
                        class="role-tree">
          <ng-template kendoTreeViewNodeTemplate let-dataItem>
            <span>{{ dataItem.resourceKey | translate }}</span>
          </ng-template>
        </kendo-treeview>
      </div>
    </app-group-box>
  </div>

  <!-- -->
  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-auditing-box [item]="role"></app-auditing-box>
  </div>

</div>
