
<app-group-box pageTitle="{{ constantHelper.LBL_PAGE_COMMON_AUDITING_TITLE | translate }}">
  <div class="row box-standard-padding auditing-data">
    <div class="col-12" *ngIf="item?.createdAt">
      <span class="bold">Creazione:</span> {{item?.createdBy}} il {{item?.createdAt | date: constantHelper.DATE_TIME_FORMAT}} <!-- constantHelper.LBL_COMMON_AUDITING_CREATION -->
    </div>

    <div class="col-12" *ngIf="item?.modifiedAt">
      <span class="bold">Ultima modifica:</span> {{item?.modifiedBy}} il {{item?.modifiedAt | date: constantHelper.DATE_TIME_FORMAT}} <!-- constantHelper.LBL_COMMON_AUDITING_EDIT -->
    </div>

    <div class="col-12" *ngIf="item?.deactivatedAt">
      <span class="bold">Disattivazione:</span> {{item?.deactivatedBy}} il {{item?.deactivatedAt | date: constantHelper.DATE_TIME_FORMAT}} <!-- constantHelper.LBL_COMMON_AUDITING_DEACTIVATION -->
    </div>
  </div>
</app-group-box>
