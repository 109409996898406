<h1 class="page-title" *ngIf="viewMode != ViewMode.New"><fa-icon icon="dumpster"></fa-icon>{{ commandTask?.name }}</h1>
<h1 class="page-title" *ngIf="viewMode == ViewMode.New"><fa-icon icon="dumpster"></fa-icon>{{ 'page.CommandTaskNew.Title' | translate }}</h1>
<app-toolbar [formGroup]="formCommandTask" [viewMode]="viewMode" (view)="onView()" (back)="onBack()" (edit)="onEdit()" (delete)="onDelete()" (save)="onSave()" (cancel)="onCancel()"></app-toolbar>

<div class="page-content">

  <!-- GENERAL -->
  <div class="mt-10">
    <app-group-box pageTitle="{{ 'page.CommandTaskDetail.Section.General.Title' | translate }}">
      <form [formGroup]="formCommandTask" class="box-standard-padding" novalidate>

        <div class="row">
          <label class="col-lg-1 col-sm-2 col-form-label">{{ 'vocabulary.CommandTask.CommandId' | translate }}</label>
          <div class="col-lg-3 col-sm-4 form-group">
            <kendo-combobox [data]="commands"
                            formControlName="commandId"
                            textField="name"
                            valueField="id"
                            [valuePrimitive]="true"
                            [filterable]="true"
                            (filterChange)="handleCommandFilter($event)">
            </kendo-combobox>
          </div>
          <label class="col-lg-1 col-sm-2 col-form-label">{{ 'common.Name' | translate }}</label>
          <div class="col-lg-3 col-sm-4 form-group">
            <input type="text" class="form-control" formControlName="name">
          </div>
          <label class="col-lg-1 col-sm-2 col-form-label">{{ 'vocabulary.CommandTask.Enabled' | translate }}</label>
          <div class="col-lg-3 col-sm-4 form-group">
            <input type="checkbox" formControlName="enabled">
          </div>
        </div>

        <div class="row">
          <label class="col-lg-1 col-sm-2 col-form-label">{{ 'common.Description' | translate }}</label>
          <div class="col-lg-11 col-sm-10 form-group">
            <input type="text" class="form-control" formControlName="description">
          </div>
        </div>

        <div class="row">
          <label class="col-lg-1 col-sm-2 col-form-label">{{ 'vocabulary.CommandTask.TriggerType' | translate }}</label>
          <div class="col-lg-3 col-sm-4 form-group">
            <kendo-dropdownlist [data]="triggerTypes"
                                formControlName="triggerType"
                                textField="triggerTypeDescription"
                                valueField="triggerType"
                                [valuePrimitive]="true">

              <ng-template kendoDropDownListItemTemplate let-dataItem>
                {{dataItem?.triggerTypeDescription | translate}}
              </ng-template>

              <ng-template kendoDropDownListValueTemplate let-dataItem>
                {{dataItem?.triggerTypeDescription | translate}}
              </ng-template>

            </kendo-dropdownlist>

          </div>
          <label class="col-lg-1 col-sm-2 col-form-label">{{ 'vocabulary.CommandTask.PlannedAt' | translate }}</label>
          <div class="col-lg-3 col-sm-4 form-group">
            <input type="text" class="form-control" formControlName="plannedAt">
          </div>
        </div>

        <!--Once-->
        <div class="form-group row" *ngIf="triggerType == TriggerType.Once">
        </div>

        <!--Daily-->
        <div class="row" *ngIf="triggerType == TriggerType.Daily">
          <label class="col-sm-2 col-form-label">{{ 'vocabulary.CommandTask.RecurEvery' | translate }}</label>
          <div class="col-sm-4 form-group">
            <input type="text" class="form-control" formControlName="recurEvery">
          </div>
        </div>
      </form>

    </app-group-box>
  </div>

  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-group-box pageTitle="{{ 'page.CommandDetail.Section.Parameters.Title' | translate }}">
    </app-group-box>
  </div>

  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-group-box pageTitle="Dispositivi">
    </app-group-box>
  </div>

  <!--<kendo-tabstrip (tabSelect)="onTabChange($event)" [keepTabContent]="true">
    <kendo-tabstrip-tab title="{{ 'component.CollectionPoints.Tab.List' | translate }}" [selected]="true">
      <ng-template kendoTabContent>
        <div class="grid-container">
          <app-custom-grid [columns]="columns" [items]="gridData" [filterable]="true" [showActions]="true" [actions]="actions" [toolbarActions]="toolbarActions"></app-custom-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'component.CollectionPoints.Tab.Map' | translate }}">
      <ng-template kendoTabContent>
        <div class="grid-container">
          <app-dumpster-grid [items]="collectionPointDevices" [(selectedDumpsters)]="selectedDevices" [readonly]="viewMode == ViewMode.New" [actions]="actions" [toolbarActions]="toolbarActions"></app-dumpster-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>-->

</div>
