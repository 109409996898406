import { Component, Inject } from '@angular/core';
import { BannerIcon, bannerActions } from '@veritas-shared/enums/banner.const';
import { BannerAction, BannerOption, BannerOptionStatus } from '@veritas-shared/models/banner-option.model';
import { TicketInfoService } from '../../services/ticket-info.service';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { DateService } from '@veritas-shared/services/date.service';
import { TicketBodyOutput } from '../../models/ticket-list-output';
import { DateFormat } from '@veritas-shared/enums/date.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ticket-banner',
  templateUrl: './ticket-banner.component.html',
  styleUrls: ['./ticket-banner.component.scss']
})
export class TicketBannerComponent {
  public option: BannerOption;
  public action: BannerAction;
  public isOpen: boolean;

  private _path: string;

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private constantHelper: ConstantHelper,
    private translate: TranslateService,
    private dateService: DateService,
    private infoService: TicketInfoService,
  ) {
    this.option = new BannerOption(BannerIcon.ticket, '');
    this.action = bannerActions.open;
    this.isOpen = false;

    this._path = '';
  }

  public setClickAction = () => window.open(this._path, '_blank');

  private _getConfirmMessage = (item: TicketBodyOutput):any => ({
    ticket: item?.ticketId || '',
    date: this.dateService.getFormat(item?.closureTimestamp, DateFormat.shortDate),
    time: this.dateService.getFormat(item?.closureTimestamp, DateFormat.shortTime),
    hours: this.dateService.getDiffDate(new Date(), item?.closureTimestamp),
  });

  private _getOption = (ticket: TicketBodyOutput):BannerOption => ({
    ...this.option,
    body: this.translate.instant(this.constantHelper.LBL_COMMON_CONFIRM_TK, { ...this._getConfirmMessage(ticket) }),
    status: new BannerOptionStatus(true, true)
  });

  private _getPath = (ticket: TicketBodyOutput):string => `${this.baseUrl}tickets/list/${ticket?.ticketId}/${ticket?.entityId}`

  public event$ = this.infoService.openBanner$.subscribe((ticket: TicketBodyOutput) => {
    if(!ticket) {
      this.isOpen = false;
      return;
    }
    this._path = this._getPath(ticket);
    this.option = this._getOption(ticket);
    this.isOpen = true;
  });
}
