import { Injectable } from '@angular/core';
import { accessibleIcon, dumpsterIcon } from '@veritas-dumpsters/constants/dumpster-icon-path.const';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { MapPathIcon } from '@veritas-shared/models/map/map-path-icon.model';

@Injectable({
  providedIn: 'root'
})
export class MapIconService {
  private _path: MapPathIcon;

  constructor(){
    this._path = dumpsterIcon;
  }

  public set path(value: MapPathIcon) { this._path = value }

  public getDetailIcon = (data: Dumpster) => {
    this._path = this._getIconPath(data);
    return this.getIcon(data);
  }

  public getIcon = (data: Dumpster) => {
    const basePath: string = './assets/img';

    if(data?.errorCount > 0) return `${basePath}/${this._path.red}`;
    if(data?.warningCount > 0) return `${basePath}/${this._path.yellow}`;
    return `${basePath}/${this._path.green}`;
  }

  private _getIconPath = (data: Dumpster): MapPathIcon => {
    if(Boolean(data?.parentDumpsterId)) return accessibleIcon;
    return dumpsterIcon;
  }
}
