<div
  *ngIf="option.viewMode !== viewMode.New"
  class="page-title d-flex justify-content-between align-items-center"
  [ngClass]="{'has-ticket': option.status.present}"
>
  <div class="banner-text d-flex align-items-center">
    <fa-icon [icon]="option.icon"></fa-icon>
    <span>{{ option.body }}</span>
  </div>
  <button
    *ngIf="option.status.present"
    type="button"
    class="btn ticket-toolbar-item btn-secondary d-flex align-items-center mr-3"
    kendoTooltip
    [title]="action.tooltip || action.text | translate"
    [disabled]="!option.status.checked"
    (click)="setClickAction()" 
  >
    <fa-icon class="ticket-button-icon" [icon]="action.icon"></fa-icon>
    <span>{{action.text | translate}}</span>
  </button>
</div>
