import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CustomGridComponent } from '@veritas-shared/components/custom-grid/custom-grid.component';
import { SelectableMode, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { Router, ActivatedRoute } from '@angular/router';
import { IncidentListOutputIncident } from '../../models/incident-list-output';
import { Action } from '@veritas-shared/models/action';

@Component({
  selector: 'app-incident-grid',
  templateUrl: './incident-grid.component.html',
  styleUrls: ['./incident-grid.component.scss']
})
export class IncidentGridComponent implements OnInit {

  @ViewChild(CustomGridComponent) grid: CustomGridComponent;

  @Input() public commandsPrefix: string;
  @Input() public items: IncidentListOutputIncident[];
  @Input() public actions: Action[];
  @Input() public toolbarActions: Action[] = [];
  @Input() public readonly: boolean = false;
  @Input() public selectableMode: SelectableMode = 'multiple';

  @Input() public set selectedIncidents(val: IncidentListOutputIncident[]) {
    this._selectedIncidents = val;
    if (this._selectedIncidents)
      this.selectedIncidentsId = this._selectedIncidents.map(d => d.id);
    else
      this.selectedIncidentsId = [];
  };

  @Output() public selectedIncidentsChange = new EventEmitter();

  public get selectedCollectionPoints(): IncidentListOutputIncident[] {
    return this._selectedIncidents;
  }

  private _selectedIncidents: IncidentListOutputIncident[];
  public selectedIncidentsId: number[];

  public columns: GridColumn[] = [];

  public selectableSettings: SelectableSettings;

  constructor(private router: Router, private route: ActivatedRoute) {

    this.view = this.view.bind(this);

    this.onSelectionChange = this.onSelectionChange.bind(this);
  }

  ngOnInit() {

    // Set columns here because column template 
    this.columns = [
      {
        field: 'incidentCode',
        title: 'vocabulary.Incident.IncidentCode',
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'link',
        callback: this.view
      },
      {
        field: 'title',
        title: 'vocabulary.Incident.Title',
        sortable: true,
        resizable: true,
        filterable: true
      },
      //{
      //  field: 'address.city',
      //  title: 'vocabulary.CollectionPoint.City',
      //  sortable: true,
      //  resizable: true,
      //  filterable: true
      //},
      //{
      //  field: 'description',
      //  title: 'vocabulary.CollectionPoint.Description',
      //  sortable: true,
      //  resizable: true,
      //  filterable: true
      //},
      //{
      //  field: 'devicesCount',
      //  title: 'vocabulary.CollectionPoint.DevicesCount',
      //  sortable: true,
      //  resizable: false,
      //  filterable: false,
      //  width: "70px"
      //}
    ];

    this.selectableSettings = {
      enabled: !this.readonly,
      mode: this.selectableMode
    };

  }

  private view(incident: IncidentListOutputIncident) {
    let commands: any[] = [incident.id];

    if (this.commandsPrefix) {
      let commandsPrefixArray: any[] = this.commandsPrefix.split("/");
      commands = commandsPrefixArray.concat(commands);
    }

    this.router.navigate(commands, { relativeTo: this.route });
  }

  public refreshSelection() {
    if (this._selectedIncidents)
      this.selectedIncidentsId = this._selectedIncidents.map(d => d.id);
    else
      this.selectedIncidentsId = [];
  }

  public onSelectionChange(e: SelectionEvent) {
    let selected = Object.assign([], this._selectedIncidents);
    if (e.selectedRows.length > 0) {
      e.selectedRows.forEach(r => selected.push(r.dataItem));
    }
    if (e.deselectedRows.length > 0) {
      e.deselectedRows.forEach(r => {
        let i = selected.findIndex(d => d.id == r.dataItem.id);
        if (i > -1) {
          selected.splice(i, 1);
        }
      });
    }
    this._selectedIncidents = selected;
    this.selectedIncidentsChange.emit(this._selectedIncidents);
  }

  public goToPage(page: number) {
    this.grid.goToPage(page);
  }
}
