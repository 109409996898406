
<app-group-box pageTitle="{{ constantHelper.LBL_COMPONENT_TICKET_SEARCH_TITLE | translate}}"
               [isCollapsible]="true"
               (onCollapse)="onCollapseChange($event)">

  <form [formGroup]="formSearch" kendoTooltip filter="label" [tooltipTemplate]="tooltipTemplate" class="box-standard-padding" novalidate>

    <!-- Disabled -->
      <!--<label class="col-xl-1 col-lg-2 col-md-3 col-12 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_TYPE | translate }}</label>
  <div class="col-xl-3 col-lg-4 col-md-9 col-12 form-group">
    <input kendoTextBox type="text" class="form-control" formControlName="ticketType">
  </div>-->
      <!--<label class="col-xl-1 col-lg-2 col-md-3 col-12 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_SENDER_TYPE | translate }}</label>
  <div class="col-xl-3 col-lg-4 col-md-9 col-12 form-group">
    <input type="text" class="form-control" formControlName="senderType">
  </div>-->
      <!--<label class="col-xl-1 col-lg-2 col-md-3 col-12 col-form-label">Data Creazione Descrescente</label>
  <div class="col-xl-3 col-lg-4 col-md-9 col-12 form-group">

    <input #yes
           [value]="true"
           class="m-1 radio-input"
           formControlName="orderByCreationDateDescending"
           kendoRadioButton
           type="radio" />
    <label [for]="yes" class="k-label m-1">Si</label>

    <input #no
           [value]="false"
           class="m-1 radio-input"
           formControlName="orderByCreationDateDescending"
           kendoRadioButton
           type="radio" />
    <label [for]="no" class="k-label m-1">No</label>
  </div>-->
      <!-- OLD -->
      <!--<label class="col-xl-1 col-lg-2 col-md-3 col-12 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_ID | translate }}</label>
  <div class="col-xl-3 col-lg-4 col-md-9 col-12 form-group">
    <input type="text" class="form-control" formControlName="ticketId">
  </div>-->
      <!--
  <label class="col-xl-1 col-sm-2 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_MUNICIPALITY | translate }}</label>
  <div class="col-xl-5 col-sm-4 form-group">
  <input type="text" class="form-control" formControlName="municipality">
  </div>
  -->
      <!-- -->
<div class = 'row'>
  <div class = 'col-6'>
    <div class = 'row'>
      <label class="col-3 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_SEARCH_DATE_FROM | translate }}</label>
      <div class="col-9 form-group">
        <kendo-datepicker formControlName="startDate" (keyup.enter)="onSearch()"></kendo-datepicker>
      </div>
    </div>
  </div>
  
  <div class = 'col-6'>
    <div class = 'row'>
      <label class="col-3 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_SEARCH_DATE_TO | translate }}</label>
      <div class="col-9 form-group">
        <kendo-datepicker formControlName="endDate" (keyup.enter)="onSearch()"></kendo-datepicker>
      </div>
      </div>
    </div>
</div>

<div class = 'row'>
  <div class = 'col-6'>
    <div class = 'row'>
      <label class="col-3 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_CODE | translate }}</label>
      <div class="col-9 form-group">
                <kendo-combobox *ngIf="dumpsters"
                          formControlName="currentDeviceId"
                          [data]="dumpsters"
                          [textField]="'code'"
                          [valueField]="'deviceExternalId'"
                          [valuePrimitive]="true"
                          [filterable]="true"
                          [allowCustom]="true"
                          (filterChange)="handleDumpstersFilter($event)"
                          (valueChange)="onDumpsterSelected($event)"
                          (keyup.enter)="onSearch()">
            <ng-template kendoComboBoxItemTemplate let-dataItem>
              {{ dataItem.code}} ({{dataItem.binId}}) - {{dataItem.address}}
            </ng-template>
          </kendo-combobox>
      </div>
    </div>
  </div>
  <div class = 'col-6'>
    <div class = 'row'>
      <label class="col-3 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_STATE | translate }}</label>
      <div class="col-9 form-group">
    
        <kendo-multiselect formControlName="state"
                            [data]="entities?.ticketStates"
                            [textField]="'description'"
                            [valueField]="'state'"
                            [(ngModel)]="selectedValues"
                            [valuePrimitive]="true"
                            (keyup.enter)="onSearch()">
        </kendo-multiselect>
      </div>
    </div>
  </div>
</div>
      <!--
  <label class="col-xl-1 col-lg-2 col-md-3 col-12 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_OPENING_REASONS | translate }}</label>
  <div class="col-xl-11 col-lg-10 col-md-9 col-12 form-group">
    <kendo-multiselect formControlName="openingReasons"
                       [data]="entities?.openingReasons"
                       [textField]="'description'"
                       [valueField]="'openingReason'"
                       [valuePrimitive]="true">
    </kendo-multiselect>
  </div>-->
      <!-- -->
      <!--<label class="col-xl-1 col-lg-2 col-md-3 col-12 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_CLOSURE_REASONS | translate }}</label>
  <div class="col-xl-11 col-lg-10 col-md-9 col-12 form-group">
    <kendo-multiselect formControlName="closureReasons"
                       [data]="entities?.closureReasons"
                       [textField]="'description'"
                       [valueField]="'closureReason'"
                       [valuePrimitive]="true">
    </kendo-multiselect>
  </div>-->
      <!--label class="col-xl-1 col-sm-2 col-form-label">Data Creazione Descrescente</label>
  <div class="col-xl-5 col-sm-4 form-group">

    <input #yes
           [value]="true"
           class="m-1 radio-input"
           formControlName="orderByCreationDateDescending"
           kendoRadioButton
           type="radio" />
    <label [for]="yes" class="k-label m-1">
      Si
    </label>

    <input #no
           [value]="false"
           class="m-1 radio-input"
           formControlName="orderByCreationDateDescending"
           kendoRadioButton
           type="radio" />
    <label [for]="no" class="k-label m-1">
      No
    </label>
  </div>-->

    <div class="row">
      <div class="col-xl-1 col-form-label">
        <button type="button" class="btn btn-outline-primary" (click)="onSearch()">
          <fa-icon icon="search"></fa-icon>
          {{ constantHelper.LBL_ACTION_COMMON_SEARCH | translate }}
        </button>
      </div>
    </div>
  </form>

</app-group-box>

<ng-template #tooltipTemplate let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
