<div class="map-wrapper">
  <div #mapContainer class="map" id="map">
    <div class="d-flex content-map-wrapper">
      <div class="external-content-wrapper">
        <ng-content></ng-content>
      </div>
      <app-leaflet-map-search
        (centerAndZoomMarker)="setCenterAndZoomOnMarkers($event)"
        (removeMarker)="removeMarker($event)"
        (addMarker)="addMarker($event)"
      ></app-leaflet-map-search>
    </div>
  </div>
</div>
