import { Pipe, PipeTransform } from '@angular/core';
import { TicketMessageType } from '@veritas-shared/models/entities/ticket-message-type';

@Pipe({
  name: 'messageType'
})
export class MessageTypePipe implements PipeTransform {

  transform(value: string, args: TicketMessageType[]): any {
    if (value == null || args == null) {
      return null;
    }

    var result = args.find(el => el.ticketMessageType == value)
    if (result) {
      return result.description;
    } else {
      return null;
    }
  }
}
