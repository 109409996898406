<app-leaflet-map-container #mapWrapper
  [defaultZoom]="zoom"
  (isMapInitialized)="setDefaultEntities($event)"
>
  <button 
    type="button" 
    class="d-flex  btn toolbar-item"
    (click)="mapWrapper.setCenterAndZoomOnMarkers(coordination)"
  >
    <fa-icon class="text-primary" icon="expand"></fa-icon>
    <span>Centra</span>
  </button>
</app-leaflet-map-container>
