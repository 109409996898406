<h1 class="page-title" *ngIf="viewMode != ViewMode.New"><fa-icon icon="key"></fa-icon>{{ authorization?.name }}</h1>
<h1 class="page-title" *ngIf="viewMode == ViewMode.New"><fa-icon icon="key"></fa-icon>{{ 'page.AuthorizationNew.Title' | translate }}</h1>
<app-toolbar [formGroup]="formAuthorization" [viewMode]="viewMode" (view)="onView()" (back)="onBack()" (edit)="onEdit()" (save)="onSave()" (cancel)="onCancel()" [hideOperations]="!hasWritePermission"></app-toolbar>

<div class="page-content">

  <!-- GENERAL -->
  <div class="mt-10">
    <app-group-box pageTitle="Dati generali">
      <form [formGroup]="formAuthorization" class="box-standard-padding" novalidate>

        <div class="row">
          <label class="col-xl-1 col-lg-2 col-sm-2 col-12 col-form-label">{{  constantHelper.LBL_COMMON_NAME | translate }}</label>
          <div class="col-xl-5 col-lg-10 col-sm-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="name" id="inputName">
          </div>
          <label class="col-xl-1 col-lg-2 col-sm-2 col-12 col-form-label">{{ constantHelper.LBL_COMMON_DESCRIPTION | translate }}</label>
          <div class="col-xl-3 col-lg-6 col-sm-6 col-12 form-group">
            <input type="text" class="form-control" formControlName="description">
          </div>
          <label class="col-xl-1 col-lg-2 col-sm-2 col-12 col-form-label">{{ constantHelper.LBL_COMMON_ISENABLED | translate }}</label>
          <div class="col-xl-1 col-lg-2 col-sm-2 col-12 form-group">
            <kendo-switch formControlName="isEnabled"></kendo-switch>
          </div>
        </div>

        <div class="row">
          <label class="col-xl-1 col-sm-2 col-12 col-form-label">{{ constantHelper.LBL_VOCABULARY_ROLE | translate }}</label>
          <div class="col-xl-5 col-sm-10 col-12 form-group">
            <kendo-combobox [data]="roles"
                            formControlName="roleId"
                            textField="name"
                            valueField="id"
                            [valuePrimitive]="true"
                            [filterable]="true"
                            (filterChange)="handleRoleFilter($event)"
                            (valueChange)="onRoleSelectChange($event)">
            </kendo-combobox>
          </div>

          <label class="col-xl-1 col-sm-2 col-12 col-form-label" *ngIf="groupVisible">{{ constantHelper.LBL_VOCABULARY_AUTHGROUP | translate }}</label>
          <div class="col-xl-5 col-sm-10 col-12 form-group" *ngIf="groupVisible">
            <kendo-combobox [data]="groups"
                            formControlName="groupId"
                            textField="name"
                            valueField="id"
                            [valuePrimitive]="true"
                            [filterable]="true"
                            (filterChange)="handleGroupFilter($event)">
            </kendo-combobox>
          </div>

        </div>
      </form>
    </app-group-box>

  </div>

  <!-- USERS -->
  <div class="">
    <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_AUTH_DETAIL_USERS_TITLE | translate }}">
      <div class="box-standard-padding users-data">
        <div class="grid-container">
          <app-user-grid [items]="authUsers"
                         [(selectedUsers)]="selectedUsers"
                         [readonly]="isUsersListReadonly"
                         [toolbarActions]="usersToolbarActions">
          </app-user-grid>
        </div>
      </div>
    </app-group-box>
  </div>

  <!-- AUDITING -->
  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-auditing-box [item]="authorization"></app-auditing-box>
  </div>
</div>

<ng-template windowVisualizer></ng-template>
