<h1 class="page-title" *ngIf="viewMode == ViewMode.View">
  <fa-icon icon="dumpster"></fa-icon>{{ 'page.CommandEventDetail.Title' | translate }} {{commandEventDate}}
</h1>
<!-- <h1 class="page-title" *ngIf="viewMode == ViewMode.New"><fa-icon icon="dumpster"></fa-icon>{{ 'page.CommandNew.Title' | translate }}</h1> -->
<app-toolbar [formGroup]="formCommandEventDetail" (back)="onBack()"></app-toolbar> <!-- [viewMode]="viewMode"  -->

<div class="page-content">

  <!-- -->
  <div class="mt-10">
    <app-group-box pageTitle="{{ 'page.CommandEventDetail.Section.General.Title' | translate }}">
      <form [formGroup]="formCommandEventDetail" class="box-standard-padding" novalidate>
        <div class="row">
          <label class="col-lg-1 col-sm-2 col-form-label">{{ 'common.CreatedBy' | translate }}</label>
          <div class="col-lg-5 col-sm-4 form-group">
            <input type="text" class="form-control" formControlName="createdBy">
          </div>

          <label class="col-lg-1 col-sm-2 col-form-label">{{ 'common.CreatedAt' | translate }}</label>
          <div class="col-lg-5 col-sm-4 form-group">
            <input type="text" class="form-control" formControlName="createdAt">
          </div>
        </div>
      </form>
    </app-group-box>
  </div>

  <!-- -->
  <div class="">
    <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_COMMAND_EVENT_DETAIL_DUMPSTERS | translate }}">
      <div class="box-standard-padding grid-container">
        <app-custom-grid [columns]="gridColumns" [items]="gridData" [filterable]="false" [showActions]="false" [editingInCell]="true">
        </app-custom-grid>
      </div>
    </app-group-box>
  </div>

</div>
