import { HostListener, Directive } from '@angular/core';
import { Observable } from 'rxjs';

@Directive()
export abstract class DeactivatedComponent {

  // Base component with canDeactivate method to prevent leave it without saving

  abstract canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }

}
