<div class="toolbar page-toolbar">
  <!-- BACK -->
  <button *ngIf="!backAction" type="button" class="btn toolbar-item" (click)="back.emit()">
    <fa-icon icon="angle-left"></fa-icon>
    <span>{{ constantHelper.LBL_ACTION_COMMON_BACK | translate}}</span>
  </button>

  <!-- EDIT -->
  <button *ngIf="viewMode == ViewMode.View && !hideEdit && !hideOperations" type="button" class="btn toolbar-item" (click)="edit.emit()">
    <fa-icon icon="pencil-alt"></fa-icon>
    <span>{{constantHelper.LBL_ACTION_COMMON_EDIT | translate}}</span>
  </button>

  <!-- VIEW -->
  <button *ngIf="viewMode == ViewMode.Edit && !hideOperations" type="button" class="btn toolbar-item" [disabled]="formGroup && formGroup.dirty" (click)="view.emit()">
    <fa-icon icon="sliders-h"></fa-icon>
    <span>{{constantHelper.LBL_ACTION_COMMON_VIEW | translate}}</span>
  </button>

  <!-- BACK -- CHECK IF TO DELETE -->
  <button *ngIf="backAction && backAction.show()" type="button" class="btn toolbar-item" (click)="backAction.callback()">
    <fa-icon icon="{{backAction.icon}}"></fa-icon>
    <span>{{backAction.resourceKey | translate}}</span>
  </button>
  <span *ngIf="viewMode != ViewMode.View" class="toolbar-separator"></span>

  <!-- SAVE -->
  <button *ngIf="viewMode != ViewMode.View && !hideOperations" type="button" class="btn toolbar-item" [disabled]="formGroup && (!formGroup.dirty || !formGroup.valid)" (click)="save.emit()">
    <fa-icon icon="save"></fa-icon>
    <span>{{constantHelper.LBL_ACTION_COMMON_SAVE | translate}}</span>
  </button>
  <!--<button *ngIf="viewMode != ViewMode.New && !deleteAction" type="button" class="btn toolbar-item" (click)="delete.emit()">
    <fa-icon icon="trash-alt"></fa-icon>
    <span>{{'action.Common.Delete' | translate}}</span>
  </button>
  <button *ngIf="deleteAction && deleteAction.show()" type="button" class="btn toolbar-item" (click)="deleteAction.callback()">
    <fa-icon icon="{{deleteAction.icon}}"></fa-icon>
    <span>{{deleteAction.resourceKey | translate}}</span>
  </button>-->

  <!-- CANCEL -->
  <button *ngIf="viewMode != ViewMode.View && !hideOperations" type="button" class="btn toolbar-item" [disabled]="formGroup && !formGroup.dirty" (click)="cancel.emit()">
    <fa-icon icon="times"></fa-icon>
    <span>{{constantHelper.LBL_ACTION_COMMON_CANCEL | translate}}</span>
  </button>
  <span *ngIf="actions.length > 0" class="toolbar-separator"></span>

  <!-- Custom actions -->
  <ng-container *ngFor="let action of actions | filterBy">
    <button type="button" class="btn toolbar-item" (click)="action.callback()">
      <fa-icon icon="{{action.icon}}"></fa-icon>
      <span>{{action.resourceKey | translate}}</span>
    </button>
    <span *ngIf="action.separatorNext" class="toolbar-separator"></span>
  </ng-container>
</div>