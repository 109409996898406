
<div class="dialog-body">
  <div class="my-2">
    <app-dumpster-search [items]="data?.allDumpsters" [(filteredItems)]="filteredAllDumpsters" [labels]="data?.allLabels"></app-dumpster-search>
  </div>
  <div class="my-2">
    <span>{{ constantHelper.LBL_PAGE_DUMPSTERS_NUMBEROFFILTERED | translate:{'0': filteredAllDumpsters ? filteredAllDumpsters.length : '0', '1': data?.allDumpsters ? data?.allDumpsters.length : '0', '2': selectedDumpstersToAdd?.length} }}</span>
  </div>

  <kendo-tabstrip class="dialog-grid-body" (tabSelect)="onTabChange($event)" [keepTabContent]="true">
    <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_DUMPSTERS_TAB_LIST | translate}}" [selected]="true">
      <ng-template kendoTabContent>
        <app-dumpster-grid [items]="filteredAllDumpsters" [(selectedDumpsters)]="selectedDumpstersToAdd"></app-dumpster-grid>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_DUMPSTERS_TAB_MAP | translate }}">
      <ng-template kendoTabContent *loadOnDemand>
        <app-dumpster-map [items]="filteredAllDumpsters" [(selectedDumpsters)]="selectedDumpstersToAdd"></app-dumpster-map>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>

<div class="dialog-footer">
  <button type="button" class="btn btn-outline-secondary mt-2" (click)="backButtonClick()">
    <fa-icon icon="chevron-left"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_CANCEL | translate }}
  </button>
  <button type="button" class="btn btn-primary float-right mt-2" [disabled]="selectedDumpstersToAdd.length == 0" (click)="saveButtonClick()">
    <fa-icon icon="save"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_SAVE | translate }}
  </button>
</div>
