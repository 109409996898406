import { Component } from '@angular/core';
import { BeanDialogComponentBase, IDialogInput } from 'bean-kendo-dialog';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { ActiveDirectoryUser } from '../../models/active-directory';

@Component({
  selector: 'app-user-select-dialog',
  templateUrl: './user-select-dialog.component.html',
  styleUrls: ['./user-select-dialog.component.scss']
})
export class UserSelectDialogComponent extends BeanDialogComponentBase<UserSelectDialogInput> {

  public data: UserSelectDialogInput;

  public filteredAllUsers: ActiveDirectoryUser[] = [];
  public selectedUsersToAdd: ActiveDirectoryUser[] = [];

  constructor(
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper
  ) {
    super();

    this.library.addIcons(faChevronLeft, faSave);
  }

  ngAfterViewInitCallback(): void {
    this.filteredAllUsers = this.data.allUsers;
  }

  cancel(e: any): boolean {
    return true;
  }

  back(e: any): boolean {
    return true;
  }

  save(e: any): Observable<ActiveDirectoryUser[]> {
    return of(this.selectedUsersToAdd);
  }

}

export class UserSelectDialogInput implements IDialogInput {
  constructor(public allUsers: ActiveDirectoryUser[], public isUsersListReadonly: boolean = false) { }
}
