import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeactivatedComponent } from '@veritas-shared/components/deactivated.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViewMode } from '@veritas-shared/models/view-mode';
import { ToastService } from '@veritas-shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave, faDumpster } from '@fortawesome/free-solid-svg-icons';
import { ViewModeHelper } from '@veritas-shared/helpers/view-mode-helper';
import { SecurityService } from '@veritas-shared/services/security.service';
import { SessionService } from '@veritas-shared/services/session.service';
import { CommandTaskService } from '../../services/commandtask.service';
import { EditCommandTaskInput } from '../../models/edit-commandtask-input';
import { NewCommandTaskInput } from '../../models/new-commandtask-input';
import { TriggerType } from '../../models/triggetype.enum';
import { NewCommandTaskOutputCommand, NewCommandTaskOutputParameterValue, NewCommandTaskOutputCommandTask, NewCommandTaskOutputTriggerType } from '../../models/new-commandtask-output';
import { EditCommandTaskOutputCommand, EditCommandTaskOutputParameterValue, EditCommandTaskOutputCommandTask, EditCommandTaskOutputTriggerType } from '../../models/edit-commandtask-output';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-commandtask-detail',
  templateUrl: './commandtask-detail.component.html',
  styleUrls: ['./commandtask-detail.component.scss']
})
export class CommandTaskDetailComponent extends DeactivatedComponent implements OnInit, OnDestroy {

  public formCommandTask: FormGroup;

  public actions: Action[];
  public toolbarActions: Action[] = [];

  public viewMode: ViewMode;
  public ViewMode = ViewMode;

  public TriggerType = TriggerType;
  public triggerType: TriggerType;

  private commandTaskId: number;

  public commandTask: NewCommandTaskOutputCommandTask | EditCommandTaskOutputCommandTask;
  private allCommands: NewCommandTaskOutputCommand[] | EditCommandTaskOutputCommand[];
  public commands: NewCommandTaskOutputCommand[] | EditCommandTaskOutputCommand[];
  public triggerTypes: NewCommandTaskOutputTriggerType[] | EditCommandTaskOutputTriggerType[];
  public parameterValues: NewCommandTaskOutputParameterValue[] | EditCommandTaskOutputParameterValue[];

  public selectedDevices: Dumpster[] = [];

  private querySubscription: Subscription;

  constructor(
    private commandTaskService: CommandTaskService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private translate: TranslateService,
    private securityService: SecurityService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper
  ) {

    super();

    this.onEdit = this.onEdit.bind(this);
    this.onView = this.onView.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);

    //this.add = this.add.bind(this);
    //this.delete = this.delete.bind(this);
    //this.deleteBulk = this.deleteBulk.bind(this);

    this.library.addIcons(faSave, faChevronLeft, faDumpster);

    //this.actions = [
    //  new GridAction('edit', 'action.Common.Edit', 'pencil-alt', this.edit),
    //  new GridAction('delete', 'action.Common.Delete', 'trash-alt', this.delete)
    //];

    //this.toolbarActions = [
    //  new GridAction('add', 'action.Common.Add', 'plus', this.add)
    //];

    // Params
    this.route.params.subscribe(params => {
      if (params) {
        this.commandTaskId = +params.id;
      }
    });

    // Query Params
    this.querySubscription = combineLatest(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
      ),
      this.route.queryParams
    ).subscribe(res => {

      // Set ViewMode
      this.viewMode = ViewModeHelper.getViewMode(this.route);

      // Enalbe / Disable the form
      if (this.formCommandTask) {
        let newForm = this.setFormGroup();
        this.commonHelper.enableValidFormFields(this.formCommandTask, newForm);
      }

      // Set PageTitle
      if (this.viewMode == ViewMode.View && this.commandTask) {
        this.sessionService.setPageTitle(this.commandTask.name);
      }
    });

  }

  canDeactivate(): boolean {
    return !this.formCommandTask.dirty;
  }

  ngOnInit() {
    this.formCommandTask = this.setFormGroup();

    switch (this.viewMode) {
      case ViewMode.Edit:
      case ViewMode.View:
        this.commandTaskService.getEditCommandTask(this.commandTaskId).subscribe(result => {
          this.commandTask = result.commandTask;
          this.sessionService.setPageTitle(result.commandTask.name);
          this.formCommandTask.patchValue(result.commandTask);
          this.commands = result.commands;
          this.allCommands = result.commands;
          this.triggerTypes = result.triggerTypes
          this.parameterValues = result.commandTask.parameterValues
        });
        break;
      case ViewMode.New:
        this.commandTaskService.getNewCommandTask().subscribe(result => {
          this.formCommandTask.patchValue(result.commandTask);
          this.commands = result.commands;
          this.allCommands = result.commands;
          this.triggerTypes = result.triggerTypes
          this.parameterValues = result.commandTask.parameterValues
        });
        break;
    }
  }

  ngOnDestroy() {
    if (this.querySubscription != null) {
      this.querySubscription.unsubscribe();
    }
  }

  private setFormGroup() {
    return this.fb.group({
      id: [''],
      commandId: [{ value: '', disabled: this.viewMode != ViewMode.New }, Validators.required],
      name: [{ value: '', disabled: this.viewMode != ViewMode.New }, Validators.required],
      description: [{ value: '', disabled: this.viewMode == ViewMode.View }],
      enabled: [{ value: '', disabled: this.viewMode == ViewMode.View }],
      triggerType: [{ value: '', disabled: this.viewMode == ViewMode.View }],
      plannedAt: [{ value: '', disabled: this.viewMode == ViewMode.View }],
      recurEvery: [{ value: '', disabled: this.viewMode == ViewMode.View }]
    });
  }

  public onEdit() {
    this.router.navigate([], { queryParams: { edit: true }, relativeTo: this.route });
  }

  public onView() {
    this.router.navigate([], { relativeTo: this.route });
  }

  public onBack() {
    this.goBack();
  }

  public onCancel() {
    this.formCommandTask.reset(this.commandTask);
  }

  public onDelete() {
    this.commandTaskService.deleteCommandTask(this.commandTaskId).subscribe(result => {
      this.toastService.showSuccess(
        this.translate.instant('page.CommandTask.Dialog.Delete.ConfirmationMessage', { '0': 1 })
      );
      this.goBack();
    });
  }

  public goBack() {
    this.router.navigate(['commands', 'tasks']);
  }

  public onSave() {
    if (this.formCommandTask.valid) {
      if (this.viewMode === ViewMode.Edit) {
        const commandInput = <EditCommandTaskInput>this.formCommandTask.getRawValue();
        commandInput.dumpsters = [1, 2, 3, 4, 6]; // TODO TEMPORARY!!!
        this.commandTaskService.updateCommandTask(commandInput).subscribe(() => {
          this.formCommandTask.reset();
          this.goBack();
        });
      } else if (this.viewMode === ViewMode.New) {
        const commandInput = <NewCommandTaskInput>this.formCommandTask.getRawValue();
        commandInput.dumpsters = [1, 2, 3, 4, 6]; // TODO TEMPORARY!!!
        this.commandTaskService.addCommandTask(commandInput).subscribe(() => {
          this.formCommandTask.reset();
          this.goBack();
        });
      }

    }
  }

  public handleCommandFilter(value: string) {
    this.commands = this.allCommands.filter((s) => s.resourceKey.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
}
