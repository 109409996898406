<h1 class="page-title" *ngIf="viewMode != ViewMode.New"><fa-icon icon="crosshairs"></fa-icon>{{ collectionPoint?.name }}</h1>
<h1 class="page-title" *ngIf="viewMode == ViewMode.New"><fa-icon icon="crosshairs"></fa-icon>{{ 'page.CollectionPointNew.Title' | translate }}</h1>
<app-toolbar [formGroup]="formCollectionPoint" [viewMode]="viewMode" (view)="onView()" (back)="onBack()" (edit)="onEdit()" (delete)="onDelete()" (save)="onSave()" (cancel)="onCancel()" [hideOperations]="!hasWritePermission"></app-toolbar>

<div class="page-content">

  <!-- GENERAL -->
  <div class="mt-10">
    <app-group-box pageTitle="{{ 'page.CollectionPointDetail.Section.General.Title' | translate }}">
      <form [formGroup]="formCollectionPoint" class="box-standard-padding" novalidate>

        <!-- -->
        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ 'vocabulary.CollectionPoint.CollectionPointCode' | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="collectionPointCode">
          </div>
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ 'common.Name' | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="name">
          </div>
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ 'common.Description' | translate }}</label>
          <div class="col-xl-3 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="description">
          </div>
        </div>

        <!-- -->
        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ 'vocabulary.CollectionPoint.Country' | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="country">
          </div>
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ 'vocabulary.CollectionPoint.Province' | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="province">
          </div>
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ 'vocabulary.CollectionPoint.City' | translate }}</label>
          <div class="col-xl-3 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="city">
          </div>
        </div>

        <!-- -->
        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ 'vocabulary.CollectionPoint.Street' | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="street">
          </div>
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ 'vocabulary.CollectionPoint.StreetNumber' | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="streetNumber">
          </div>
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ 'vocabulary.CollectionPoint.Note' | translate }}</label>
          <div class="col-xl-3 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="note">
          </div>
        </div>

        <!-- -->
        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ 'vocabulary.Location.Latitude' | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="latitude">
          </div>
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ 'vocabulary.Location.Longitude' | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="longitude">
          </div>
        </div>
      </form>
    </app-group-box>
  </div>

  <!-- MAP -->
  <div class="">
    <div class="row">

      <!-- MAP -->
      <div class="col-12 status-map-container">
        <app-group-box pageTitle="{{ 'page.CollectionPointDetail.Section.Map.Title' | translate }}">
          <div class="map-container">
            <app-collectionpoint-map [mode]="'single'" [items]="collectionPoints" [(selectedCollectionPoints)]="selectedCollectionPoints"></app-collectionpoint-map>
          </div>
        </app-group-box>
      </div>
    </div>
  </div>

  <!-- DUMPSTERS -->
  <div class="">
    <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_COLL_POINT_DETAIL_DUMPSTER_TITLE | translate }}">
      <div class="box-standard-padding dumpsters-data">
        <kendo-tabstrip (tabSelect)="onTabChange($event)" [keepTabContent]="true">
          <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_DUMPSTERS_TAB_LIST | translate }}" [selected]="true">
            <ng-template kendoTabContent>
              <div class="grid-container">
                <app-dumpster-grid [items]="collectionPointDumpsters" [(selectedDumpsters)]="selectedDumpsters" [readonly]="isDumpstersListReadonly" [actions]="actions" [toolbarActions]="toolbarActions"></app-dumpster-grid>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_DUMPSTERS_TAB_MAP | translate }}">
            <ng-template kendoTabContent *loadOnDemand>
              <div class="map-container">
                <app-dumpster-map [items]="collectionPointDumpsters" [(selectedDumpsters)]="selectedDumpsters"></app-dumpster-map>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
    </app-group-box>
  </div>

  <!-- AUDITING -->
  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-auditing-box [item]="collectionPoint"></app-auditing-box>
  </div>

  <!-- GROUPS -->
  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-info-sequence title="{{ 'page.CollectionPointDetail.Section.AuthorizationGroups.Title' | translate }}"
                       [items]="groups"
                       (onClick)="goToGroupDetail($event)">
    </app-info-sequence>
  </div>
</div>

<ng-template windowVisualizer></ng-template>
