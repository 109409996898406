
<app-custom-grid [columns]="columns"
                 [showActions]="true"
                 [disableActions]="readonlyData"
                 [actions]="actions"
                 [toolbarActions]="toolbarActions"
                 [selectable]="selectableSettings"
                 [showSelectAll]="true"
                 [selectedKeys]="selectedUsersId"
                 [selectCallback]="onSelectionChange"
                 [pageable]="true"
                 [pageSizes]="true"
                 [filterable]="true"
                 [defaultFilter]="defaultFilter"
                 [items]="items">
</app-custom-grid>
