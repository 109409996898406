import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@veritas-shared/services/api.service';
import { ResourcesOutput } from '../models/resources-output';
import { ToastService } from "@veritas-shared/services/toast.service";
import { WaitService } from "@veritas-shared/services/wait.service";
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from "@veritas-shared/helpers/common.helper";

@Injectable({
  providedIn: 'root'
})
export class ResourceService extends ApiService {

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, toastService: ToastService, waitService: WaitService, commonHelper: CommonHelper, newToastService: BeanToastService) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  public getResources(lang: string): Observable<any> {
    return this.getBase<ResourcesOutput>('api/resource').pipe(map(result => result.resources));
  }
}   
