export class ImportResultOutput {
    validRows: number;
    totalRows: number;
    rows: ImportResultOutputRow[];

  constructor(validRows: number, totalRows: number, rows: ImportResultOutputRow[]) {
        this.validRows = validRows;
        this.totalRows = totalRows;
        this.rows = rows;
    }
}

export class ImportResultOutputRow {
  row: number | null;
  type: ImportResultOutputMessage;
  typeDescription: string
  message: string;

  constructor() {
  }

}

export enum ImportResultOutputMessage {
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error'
}
