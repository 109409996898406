<h1 class="page-title" *ngIf="viewMode != ViewMode.New"><fa-icon icon="users"></fa-icon>{{ group?.name }}</h1>
<h1 class="page-title" *ngIf="viewMode == ViewMode.New"><fa-icon icon="users"></fa-icon>{{ constantHelper.LBL_PAGE_AUTHGROUP_NEW_TITLE | translate }}</h1>
<app-toolbar [formGroup]="formGroup" [viewMode]="viewMode" (view)="onView()" (back)="onBack()" (edit)="onEdit()" (delete)="onDelete()" (save)="onSave()" (cancel)="onCancel()" [hideOperations]="!hasWritePermission"></app-toolbar>

<div class="page-content">

  <!-- GENERAL-->
  <div class="mt-10">
    <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_GENERAL_TITLE | translate }}">
      <form [formGroup]="formGroup" class="box-standard-padding" novalidate>
        <div class="row">
          <label class="col-xl-2 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_COMMON_NAME | translate}}">{{ constantHelper.LBL_COMMON_NAME | translate}}</label>
          <div class="col-xl-6 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="name" id="inputName">
          </div>
        </div>
        <div class="row">
          <label class="col-xl-2 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_COMMON_DESCRIPTION | translate}}">{{ constantHelper.LBL_COMMON_DESCRIPTION | translate}}</label>
          <div class="col-xl-6 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="description">
          </div>
        </div>
        <div class="row">
          <label class="col-xl-2 col-md-2 col-12 col-form-label" title="Mailing List">Mailing List</label>
          <div class="col-xl-6 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="mailingList" id="mailingList" title="Inserire una o più mail valide separate da ;">
          </div>
        </div>
        <div class="row" *ngIf="groupTypeVisible">
          <label class="col-xl-2 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_AUTHGROUP_TYPE | translate}}">{{ constantHelper.LBL_VOCABULARY_AUTHGROUP_TYPE | translate}}</label>
          <div class="col-xl-6 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="groupTypeDescription">
          </div>
        </div>
      </form>
    </app-group-box>
  </div>

  <!-- DUMPSTERS -->
  <div class="">
    <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DUMPSTERS_TITLE | translate }}">
      <div class="box-standard-padding dumpsters-data">
        <kendo-tabstrip (tabSelect)="onTabChange($event)" [keepTabContent]="true">
          <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_DUMPSTERS_TAB_LIST | translate }}" [selected]="true">
            <ng-template kendoTabContent>
              <div class="tab-body">
                <app-dumpster-grid [items]="groupDumpsters"
                                   [(selectedDumpsters)]="selectedDumpsters"
                                   [selectableCheckboxSettings]="selectableCheckboxSettings"
                                   [checkboxOnly]="true"
                                   [readonly]="isDumpstersListReadonly"
                                   [actions]="actions"
                                   [toolbarActions]="toolbarActions">
                </app-dumpster-grid>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_DUMPSTERS_TAB_MAP | translate }}">
            <ng-template kendoTabContent *loadOnDemand>
              <div class="tab-body">
                <app-dumpster-map
                  [items]="groupDumpsters"
                  [markerCustom]="getIconMap"
                  [(selectedDumpsters)]="selectedDumpsters"
                ></app-dumpster-map>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
    </app-group-box>
  </div>

  <!-- AUDITING -->
  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-auditing-box [item]="group"></app-auditing-box>
  </div>

  <!-- AUTHORIZATIONS -->
  <div class="" *ngIfPermission="'constantHelper.LBL_PERMISSION_DUMPSTER_WRITE'">
    <app-info-sequence title="{{ constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_AUTHORIZATIONS_TITLE | translate }}"
                       [items]="groupAuthorizations"
                       (onClick)="goToAuthorizationDetail($event)"> 
    </app-info-sequence>
  </div>

</div>

<ng-template windowVisualizer></ng-template>
