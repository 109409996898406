import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserService } from '@veritas-core/services/user.service';
import { SessionService } from './shared/services/session.service';
import { WaitService } from './shared/services/wait.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { version } from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isMenuOpen: boolean = true;
  public isWaitVisible: boolean = false;
  private loggedIn = false;
  private readonly _destroying$ = new Subject<void>();

  private waitSubscription: Subscription;

  public guiVersion: string = version;

  constructor(
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private waitService: WaitService,
    private userService: UserService,
    private sessionService: SessionService,
    private translateService: TranslateService
  ) {

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('it');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use('it');

    this.waitSubscription = this.waitService.getWait().subscribe(show => {
      // Fix https://stackoverflow.com/questions/34364880/expression-has-changed-after-it-was-checked
      setTimeout(() => {
        this.isWaitVisible = show;
      }, 1);
    });
  }

  ngOnInit(): void {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkoutAccount();
      });
  }

  ngOnDestroy(): void {
    if (this.waitSubscription != undefined) {
      this.waitSubscription.unsubscribe();
    }

    this._destroying$.next(null);
    this._destroying$.complete();
  }

  checkoutAccount() {
    this.loggedIn = !!this.authService.instance.getActiveAccount();

    if (!this.loggedIn && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);

      this.loggedIn = true;
    }

    if (this.loggedIn) {

      // User Menu and User Profile
      this.sessionService.updateUserRoles();

      this.userService.getUserProfile().subscribe(result => {
        this.sessionService.setUserProfile(result);
      },
        error => {
          console.log(error);
          localStorage.clear();
          //this.authService.loginRedirect();
        });
    }
  }
}
