import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, Router } from '@angular/router';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {

  constructor(private securityService: SecurityService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // User authenticated
    let roles = route.data.roles;
    let permissions = route.data.permissions;
    if (roles && roles.length > 0) {
      return this.securityService.userInRoles(roles).pipe();
    }
    else if (permissions && permissions.length > 0) {
      return this.securityService.userHasPermissions(permissions).pipe();
    } else {
      return true;
    }
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    let roles = route.data.roles;
    if (roles && roles.length > 0) {
      return this.securityService.userInRoles(roles).pipe();
    }
    else {
      return true;
    }
  }

}
