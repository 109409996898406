import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSignOutAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { UserProfileOutput } from '@veritas-shared/models/user-profile-output';
import { SessionService } from '@veritas-shared/services/session.service';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({ opacity: 1, transform: 'translateX(-300px)' })),
      state('close', style({ opacity: 0, transform: 'translateX(0)' })),
      transition('* => *', animate(200))
    ])]
})
export class UserPanelComponent {

  @ViewChild('userPanel', { static: true }) public panel: ElementRef;

  public isOpen = false;

  public userProfile: UserProfileOutput;

  constructor(private sessionService: SessionService, private authService: MsalService, private library: FaIconLibrary) {

    library.addIcons(faTimes, faSignOutAlt);

    this.sessionService.userProfile.subscribe(userProfile => {
      this.userProfile = userProfile;
    });
  }

  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }

  public logout() {
    localStorage.clear();
    this.authService.logout();
    return false;
  }

}
