<nav aria-label="breadcrumb">
  <ol class="breadcrumb border-bottom">

    <li *ngFor="let breadcrumb of breadcrumbs; last as isLast;"
        class="breadcrumb-item"
        [ngClass]="{'active': isLast}" aria-current="page">

      <a *ngIf="!isLast; else lastRoute"
         [routerLink]="[breadcrumb.url]"
         routerLinkActive="active">
        {{ breadcrumb.resourceKey | translate }}
      </a>

      <ng-template #lastRoute>{{ pageTitle | translate }}</ng-template>

    </li>

  </ol>
</nav>
