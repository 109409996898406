import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faKey, faSave } from '@fortawesome/free-solid-svg-icons';
import { ViewModeHelper } from '@veritas-shared/helpers/view-mode-helper';
import { ViewMode } from '@veritas-shared/models/view-mode';
import { ToastService } from '@veritas-shared/services/toast.service';
import { EditAuthorizationInput } from '../../models/edit-authorization-input';
import { NewAuthorizationInput } from '../../models/new-authorization-input';
import { AuthorizationService } from '../../services/authorization.service';
import { DeactivatedComponent } from '@veritas-shared/components/deactivated.component';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@veritas-shared/services/session.service';
import { ActiveDirectoryUser } from '../../models/active-directory';
import { Role } from '../../models/role';
import { Group } from '../../../group/models/group';
import { Action } from '@veritas-shared/models/action';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { EditAuthUsersInput } from '../../models/edit-authorization-users-input';
import { BeanKendoDialogService, WindowContainerDimension, WindowVisualizerDirective } from 'bean-kendo-dialog';
import { UserSelectDialogComponent, UserSelectDialogInput } from '../../components/user-select-dialog/user-select-dialog.component';
import { BeanDialogDimension } from 'bean-kendo-dialog/lib/classes/bean-dialog-dimension.class';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Authorization } from '../../models/edit-authorization-output';
import { combineLatest, Subscription } from 'rxjs';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { SecurityService } from '@veritas-shared/services/security.service';
import { UserService } from '@veritas-core/services/user.service';
import { NavigationService } from '@veritas-shared/services/navigation.service';

@Component({
  selector: 'app-authorization-detail',
  templateUrl: './authorization-detail.component.html',
  styleUrls: ['./authorization-detail.component.scss']
})
export class AuthorizationDetailComponent extends DeactivatedComponent implements OnInit, OnDestroy {

  @ViewChild(WindowVisualizerDirective, { static: true }) public windowVisualizer: WindowVisualizerDirective;

  public hasWritePermission: boolean;
  public viewMode: ViewMode;
  public ViewMode = ViewMode;

  public formAuthorization: FormGroup;
  private authorizationId: number;
  public authorization: Authorization;

  private allRoles: Role[];
  public roles: Role[];

  // AD
  public authUsers: ActiveDirectoryUser[] = [];
  public selectedUsers: ActiveDirectoryUser[] = [];

  public isUsersListReadonly: boolean = false;

  private allGroups: Group[];
  public groups: Group[];
  public groupVisible = true;
  private originalGroupVisible = true;

  public usersToolbarActions: Action[] = [];

  private querySubscription: Subscription;

  constructor(
    private securityService: SecurityService,
    private authorizationService: AuthorizationService,
    private userService: UserService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private sessionService: SessionService,
    private translate: TranslateService,
    private dialogService: BeanKendoDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper,
    private navigationService: NavigationService
  ) {
    super();

    this.addUsers = this.addUsers.bind(this);
    this.deleteBulk = this.deleteBulk.bind(this);

    this.onEdit = this.onEdit.bind(this);
    this.onView = this.onView.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.library.addIcons(faSave, faChevronLeft, faKey);

    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_AUTHORIZATION_WRITE).subscribe(res => {
      this.hasWritePermission = res;

      if (res) {
        this.usersToolbarActions = [
          new Action('add', this.constantHelper.LBL_ACTION_AUTH_ADD_USER, 'plus', this.addUsers, null),
          new Action('delete', this.constantHelper.LBL_ACTION_AUTH_REMOVE_USER, 'trash-alt', this.deleteBulk, true)
        ];
      }
    });

    // Params
    this.route.params.subscribe(params => {
      if (params) {
        this.authorizationId = +params.authorizationId;
      }
    });

    // Query Params
    this.querySubscription = combineLatest(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
      ),
      this.route.queryParams
    ).subscribe(res => {

      // Set ViewMode
      this.viewMode = ViewModeHelper.getViewMode(this.route, 'authorizationId');
      this.isUsersListReadonly = (this.viewMode === ViewMode.View) || (this.viewMode === ViewMode.New);

      // Enalbe / Disable the form
      if (this.formAuthorization) {
        const newForm = this.setFormGroup();
        this.commonHelper.enableValidFormFields(this.formAuthorization, newForm);
      }

      // Set PageTitle
      if (this.viewMode == ViewMode.View && this.authorization) {
        this.sessionService.setPageTitle(this.authorization.name);
      }
    });
  }

  canDeactivate(): boolean {
    return !this.formAuthorization.dirty;
  }

  ngOnInit() {

    this.formAuthorization = this.setFormGroup();

    switch (this.viewMode) {
      case ViewMode.Edit:
      case ViewMode.View:
        this.authorizationService.getEditAuthorization(this.authorizationId).subscribe(result => {

          // External Set
          this.sessionService.setPageTitle(result.authorization.name);
          this.formAuthorization.patchValue(result.authorization);

          // Set Results
          this.authorization = result.authorization;
          this.allRoles = result.roles;
          this.roles = result.roles;
          this.allGroups = result.groups;
          this.groups = result.groups;

          this.authUsers = result.users;

          this.setGroupSelect(result.authorization.roleId, false);
        });
        break;
      case ViewMode.New:
        this.authorizationService.getNewAuthorization().subscribe(result => {
          this.formAuthorization.patchValue(result.authorization);

          //
          this.allRoles = result.roles;
          this.roles = result.roles;
          this.allGroups = result.groups;
          this.groups = result.groups;
        });
        break;
    }
  }

  ngOnDestroy() {
    if (this.querySubscription != null) {
      this.querySubscription.unsubscribe();
    }
  }

  private setFormGroup() {
    return this.fb.group({
      id: [''],
      name: [{ value: '', disabled: this.viewMode != ViewMode.New }, Validators.required],
      description: [{ value: '', disabled: this.viewMode == ViewMode.View }, null],
      roleId: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
      groupId: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
      isEnabled: [{ value: '', disabled: this.viewMode == ViewMode.View }]
    });
  }

  public onEdit() {
    this.router.navigate([], { queryParams: { edit: true }, relativeTo: this.route });
  }

  public onView() {
    this.router.navigate([], { relativeTo: this.route });
  }

  public onBack() {
    this.goToList();
  }

  public onDelete() {
    this.authorizationService.deleteAuthorization(this.authorizationId).subscribe(result => {
      this.toastService.showSuccess(
        this.translate.instant('page.AuthorizationDetail.Dialog.Delete.ConfirmationMessage', { '0': 1 })
      );
      this.goToList();
    });
  }

  public onCancel() {
    this.formAuthorization.reset(this.authorization);

    this.groupVisible = this.commonHelper.clone(this.originalGroupVisible);
  }

  public onSave() {

    if (this.formAuthorization.valid) {

      if (this.viewMode == ViewMode.Edit) {
        const authorizationInput = <EditAuthorizationInput>this.formAuthorization.getRawValue();
        this.authorizationService.updateAuthorization(authorizationInput).subscribe(result => {
          this.formAuthorization.reset();
          this.goToList();
        });
      }
      else if (this.viewMode == ViewMode.New) {
        const authorizationInput = <NewAuthorizationInput>this.formAuthorization.getRawValue();
        this.authorizationService.addAuthorization(authorizationInput).subscribe(result => {
          this.formAuthorization.reset();
          this.goToList();
        });
      }
    }
  }

  public handleGroupFilter(value: string) {
    this.groups = this.allGroups.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public handleRoleFilter(value: string) {
    this.roles = this.allRoles.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public onRoleSelectChange(roleId: number) {
    this.setGroupSelect(roleId, true);
  }

  public goToList() {
    this.navigationService.back('/administration/authorizations');
  }

  // Users
  private addUsers() {

    this.authorizationService.getActiveDirectoryUsers().subscribe(res => {

      const userIds = this.authUsers?.map(d => d.id) ?? [];
      var allOtherUsers = res.filter(d => userIds.indexOf(d.id) == -1);

      this.dialogService.openDialog(
        this.translate.instant(this.constantHelper.LBL_PAGE_AUTH_DETAIL_DIALOG_SEL_USERS_TITLE),
        new UserSelectDialogInput(allOtherUsers, this.isUsersListReadonly),
        UserSelectDialogComponent,
        this.windowVisualizer,
        () => { },
        (res: ActiveDirectoryUser[]) => {

          const addedUserIds = res.map(d => d.id);
          const input = new EditAuthUsersInput(this.authorizationId, addedUserIds);

          return this.authorizationService.addUsers(input).pipe(map(() => {
            this.toastService.showSuccess(
              this.translate.instant(this.constantHelper.LBL_PAGE_AUTH_DETAIL_ADDUSERS_CONFIRM_MESSAGE, { '0': addedUserIds.length })
            );

            this.updatePermissionAndUsers();
          }));
        },
        true,
        WindowContainerDimension.Medium,
        {
          mediumHeight: 600,
          mediumWidth: 1000,
        } as BeanDialogDimension
      );
    })
  }

  private deleteBulk() {
    if (this.selectedUsers.length > 0) {
      const toRemove = new EditAuthUsersInput(this.authorizationId, this.selectedUsers.map(d => d.id));
      this.authorizationService.removeUsers(toRemove).subscribe(() => {
        this.toastService.showSuccess(
          this.translate.instant(this.constantHelper.LBL_PAGE_AUTH_DETAIL_DELETEUSERS_CONFIRM_MESSAGE, { '0': this.selectedUsers.length })
        );

        this.updatePermissionAndUsers();
      });
    }
  }

  /**
   * Update the Menù and the userProfile in session. Get the users if the user got the permission to read, otherwise go to home
   */
  private updatePermissionAndUsers() {

    // Emit to changeUserRoles
    this.sessionService.updateUserRoles();
    this.userService.getUserProfile().subscribe(result => {
      this.sessionService.setUserProfile(result);

      // If (after delete) the user doesn't have the authorization, go to Home
      this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_AUTHORIZATION_READ).subscribe(res => {

        // Reload users or send to home
        if (res) {
          this.authorizationService.getUsers(this.authorizationId).subscribe(result => {
            this.authUsers = result.users;
          });
        } else {
          this.router.navigate(['']);
        }
      });
    });
  }

  private setGroupSelect(roleId: number, reset: boolean) {
    if (this.allRoles.find(x => x.id == roleId).isGlobal) {
      this.groupVisible = false;

      // Find global Group
      var globalGroup = this.allGroups.find(x => x.isGlobal);
      if (!globalGroup) {
        this.toastService.showError(this.translate.instant(this.constantHelper.LBL_PAGE_AUTH_DETAIL_NO_GLOBAL_GROUP_MESSAGE));
        this.formAuthorization.get('groupId').setValue(null);
        return;
      }

      this.formAuthorization.get('groupId').setValue(globalGroup.id);
    } else {
      this.groupVisible = true;
      if (reset) {
        this.formAuthorization.get('groupId').setValue(null);
      }
    }
    this.originalGroupVisible = this.commonHelper.clone(this.groupVisible);
  }

}
