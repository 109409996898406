import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@veritas-shared/services/toast.service';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { CollectionPointService } from '../../services/collectionpoint.service';
import { CollectionPointListReactivateCollectionPointsInput } from '../../models/collectionpoint-list-reactivate-collectionpoints-input';
import { CollectionPointGridComponent } from '@veritas-shared/components/collectionpoint-grid/collectionpoint-grid.component';
import { CollectionPointMapComponent } from '@veritas-shared/components/collectionpoint-map/collectionpoint-map.component';
import { CollectionPointListOutputCollectionPoint } from '@veritas-shared/models/collectionpoint-list-output';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { SecurityService } from '@veritas-shared/services/security.service';
import { NavigationService } from '@veritas-shared/services/navigation.service';

@Component({
  selector: 'app-collectionpoints-deactivated',
  templateUrl: './collectionpoints-deactivated.component.html',
  styleUrls: ['./collectionpoints-deactivated.component.scss']
})
export class CollectionPointsDeactivatedComponent implements OnInit {

  @ViewChild(CollectionPointGridComponent, { read: CollectionPointGridComponent }) collectionPointGrid: CollectionPointGridComponent;
  @ViewChild(CollectionPointMapComponent, { read: CollectionPointMapComponent }) collectionPointMap: CollectionPointMapComponent;

  public filteredCollectionPoints: CollectionPointListOutputCollectionPoint[];
  private _tempFilteredCollectionPoints: CollectionPointListOutputCollectionPoint[]; // used for temporary store collection points when show only selected is checked

  public collectionPoints: CollectionPointListOutputCollectionPoint[];
  public selectedCollectionPoints: CollectionPointListOutputCollectionPoint[] = [];

  public actions: Action[];
  public toolbarActions: Action[] = [];

  public isShowOnlySelected: boolean = false;

  constructor(
    private collectionPointService: CollectionPointService,
    private toastService: ToastService,
    private library: FaIconLibrary,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private securityService: SecurityService,
    private router: Router,
    public constantHelper: ConstantHelper,
    private navigationService: NavigationService
  ) {

    this.library.addIcons(faAngleDoubleLeft, faAngleDoubleRight);

    this.delete = this.delete.bind(this);
    this.reactivateBulk = this.reactivateBulk.bind(this);

    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_COLLECTION_POINT_WRITE).subscribe(result => {
      if (result) {
        this.toolbarActions = [
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DELETE, 'trash-alt', this.delete, true),
          new Action('reactivate', this.constantHelper.LBL_ACTION_COLLPOINT_REACTIVATE, 'trash-restore-alt', this.reactivateBulk, true),
        ];
      }
    });
  }

  ngOnInit() {
    this.loadData();
  }

  public onTabChange(event: SelectEvent) {
    if (event.index == 0) {
      this.collectionPointGrid.refreshSelection();
    }
    if (event.index == 1) {
      window.setTimeout(() => this.collectionPointMap.centerAndZoomOnMarkers(), 200);
    }
  }

  public onSearchChanged() {
    this.selectedCollectionPoints = [];
    this.isShowOnlySelected = false;
  }

  public toggleShowOnlySelected() {
    if (this.isShowOnlySelected) {
      this._tempFilteredCollectionPoints = this.filteredCollectionPoints;
      this.filteredCollectionPoints = this.selectedCollectionPoints;
      this.collectionPointGrid.goToPage(0);
    }
    else {
      this.filteredCollectionPoints = this._tempFilteredCollectionPoints;
      this._tempFilteredCollectionPoints = [];
    }
  }

  public delete() {
        // todo ????
  }

  public reactivateBulk() {
    if (this.selectedCollectionPoints.length > 0) {
      var collectionPointsToReactivate = new CollectionPointListReactivateCollectionPointsInput(this.selectedCollectionPoints.map(d => d.id));
      this.collectionPointService.reactivateCollectionPoints(collectionPointsToReactivate).subscribe(result => {
        this.toastService.showSuccess(
          this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DELETEDUMPSTERS_CONFIRM_MESSAGE, { '0': this.selectedCollectionPoints.length })
        );
        // Reload collection points
        this.collectionPointService.getDeactivatedCollectionPoints().subscribe(result => {
          this.collectionPoints = result.collectionPoints;
          this.filteredCollectionPoints = result.collectionPoints;
        });
      });
    }
  }


  public goBack() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private loadData() {
    this.collectionPointService.getDeactivatedCollectionPoints().subscribe(result => {
      this.collectionPoints = result.collectionPoints;
      this.filteredCollectionPoints = result.collectionPoints;
    });
  }
}
