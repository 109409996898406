<div class="dialog-body">
  <div class="register-body">
    <!-- Prima Riga -->
    <form [formGroup]="configForm" novalidate>
      <div class="row">
        <div class="col-6">
          <h5 style="color:#5AA8E3">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_CONFERMENT_TITLE | translate}}
          </h5>
          <div class="row">
            <label class="col-3 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LOW_LEVEL_PERCENTAGE |
              translate}}</label>
            <label class="col-3 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_HIGH_LEVEL_PERCENTAGE |
              translate}}</label>
            <label class="col-3 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_MAX_BOOKINGS |
              translate}}</label>
            <label class="col-3 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_TOGGLE |
              translate}}</label> 
          </div>
          <div class="row">
            <div class="col-3">
              <input type="number" step="1" class="form-control" formControlName="dumpsterFullLowLevel" min="0"
                [readonly]= "!this.dumpsterFullConfig.enabled || this.dumpsterFullConfig.lastForwardStatus.toLowerCase() === 'sent'" />

            </div>
            <div class="col-3">
              <input type="number" step="1" class="form-control" formControlName="dumpsterFullHighLevel" min="0"
                [readonly]="!this.dumpsterFullConfig.enabled || this.dumpsterFullConfig.lastForwardStatus.toLowerCase() === 'sent'" />
            </div>
            <div class="col-3">
              <input type="number" step="1" class="form-control" formControlName="dumpsterFullMaxBookings" min="0"
                [readonly]="!this.dumpsterFullConfig.enabled || this.dumpsterFullConfig.lastForwardStatus.toLowerCase() === 'sent'" />
            </div>
            <div class="col-3">
              <kendo-switch [(ngModel)]="this.dumpsterFullConfig.enabled" formControlName="dumpsterFullEnabled" [disabled]="this.dumpsterFullConfig.lastForwardStatus.toLowerCase() === 'sent'">
              </kendo-switch>
            </div>
          </div>
          <div class="separator1"></div>
          <div class="row">
            <div class="col-12">
              <label><b>Ultimo Invio:&nbsp;</b></label> <label *ngIf="this.dumpsterFullConfig.lastForwardStatus.toLowerCase() !== 'none'" [ngClass]="this.dumpsterFullConfig.lastForwardStatus.toLowerCase()">{{this.dumpsterFullConfig.forwardTimestamp | date: constantHelper.DATE_TIME_FORMAT}} - {{this.dumpsterFullConfig.lastForwardStatus | configurationStatusTranslate}} ({{this.dumpsterFullConfig.feedbackTimestamp | date: constantHelper.DATE_TIME_FORMAT}})</label>
            </div>
          </div>
        </div>
        <div class="col-6">
          <h5 style="color:#5AA8E3">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LAST_CONFERMENT_TITLE |
            translate}}</h5>
          <div class="row">
            <label class="col-4 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LOW_LEVEL |
              translate}}</label>
            <label class="col-4 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_HIGH_LEVEL |
              translate}}</label>
            <label class="col-4 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_TOGGLE |
              translate}}</label>
          </div>
          <div class="row">
            <div class="col-4">
              <input type="number" step="1" class="form-control" formControlName="lastBookingLowLevel" min="0"
                [readonly]="!this.lastBookingConfig.enabled || this.lastBookingConfig.lastForwardStatus.toLowerCase() === 'sent'" />
            </div>
            <div class="col-4">
              <input type="number" step="1" class="form-control" formControlName="lastBookingHighLevel" min="0"
                [readonly]="!this.lastBookingConfig.enabled || this.lastBookingConfig.lastForwardStatus.toLowerCase() === 'sent'" />
            </div>
            <div class="col-4">
              <kendo-switch [(ngModel)]="this.lastBookingConfig.enabled" formControlName="lastBookingEnabled" [disabled]="this.lastBookingConfig.lastForwardStatus.toLowerCase() === 'sent'">
              </kendo-switch>
            </div>
          </div>
          <div class="separator1"></div>
          <div class="row">
            <div class="col-12">
              <label><b>Ultimo Invio:&nbsp;</b></label> <label *ngIf="this.lastBookingConfig.lastForwardStatus.toLowerCase() !== 'none'" [ngClass]="this.lastBookingConfig.lastForwardStatus.toLowerCase()">{{this.lastBookingConfig.forwardTimestamp | date: constantHelper.DATE_TIME_FORMAT}} - {{this.lastBookingConfig.lastForwardStatus | configurationStatusTranslate}} ({{this.lastBookingConfig.feedbackTimestamp | date: constantHelper.DATE_TIME_FORMAT}})</label>
            </div>
          </div>
        </div>
      </div>
      <div class="separator"></div>
      <div class="row">
        <div class="col-6">
          <h5 style="color:#5AA8E3">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LAST_PASSAGE_TITLE |
            translate}}</h5>
          <div class="row">
            <label class="col-4 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LOW_LEVEL |
              translate}}</label>
            <label class="col-4 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_HIGH_LEVEL |
              translate}}</label>
            <label class="col-4 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_TOGGLE |
              translate}}</label>
          </div>
          <div class="row">
            <div class="col-4">
              <input type="number" step="1" class="form-control" formControlName="lastEmptyingLowLevel" min="0"
                [readonly]="!this.lastEmptyingConfig.enabled || this.lastEmptyingConfig.lastForwardStatus.toLowerCase() === 'sent'" />
            </div>
            <div class="col-4">
              <input type="number" step="1" class="form-control" formControlName="lastEmptyingHighLevel" min="0"
                [readonly]="!this.lastEmptyingConfig.enabled || this.lastEmptyingConfig.lastForwardStatus.toLowerCase() === 'sent'" />
            </div>
            <div class="col-4">
              <kendo-switch [(ngModel)]="this.lastEmptyingConfig.enabled" formControlName="lastEmptyingEnabled" [disabled]="this.lastEmptyingConfig.lastForwardStatus.toLowerCase() === 'sent'">
              </kendo-switch>
            </div>
          </div>
          <div class="separator1"></div>
          <div class="row">
            <div class="col-12">
              <label><b>Ultimo Invio:&nbsp;</b></label> <label *ngIf="this.lastEmptyingConfig.lastForwardStatus.toLowerCase() !== 'none'" [ngClass]="this.lastEmptyingConfig.lastForwardStatus.toLowerCase()">{{this.lastEmptyingConfig.forwardTimestamp | date: constantHelper.DATE_TIME_FORMAT}} - {{this.lastEmptyingConfig.lastForwardStatus | configurationStatusTranslate}} ({{this.lastEmptyingConfig.feedbackTimestamp | date: constantHelper.DATE_TIME_FORMAT}})</label>
            </div>
          </div>
        </div>
        <div class="col-6">
          <h5 style="color:#5AA8E3">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LAST_CONNECTION_TITLE |
            translate}}</h5>
          <div class="row">
            <label class="col-4 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LOW_LEVEL |
              translate}}</label>
            <label class="col-4 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_HIGH_LEVEL |
              translate}}</label>
            <label class="col-4 col-form-label">{{constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_TOGGLE |
              translate}}</label>
          </div>
          <div class="row">
            <div class="col-4">
              <input type="number" step="1" class="form-control" formControlName="lastConnectionLowLevel" min="0"
                [readonly]="!this.lastConnectionConfig.enabled || this.lastConnectionConfig.lastForwardStatus.toLowerCase() === 'sent'" />
            </div>
            <div class="col-4">
              <input type="number" step="1" class="form-control" formControlName="lastConnectionHighLevel" min="0"
                [readonly]="!this.lastConnectionConfig.enabled || this.lastConnectionConfig.lastForwardStatus.toLowerCase() === 'sent'" />
            </div>
            <div class="col-4">
              <kendo-switch [(ngModel)]="this.lastConnectionConfig.enabled" formControlName="lastConnectionEnabled" [disabled]="this.lastConnectionConfig.lastForwardStatus?.toLowerCase() === 'sent'">
              </kendo-switch>
            </div>
          </div>
          <div class="separator1"></div>
          <div class="row">
            <div class="col-12">
              <label><b>Ultimo Invio:&nbsp;</b></label> <label *ngIf="this.lastConnectionConfig.lastForwardStatus.toLowerCase() !== 'none'" [ngClass]="this.lastConnectionConfig.lastForwardStatus.toLowerCase() ">{{ this.lastConnectionConfig.forwardTimestamp | date: constantHelper.DATE_TIME_FORMAT}} - {{this.lastConnectionConfig.lastForwardStatus | configurationStatusTranslate}} ({{this.lastConnectionConfig.feedbackTimestamp | date: constantHelper.DATE_TIME_FORMAT}})</label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <button type="button" class="btn btn-outline-secondary mt-2" (click)="backButtonClick()">
      <fa-icon icon="chevron-left"></fa-icon>
      {{ constantHelper.LBL_ACTION_COMMON_CANCEL | translate }}
    </button>
    <button type="button" class="btn btn-primary float-right mt-2" [disabled]="!configForm.valid || checkAllConfigStatus() || !this.toggleSave " (click)="onSave()">
      <fa-icon icon="save"></fa-icon>
      {{ constantHelper.LBL_ACTION_COMMON_SAVE | translate }}
    </button>
  </div>