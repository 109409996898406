
<div class="row box-standard-padding">
  <div class="col-6 form-group"><span class="bold">Id:</span></div>
  <div class="col-6">{{ data?.event.id }}</div>

  <div class="col-6 form-group"><span class="bold">Data:</span></div>
  <div class="col-6">{{ data?.event.timestamp | date: constantHelper.DATE_TIME_FORMAT }}</div>

  <div class="col-6 form-group"><span class="bold">Servizio:</span></div>
  <div class="col-6">{{ data?.event.service }}</div>

  <div class="col-6 form-group"><span class="bold">Fornitore:</span></div>
  <div class="col-6">{{ data?.event.supplier }}</div>

  <div class="col-6 form-group"><span class="bold">Tipo:</span></div>
  <div class="col-6">{{ data?.event.payloadType | payloadType: data?.payloadTypes }}</div>

  <div class="col-6 form-group"><span class="bold">Versione:</span></div>
  <div class="col-6">{{ data?.event.version }}</div>

  <div class="col-4">
    <button type="button" class="btn btn-primary mt-2" (click)="downloadFile()">
      <fa-icon icon="file-export"></fa-icon>
      {{ constantHelper.LBL_PAGE_DEVICEDETAIL_EVENT_EXPORT_ACTION | translate }}
    </button>
  </div>
</div>
