import { Injectable } from '@angular/core';
import { IDumpsterRelation } from '@veritas-dumpsters/models/dumpster-relation.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RelationService {
  private _dumpsterRelation$ = new Subject<IDumpsterRelation>();
  public dumpsterRelation$ = this._dumpsterRelation$.asObservable();

  public setRelation = (dumpster: IDumpsterRelation) => this._dumpsterRelation$.next(dumpster);
}
