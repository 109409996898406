import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ToastService } from '@veritas-shared/services/toast.service';
import { WaitService } from '@veritas-shared/services/wait.service';
import { Observable } from 'rxjs';
import { ImportBehavior } from '@veritas-shared/models/import-behavior';
import { NewCollectionPointOutput } from '../../collectionpoints/models/new-collectionpoint-output';
import { NewCollectionPointInput } from '../../collectionpoints/models/new-collectionpoint-input';
import { EditCollectionPointOutput } from '../../collectionpoints/models/edit-collectionpoint-output';
import { EditCollectionPointInput } from '../../collectionpoints/models/edit-collectionpoint-input';
import { CollectionPointListDeactivateCollectionPointsInput } from '../../collectionpoints/models/collectionpoint-list-deactivate-collectionpoints-input';
import { CollectionPointListReactivateCollectionPointsInput } from '../../collectionpoints/models/collectionpoint-list-reactivate-collectionpoints-input';
import { CollectionPointDumpsterListOutput } from '../models/collectionpoint-dumpster-list-output';
import { EditCollectionPointAddDumpstersInput } from '../models/edit-collectionpoint-add-dumpsters-input';
import { EditCollectionPointRemoveDumpstersInput } from '../models/edit-collectionpoint-remove-dumpsters-input';
import { CollectionPointListOutput } from '@veritas-shared/models/collectionpoint-list-output';
import { ApiService } from '@veritas-shared/services/api.service';
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from '@veritas-shared/helpers/common.helper';

@Injectable({
  providedIn: 'root'
})
export class CollectionPointService extends ApiService {

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, toastService: ToastService, waitService: WaitService, commonHelper: CommonHelper, newToastService: BeanToastService) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  public getCollectionPoints(): Observable<CollectionPointListOutput> {
    return this.getBase<CollectionPointListOutput>('api/collectionpoint');
  }

  public getDeactivatedCollectionPoints(): Observable<CollectionPointListOutput> {
    return this.getBase<CollectionPointListOutput>('api/collectionpoint/deactivated');
  }

  public getNewCollectionPoint(): Observable<NewCollectionPointOutput> {
    return this.getBase<NewCollectionPointOutput>('api/collectionpoint/new');
  }

  public getEditCollectionPoint(id: number): Observable<EditCollectionPointOutput> {
    return this.getBase<EditCollectionPointOutput>('api/collectionpoint/' + id + '/edit');
  }

  public addCollectionPoint(collectionPoint: NewCollectionPointInput): Observable<void> {
    return this.postBase<NewCollectionPointInput, void>('api/collectionpoint', collectionPoint);
  }

  public updateCollectionPoint(collectionPoint: EditCollectionPointInput): Observable<void> {
    return this.putBase<EditCollectionPointInput, void>('api/collectionpoint', collectionPoint);
  }

  public reactivateCollectionPoints(collectionPoints: CollectionPointListReactivateCollectionPointsInput): Observable<void> {
    return this.putBase<CollectionPointListReactivateCollectionPointsInput, void>('api/collectionpoint/reactivate', collectionPoints);
  }

  public deactivateCollectionPoints(collectionPoints: CollectionPointListDeactivateCollectionPointsInput): Observable<void> {
    return this.putBase<CollectionPointListDeactivateCollectionPointsInput, void>('api/collectionpoint/deactivate', collectionPoints);
  }

  public deleteCollectionPoint(id: number): Observable<void> {
    return this.deleteBase<void>('api/collectionpoint' + id);
  }

  //
  public getDumpsters(id: number): Observable<CollectionPointDumpsterListOutput> {
    return this.getBase<CollectionPointDumpsterListOutput>('api/collectionpoint/' + id + '/dumpster');
  }

  public addDumpsters(dumpsters: EditCollectionPointAddDumpstersInput): Observable<void> {
    return this.putBase<EditCollectionPointAddDumpstersInput, void>('api/collectionpoint/dumpster/add', dumpsters);
  }

  public removeDumpsters(dumpsters: EditCollectionPointRemoveDumpstersInput): Observable<void> {
    return this.putBase<EditCollectionPointRemoveDumpstersInput, void>('api/collectionpoint/dumpster/remove', dumpsters);
  }

  //public updateLabels(labels: EditDeviceUpdateLabelsInput): Observable<void> {
  //  return this.putBase<EditDeviceUpdateLabelsInput, void>('api/device/label/update', labels);
  //}

  //public upload(importBehavior: ImportBehavior, files: FileInfo[]): Observable<ValidationOutputModel> {
  //  // array or single file
  //  const formData: FormData = new FormData();
  //  files.forEach(file => {
  //    formData.append('files', file.rawFile, file.name);
  //  });
  //  return this.postBase<FormData, ValidationOutputModel>('api/device/upload?importBehavior=' + importBehavior, formData);
  //}

  public getUploadUrl(importBehavior: ImportBehavior): string {
    return 'api/collectionpoint/upload?importBehavior=' + importBehavior;
  }

}
