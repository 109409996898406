import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCubes, faShieldAlt, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { ModalService } from '@veritas-shared/services/modal.service';
import { ToastService } from '@veritas-shared/services/toast.service';
import { CommandListOutputCommand } from '../../models/command-list-output';
import { CommandService } from '../../services/command.service';

@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.scss']
})
export class CommandsComponent implements OnInit {

  @ViewChild('isSystemColumn', { static: true }) public isSystemColumn: TemplateRef<any>;

  public columns: GridColumn[] = [];
  public actions: Action[] = [];
  public gridData: CommandListOutputCommand[];

  constructor(private commandService: CommandService, private toastService: ToastService, private router: Router, private translate: TranslateService, private modalService: ModalService, private library: FaIconLibrary,
    public constantHelper: ConstantHelper
  ) {

    this.view = this.view.bind(this);

    this.library.addIcons(faUserShield, faShieldAlt, faCubes);

    this.actions = [
      //new Action('edit', 'action.Common.Edit', 'pencil-alt', this.edit),
      //new Action('delete', 'action.Common.Delete', 'trash-alt', this.delete, null, false, (command: CommandListOutputCommand) => !command.isSystem)
    ];
  }

  ngOnInit() {

    this.columns = [
      {
        field: 'name',
        title: this.constantHelper.LBL_VOCABULARY_COMMAND_NAME,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'link',
        callback: this.view
      },
      {
        field: 'description',
        title: this.constantHelper.LBL_VOCABULARY_COMMAND_DESCRIPTION,
        sortable: true,
        resizable: true,
        filterable: true
      },
      {
        field: 'isSystem',
        title: this.constantHelper.LBL_COMMON_ISSYSTEM,
        sortable: true,
        resizable: false,
        filterable: false,
        template: this.isSystemColumn,
        width: "70px"
      }
    ];

    this.loadData();
  }

  //public add() {
  //  this.router.navigate(['commands', 'management', 'new']);
  //}

  //public edit(command: CommandListOutputCommand) {
  //  this.router.navigate(['commands', 'management', command.id, 'edit']);
  //}

  public view(command: CommandListOutputCommand) {
    this.router.navigate(['commands', 'management', command.id]);
  }

  //public delete(command: CommandListOutputCommand) {
  //  if (command.isSystem == true) {
  //    this.modalService.showAlert(
  //      this.translate.instant('exception.Command.Validator.InvalidRemove'),
  //      () => { });
  //  }
  //  else {
  //    this.commandService.getCommandDelete(command.id).subscribe(result => {
  //      if (result.commandTasksCount > 0) {
  //        this.modalService.showAlert(
  //          this.translate.instant('exception.Command.Validator.UsedCommand', { '0': result.commandTasksCount }),
  //          () => { });
  //      } else {
  //        this.modalService.showConfirm(
  //          this.translate.instant('common.Confirm.Delete'),
  //          () => {
  //            this.commandService.deleteCommand(command.id).subscribe(result => {
  //              this.loadData();
  //            });
  //          });
  //      }
  //    });
  //  }
  //}

  private loadData() {
    this.commandService.getCommands().subscribe(result => {
      this.gridData = result.commands;
    });
  }

}
