<h1 class="page-title"><fa-icon icon="users"></fa-icon>{{ 'page.CollectionPointGroups.Title' | translate }}</h1>

<div class="page-content">
  <div class="mt-10">
    <app-group-box [pageTitle]="'Elenco Gruppi'">

      <div class="grid-container standard-padding">
        <app-custom-grid [columns]="columns" [items]="gridData" [pageable]="{pageSizes: true}" [filterable]="true" [showActions]="true" [actions]="actions" [toolbarActions]="toolbarActions"></app-custom-grid>
      </div>

    </app-group-box>

  </div>
</div>

<ng-template #isSystemColumn let-dataItem>
  <app-info-icon *ngIf="dataItem.isSystem" [icon]="'user-shield'" class="text-primary" tooltip="{{'common.IsSystem' | translate}}"></app-info-icon>
</ng-template>
