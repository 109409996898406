import { Pipe, PipeTransform } from '@angular/core';
import { DeviceState } from '../models/entities/device-state';

@Pipe({
  name: 'deviceState'
})
export class DeviceStatePipe implements PipeTransform {

  transform(value: string, args: DeviceState[]): any {
    if (value == null || args == null) {
      return null;
    }

    var result = args.find(el => el.deviceState == value)
    if (result) {
      return result.description;
    } else {
      return null;
    }
  }
}
