
<div class="run-command-body">
  <div class="">
    <form [formGroup]="formRunCommand" novalidate>

      <!-- COMMANDS -->
      <div class="row">
        <label class="col-lg-2 col-sm-2 col-form-label">{{ 'common.Command' | translate }}</label>
        <div class="col-lg-10 col-sm-10 form-group">
          <kendo-combobox [data]="data?.commands"
                          formControlName="commandId"
                          textField="description"
                          valueField="id"
                          [valuePrimitive]="true"
                          [filterable]="true"
                          (filterChange)="handleCommandFilter($event)"
                          (valueChange)="onCommandSelected($event)">
          </kendo-combobox>
        </div>
      </div>

      <!-- PARAMETERS -->
      <div class="row parameter-box" formArrayName="commandParameters" *ngIf="selectedCommandParameters && selectedCommandParameters.length > 0">
        <div class="col-12" *ngFor="let parameter of commandParameters.controls; let i=index">

          <div [formGroupName]="i" class="parameter-row row" *ngIf="!parameter.controls.hidden.value">
            <label class="col-2">{{parameter.controls.label.value != null ? (parameter.controls.label.value | translate) : parameter.controls.name.value }}</label>

            <!-- STRING -->
            <input class="k-textbox form-control col-10" type="text" *ngIf="parameter.controls.type.value == ParameterType.String" formControlName="value" />

            <!-- NUMBER -->
            <input class="form-control col-10" type="number" *ngIf="parameter.controls.type.value == ParameterType.Integer" formControlName="value" />

            <!-- DROPDOWN -->
            <kendo-combobox *ngIf="parameter.controls.type.value == ParameterType.Dropdown"
                            class="col-10"
                            [data]="supportValues[parameter.controls.name.value]"
                            textField="description"
                            valueField="identifier"
                            formControlName="value"
                            [valuePrimitive]="true"
                            [filterable]="true"
                            (filterChange)="handleListFilter($event, parameter.controls.name.value)">
            </kendo-combobox>

            <!-- MULTI SELECT -->
            <!--<kendo-multiselect *ngIf="parameter.controls.type.value == ParameterType.Multiselect"
                               class="col-10"
                               [data]="supportValues[parameter.controls.name.value]"
                               formControlName="value">
            </kendo-multiselect>-->

            <kendo-multiselect *ngIf="parameter.controls.type.value == ParameterType.Multiselect"
                               class="col-10"
                               [data]="supportValues[parameter.controls.name.value]"
                               formControlName="value"
                               textField="description"
                               valueField="identifier"
                               [valuePrimitive]="true">
            </kendo-multiselect>

          </div>
        </div>
      </div>

      <!--<div class="row" *ngIf="selectedCommandParameters">
        <div class="col-12" *ngFor="let parameter of selectedCommandParameters">
          <div class="row">
            <label class="col-lg-2 col-sm-2 col-form-label">{{ parameter.name }}</label>
            <input class="col-lg-10 col-sm-10 k-textbox" type="text" *ngIf="parameter.type == ParameterType.String && !parameter.hidden" [(ngModel)]="parameter.value"/>
            <input class="col-lg-10 col-sm-10 form-control" type="number" *ngIf="parameter.type == ParameterType.Integer && !parameter.hidden" [(ngModel)]="parameter.value"/>
          </div>
        </div>
      </div>-->

    </form>
  </div>
</div>

<div class="dialog-footer">
  <button type="button" class="btn btn-outline-secondary mt-2" (click)="backButtonClick()">
    <fa-icon icon="chevron-left"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_CANCEL | translate }}
  </button>
  <button type="button" class="btn btn-primary float-right mt-2" [disabled]="!formRunCommand?.dirty" (click)="saveButtonClick()">
    <fa-icon icon="play"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_RUN | translate }}
  </button>
</div>
