import { Component } from '@angular/core';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { AlertEvent } from '@veritas-shared/models/alert-event';
import { ModalService } from '@veritas-shared/services/modal.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  public message: string;
  public isAlertOpen: boolean;
  private alertEvent: AlertEvent;

  constructor(private modalService: ModalService, public constantHelper: ConstantHelper) {

    this.modalService.alert.subscribe((e: AlertEvent) => {
      this.alertEvent = e;
      this.message = e.message;
      this.isAlertOpen = true;
    });
  }

  public close() {
    if (this.alertEvent.onOk) {
      this.alertEvent.onOk();
    }
    this.isAlertOpen = false;
  }
}
