import { MapPathIcon } from "@veritas-shared/models/map/map-path-icon.model";

export const dumpsterIcon: MapPathIcon = {
  red: 'dumpster-red.svg',
  yellow: 'dumpster-yellow.svg',
  green: 'dumpster-green.svg'
};

export const accessibleIcon: MapPathIcon = {
  red: 'accessible-icon-red.svg',
  yellow: 'accessible-icon-yellow.svg',
  green: 'accessible-icon-green.svg'
};