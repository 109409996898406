import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { BeanDialogComponentBase, IDialogInput } from 'bean-kendo-dialog';
import { Observable, of } from 'rxjs';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { ClosureReason } from '@veritas-shared/models/entities/closure-reason';
import { TicketCloseInput } from '../../models/ticket-close-input';

@Component({
  selector: 'app-ticket-close-dialog',
  templateUrl: './ticket-close-dialog.component.html',
  styleUrls: ['./ticket-close-dialog.component.scss']
})
export class TicketCloseDialogComponent extends BeanDialogComponentBase<TicketCloseDialogInput> {

  public data: TicketCloseDialogInput;
  public formTicket: FormGroup;

  private allClosureReasons: ClosureReason[];

  constructor(
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper,
    private fb: FormBuilder,
  ) {
    super();

    this.library.addIcons(faChevronLeft, faSave);

    this.formTicket = this.fb.group({
      closureReason: [{ value: "", disabled: false }, [Validators.required]],
    });
  }

  ngAfterViewInitCallback(): void {
    this.allClosureReasons = this.data.closureReasons;
  }

  cancel(e: any): boolean {
    return true;
  }

  back(e: any): boolean {
    return true;
  }

  save(e: any): Observable<any> {

    if (!this.formTicket.valid) {
      this.commonHelper.validateAllFormFields(this.formTicket);
      return;
    }

    let res = this.formTicket.getRawValue();

    return of({ closureReason: res.closureReason } as TicketCloseInput);
  }

  public handleClosureFilter(value: string) {
    this.data.closureReasons = this.allClosureReasons.filter((s) => s.description.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
}

export class TicketCloseDialogInput implements IDialogInput {
  constructor(public closureReasons: ClosureReason[]) { }
}

