import { Component, OnInit, ViewChild, HostListener, ElementRef, Input } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBell, faUser } from '@fortawesome/free-solid-svg-icons';

import { UserPanelComponent } from '../user-panel/user-panel.component';
import { SessionService } from '@veritas-shared/services/session.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  @ViewChild(UserPanelComponent, { static: true }) userPanel: UserPanelComponent;
  @ViewChild('userPanelButton', { static: true }) userPanelButton: ElementRef;

  public userDisplayName: string;

  constructor(private authService: MsalService, private sessionService: SessionService, private library: FaIconLibrary) {
    library.addIcons(faBell, faUser);

    this.sessionService.userProfile.subscribe(userProfile => {
      if (userProfile) {
        this.userDisplayName = userProfile.name;
      }
    });
  }

  ngOnInit(): void {

    if (!!this.authService.instance.getActiveAccount()) {
      this.userDisplayName = this.authService.instance.getActiveAccount().username;
    }

  }

  public toggleUserPanel() {
    if (this.userPanel.isOpen) {
      this.userPanel.close();
    }
    else {
      this.userPanel.open();
    }
    return false;
  }

  private contains(target: any): boolean {
    return this.userPanel.panel.nativeElement.contains(target) ||
      (this.userPanelButton.nativeElement.contains(target));
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target) && this.userPanel.isOpen) {
      this.userPanel.close();
    }
  }

}
