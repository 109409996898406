import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faDumpster, faHome } from '@fortawesome/free-solid-svg-icons';
import { DumpsterService } from '@veritas-dumpsters/services/dumpster.service';
import { DumpsterStatus } from '@veritas-shared/enums/dumpster-status.enum';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { SessionService } from '@veritas-shared/services/session.service';
import { WaitService } from '@veritas-shared/services/wait.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  @ViewChild('homeInfoWindowTemplate', { static: true }) infoWindowInstance: ElementRef;

  public dumpsters: Dumpster[];
  public selectedDumpsters: Dumpster[] = [];

  public readonly activeColor: string = "#539F4D";
  public readonly errorColor: string = "#FF0000";
  public readonly warningColor: string = "#F1AD64";
  public readonly unregisteredColor: string = "#778899";

  public activeCount: number = 0;
  public errorCount: number = 0;
  public warningCount: number = 0;
  public unregisteredCount: number = 0;

  public DumpsterStatus = DumpsterStatus;

  constructor(
    public constantHelper: ConstantHelper,
    private faLibrary: FaIconLibrary,
    private dumpsterService: DumpsterService,
    private router: Router,
    private sessionService: SessionService,
  ) {
    this.faLibrary.addIcons(faHome, faDumpster);
    this.sessionService.setPageTitle(this.constantHelper.LBL_MENU_HOME);

    this.loadData();
  }

  private loadData() {
    this.dumpsterService.getDumpsters().subscribe(result => {
      const dumpster = result.dumpsters;

      this.dumpsters = dumpster?.filter((dumpster: Dumpster) => !dumpster?.parentDumpsterId);
      this.selectedDumpsters = dumpster;

      //
      this.activeCount = dumpster.length;
      this.errorCount = dumpster.filter(el => el.errorCount > 0).length;
      this.warningCount = dumpster.filter(el => el.errorCount == 0 && el.warningCount > 0).length;

      this.unregisteredCount = result.dumpstersUnregisteredCount;
    });
  }

  public goToDumpsterLists(status?: DumpsterStatus) {
    this.router.navigate(['dumpsters', 'list'], status ? { queryParams: { status: status } } : {});
  }

  public goToUnregisteredDumpsters() {
    this.router.navigate(['dumpsters', 'unregistered']);
  }
}
