import { Component, Input } from '@angular/core';
import { Label } from '../../models/label';

@Component({
  selector: 'app-labels-badge',
  templateUrl: './labels-badge.component.html',
  styleUrls: ['./labels-badge.component.scss']
})
export class LabelsBadgeComponent {

  @Input() public labels: Label[] = [];

  constructor() { }

  // public getLabelsTooltip() {
  //  let html = '';
  //  this.labels.slice(1).forEach(l => {
  //    html += l.name + '</br>';
  //  });
  //  return html;
  // }
}
