
<h1 class="page-title">
  <fa-icon icon="home"></fa-icon>Home
</h1>

<div class="container-fluid">
  <div class="row home-boxes">

    <!-- -->
    <div class="col-xl-3 col-md-6 col-sm-12">
      <app-group-box [pageTitle]="'Cassonetti Attivi'"
                     [borderColor]="activeColor"
                     [headerRightTemplate]="iconTemplate">
        <div class="home-group-body c-pointer" (click)="goToDumpsterLists()">
          {{activeCount}}
        </div>
      </app-group-box>
    </div>

    <!-- -->
    <div class="col-xl-3 col-md-6 col-sm-12">
      <app-group-box [pageTitle]="'Cassonetti con Errori'"
                     [borderColor]="errorColor"
                     [headerRightTemplate]="iconTemplate">
        <div class="home-group-body c-pointer" (click)="goToDumpsterLists(DumpsterStatus.Error)">
          {{errorCount}}
        </div>
      </app-group-box>
    </div>

    <!-- -->
    <div class="col-xl-3 col-md-6 col-sm-12">
      <app-group-box [pageTitle]="'Cassonetti con Warning'"
                     [borderColor]="warningColor"
                     [headerRightTemplate]="iconTemplate">
        <div class="home-group-body c-pointer" (click)="goToDumpsterLists(DumpsterStatus.Warning)">
          {{warningCount}}
        </div>
      </app-group-box>
    </div>

    <!---->
    <div class="col-xl-3 col-md-6 col-sm-12">
      <app-group-box [pageTitle]="'Cassonetti non registrati'"
                     [borderColor]="unregisteredColor"
                     [headerRightTemplate]="iconTemplate">
        <div class="home-group-body c-pointer" (click)="goToUnregisteredDumpsters()">
          {{unregisteredCount}}
        </div>
      </app-group-box>
    </div>

  </div>

  <!-- MAP -->
  <div class="row mt-20">
    <div class="col-12">
      <div class="home-map">
        <app-dumpster-map
          [mode]="'single'"
          [items]="dumpsters"
          [(selectedDumpsters)]="selectedDumpsters"
          [zoom]="4"
          [useInfoWindowWithBtn]="true"
          [markerColorByStatus]="true"
        ></app-dumpster-map>
      </div>
    </div>
  </div>
</div>

<ng-template #iconTemplate>
  <fa-icon icon="dumpster"></fa-icon>
</ng-template>

