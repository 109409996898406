import { Injectable, EventEmitter } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { ReplaySubject } from 'rxjs';
import { UserProfileOutput } from '@veritas-shared/models/user-profile-output';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public userProfile: ReplaySubject<UserProfileOutput> = new ReplaySubject(null);

  public pageTitle: string;
  public changePageTitleEvent = new EventEmitter<string>();
  public changeUserRolesEvent = new EventEmitter<any>();
  public dumpsterLabelPaneHidden: boolean;
  public disabledDumpsterLabelPaneHidden: boolean;

  private gridStates: { [key: string]: State } = {};
  private navigationHistory: string[] = [];
  private readonly SS_DUMPSTER_LABEL_PANE_HIDDEN: string;
  private readonly SS_DISABLED_DUMPSTER_LABEL_PANE_HIDDEN: string;
  private readonly SS_NAVIGATION_HISTORY: string;

  constructor() {
    this.SS_DUMPSTER_LABEL_PANE_HIDDEN = 'VIOTA_SS_Dumpster_Label_Pane_Hidden';
    this.SS_DISABLED_DUMPSTER_LABEL_PANE_HIDDEN = 'VIOTA_SS_Disabled_Dumpster_Label_Pane_Hidden';
    this.SS_NAVIGATION_HISTORY = 'SS_NAVIGATION_HISTORY';

    if (localStorage.getItem(this.SS_DUMPSTER_LABEL_PANE_HIDDEN)) {
      this.dumpsterLabelPaneHidden = JSON.parse(localStorage.getItem(this.SS_DUMPSTER_LABEL_PANE_HIDDEN));
    }

    if (localStorage.getItem(this.SS_DISABLED_DUMPSTER_LABEL_PANE_HIDDEN)) {
      this.disabledDumpsterLabelPaneHidden = JSON.parse(localStorage.getItem(this.SS_DISABLED_DUMPSTER_LABEL_PANE_HIDDEN));
    }

    if (sessionStorage.getItem(this.SS_NAVIGATION_HISTORY)) {
      this.navigationHistory = JSON.parse(sessionStorage.getItem(this.SS_NAVIGATION_HISTORY));
    }
  }

  public setUserProfile(userProfile: UserProfileOutput) {
    this.userProfile.next(userProfile);
  }

  public getGridState(key: string) {
    let state = null;
    if (this.gridStates[key] != undefined) {
      state = this.gridStates[key];
    }
    return state;
  }

  public setGridState(key: string, state: State) {
    this.gridStates[key] = state;
  }

  public setPageTitle(title: string) {
    this.pageTitle = title;
    this.changePageTitleEvent.emit(title);
  }

  public setDumpsterLabelPaneHidden(hidden: boolean) {
    this.dumpsterLabelPaneHidden = hidden;

    localStorage.setItem(this.SS_DUMPSTER_LABEL_PANE_HIDDEN, JSON.stringify(this.dumpsterLabelPaneHidden));
  }

  public getDumpsterLabelPaneHidden(): boolean {
    return this.dumpsterLabelPaneHidden;
  }

  public setDisabledDumpsterLabelPaneHidden(hidden: boolean) {
    this.disabledDumpsterLabelPaneHidden = hidden;

    localStorage.setItem(this.SS_DISABLED_DUMPSTER_LABEL_PANE_HIDDEN, JSON.stringify(this.disabledDumpsterLabelPaneHidden));
  }

  public getDisabledDumpsterLabelPaneHidden(): boolean {
    return this.disabledDumpsterLabelPaneHidden;
  }

  public updateUserRoles() {
    this.changeUserRolesEvent.emit();
  }

  //#region Navigation History management

  public addToNavigationHistory(url: string) {
    this.navigationHistory.push(url);

    sessionStorage.setItem(this.SS_NAVIGATION_HISTORY, JSON.stringify(this.navigationHistory));
  }

  public getNavigationHistory() {
    return this.navigationHistory;
  }

  public popFromNavigationHistory(): string {
    let path = this.navigationHistory.pop();
    return path;
  }

  //#endregion
}
