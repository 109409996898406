<h1 class="page-title" *ngIf="viewMode != ViewMode.New"><fa-icon icon="tags"></fa-icon>{{ label?.name }}</h1>
<h1 class="page-title" *ngIf="viewMode == ViewMode.New"><fa-icon icon="tags"></fa-icon>{{ constantHelper.LBL_PAGE_LABEL_NEW_TITLE | translate }}</h1>
<app-toolbar [formGroup]="formLabel" [viewMode]="viewMode" (view)="onView()" (back)="onBack()" (edit)="onEdit()" (save)="onSave()" (delete)="onDelete()" (cancel)="onCancel()" [hideOperations]="!hasWritePermission"></app-toolbar>

<div class="page-content">

  <!-- -->
  <div class="mt-10">
    <app-group-box pageTitle="Dati">
      <form [formGroup]="formLabel" class="box-standard-padding" novalidate>
        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ constantHelper.LBL_COMMON_NAME | translate }}</label>
          <div class="col-xl-5 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="name" id="inputName">
          </div>
          
          <label class="col-xl-1 col-md-2 col-12 col-form-label">{{ constantHelper.LBL_VOCABULARY_LABEL_COLOR | translate }}</label>
          <div class="col-xl-5 col-md-10 col-12 form-group">
            <kendo-colorpicker #picker
                               [view]="'palette'"
                               [paletteSettings]="{palette: 'basic'}"
                               [format]="'hex'"
                               formControlName="color"
                               id="inputColor">
            </kendo-colorpicker>
          </div>
        </div>
      </form>
    </app-group-box>
  </div>

  <!-- AUDITING -->
  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-auditing-box [item]="label"></app-auditing-box>
  </div>

</div>
