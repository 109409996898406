<p>
Dettagli: {{this.data.msg}}
</p>
<div class="dialog-footer">

  <button type="button" class="btn btn-outline-primary mt-2" (click)="backButtonClick()">
    <fa-icon icon="chevron-left"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_CLOSE | translate }}
  </button>
  <a class="btn btn-primary float-right mt-2" href="{{baseUrl}}/tickets/list/{{data.other?.ticketId}}/{{data.other?.entityId}} " *ngIf="this.data?.other?.ticketId"> 
    Vai al Ticket
  </a>
</div>
