import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFieldActionOption } from '@veritas-shared/models/field-action-option.model';

@Component({
  selector: 'app-field-action',
  templateUrl: './field-action.component.html',
  styleUrls: ['./field-action.component.scss']
})
export class FieldActionComponent {
  @Input() option: IFieldActionOption;
  @Input() isDisable: boolean;

  @Output() emitClick: EventEmitter<boolean>;

  constructor() { 
    this.option = { text: '', icon: '' };
    this.isDisable = false;
    this.emitClick = new EventEmitter<boolean>();
  }

  public setEmitClick = () => this.emitClick.emit(true); 
}
