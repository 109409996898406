import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Dumpster } from '@veritas-shared/models/dumpster';

@Component({
  selector: 'app-field-dumpster-code',
  templateUrl: './field-dumpster-code.component.html',
  styleUrls: ['./field-dumpster-code.component.scss']
})
export class FieldDumpsterCodeComponent {
  @Input() public dumpster: Dumpster;
  @Output() public emitClick: EventEmitter<Dumpster>;

  constructor() {
    this.emitClick = new EventEmitter<Dumpster>();
  }

  public setFieldClick = () => this.emitClick.emit(this.dumpster);
}
