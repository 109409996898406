import { Injectable } from '@angular/core';
import { DumpsterConfigurationDialogInput } from '@veritas-dumpsters/components/dumpster-configuration-dialog/dumpster-configuration-dialog.component';
import { GetConfigurationResponse, Parameter } from '@veritas-dumpsters/models/dumpster-configuration';
import { DumpsterEventDetailInput } from '@veritas-dumpsters/models/dumpster-event-detail';
import { CommandService } from 'src/app/commands/services/command.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationMaskService {
  constructor(
    private commandService: CommandService,
  ) { }

  public getParam = (params: Parameter[], name: string) => params?.find((param: Parameter) => param?.parameterName === name)?.value

  public getDumpsterEdit = (request: {
    id: string, 
    data: DumpsterConfigurationDialogInput,
    func: (command: GetConfigurationResponse) => void,
  }) => {
    const { id, data, func } = request;

    if(this._getIsNullOrEmpty(id)) return;

    const payload:DumpsterEventDetailInput = this._getDumpsterPayload(id, data);
    this.commandService.getConfigurationV2(payload).subscribe((command: GetConfigurationResponse) => func(command));
  }

  private _getIsNullOrEmpty = (str?: string): boolean => !str || str?.length === 0;

  private _getDumpsterPayload = (
    id: string, data: DumpsterConfigurationDialogInput
  ):DumpsterEventDetailInput => ({
    id,
    service: "Waste",
    supplier: data?.dumpster?.supplierName,
    entityId: data?.dumpster?.code
  });
}
