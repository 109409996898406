import { Pipe, PipeTransform } from '@angular/core';
import { OpeningReason } from '../models/entities/opening-reason';

@Pipe({
  name: 'openingReason'
})
export class OpeningReasonPipe implements PipeTransform {

  transform(value: string, args: OpeningReason[]): any {
    if (value == null || args == null) {
      return null;
    }

    var result = args.find(el => el.openingReason == value)
    if (result) {
      return result.description;
    } else {
      return null;
    }
  }
}
