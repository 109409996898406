import { tileLayer } from "leaflet";

export enum coordinationType {
  googleMaps = 'Indirizzo calcolato automaticamente tramite google maps',
  manually = 'Indirizzo inserito manualmente',
  forcedAddress = 'Indirizzo forzato',
}

export const googleMapLayer = (maxZoom: number) => tileLayer(
  'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}?lang=it',
  {
    maxNativeZoom: maxZoom,
    minZoom: 3.5,
    maxZoom: maxZoom,
    subdomains:['mt0','mt1','mt2','mt3']
  }
);

export const openStreetMapLayer = (maxZoom: number) => tileLayer(
  "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  {
    maxNativeZoom: maxZoom,
    minZoom: 3.5,
    maxZoom: maxZoom,
  }
);

export const satelliteMapLayer = (maxZoom: number) => tileLayer(
  'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
  {
    maxNativeZoom: maxZoom,
    minZoom: 3.5,
    maxZoom: maxZoom,
    subdomains:['mt0','mt1','mt2','mt3']
  }
);