import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faTicketAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  BeanKendoDialogService,
  WindowContainerDimension,
  WindowVisualizerDirective,
} from "bean-kendo-dialog";
import { BeanDialogDimension } from "bean-kendo-dialog/lib/classes/bean-dialog-dimension.class";
import { of, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { DeactivatedComponent } from "@veritas-shared/components/deactivated.component";
import { CommonHelper } from "@veritas-shared/helpers/common.helper";
import { ConstantHelper } from "@veritas-shared/helpers/constant.helper";
import { Action } from "@veritas-shared/models/action";
import { AddressMapping, Dumpster } from "@veritas-shared/models/dumpster";
import { GridColumn } from "@veritas-shared/models/grid-column";
import { RegionCode } from "@veritas-shared/models/region-code";
import { ViewMode } from "@veritas-shared/models/view-mode";
import { SecurityService } from "@veritas-shared/services/security.service";
import { SessionService } from "@veritas-shared/services/session.service";
import { ToastService } from "@veritas-shared/services/toast.service";
import {
  TicketHistoryDialogComponent,
  TicketHistoryDialogInput,
} from "../../components/ticket-history-dialog/ticket-history-dialog.component";
import { EntityListOutput } from "@veritas-shared/models/entities/entity-list-output";
import { Ticket, TicketRequestInput } from "../../models/ticket";
import { TicketUpdateInput } from "../../models/ticket-update-input";
import { TicketCloseInput } from "../../models/ticket-close-input";
import { TicketService } from "../../services/ticket.service";
import { OpeningReason } from "@veritas-shared/models/entities/opening-reason";
import { WasteType } from "@veritas-shared/models/entities/waste-type";
import { CollectionPointListOutputCollectionPoint } from "@veritas-shared/models/collectionpoint-list-output";
import { DumpsterService } from "../../../dumpsters/services/dumpster.service";
import { AlertDialogComponent, AlertDialogInput } from "@veritas-shared/components/alert-dialog/alert-dialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { NewTicketOuput } from "../../models/new-ticket-output";
import { TicketInfoService } from "../../services/ticket-info.service";

@Component({
  selector: "app-ticket-detail",
  templateUrl: "./ticket-detail.component.html",
  styleUrls: ["./ticket-detail.component.scss"],
})
export class TicketDetailComponent extends DeactivatedComponent implements OnInit, OnDestroy {
  @ViewChild(WindowVisualizerDirective, { static: true })
  public windowVisualizer: WindowVisualizerDirective;
  @ViewChild("columnMessageTypeTemplate", { static: true })
  columnMessageTypeTemplate: TemplateRef<any>;
  // @ViewChild('columnMessageTypeTemplate', { static: true }) columnMessageTypeTemplate: TemplateRef<any>;
  @ViewChild("columnNotesTemplate", { static: true })
  columnNotesTemplate: TemplateRef<any>;

  public hasWritePermission: boolean;

  public formTicket: FormGroup;

  public formTicketToolbar: FormGroup;

  public formDumpster: FormGroup;

  public viewMode: ViewMode;

  public ticket: Ticket;

  public dumpster: Dumpster;
  public allDumpsters: Dumpster[] = [];
  public dumpsters: Dumpster[];

  public entities: EntityListOutput;
  private allOpeningReasons: OpeningReason[];
  private allWasteTypes: WasteType[];

  // Region Codes
  private regionCodes: RegionCode[];
  public municipalities: string[] = [];
  public zones: number[] = [];
  public subZones: number[] = [];

  private allAssigments: string[] = ["Veritas", "Kgn"];
  public assignmentList = ["Veritas", "Kgn"];

  private ticketId: string;
  private dumpsterId: string;

  public attachmentToolbarActions: Action[] = [];
  public attachments: File[] = [];
  public attachmentColumns: GridColumn[] = [];

  public historyToolbarActions: Action[] = [];
  public historyActions: Action[] = [];
  public historyColumns: GridColumn[] = [];

  public ticketActions: Action[] = [];

  public ViewMode = ViewMode;

  private dumpsterSubscription: Subscription;

  public isOpen: boolean = false;

  public defaultMailingList: string = '';
  public dataReady: boolean = false;

  public deviceExternalIdRoute: string;

  //General properties properties
  public collectionPoints: CollectionPointListOutputCollectionPoint[] = [];

  constructor(
    private ticketService: TicketService,
    private infoService: TicketInfoService,
    private fb: FormBuilder,
    private library: FaIconLibrary,
    private toastService: ToastService,
    private sessionService: SessionService,
    private translate: TranslateService,
    private dialogService: BeanKendoDialogService,
    private securityService: SecurityService,
    private route: ActivatedRoute,
    private router: Router,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper,
    private dumpsterService: DumpsterService
  ) {
    super();

    this.onView = this.onView.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onFilesInput = this.onFilesInput.bind(this);
    this.onHistoryAdd = this.onHistoryAdd.bind(this);
    this.onHistoryView = this.onHistoryView.bind(this);
    this.closeTicket = this.closeTicket.bind(this);

    this.library.addIcons(faTicketAlt, faTimesCircle);
  }

  canDeactivate(): boolean {
    return !this.formTicket.dirty;
  }

  ngOnInit() {
    this.ticketId = this.route.snapshot.paramMap.get("ticketId");
    this.dumpsterId = this.route.snapshot.paramMap.get("dumpsterId");
    this.route.queryParams.subscribe(params => {
      this.deviceExternalIdRoute = params['deviceExternalId'];
    });

    if (this.ticketId == null) {
      this.viewMode = ViewMode.New;
    } else {
      let isEdit =
        this.route.snapshot.url.findIndex((u) => u.path == "edit") > -1;
      if (isEdit) {
        this.viewMode = ViewMode.Edit;
      } else {
        this.viewMode = ViewMode.View;
      }
    }

    // Form
    this.formTicket = this.fb.group({
      ticketId: [{ value: "", disabled: true }],
      entityId: [
        { value: "", disable: this.viewMode != ViewMode.New },
        [Validators.required],
      ],
      openingReason: [
        { value: "", disabled: this.viewMode != ViewMode.New },
        [Validators.required],
      ],
      closureReason: [{ value: "", disabled: true }],
      assignedFrom: [{ value: "Veritas", disabled: true }],
      assignedTo: [
        { value: "Kgn", disabled: this.viewMode != ViewMode.New },
        [Validators.required],
      ],
      note: [
        { value: "", disabled: this.viewMode != ViewMode.New }, 
        [this.noteValidator],
      ],
      priority: [{ value: "", disabled: true }],
      tags: [{ value: "", disabled: this.viewMode != ViewMode.New }],
      recipients: [{ value: "", disabled: this.viewMode != ViewMode.New }, [Validators.pattern(this.constantHelper.MAILING_LIST_VALIDATOR_REGEX)]],
      loop: [{ value: [], disabled: true }],

      municipality: [{ value: "", disabled: this.viewMode != ViewMode.New }],
      zone: [{ value: "", disabled: true }],
      subZone: [{ value: "", disabled: true }],
      wasteType: [{ value: "", disabled: this.viewMode != ViewMode.New }],

      city: [{ value: "", disabled: this.viewMode != ViewMode.New }],
      zipCode: [{ value: "", disabled: this.viewMode != ViewMode.New }],

      // From Dumpster
      latitude: [{ value: "", disabled: true }],
      longitude: [{ value: "", disabled: true }],
      address: [{ value: "", disabled: true }],
    });

    this.formDumpster = this.setFormGroup();

    switch (this.viewMode) {
      case ViewMode.Edit:
      case ViewMode.View:
        this.reload();
        break;
      case ViewMode.New:
        // Ticket
        this.ticketService.getNewTicket().subscribe((result: NewTicketOuput) => {
          this.formTicket.patchValue(result.ticket);

          //this.setDumpsterSubscription();

          // Dumpsters
          this.dumpsters = result.dumpsters;
          this.allDumpsters = result.dumpsters;

          // Region Codes
          this.regionCodes = result.regionCodes;
          if (this.regionCodes) {
            this.municipalities = Array.from(
              new Set(this.regionCodes.map((el) => el.municipality))
            );
          }
          // Entities
          this.entities = result.entities;
          this.allOpeningReasons = result.entities.openingReasons;
          this.allWasteTypes = result.entities.wasteTypes;
          this.formTicket.get("assignedFrom").setValue("Veritas");
          this.formTicket.get("assignedTo").setValue("Kgn");

          // nel caso in cui arrivi dalla pagina di dettaglio calotta (quindi il codice cassonetto mi viene passato come querystring nell'url) seleziono già il cassonetto 
          if(this.deviceExternalIdRoute){
            
              this.onDumpsterSelected(this.deviceExternalIdRoute)
            
          }

        });

        break;
    }

    // Grid
    this.attachmentColumns = [
      {
        field: "name",
        title: this.constantHelper.LBL_VOCABULARY_DUMPSTER_NAME,
        sortable: true,
        resizable: true,
        filterable: false,
        display: "text",
      },
    ];

    // Actions
    this.securityService
      .userHasPermission(this.constantHelper.LBL_PERMISSION_TICKET_WRITE)
      .subscribe((res) => {
        this.hasWritePermission = res;

        if (res) {
          this.historyToolbarActions = [
            new Action("add", "Aggiungi", "plus", this.onHistoryAdd),
          ];

          this.attachmentToolbarActions = [
            new Action("add", "Aggiungi Allegato", "plus", this.onFilesInput),
          ];
        }
      });

    this.historyActions = [
      new Action("view", "Vedi", "eye", this.onHistoryView),
    ];

    this.historyColumns = [
      {
        field: "messageType",
        title: "Stato",
        sortable: true,
        resizable: true,
        filterable: false,
        template: this.columnMessageTypeTemplate,
        width: "120",
      },
      {
        field: "timestamp",
        title: "Data",
        sortable: true,
        resizable: true,
        filterable: false,
        display: "date",
        dateType: this.constantHelper.DATE_TIME_FORMAT,
        width: "120",
      },
      {
        field: "username",
        title: "Utente",
        sortable: true,
        resizable: true,
        filterable: false,
        display: "text",
        width: "100",
      },
      {
        field: "detail.note",
        title: "Note",
        sortable: true,
        resizable: true,
        filterable: false,
        template: this.columnNotesTemplate,
      },
    ];

    this.formTicket.get('openingReason').valueChanges
    .subscribe(value => {
        this.formTicket.get('note').updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    if (this.dumpsterSubscription != null) {
      this.dumpsterSubscription.unsubscribe();
    }
  }

  // Dumpster
  public handleDumpstersFilter(value: string) {
    this.dumpsters = this.allDumpsters.filter(
      (s) =>
        (s.code + s.binId + s.address).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public getDefaultMailingList(dumpster: Dumpster){
    this.defaultMailingList = '';
    this.ticketService.getDeviceGroups(dumpster?.deviceExternalId).subscribe((res) => {

      // per ogni gruppo, per ogni mail nella lista di quel gruppo, aggiungo a lista finale solo se la mail non è già nella lista finale
      res.forEach(group => {
        if(group.mailingList){
          group.mailingList.forEach(newMail => {
            if(!this.defaultMailingList.includes(newMail)){
              this.defaultMailingList += ';' + newMail;
            }
          });
        }  
      });

      if(this.defaultMailingList.startsWith(';'))
      this.defaultMailingList = this.defaultMailingList.substring(1);
    })
  }

  public calculateAdditionalMail() : string{
    let loop = this.ticket.loop;
    let defaultList = this.defaultMailingList.split(';');
    return loop?.filter(x => !defaultList.includes(x))?.join(';') ?? ''
  }

  public onDumpsterSelected = (id: string) => id && this.infoService.setTicketCheck(this._getSelectedDumpster(id));

  public dumpsterSelected$ = this.infoService.saveTicket$.subscribe(
    (dumpster: Dumpster) => {
      if(!dumpster) {
        this.formTicket.get("entityId").setValue(null);
        return;
      }
      this._setDumpsterSelect(dumpster)
    }
  );

  private _getSelectedDumpster = (id: string):Dumpster => this.allDumpsters.find((d) => d?.deviceExternalId == id);

  private _setDumpsterSelect = (dumpster: Dumpster) => {
    // prendo lista mail di default
    this.getDefaultMailingList(dumpster)

    this.formTicket.get("entityId").setValue(dumpster?.deviceExternalId);
    if (dumpster?.location) {
      this.formTicket.get("latitude").setValue(dumpster?.location?.latitude);
      this.formTicket.get("longitude").setValue(dumpster?.location?.longitude);
    }
    this.formTicket.get("address").setValue(
      !this.commonHelper.isNullOrWhiteSpace(dumpster?.address) ? dumpster?.address: ""
    );

    const mapped = this.getDataFromAddress(dumpster?.address);
    if (mapped !== null) {
      this.formTicket.get("city").setValue(mapped?.city);
      this.formTicket.get("zipCode").setValue(mapped?.zipCode);
    } else {
      this.formTicket.get("city").setValue("");
      this.formTicket.get("zipCode").setValue("");

      // TODO add translation
      this.toastService.showWarning(
        "Il cassonetto selezionato non ha informazioni sulla città e sul CAP"
      );
    }
  }

  // Region Code - Municipality - Zone - Subzone
  public onMunicipalitySelected(municipality: string) {
    if (municipality) {
      this.zones = Array.from(
        new Set(
          this.regionCodes
            .filter((el) => el.municipality === municipality)
            .map((el) => el.zone)
        )
      );

      this.formTicket.get("zone").enable();
    } else {
      // Reset Municipality - Zone - Subzone Values
      this.zones = [];
      this.subZones = [];

      this.formTicket.get("municipality").reset();
      this.formTicket.get("zone").reset();
      this.formTicket.get("subZone").reset();

      this.formTicket.get("zone").disable();
      this.formTicket.get("subZone").disable();
    }
  }

  public onZoneSelected(zone: number) {
    let currentMunicipality = this.formTicket.get("municipality").value;

    if (zone) {
      this.subZones = Array.from(
        new Set(
          this.regionCodes
            .filter(
              (el) => el.municipality === currentMunicipality && el.zone == zone
            )
            .map((el) => el.subZone)
        )
      );
      this.formTicket.get("subZone").enable();
    } else {
      // Reset Municipality - Zone - Subzone Values
      this.subZones = [];

      this.formTicket.get("zone").reset();
      this.formTicket.get("subZone").reset();

      this.formTicket.get("subZone").disable();
    }
  }

  public handleOpeningFilter(value: string) {
    this.entities.openingReasons = this.allOpeningReasons.filter(
      (s) => s.description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleWasteFilter(value: string) {
    this.entities.wasteTypes = this.allWasteTypes.filter(
      (s) => s.description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleAssigmentFilter(value: string) {
    this.assignmentList = this.allAssigments.filter(
      (s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  private getDataFromAddress(address: string): AddressMapping {
    if (!this.commonHelper.isNullOrWhiteSpace(address)) {
      // Dall'indirizzo ottengo -> CAP Città Provincia
      var splittedAddress = address.split(",");
      if (splittedAddress.length > 2) {
        // Creo un array
        var spaceSplit = splittedAddress[2].trim().split(" ");
        if (spaceSplit.length > 2) {
          // Il primo so che è il CAP
          var zipCode = spaceSplit.shift();

          // Devo prendere tutto il restante array tranne l'ultimo elemento (la Provincia) per ottenere la città
          var cityMapping = spaceSplit
            .map((el, index, arr) => (index != arr.length - 1 ? el : undefined))
            .filter((el) => el != undefined);
          var city = cityMapping.join(" ");

          return {
            zipCode: zipCode,
            city: city,
          } as AddressMapping;
        }
      }
    }
    return null;
  }

  // Ticket
  public reload() {
    this.dataReady = false;
    
    var request = {
      ticketId: this.ticketId,
      entityId: this.dumpsterId,
    } as TicketRequestInput;

    this.ticketService.getTicket(request).subscribe((res) => {
      this.ticket = res.ticket;
      this.dumpster = res.dumpster;
      
      this.entities = res.entities;

      this.ticketActions = [];

      this.isOpen = this.ticket?.state == "Opened"

      if(this.isOpen && this.ticket?.assignedTo !== "Kgn")
        this.ticketActions = [
          new Action(
            "close",
            this.constantHelper.LBL_ACTION_TICKET_CLOSE,
            "times-circle",
            this.closeTicket,
            true
          ),
        ];

      this.sessionService.setPageTitle(this.ticket.ticketId);
      this.formTicket.patchValue(this.ticket);
      this.formDumpster.patchValue(this.dumpster);

      // Metadata with reflection
      for (const propName of Object.keys(this.ticket.metadata)) {
        if (
          this.ticket.metadata[propName] != null &&
          this.formTicket.get(propName) != null
        ) {
          this.formTicket
            .get(propName)
            .setValue(this.ticket.metadata[propName]);
        }
      }

      this.formTicket
        .get("openingReason")
        .setValue(this.translate.instant(this.ticket.openingReason));

      if (this.ticket.closureReason) {
        this.formTicket
          .get("closureReason")
          .setValue(this.translate.instant(this.ticket.closureReason));
      }

      if (this.dumpster.garbageType) {
        this.formDumpster.get('garbageType').setValue(this.translate.instant(this.dumpster.garbageType));
      }

      this.getDefaultMailingList(res.dumpster);
      this.dataReady = true;
    });    
  }

  private onFilesInput() {
    var input = document.getElementById(
      "attachmentUploader"
    ) as HTMLInputElement;
    let that = this;

    input.onchange = (e: Event) => {
      that.attachments = [];

      Array.from((<HTMLInputElement>e.target).files).forEach((file) => {
        that.attachments.push(file as File);
      });
    };

    input.value = null;

    // Click to open popup
    input.click();
  }

  private onHistoryAdd(event) {
    this.dialogService.openDialog(
      this.translate.instant(
        this.constantHelper.LBL_PAGE_TICKETDETAIL_HISTORY_TITLE
      ),
      new TicketHistoryDialogInput(null, false),
      TicketHistoryDialogComponent,
      this.windowVisualizer,
      () => {},
      (res: TicketUpdateInput) => {
        res.entityId = this.ticket.entityId;
        res.ticketId = this.ticket.ticketId;

        const formData = new FormData();
        res.attachments.forEach((file) => {
          formData.append("files", file, file.name);
        });

        formData.append("data", JSON.stringify(res));

        return this.ticketService.updateTicket(formData).pipe(
          map(() => {
            this.reload();
          })
        );
      },
      true,
      WindowContainerDimension.Medium,
      {
        mediumHeight: 500,
      } as BeanDialogDimension
    );
  }

  private onHistoryView(event) {
    this.dialogService.openDialog(
      "History",
      new TicketHistoryDialogInput(event),
      TicketHistoryDialogComponent,
      this.windowVisualizer,
      () => {},
      (res) => of(true),
      true,
      WindowContainerDimension.Medium,
      {
        mediumHeight: 500,
      } as BeanDialogDimension
    );
  }

  // Toolbar
  public onView() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  public onBack() {
    this.goBack();
  }

  public onSave() {
    if (this.formTicket.valid) {
      const rawValue = this.formTicket.getRawValue();
      rawValue.metadata = {};

      // METADATA DATA
      if (rawValue.municipality) {
        rawValue.metadata["municipality"] = rawValue.municipality;
      }

      if (rawValue.zone) {
        rawValue.metadata["zone"] = rawValue.zone;
      }

      if (rawValue.subZone) {
        rawValue.metadata["subZone"] = rawValue.subZone;
      }

      if (rawValue.wasteType) {
        rawValue.metadata["wasteType"] = rawValue.wasteType;
      }

      if(this.viewMode !== ViewMode.New) return;
      // Form
      const formData = new FormData();
      this.attachments.forEach((file) => {
        formData.append("files", file, file.name);
      });

      let mailingListComplete : string[] = [];

      if(this.defaultMailingList.length > 0){
        this.defaultMailingList.split(";").forEach(mail => {
          mailingListComplete.push(mail);
        }); 
      }
      
      if (rawValue.recipients) {
        rawValue.recipients.split(";").forEach(mail => {
          mailingListComplete.push(mail);
        }); 
      }
      
      if(mailingListComplete.length>0)
        rawValue.loop = mailingListComplete

      formData.append("data", JSON.stringify(rawValue));

      this.ticketService.openTicket(formData).subscribe({
        next: (res) => {
          this.formTicket.reset();
          this.router.navigate(["tickets", "list", res.ticketId, res.entityId]);
        },
        error: (error) => {
          let msg = error.message;
          let other = {};

          if (error instanceof HttpErrorResponse)
            msg = error.error.messages.join("\n");
          if(msg.includes("Existing ticket")){
            const regExTicketId = /(Ticket\sid\sis:\s[\da-zA-Z]{8}-([\da-zA-Z]{4}-){3}[\da-zA-Z]{12})/;
            const regExEntityId = /(Dumpster,\s[\da-zA-Z]{8}-([\da-zA-Z]{4}-){3}[\da-zA-Z]{12})/;
            const regExGuid = /[\da-zA-Z]{8}-([\da-zA-Z]{4}-){3}[\da-zA-Z]{12}/;

            const ticketRegexRes = regExGuid.exec((regExTicketId.exec(msg)[0] ?? ""))
            const entityRegexRes = regExGuid.exec((regExEntityId.exec(msg)[0] ?? ""))

            const ticketId = ticketRegexRes ? ticketRegexRes[0] : "";  
            const entityId = entityRegexRes ? entityRegexRes[0] : "";
            
            msg = msg.replace("Existing ticket for","Ticket già esistente per");
            msg = msg.replace("Ticket id is:","Id del ticket:");

            other = {ticketId : ticketId, entityId : entityId}
          }

          this.dialogService.openDialog(
            "Errore",
            new AlertDialogInput(msg,other),
            AlertDialogComponent,
            this.windowVisualizer,
            () => {},
            (res) => of(true),
            true,
            WindowContainerDimension.Small,
            {
              mediumHeight: 500,
            } as BeanDialogDimension
          );
        }
      });
    }
  }

  public onCancel() {
    this.formTicket.reset(this.ticket);
  }

  public goBack() {
    let path = "../../";
    if (this.viewMode == ViewMode.New) {
      path = "../";
    }
    this.router.navigate([path], { relativeTo: this.route });
  }

  private setFormGroup(): FormGroup {
    return this.fb.group({
      id: [""],
      code: [
        { value: "", disabled: this.viewMode != ViewMode.New },
        Validators.required,
      ],
      binId: [{ value: "", disabled: this.viewMode == ViewMode.View }],
      regionCode: [{ value: "", disabled: true }, Validators.required],
      regionCodeSecond: [{ value: "", disabled: true }],
      regionCodeThird: [{ value: "", disabled: true }],

      supplierName: [{ value: "", disabled: true }, Validators.required],
      binType: [{ value: "", disabled: true }],
      garbageType: [{ value: "", disabled: true }],

      location: this.fb.group({
        latitude: [
          { value: 0, disabled: this.viewMode == ViewMode.View },
          Validators.required,
        ],
        longitude: [
          { value: 0, disabled: this.viewMode == ViewMode.View },
          Validators.required,
        ],
      }),

      collectionPointId: [
        { value: undefined, disabled: this.viewMode == ViewMode.View },
      ],
      collectionPointName: [
        { value: "", disabled: this.viewMode == ViewMode.View },
      ],
      collectionPointDistance: [
        { value: 30, disabled: this.viewMode == ViewMode.View },
      ],

      address: [{ value: "", disabled: this.viewMode == ViewMode.View }],
    });
  }

  closeTicket() {
    var request: TicketCloseInput = {
      entityType: this.ticket.entityType,
      entityId: this.ticket.entityId,
      ticketId: this.ticket.ticketId,
      closureReason: "Other",
      warranty: false,
      oldDeviceId: null,
      newDeviceId: null,
      subject: this.ticket.subject,
      note: "",
      priority: this.ticket.priority,
      attachments: [],
      metadata: {},
      tags: this.ticket.tags,
      loop: this.ticket.loop,
    };
      
    this.ticketService.closeTicket(request).subscribe((res) => {
      this.reload();
      this.toastService.showSuccess(
        "Il ticket è stato aggiornato correttamente"
      );
    })
  }

  noteValidator (formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    
    if (formControl.parent.get('openingReason').value === "Other") {
      return Validators.required(formControl); 
    }
    return null;
  }

  // public getDumpsterDetails(dumpsterId) {

  // this.dumpsterService.getByDumpsterId(dumpsterId).subscribe(result => {
  //   this.dumpster = result.dumpster;
  //   this.dumpsters = [result.dumpster];

  //   this.sessionService.setPageTitle(result.dumpster.code);

  //   //
  //   this.formDumpster.patchValue(result.dumpster);
  //   if (result.dumpster.collectionPoint) {
  //     this.formDumpster.get('collectionPointId').patchValue(result.dumpster.collectionPoint.id);
  //     this.formDumpster.get('collectionPointName').patchValue(result.dumpster.collectionPoint.name);
  //   } else {
  //     this.formDumpster.get('collectionPointId').patchValue(null);
  //     this.formDumpster.get('collectionPointName').patchValue(null);
  //   }

  // this.entities = result.entities;

  // let translatedObject = []
  // result.events.forEach(event => {
  //   let translatedEvent = JSON.parse(JSON.stringify(event));
  //   translatedEvent.payloadType = this.translate.instant(event.payloadType);
  //   if (event.messageType) {
  //     let messageToTranslate = event.messageType.replace(/[(,-]/g, ' ').replace(/[),:]/g, '').split(' ');
  //     let index = 0;
  //     messageToTranslate.forEach(messagePiece => {
  //       messageToTranslate[index] = this.translate.instant(messagePiece);
  //       index++;
  //     });
  //     if (messageToTranslate.length == 2) {
  //       translatedEvent.messageType = `${messageToTranslate[0]} : ${messageToTranslate[1]}`;

  //     }
  //     else if (messageToTranslate.length == 3) {
  //       translatedEvent.messageType = `${messageToTranslate[0]} (${messageToTranslate[1]} - ${messageToTranslate[2]})`;
  //     }
  //     else {
  //       translatedEvent.messageType = this.translate.instant(event.messageType);
  //     }
  //   }
  //   translatedObject.push(translatedEvent);
  // });

  // });
  // }
}
