import { Injectable } from '@angular/core';
import { CollectionPointListOutputCollectionPoint } from '@veritas-shared/models/collectionpoint-list-output';
import { Location } from '@veritas-shared/models/location';

@Injectable({
  providedIn: 'root'
})
export class DumpsterLocationService {
  public getLocation = (coordination: string): Location => {
    const coordinations: string[] = coordination.split(" - ");
    return { latitude: parseFloat(coordinations[0]), longitude: parseFloat(coordinations[1]) };
  };

  public getNearestCollectionPoint = (
    latitude: number,
    longitude: number,
    maxDistance: number,
    allCollectionPoints: CollectionPointListOutputCollectionPoint[]
  ) => {
    let position = new google.maps.LatLng(latitude, longitude);
    let minMeters;
    let nearCollectionPoint;

    allCollectionPoints.filter((cp) => cp.address).forEach((cp) => {
      const destination = new google.maps.LatLng(
        cp.address.location.latitude,
        cp.address.location.longitude
      );
      const meters = google.maps.geometry.spherical.computeDistanceBetween(
        position,
        destination
      );

      if (
        minMeters == undefined || (meters <= minMeters && meters < maxDistance)
      ) {
        minMeters = meters;
        nearCollectionPoint = cp;
      }
    });
    return nearCollectionPoint;
  }

  public getAddressByLocation = (latitude: number, longitude: number) => {
    const position = new google.maps.LatLng(latitude, longitude);

    return new Promise<string>((resolve, reject) => new google.maps.Geocoder().geocode(
      { location: position },
      (res, status) => {
        if (status !== 'OK') return
        var streets = res.filter((el) => el.types.includes('street_address'));

        if (streets != null && streets.length > 0) {
          resolve(streets[0].formatted_address);
          return;
        }
        reject();
      }
    ));
  }
}
