<h1 class="page-title"><fa-icon icon="key"></fa-icon>{{ 'page.Authorizations.Title' | translate }}</h1>
<div class="page-content">
  <div class="mt-10">
    <app-group-box [pageTitle]="'Elenco Autorizzazioni'">

      <div class="grid-container standard-padding">
        <app-custom-grid [columns]="columns" [items]="gridData" [filterable]="true" [showActions]="true" [actions]="actions" [toolbarActions]="toolbarActions"></app-custom-grid>
      </div>
    </app-group-box>

  </div>
</div>

<ng-template #isSystemColumn let-dataItem>
  <app-info-icon *ngIf="dataItem.isSystem" [icon]="'user-shield'" class="text-primary" tooltip="{{ constantHelper.LBL_COMMON_ISSYSTEM | translate}}"></app-info-icon>
</ng-template>
<ng-template #isEnabledColumn let-dataItem>
  <app-info-icon *ngIf="!dataItem.isEnabled" [icon]="'eye-slash'" class="text-primary" tooltip="{{ constantHelper.LBL_COMMON_ISENABLED | translate}}"></app-info-icon>
</ng-template>
