import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@veritas-shared/services/api.service';
import { GroupListOutput } from "../models/group-list-output";
import { NewGroupOutput } from "../models/new-group-output";
import { NewGroupInput } from "../models/new-group-input";
import { EditGroupInput } from "../models/edit-group-input";
import { ToastService } from "@veritas-shared/services/toast.service";
import { EditGroupAddDumpstersInput } from "../models/edit-group-add-dumpsters-input";
import { EditGroupRemoveDumpstersInput } from "../models/edit-group-remove-dumpsters-input";
import { GroupDumpsterListOutput } from "../models/group-dumpster-list-output";
import { WaitService } from "@veritas-shared/services/wait.service";
import { EditCollectionPointGroupOutput } from "../../collectionpoints/models/edit-collectionpointgroup-output";
import { EditCollectionPointGroupAddCollectionPointsInput } from "../../collectionpoints/models/edit-collectionpointgroup-add-collectionpoints-input";
import { EditCollectionPointGroupRemoveCollectionPointsInput } from "../../collectionpoints/models/edit-collectionpointgroup-remove-collectionpoints-input";
import { CollectionPointGroupCollectionPointListOutput } from "../../collectionpoints/models/collectionpointgroup-collectionpoint-list-output";
import { EditGroupOutput } from "../models/edit-group-output";
import { GroupDeleteOutput } from "../models/group-delete-output";
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from "@veritas-shared/helpers/common.helper";

@Injectable({
  providedIn: 'root'
})
export class GroupService extends ApiService {

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, toastService: ToastService, waitService: WaitService, commonHelper: CommonHelper, newToastService: BeanToastService) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  public getGroups(): Observable<GroupListOutput> {
    return this.getBase<GroupListOutput>('api/group');
  }

  public getCollectionPointGroups(): Observable<GroupListOutput> {
    return this.getBase<GroupListOutput>('api/group/collectionpoint');
  }

  public getNewGroup(): Observable<NewGroupOutput> {
    return this.getBase<NewGroupOutput>('api/group/new');
  }

  public getNewCollectionPointGroup(): Observable<NewGroupOutput> {
    return this.getBase<NewGroupOutput>('api/group/collectionpoint/new');
  }

  public getEditGroup(id: number): Observable<EditGroupOutput> {
    return this.getBase<EditGroupOutput>('api/group/' + id + '/edit');
  }

  public getGroupDelete(id: number): Observable<GroupDeleteOutput> {
    return this.getBase<GroupDeleteOutput>('api/group/' + id + '/delete');
  }

  public getEditCollectionPointGroup(id: number): Observable<EditCollectionPointGroupOutput> {
    return this.getBase<EditCollectionPointGroupOutput>('api/group/collectionpoint/' + id + '/edit');
  }

  public getDumpsters(id: number): Observable<GroupDumpsterListOutput> {
    return this.getBase<GroupDumpsterListOutput>('api/group/' + id + '/dumpster');
  }

  public getCollectionPoints(id: number): Observable<CollectionPointGroupCollectionPointListOutput> {
    return this.getBase<CollectionPointGroupCollectionPointListOutput>('api/group/' + id + '/collectionpoint');
  }

  public addGroup(group: NewGroupInput): Observable<void> {
    return this.postBase<NewGroupInput, void>('api/group', group);
  }

  public updateGroup(group: EditGroupInput): Observable<void> {
    return this.putBase<EditGroupInput, void>('api/group', group);
  }

  public deleteGroup(id: number): Observable<void> {
    return this.deleteBase<void>('api/group/' + id);
  }
  
  public addDumpsters(dumpsters: EditGroupAddDumpstersInput): Observable<void> {
    return this.putBase<EditGroupAddDumpstersInput, void>('api/group/dumpster/add', dumpsters);
  }

  public addCollectionPoints(collectionPoints: EditCollectionPointGroupAddCollectionPointsInput): Observable<void> {
    return this.putBase<EditCollectionPointGroupAddCollectionPointsInput, void>('api/group/collectionpoint/add', collectionPoints);
  }

  public removeDumpsters(dumpsters: EditGroupRemoveDumpstersInput): Observable<void> {
    return this.putBase<EditGroupRemoveDumpstersInput, void>('api/group/dumpster/remove', dumpsters);
  }

  public removeCollectionPoints(collectionPoints: EditCollectionPointGroupRemoveCollectionPointsInput): Observable<void> {
    return this.putBase<EditCollectionPointGroupRemoveCollectionPointsInput, void>('api/group/collectionpoint/remove', collectionPoints);
  }

}
