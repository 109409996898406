import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import * as fas from '@fortawesome/free-solid-svg-icons';
import { MenuItemOutput } from '../../models/menu-output';
import { MenuService } from '../../services/menu.service';
import { IconHelper } from '@veritas-shared/helpers/icon-helper';
import { SessionService } from '@veritas-shared/services/session.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({ height: '{{menuItemHeight}}px' }), { params: { menuItemHeight: '*' } }),
      state('closed', style({ height: '0' })),
      transition('open <=> closed', animate(250))
    ])]
})

export class NavMenuComponent implements OnInit {
  @Input() isMenuOpen: boolean;
  @Input() guiVersion: string;

  @Output() isMenuOpenChange = new EventEmitter();

  public menuItems: MenuItemOutput[];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth <= 768) {
      if (this.isMenuOpen == true) {
        this.toggleMenu();
      }
      else {
        this.isMenuOpen = false;
        this.isMenuOpenChange.emit(false);
      }
    } else {
      this.isMenuOpen = true;
      this.isMenuOpenChange.emit(true);
    }
  }

  constructor(
    private menuService: MenuService,
    private sessionService: SessionService,
    private library: FaIconLibrary
  ) {
    this.library.addIcons(fas.faChevronRight, fas.faBars, fas.faChevronLeft);
  }

  ngOnInit(): void {
    this.sessionService.changeUserRolesEvent.subscribe(res => {
      this.loadMenu();
    });
  }

  private loadMenu() {
    const excludedSectionsResourcKeys = ['menu.CollectionPoints','menu.Commands','menu.Monitoring'];
    
    this.menuService.getMenu().subscribe(result => {
      this.menuItems = result.menuItems;
      this.menuItems = this.menuItems.filter(x => !excludedSectionsResourcKeys.includes(x.resourceKey))
      result.icons.forEach(i => this.library.addIcons(fas[IconHelper.normalizeIconName(i)]));
    });
  }

  public toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;

    if (!this.isMenuOpen) {
      this.menuItems.forEach(m => m.isOpen = false);
    }

    this.isMenuOpenChange.emit(this.isMenuOpen);
  }

  public menuItemClick(menuItem: MenuItemOutput) {
    if (menuItem.menuItems.length > 0) {
      menuItem.isOpen = !menuItem.isOpen;
      // Expand menu if collapsed
      if (!this.isMenuOpen) {
        this.isMenuOpen = true;
        this.isMenuOpenChange.emit(this.isMenuOpen);
      }
      return false;
    }
  }
}
