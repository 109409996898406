<div
  *ngIf="dumpster?.parentDumpsterId || dumpster?.childDumpsterId" 
  class="field-icon d-flex align-items-center"
>
  <ng-container
    [ngTemplateOutlet]="dumpster?.parentDumpsterId ? iconChild : iconFather"
  ></ng-container>
</div>

<ng-template #iconFather>
  <fa-icon icon="dumpster" title="{{ '$lbl.dumpsterFather' | translate }}"></fa-icon>
  <i [ngClass]="relation.icon"></i>
</ng-template>

<ng-template #iconChild>
  <i [ngClass]="relation.icon" title="{{ '$lbl.dumpsterChild' | translate }}"></i>
</ng-template>
