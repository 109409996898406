<h1 class="page-title" *ngIf="viewMode != ViewMode.New"><fa-icon icon="dumpster"></fa-icon>{{ command?.name }}</h1>
<h1 class="page-title" *ngIf="viewMode == ViewMode.New"><fa-icon icon="dumpster"></fa-icon>{{ 'page.CommandNew.Title' | translate }}</h1>
<app-toolbar [formGroup]="formCommand" [viewMode]="viewMode" (view)="onView()" (back)="onBack()" (edit)="onEdit()" (delete)="onDelete()" (save)="onSave()" (cancel)="onCancel()"></app-toolbar>

<div class="page-content">

  <!-- GENERAL -->
  <div class="mt-10">
    <app-group-box pageTitle="{{ 'page.CommandDetail.Section.General.Title' | translate }}">
      <form [formGroup]="formCommand" class="box-standard-padding" novalidate>
        <div class="row">
          <label class="col-sm-2 col-form-label">{{ 'common.Name' | translate }}</label>
          <div class="col-sm-4 form-group">
            <input type="text" class="form-control" formControlName="name">
          </div>
        </div>
        <div class="row">
          <label class="col-sm-2 col-form-label">{{ 'common.Description' | translate }}</label>
          <div class="col-sm-10 form-group">
            <input type="text" class="form-control" formControlName="description">
          </div>
        </div>
        <div class="row">
          <label class="col-sm-2 col-form-label">{{ 'common.Template' | translate }}</label>
          <div class="col-sm-10 form-group">
            <input type="text" class="form-control" formControlName="template">
          </div>
        </div>
      </form>
    </app-group-box>
  </div>

  <!-- -->
  <div class="">
    <app-group-box pageTitle="{{ 'page.CommandDetail.Section.Parameters.Title' | translate }}">
      <div class="box-standard-padding grid-container">
        <app-custom-grid [columns]="gridColumns" [items]="gridData" [filterable]="false" [showActions]="false" [toolbarActions]="gridToolbarActions" [editingInCell]="true"></app-custom-grid>
      </div>
    </app-group-box>
  </div>

</div>

<ng-template #columnTypeTemplate
             let-dataItem>
  {{ dataItem.type | parameterType }}
</ng-template>
