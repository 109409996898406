import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faPencilAlt, faTrashAlt, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { ModalService } from '@veritas-shared/services/modal.service';
import { SecurityService } from '@veritas-shared/services/security.service';
import { LabelListOutputLabel } from '../../models/label-list-output';
import { LabelService } from '../../services/label.service';

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss']
})
export class LabelsComponent implements OnInit {

  @ViewChild('isSystemColumn', { static: true }) public isSystemColumn: TemplateRef<any>;
  @ViewChild('colorColumn', { static: true }) public colorColumn: TemplateRef<any>;

  public columns: GridColumn[] = [];
  public actions: Action[] = [];
  public toolbarActions: Action[] = [];
  public gridData: LabelListOutputLabel[];

  constructor(
    private labelService: LabelService,
    private router: Router,
    private translate: TranslateService,
    private modalService: ModalService,
    private library: FaIconLibrary,
    private securityService: SecurityService,
    public constantHelper: ConstantHelper
  ) {
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);

    this.library.addIcons(faUserShield, faPencilAlt, faTrashAlt);

    this.actions = [
      new Action('view', this.constantHelper.LBL_ACTION_COMMON_VIEW, 'eye', this.edit),
    ];

    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_LABEL_MANAGEMENT).subscribe(result => {
      if (result) {
        this.actions = [
          new Action('edit', this.constantHelper.LBL_ACTION_COMMON_EDIT, 'pencil-alt', this.edit),
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DELETE, 'trash-alt', this.delete),
        ];

        this.toolbarActions = [
          new Action('add', this.constantHelper.LBL_ACTION_COMMON_ADD, 'plus', this.add)
        ];
      }
    });
  }

  ngOnInit() {

    this.columns = [
      {
        field: 'color',
        title: this.constantHelper.LBL_COMMON_COLOR,
        sortable: false,
        resizable: false,
        filterable: false,
        template: this.colorColumn,
        width: "70px"
      },
      {
        field: 'name',
        title: this.constantHelper.LBL_COMMON_NAME,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'link',
        callback: this.view
      },
      {
        field: 'dumpstersCount',
        title: this.constantHelper.LBL_VOCABULARY_LABEL_DUMPSTERS_COUNT,
        sortable: true,
        resizable: false,
        filterable: false,
        width: "70px"
      },
      {
        field: 'isSystem',
        title: this.constantHelper.LBL_COMMON_ISSYSTEM,
        sortable: true,
        resizable: false,
        filterable: false,
        template: this.isSystemColumn,
        width: "70px"
      }
    ];

    this.loadData();
  }

  public add() {
    this.router.navigate(['dumpsters', 'labels', 'new']);
  }

  public edit(label: LabelListOutputLabel) {
    this.router.navigate(['dumpsters', 'labels', label.id], { queryParams: { edit: true } });
  }

  public view(label: LabelListOutputLabel) {
    this.router.navigate(['dumpsters', 'labels', label.id]);
  }

  public delete(label: LabelListOutputLabel) {

    this.modalService.showConfirm(
      this.translate.instant(this.constantHelper.LBL_COMMON_CONFIRM_DELETE),
      () => {
        this.labelService.deleteLabel(label.id).subscribe(result => {
          this.loadData();
        });
      });
  }

  private loadData() {
    this.labelService.getLabels().subscribe(result => {
      this.gridData = result.labels;
    });
  }

}
