import { Injectable } from '@angular/core';

@Injectable()
export class ConstantHelper {

  //
  // API
  //

  // DUMPSTER
  DUMPSTERS_BASE = 'api/dumpster';
  DUMPSTERS_GET_DEASSOCIATED = 'api/dumpster/deassociated';
  DUMPSTERS_GET_DEACTIVATED = 'api/dumpster/deactivated';
  DUMPSTERS_GET_UNREGISTERED = 'api/dumpster/unregistered';
  DUMPSTERS_GET_NEW = 'api/dumpster/new';
  DUMPSTERS_GET_BY_DUMPSTER_ID = 'api/dumpster/:dumpsterId/';
  DUMPSTERS_GET_EDIT_V2 = 'api/dumpster/v2/:id/edit';
  DUMPSTERS_GET_EDIT = 'api/dumpster/:id/edit';
  DUMPSTERS_GET_REGISTER_DATA = 'api/dumpster/register/get';
  DUMPSTERS_GET_EVENTS = 'api/dumpster/events';
  DUMPSTERS_GET_EVENT_DETAIL = 'api/dumpster/event';
  DUMPSTERS_DOWNLOAD_EVENT_DETAIL = 'api/dumpster/eventFile';
  DUMPSTERS_GET_STATUS = 'api/dumpster/status';
  DUMPSTERS_REACTIVATE = 'api/dumpster/reactivate';
  DUMPSTERS_DEACTIVATE = 'api/dumpster/deactivate';
  DUMPSTERS_DELETE_MULTI = 'api/dumpster/delete';
  DUMPSTERS_DELETE = 'api/dumpster/:id';
  DUMPSTERS_LABEL_UPDATE = 'api/dumpster/label/update';
  DUMPSTERS_LABEL_NEW = 'api/dumpster/label/new';
  DUMPSTERS_GET_COLLECTION_POINT = 'api/dumpster/collectionpoint';
  DUMPSTERS_ASSOCIATE_COLLECTION_POINT = 'api/dumpster/collectionpoint/associate';
  DUMPSTERS_DEASSOCIATE_COLLECTION_POINT = 'api/dumpster/collectionpoint/deassociate';
  DUMPSTERS_ADD_TO_GROUP = 'api/dumpster/group/add';
  DUMPSTERS_UPLOAD = 'api/dumpster/upload';
  DUMPSTERS_REGISTER = 'api/dumpster/register';
  DUMPSTERS_SET_CUSTOM_ADDRESS = 'api/dumpster/setCustomAddress';
  DUMPSTERS_RESET_CUSTOM_ADDRESS = 'api/dumpster/:id/resetCustomAddress';


  // TICKET
  TICKETS_BASE = "api/ticket";
  TICKETS_GET_NEW = "api/ticket/new";
  TICKETS_GET_DETAIL = "api/ticket/detail";
  TICKETS_OPEN = "api/ticket/open";
  TICKETS_UPDATE = "api/ticket/update";
  TICKETS_CLOSE = "api/ticket/close";

  // ENTITIES
  ENTITIES_GET = "api/entity";

  // DEVICE
  GET_DEVICE_GROUPS = 'api/dumpster/:deviceexternalid/groups'

  //
  // COMMON
  //

  LBL_COMMON_NAME = 'common.Name';
  LBL_COMMON_DESCRIPTION = 'common.Description';
  LBL_COMMON_ISSYSTEM = 'common.IsSystem';
  LBL_COMMON_ISENABLED = 'common.IsEnabled';
  LBL_COMMON_OK = 'common.Ok';
  LBL_COMMON_YES = 'common.Yes';
  LBL_COMMON_NO = 'common.No';
  LBL_COMMON_COLOR = 'common.Color';
  LBL_COMMON_TK_CREATE = 'common.createTk';
  LBL_COMMON_TK_OPEN = 'common.openTk';
  LBL_COMMON_TAGS = 'common.tags';
  LBL_COMMON_PROPERTY = 'common.property';
  LBL_ACTION_CONFIRM = 'common.confirm';
  
  LBL_COMMON_SHOW_SELECTED = 'common.ShowOnlySelected';

  LBL_COMMON_AUDITING_CREATION = 'common.Auditing.Creation';
  LBL_COMMON_AUDITING_EDIT = 'common.Auditing.Edit';
  LBL_COMMON_AUDITING_DEACTIVATION = 'common.Auditing.Deactivation';

  LBL_COMMON_CONFIRM_DELETE = 'common.Confirm.Delete';

  LBL_COMMON_FROM = 'common.From';
  LBL_COMMON_TO = 'common.To';

  LBL_COMMON_WARNING = 'common.Warning';

  LBL_COMMON_ERROR_REQUIRED = 'common.Error.required';
  LBL_COMMON_ERROR_MAX_ITEMS = 'common.Error.maxItems';

  //
  // MENU
  //
  LBL_MENU_HOME = 'menu.Home';
  LBL_MENU_ADMINISTRATION = 'menu.Administration';

  LBL_MENU_DUMPSTERS_REGISTRY = 'menu.Dumpsters.Registry';

  //
  // GRID
  //
  LBL_GRID_COLUMN_MENU = 'component.grid.COLUMNMENU';

  //
  // PAGES
  //

  // COMMON
  LBL_PAGE_COMMON_AUDITING_TITLE = 'page.Common.Section.Auditing.Title';

  // DUMPSTERS
  LBL_PAGE_DUMPSTERS_TITLE = 'page.Dumpsters.Title';
  LBL_PAGE_DUMPSTERS_BOX_TITLE = 'page.Dumpsters.DumpstersList'; // Elenco Dispositivi
  LBL_PAGE_DUMPSTERS_NUMBEROFFILTERED = 'page.Dumpsters.NumberOfFiltered';

  LBL_PAGE_DUMPSTER_DIALOG_SETGROUPS_TITLE = 'page.Dumpster.Dialog.SetGroups.Title';
  LBL_PAGE_DEVICES_DIALOG_ADDTOGROUP_CONFIRM_MESSAGE = 'page.Dumpsters.Dialog.AddToAuthorizationGroup.ConfirmationMessage';
  LBL_PAGE_DEVICES_DIALOG_LABELS_SUCCESS_MESSAGE = 'page.Dumpsters.Dialog.Labels.SuccessMessage';

  LBL_PAGE_DUMPSTERS_DIALOG_IMPORT_TITLE = 'page.Dumpsters.Dialog.Import.Title';
  LBL_PAGE_DUMPSTERS_DIALOG_IMPORT_MERGE = 'page.Dumpsters.Dialog.Import.Merge';
  LBL_PAGE_DUMPSTERS_DIALOG_IMPORT_RESULT = 'page.Dumpsters.Dialog.Import.Result';

  LBL_PAGE_DUMPSTERS_DIALOG_RUN_COMMAND_TITLE = 'page.Dumpsters.Dialog.RunCommand.Title';
  LBL_PAGE_DUMPSTERS_DIALOG_RUN_COMMAND_WARNING_MESSAGE = 'page.Dumpsters.Dialog.RunCommand.WarningMessage';
  LBL_PAGE_DUMPSTERS_DIALOG_RUN_COMMAND_CONFIRMATION_MESSAGE = 'page.Dumpsters.Dialog.RunCommand.ConfirmationMessage';

  // DEVICE DETAIL
  LBL_PAGE_DEVICEDETAIL_NEW_TITLE = 'page.DumpsterNew.Title';

  LBL_PAGE_DEVICEDETAIL_GENERAL_TITLE = 'page.DumpsterDetail.Section.General.Title';
  LBL_PAGE_DEVICEDETAIL_TIMELINE_TITLE = 'page.DumpsterDetail.Section.Timeline.Title';
  LBL_PAGE_DEVICEDETAIL_STATUS_TITLE = 'page.DumpsterDetail.Section.Status.Title';
  LBL_PAGE_DEVICEDETAIL_MAP_TITLE = 'page.DumpsterDetail.Section.Map.Title';
  LBL_PAGE_DEVICEDETAIL_GROUPS_TITLE = 'page.DumpsterDetail.Section.AuthorizationGroups.Title';

  LBL_PAGE_DEVICEDETAIL_COLLECTION_POINT_DISTANCE = 'page.DumpsterDetail.CollectionPoint.Distance';
  LBL_PAGE_DEVICEDETAIL_REGION_CODE_FIRST = 'page.DumpsterDetail.Dumpster.RegionCodeFirst';
  LBL_PAGE_DEVICEDETAIL_REGION_CODE_SECOND = 'page.DumpsterDetail.Dumpster.RegionCodeSecond';
  LBL_PAGE_DEVICEDETAIL_REGION_CODE_THIRD = 'page.DumpsterDetail.Dumpster.RegionCodeThird';
  LBL_PAGE_DEVICEDETAIL_FILLPERCENTAGE = 'page.DumpsterDetail.Dumpster.FillLevel';

  LBL_PAGE_DEVICEDETAIL_DIALOG_DEACTIVATE_CONFIRM_MESSAGE = 'page.DumpsterDetail.Dialog.Deactivate.ConfirmationMessage';
  LBL_PAGE_DUMPSTERDETAIL_DIALOG_COLLECTION_POINT_TITLE = 'page.DumpsterDetail.Dialog.SelectCollectionPoint.Title';
  LBL_PAGE_DEVICEDETAIL_DIALOG_TIMELINE_TITLE = 'page.DumpsterDetail.Dialog.TimeLine.Title'; // Dettaglio Evento

  LBL_PAGE_DEVICEDETAIL_EVENT_EXPORT_ACTION = 'page.DumpsterDetail.Event.Export.Action'; // Esporta Evento

  LBL_PAGE_DEASSOCIATEDDUMPSTERS_DIALOG_ASSOCIATE_COLLPOINT_CONFIRM_MESSAGE = 'page.DeassociatedDumpsters.Dialog.AssociateCollectionPoint.ConfirmationMessage';

  LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LOW_LEVEL = 'page.DumpsterDetail.Dialog.Config.LowLevel';
  LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_HIGH_LEVEL = 'page.DumpsterDetail.Dialog.Config.HighLevel';
  LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LOW_LEVEL_PERCENTAGE = 'page.DumpsterDetail.Dialog.Config.LowLevelPercentage';
  LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_HIGH_LEVEL_PERCENTAGE = 'page.DumpsterDetail.Dialog.Config.HighLevelPercentage';
  LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_TOGGLE = 'page.DumpsterDetail.Dialog.Config.Toggle';
  LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_TITLE = 'page.DumpsterDetail.Dialog.Config.Title';
  LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_CONFERMENT_TITLE = 'page.DumpsterDetail.Dialog.Config.ConfermentTitle';
  LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LAST_CONFERMENT_TITLE = 'page.DumpsterDetail.Dialog.Config.LastConfermentTitle';
  LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LAST_PASSAGE_TITLE = 'page.DumpsterDetail.Dialog.Config.LastPassageTitle';
  LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_LAST_CONNECTION_TITLE = 'page.DumpsterDetail.Dialog.Config.LastConnectionTitle';
  LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_MAX_BOOKINGS = 'page.DumpsterDetail.Dialog.Config.MaxBookings';

  // DEASSOCIATED DUMPSTER
  LBL_PAGE_DEASSOCIATED_DUMPSTERS_TITLE = 'page.DeassociatedDumpsters.Title';

  // DEACTIVATED DUMPSTERS
  LBL_PAGE_DEACTIVATED_DEVICES_TITLE = 'page.DeactivatedDumpsters.Title';
  LBL_PAGE_DEACTIVATED_DEVICES_BOX_TITLE = 'page.DeactivatedDumpsters.DumpstersList';

  // UNREGISTERED DUMPSTERS
  LBL_PAGE_UNREGISTERED_DEVICES_TITLE = 'page.UnregisteredDumpsters.Title';

  // COLLECTION POINTS
  LBL_PAGE_COLL_POINTS_NUMBEROFFILTERED = 'page.CollectionPoints.NumberOfFiltered';

  // COLLECTION POINT DETAIL
  LBL_PAGE_COLL_POINT_DETAIL_DUMPSTER_TITLE = 'page.CollectionPointDetail.Section.Dumpsters.Title';
  LBL_PAGE_COLL_POINT_DETAIL_SELECTED_DUMPSTER_TITLE = 'page.CollectionPointDetail.Dialog.SelectDumpsters.Title';

  // AUTHORIZATION GROUPS
  LBL_PAGE_AUTHGROUPS_TITLE = 'page.AuthorizationGroups.Title';
  LBL_PAGE_AUTHGROUPS_BOX_TITLE = 'page.AuthorizationGroups.GroupList'; // Elenco Gruppi
  LBL_PAGE_AUTHGROUPS_NUMBEROFFILTERED = 'page.Groups.NumberOfFiltered';
  LBL_PAGE_AUTHGROUPS_DIALOG_DELETECOLLECTION_POINTGROUP_WARNING = 'page.AuthorizationGroups.Dialog.DeleteCollectionPointGroup.WarningMessage';
  LBL_PAGE_AUTHGROUPS_DIALOG_DELETEDUMPSTERGROUP_WARNING = 'page.AuthorizationGroups.Dialog.DeleteDumpsterGroup.WarningMessage';

  // AUTHORIZATION GROUP DETAIL
  LBL_PAGE_AUTHGROUP_NEW_TITLE = 'page.AuthorizationGroupNew.Title';
  LBL_PAGE_AUTHGROUP_DETAIL_GENERAL_TITLE = 'page.AuthorizationGroupDetail.Section.General.Title';
  LBL_PAGE_AUTHGROUP_DETAIL_DUMPSTERS_TITLE = 'page.AuthorizationGroupDetail.Section.Dumpsters.Title';
  LBL_PAGE_AUTHGROUP_DETAIL_DUMPSTERS_DISABLED_CHECKBOX = 'page.AuthorizationGroupDetail.Section.Dumpsters.DisabledCheckbox';
  LBL_PAGE_AUTHGROUP_DETAIL_AUTHORIZATIONS_TITLE = 'page.AuthorizationGroupDetail.Section.Authorizations.Title';

  LBL_PAGE_AUTHGROUP_DETAIL_DIALOG_SEL_DUMPSTERS_TITLE = 'page.AuthorizationGroupDetail.Dialog.SelectDumpsters.Title';

  LBL_PAGE_AUTHGROUP_DETAIL_ADDDUMPSTERS_CONFIRM_MESSAGE = 'page.AuthorizationGroupDetail.Dialog.AddDumpsters.ConfirmationMessage';
  LBL_PAGE_AUTHGROUP_DETAIL_DELETED_CONFIRM_MESSAGE = 'page.AuthorizationGroupDetail.Dialog.Delete.ConfirmationMessage';
  LBL_PAGE_AUTHGROUP_DETAIL_DELETEDUMPSTERS_CONFIRM_MESSAGE = 'page.AuthorizationGroupDetail.Dialog.DeleteDumpsters.ConfirmationMessage';

  // LABELS
  LBL_PAGE_LABELS_TITLE = 'page.Labels.Title';
  LBL_COMMON_CONFIRM = 'common.confirm';
  LBL_COMMON_CONFIRM_TK = 'common.confirmOpenTk';
  LBL_COMMON_DESTINATION_DEFAULT = 'common.defaultMail';
  LBL_COMMON_DESTINATION_ADD = 'common.addMail';

  // LABEL DETAIL
  LBL_PAGE_LABEL_NEW_TITLE = 'page.LabelNew.Title';
  LBL_PAGE_LABEL_DETAIL_DIALOG_DELETE_CONFIRM_MESSAGE = 'page.LabelDetail.Dialog.Delete.ConfirmationMessage';

  // AUTHORIZATIONS

  // AUTHORIZATION DETAIL
  LBL_PAGE_AUTH_DETAIL_USERS_TITLE = 'page.AuthorizationDetail.Section.Users.Title';
  LBL_PAGE_AUTH_DETAIL_DIALOG_SEL_USERS_TITLE = 'page.AuthorizationDetail.Dialog.SelectUsers.Title';
  LBL_PAGE_AUTH_DETAIL_ADDUSERS_CONFIRM_MESSAGE = 'page.AuthorizationDetail.Dialog.AddUsers.ConfirmationMessage';
  LBL_PAGE_AUTH_DETAIL_DELETEUSERS_CONFIRM_MESSAGE = 'page.AuthorizationDetail.Dialog.DeleteUsers.ConfirmationMessage';

  LBL_PAGE_AUTH_DETAIL_NO_GLOBAL_GROUP_MESSAGE = 'page.AuthorizationDetail.NoGlobalGroupMessage';

  // ROLES
  LBL_PAGE_ROLES_TITLE = 'page.Roles.Title';
  LBL_PAGE_ROLES_DELETEROLE_ERROR_MESSAGE = 'page.Roles.Dialog.DeleteRole.ErrorMessage';

  // ROLE DETAIL
  LBL_PAGE_ROLE_NEW_TITLE = 'page.RoleNew.Title';
  LBL_PAGE_ROLE_DETAIL_DELETE_CONFIR_MESSAGE = 'page.RoleDetail.Dialog.Delete.ConfirmationMessage';

  // USERS
  LBL_PAGE_USERS_NUMBEROFFILTERED = 'page.Users.NumberOfFiltered';

  // COMMANDS
  LBL_PAGE_COMMANDS_TITLE = 'page.Commands.Title';
  LBL_PAGE_COMMANDS_GRID_TITLE = 'page.Commands.GridTitle';

  // COMMAND EVENTS
  LBL_PAGE_COMMAND_EVENT_DETAIL_DUMPSTERS = 'page.CommandEventsDetail.Dumpsters';
  LBL_PAGE_COMMAND_EVENT_DETAIL_DUMPSTER_NAME = 'page.CommandEventsDetail.DumpsterName';

  LBL_PAGE_COMMAND_EVENT_DUMPSTER_STATUS = 'page.CommandEvent.DumpsterStatus';
  LBL_PAGE_COMMAND_EVENTS_DUMPSTERS_STATUS = 'page.CommandEvents.DumpstersStatus';

  // COMMAND TASK
  LBL_PAGE_COMMAND_TASK_SECTION_DUMPSTERS_TITLE = 'page.CommandTask.Section.Dumpsters.Title';

  LBL_PAGE_COMMAND_TASK_DUMPSTER_COUNT = 'page.CommandTasks.DumpsterCount';

  // TICKETS
  LBL_PAGE_TICKETS_TITLE = 'page.Tickets.Title'
  LBL_PAGE_TICKETS_NUMBEROFFILTERED = 'page.Tickets.NumberOfFiltered';
  LBL_PAGE_TICKETS_CLOSE_TITLE = 'page.Tickets.CloseTitle';

  // TICKET DETAIL
  LBL_PAGE_TICKETDETAIL_NEW_TITLE = 'page.TicketNew.Title';
  LBL_PAGE_TICKETDETAIL_GENERAL_TITLE = 'page.TicketDetail.Section.General.Title';
  LBL_PAGE_TICKETDETAIL_OPENING_REASON = 'page.TicketDetail.Section.General.OpeningReason';
  LBL_PAGE_TICKETDETAIL_CLOSURE_REASON = 'page.TicketDetail.Section.General.ClosureReason';

  LBL_PAGE_TICKETDETAIL_HISTORY_TITLE = 'page.TicketDetail.Section.History.Title';

  //
  // COMPONENT
  //

  // DEVICE
  LBL_COMPONENT_DEVICE_DIALOG_SELECTLABELS = 'component.Dumpster.Dialog.SelectLabels.Title';

  // TAB
  LBL_COMPONENT_DUMPSTERS_TAB_LIST = 'component.Dumpsters.Tab.List';
  LBL_COMPONENT_DUMPSTERS_TAB_MAP = 'component.Dumpsters.Tab.Map';

  LBL_COMPONENT_COLLP_TAB_LIST = 'component.CollectionPoints.Tab.List';
  LBL_COMPONENT_COLLP_TAB_MAP = 'component.CollectionPoints.Tab.Map';

  // MAP
  LBL_COMPONENT_MAP_CENTER = 'component.Map.Center'; // Centra

  // TICKET
  LBL_COMPONENT_TICKET_SEARCH_TITLE = 'component.TicketSearch.Title';

  //
  // VOCABULARY
  //
  LBL_VOCABULARY_DUMPSTERS = 'vocabulary.Dumpsters';
  LBL_VOCABULARY_DUMPSTER = 'vocabulary.Dumpster';
  LBL_VOCABULARY_NOTE = 'vocabulary.CollectionPoint.Note';


  LBL_VOCABULARY_DUMPSTER_CODE = 'vocabulary.Dumpster.DumpsterCode';
  LBL_VOCABULARY_DUMPSTER_SUPPLIER = 'vocabulary.Dumpster.Supplier';
  LBL_VOCABULARY_DUMPSTER_TYPE = 'vocabulary.Dumpster.DumpsterType';
  LBL_VOCABULARY_DUMPSTER_GARBAGE_TYPE = 'vocabulary.Dumpster.GarbageType';
  LBL_VOCABULARY_DUMPSTER_NAME = 'vocabulary.Dumpster.Name';
  LBL_VOCABULARY_DUMPSTER_LABELS = 'vocabulary.Dumpster.Labels';
  LBL_VOCABULARY_DUMPSTER_STATUS = 'vocabulary.Dumpster.Status';
  LBL_VOCABULARY_DUMPSTER_REGION_CODE = 'vocabulary.Dumpster.RegionCode';
  LBL_VOCABULARY_DUMPSTER_ERROR_COUNT = 'vocabulary.Dumpster.ErrorCount';
  LBL_VOCABULARY_DUMPSTER_WARNING_COUNT = 'vocabulary.Dumpster.WarningCount';
  LBL_VOCABULARY_DUMPSTER_USER_TYPE = 'vocabulary.Dumpster.UserType';
  LBL_VOCABULARY_DUMPSTER_TAG = 'vocabulary.Dumpster.Tag';
  LBL_VOCABULARY_DUMPSTER_GUID = 'vocabulary.Dumpster.Guid';
  LBL_VOCABULARY_DUMPSTER_BOOKINGNUMBER = 'vocabulary.Dumpster.BookingNumber';

  LBL_VOCABULARY_DEVICESTATUS_LAST_CONN = 'vocabulary.DumpsterStatus.LastConnection';
  LBL_VOCABULARY_DEVICESTATUS_BATTERY_LEVEL = 'vocabulary.DumpsterStatus.BatteryLevel';
  LBL_VOCABULARY_DEVICESTATUS_LOCATION_DET = 'vocabulary.DumpsterStatus.LocationDetected';
  LBL_VOCABULARY_DEVICESTATUS_LAST_CONFERMENT = 'vocabulary.DumpsterStatus.LastConferment';
  LBL_VOCABULARY_DEVICESTATUS_LAST_TRUCK = 'vocabulary.DumpsterStatus.LastTruckPassage';
  LBL_VOCABULARY_DEVICESTATUS_FILL_PERCENT = 'vocabulary.DumpsterStatus.FillPercent';

  LBL_VOCABULARY_TIMELINE_TITLE = 'vocabulary.TimeLine.Title';
  LBL_VOCABULARY_TIMELINE_DATE = 'vocabulary.TimeLine.Date';
  LBL_VOCABULARY_TIMELINE_STATUS = 'vocabulary.TimeLine.Status';

  LBL_VOCABULARY_LOCATION_LATITUDE = 'vocabulary.Location.Latitude';
  LBL_VOCABULARY_LOCATION_LONGITUDE = 'vocabulary.Location.Longitude';

  LBL_VOCABULARY_COLLECTIONPOINT = 'vocabulary.CollectionPoint';
  LBL_VOCABULARY_COLLECTIONPOINT_CODE = 'vocabulary.CollectionPoint.CollectionPointCode';
  LBL_VOCABULARY_COLLECTIONPOINT_NAME = 'vocabulary.CollectionPoint.Name';
  LBL_VOCABULARY_COLLECTIONPOINT_CITY = 'vocabulary.CollectionPoint.City';
  LBL_VOCABULARY_COLLECTIONPOINT_DESCRIPTION = 'vocabulary.CollectionPoint.Description';
  LBL_VOCABULARY_COLLECTIONPOINT_DUMPSTERS_COUNT = 'vocabulary.CollectionPoint.DumpstersCount';

  LBL_VOCABULARY_AUTHGROUP = 'vocabulary.AuthorizationGroup';
  LBL_VOCABULARY_AUTHGROUP_TYPE = 'vocabulary.AuthorizationGroup.Type';
  LBL_VOCABULARY_AUTHGROUP_DUMPSTERSCOUNT = 'vocabulary.AuthorizationGroup.DumpstersCount';

  LBL_VOCABULARY_LABEL_DUMPSTERS_COUNT = 'vocabulary.Label.DumpstersCount';
  LBL_VOCABULARY_LABEL_COLOR = 'vocabulary.Label.Color';

  LBL_VOCABULARY_TICKETS = 'vocabulary.Tickets';
  LBL_VOCABULARY_TICKET_ID = 'vocabulary.Ticket.Id';
  LBL_VOCABULARY_TICKET_TYPE = 'vocabulary.Ticket.Type';
  LBL_VOCABULARY_TICKET_MUNICIPALITY = 'vocabulary.Ticket.Municipality';
  LBL_VOCABULARY_TICKET_ASSIGNED_TO = 'vocabulary.Ticket.AssignedTo';
  LBL_VOCABULARY_TICKET_START_DATE = 'vocabulary.Ticket.StartDate';
  LBL_VOCABULARY_TICKET_END_DATE = 'vocabulary.Ticket.EndDate';
  LBL_VOCABULARY_TICKET_SENDER_TYPE = 'vocabulary.Ticket.SenderType';
  LBL_VOCABULARY_TICKET_ASSIGNED_FROM = 'vocabulary.Ticket.AssignedFrom';
  LBL_VOCABULARY_TICKET_TAGS = 'vocabulary.Ticket.Tags';
  LBL_VOCABULARY_TICKET_OWNER = 'vocabulary.Ticket.Owner';
  LBL_VOCABULARY_TICKET_STATE = 'vocabulary.Ticket.State';
  LBL_VOCABULARY_TICKET_CLOSURE_REASONS = 'vocabulary.Ticket.ClosureReasons';
  LBL_VOCABULARY_TICKET_OPENING_REASONS = 'vocabulary.Ticket.OpeningReasons';
  LBL_VOCABULARY_TICKET_CODE = 'vocabulary.Ticket.Code';
  LBL_VOCABULARY_TICKET_BIN_ID = 'vocabulary.Ticket.BinId';
  LBL_VOCABULARY_TICKET_SEARCH_DATE_FROM = 'vocabulary.Ticket.SearchDateFrom';
  LBL_VOCABULARY_TICKET_SEARCH_DATE_TO = 'vocabulary.Ticket.SearchDateTo';
  LBL_VOCABULARY_TICKET_ADDRESS = 'vocabulary.Ticket.Address';

  LBL_VOCABULARY_AUTHORIZATION_USERS_COUNT = 'vocabulary.Authorization.UsersCount';

  LBL_VOCABULARY_ROLE = 'vocabulary.Role';
  LBL_VOCABULARY_ROLE_ISGLOBAL = 'vocabulary.Role.IsGlobal';
  LBL_VOCABULARY_ROLE_AUTHORIZATIONS_COUNT = 'vocabulary.Role.AuthorizationsCount';

  LBL_VOCABULARY_COMMAND_NAME = 'vocabulary.Command.Name';
  LBL_VOCABULARY_COMMAND_DESCRIPTION = 'vocabulary.Command.Name';


  //
  // PERMISSION
  //
  LBL_PERMISSION_DUMPSTER_WRITE = 'dumpster.write';
  LBL_PERMISSION_LABEL_MANAGEMENT = 'dumpster.labelManagement';

  LBL_PERMISSION_COLLECTION_POINT_WRITE = 'collectionPoint.write';
  LBL_PERMISSION_COLLECTION_POINT_UPLOAD = 'collectionPoint.upload';
  LBL_PERMISSION_GROUP_WRITE = 'group.write';
  LBL_PERMISSION_ROLE_WRITE = 'role.write';
  LBL_PERMISSION_AUTHORIZATION_READ = 'authorization.read';
  LBL_PERMISSION_AUTHORIZATION_WRITE = 'authorization.write';
  LBL_PERMISSION_TICKET_WRITE = 'ticket.write';

  //
  // ACTION
  //
  LBL_ACTION_COMMON_ADD = 'action.Common.Add';
  LBL_ACTION_COMMON_EDIT = 'action.Common.Edit';
  LBL_ACTION_COMMON_DELETE = 'action.Common.Delete';
  LBL_ACTION_COMMON_CANCEL = 'action.Common.Cancel';
  LBL_ACTION_COMMON_SAVE = 'action.Common.Save';
  LBL_ACTION_COMMON_DEACTIVATE = 'action.Common.Deactivate';
  LBL_ACTION_COMMON_IMPORT = 'action.Common.Import';
  LBL_ACTION_COMMON_CLOSE = 'action.Common.Close';
  LBL_ACTION_COMMON_VIEW_DUMPSTERS = 'action.Common.ViewDumpsters';
  LBL_ACTION_COMMON_SEARCH = 'action.Common.Search';
  LBL_ACTION_COMMON_DISABLE = 'action.Common.Disable';
  LBL_ACTION_COMMON_ENABLE = 'action.Common.Enable';
  LBL_ACTION_COMMON_RUN = 'action.Common.Run';
  LBL_ACTION_COMMON_VIEW = 'action.Common.View';
  LBL_ACTION_COMMON_BACK = 'action.Common.Back';


  LBL_ACTION_DUMPSTER_ADD_TOGROUP = 'action.Dumpster.AddToGroup';
  LBL_ACTION_DUMPSTER_REMOVE_FROMGROUP = 'action.Dumpster.RemoveFromGroup';
  LBL_ACTION_DUMPSTER_REACTIVATE = 'action.Dumpster.Reactivate';
  LBL_ACTION_DUMPSTER_ADD_TO_COLLPOINT = 'action.Dumpster.AddToCollectionPoint';
  LBL_ACTION_DUMPSTER_SET_LABELS = 'action.Dumpster.SetLabels';
  LBL_ACTION_DUMPSTER_SET_AUTH_GROUPS = 'action.Dumpster.SetAuthorizationGroups';
  LBL_ACTION_DUMPSTER_RUN_COMMAND = 'action.Dumpster.RunCommand';

  LBL_ACTION_COLLPOINT_REACTIVATE = 'action.CollectionPoint.Reactivate';
  LBL_ACTION_COLLPOINT_ADD_DUMPSTER = 'action.CollectionPoint.Dumpster.Add';
  LBL_ACTION_COLLPOINT_DELETE_DUMPSTER = 'action.CollectionPoint.Dumpster.Delete';

  LBL_ACTION_AUTH_ADD_USER = 'action.Authorization.AddUser';
  LBL_ACTION_AUTH_REMOVE_USER = 'action.Authorization.RemoveUser';

  LBL_ACTION_TICKET_CLOSE = 'action.Ticket.Close';

  LBL_ACTION_DUMPSTER_SET_CONFIGURATION = 'action.Dumpster.SetConfiguration';


  //
  // ASSETS & SUPPORT
  //

  IMG_PIN_DOT = 'red-dot.png';
  IMG_RED_DOT = 'dumpster-red.svg';
  // IMG_RED_DOT = 'red-dot.png';
  IMG_YELLOW_DOT = 'dumpster-yellow.svg';
  // IMG_YELLOW_DOT = 'yellow-dot.png';
  IMG_GREEN_DOT = 'dumpster-green.svg';
  // IMG_GREEN_DOT = 'green-dot.png';

  DATE_FORMAT = 'dd/MM/yyyy';
  DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';

  MAILING_LIST_VALIDATOR_REGEX = new RegExp(/^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/);

}
