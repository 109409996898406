import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BeanDialogComponentBase, IDialogInput } from 'bean-kendo-dialog';
import { Observable, of } from 'rxjs';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { 
  CommandConfigurationRequest, DumpsterFullConfig, GetConfigurationResponse, LastBookingConfig, LastConnectionConfig,
  LastEmptyingConfig, Parameter, SetParametersCommand
} from '@veritas-dumpsters/models/dumpster-configuration';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { CommandService } from 'src/app/commands/services/command.service';
import { ToastService } from '@veritas-shared/services/toast.service';
import { ConfigurationMaskService } from '@veritas-dumpsters/services/configuration-mask.service';


@Component({
  selector: 'app-dumpster-configuration-dialog',
  templateUrl: './dumpster-configuration-dialog.component.html',
  styleUrls: ['./dumpster-configuration-dialog.component.scss']
})
export class DumpsterConfigurationComponent extends BeanDialogComponentBase<DumpsterConfigurationDialogInput> implements OnInit, AfterViewInit {
  public configForm: FormGroup;

  public dumpsterFullConfig: DumpsterFullConfig = new DumpsterFullConfig();
  public lastEmptyingConfig: LastEmptyingConfig = new LastEmptyingConfig();
  public lastBookingConfig: LastBookingConfig = new LastBookingConfig();
  public lastConnectionConfig: LastConnectionConfig = new LastConnectionConfig();

  public toggleSave = true;

  constructor(
    public constantHelper: ConstantHelper,
    public toastService: ToastService,
    private library: FaIconLibrary,
    private fb: FormBuilder,
    private commandService: CommandService,
    private maskService: ConfigurationMaskService,
  ) {
    super();

    this.library.addIcons(faChevronLeft, faSave);
  }

  ngOnInit(): void { 
    this.formInit();
  }

  ngOnChanges(changes) { }
  ngAfterViewInitCallback(): void { }

  ngAfterViewInit(): void { 
    this._setDumpsterFullConfig();
    this._setLastEmptyingConfig();
    this._setLastBookingConfig();
    this._setLastConnectionConfig();
  }

  public checkAllConfigStatus = (): boolean => this.dumpsterFullConfig.lastForwardStatus.toLowerCase() === 'sent'
    && this.lastBookingConfig.lastForwardStatus.toLowerCase() === 'sent'
    && this.lastEmptyingConfig.lastForwardStatus.toLowerCase() === 'sent'
    && this.lastConnectionConfig.lastForwardStatus.toLowerCase() === 'sent'

  public formInit = (): void => {
    this.configForm= this.fb.group({
      dumpsterFullLowLevel: [{ value:'', disabled: false }, [Validators.required]],
      dumpsterFullHighLevel: [{ value:'', disabled: false }, [Validators.required]],
      dumpsterFullMaxBookings: [{ value:'', disable: false }, [Validators.required]],
      dumpsterFullEnabled: [{ value:'', disabled: false }, [Validators.required]],

      lastBookingLowLevel: [{ value:'', disabled: false }, [Validators.required]],
      lastBookingHighLevel: [{ value:'', disabled: false }, [Validators.required]],
      lastBookingEnabled: [{ value:'', disabled: false }, [Validators.required]],

      lastEmptyingLowLevel: [{ value:'', disable: false }, [Validators.required]],
      lastEmptyingHighLevel: [{ value:'', disable: false }, [Validators.required]],
      lastEmptyingEnabled: [{ value:'', disabled: false }, [Validators.required]],

      lastConnectionLowLevel: [{ value:'', disable: false }, [Validators.required]],
      lastConnectionHighLevel: [{ value:'', disable: false }, [Validators.required]],
      lastConnectionEnabled: [{ value:'', disabled: false }, [Validators.required]]
    })

    this.reloadDumpsterFull();
    this.reloadLastBooking();
    this.reloadLastEmptying();
    this.reloadLastConnection();
  }

  public reloadDumpsterFull = () => {
    this.configForm.get('dumpsterFullLowLevel').setValue(this.dumpsterFullConfig.lowLevel);
    this.configForm.get('dumpsterFullHighLevel').setValue(this.dumpsterFullConfig.highLevel);
    this.configForm.get('dumpsterFullMaxBookings').setValue(this.dumpsterFullConfig.maxBookings);
    this.configForm.get('dumpsterFullEnabled').setValue(this.dumpsterFullConfig.enabled);
  }

  public reloadLastBooking = () => {
    this.configForm.get('lastBookingLowLevel').setValue(this.lastBookingConfig.lowLevel);
    this.configForm.get('lastBookingHighLevel').setValue(this.lastBookingConfig.highLevel);
    this.configForm.get('lastBookingEnabled').setValue(this.lastBookingConfig.enabled);
  }

  public reloadLastEmptying = () => {
    this.configForm.get('lastEmptyingLowLevel').setValue(this.lastEmptyingConfig.lowLevel);
    this.configForm.get('lastEmptyingHighLevel').setValue(this.lastEmptyingConfig.highLevel);
    this.configForm.get('lastEmptyingEnabled').setValue(this.lastEmptyingConfig.enabled);
  }

  public reloadLastConnection = () => {
    this.configForm.get('lastConnectionLowLevel').setValue(this.lastConnectionConfig.lowLevel);
    this.configForm.get('lastConnectionHighLevel').setValue(this.lastConnectionConfig.highLevel);
    this.configForm.get('lastConnectionEnabled').setValue(this.lastConnectionConfig.enabled);
  }

  public IsNullOrEmpty = (str?: string): boolean => !str || str?.length === 0;

  public onSave = () => {
    const request = this.MapConfigurationFormToCommandRequest(this.data.dumpster.id,this.configForm); //TODO ereditare Dumpster da padre per avere sumpsterId

    this.commandService.sendConfiguration(request)
    this.toggleSave = false;
    this.toastService.showSuccess("Configurazione salvata con successo.")
  }

  private _setDumpsterFullConfig = () => this.maskService.getDumpsterEdit({
    id: this.data?.dumpster?.dumpsterFullConfigTransactionId,
    data: this.data,
    func: (command: GetConfigurationResponse) => {
      this.dumpsterFullConfig = {
        ...this.dumpsterFullConfig,
        lowLevel: this.maskService.getParam(command?.parameters, 'DisposalLimitPrealarmPercentage') ?? this.dumpsterFullConfig?.lowLevel,
        highLevel: this.maskService.getParam(command?.parameters, 'DisposalLimitAlarmPercentage') ?? this.dumpsterFullConfig?.highLevel,
        maxBookings: this.maskService.getParam(command?.parameters, 'MaxDisposalNumber') ?? this.dumpsterFullConfig?.maxBookings,
        enabled: command?.enabled ?? this.dumpsterFullConfig?.enabled,
        forwardTimestamp: command?.forwardTimestamp ?? this.dumpsterFullConfig?.forwardTimestamp,
        lastForwardStatus: command?.lastForwardStatus ?? this.dumpsterFullConfig?.lastForwardStatus,
        feedbackTimestamp: command?.feedbackTimestamp ?? this.dumpsterFullConfig?.feedbackTimestamp
      };

      this.reloadDumpsterFull();
    }
  })

  private _setLastEmptyingConfig = () => this.maskService.getDumpsterEdit({
    id: this.data?.dumpster?.lastEmptyingConfigTransactionId,
    data: this.data,
    func: (command: GetConfigurationResponse) => {
      this.lastEmptyingConfig = {
        ...this.lastEmptyingConfig,
        lowLevel: this.maskService.getParam(command?.parameters, 'LowerThreshold') ?? this.lastEmptyingConfig.lowLevel,
        highLevel: this.maskService.getParam(command?.parameters, 'HigherThreshold') ?? this.lastEmptyingConfig.highLevel,
        enabled: command?.enabled ?? this.lastEmptyingConfig.enabled,
        forwardTimestamp: command?.forwardTimestamp ?? this.lastEmptyingConfig.forwardTimestamp,
        lastForwardStatus: command?.lastForwardStatus ?? this.lastEmptyingConfig.lastForwardStatus,
        feedbackTimestamp: command?.feedbackTimestamp ?? this.lastEmptyingConfig.feedbackTimestamp,
      }

      this.reloadLastEmptying()
    }
  })

  private _setLastBookingConfig = () => this.maskService.getDumpsterEdit({
    id: this.data.dumpster.lastBookingConfigTransactionId,
    data: this.data,
    func: (command: GetConfigurationResponse) => {
      this.lastBookingConfig.lowLevel = this.maskService.getParam(command?.parameters, "LowerThreshold") ?? this.lastBookingConfig.lowLevel
      this.lastBookingConfig.highLevel = this.maskService.getParam(command?.parameters, "HigherThreshold") ?? this.lastBookingConfig.highLevel
      this.lastBookingConfig.enabled = command?.enabled ?? this.lastBookingConfig.enabled
      this.lastBookingConfig.forwardTimestamp = command?.forwardTimestamp ?? this.lastBookingConfig.forwardTimestamp
      this.lastBookingConfig.lastForwardStatus = command?.lastForwardStatus ?? this.lastBookingConfig.lastForwardStatus
      this.lastBookingConfig.feedbackTimestamp = command?.feedbackTimestamp ?? this.lastBookingConfig.feedbackTimestamp

      this.reloadLastBooking()
    }
  })

  private _setLastConnectionConfig = () => this.maskService.getDumpsterEdit({
    id: this.data.dumpster.lastConnectionConfigTransactionId,
    data: this.data,
    func: (command: GetConfigurationResponse) => {
      this.lastConnectionConfig.lowLevel = this.maskService.getParam(command?.parameters, "LowerThreshold") ?? this.lastConnectionConfig.lowLevel
      this.lastConnectionConfig.highLevel = this.maskService.getParam(command?.parameters, "HigherThreshold") ?? this.lastConnectionConfig.highLevel
      this.lastConnectionConfig.enabled = command?.enabled ?? this.lastConnectionConfig.enabled
      this.lastConnectionConfig.forwardTimestamp = command?.forwardTimestamp ?? this.lastConnectionConfig.forwardTimestamp
      this.lastConnectionConfig.lastForwardStatus = command?.lastForwardStatus ?? this.lastConnectionConfig.lastForwardStatus
      this.lastConnectionConfig.feedbackTimestamp = command?.feedbackTimestamp ?? this.lastConnectionConfig.feedbackTimestamp

      this.reloadLastConnection()
    }
  })

  private MapConfigurationFormToCommandRequest(dumpsterId: number, configForm: FormGroup): CommandConfigurationRequest{
    let res = configForm.getRawValue();

    //dumpsterFull
    let dumpsterFullparameterss = [new Parameter("DisposalLimitPrealarmPercentage",res.dumpsterFullLowLevel)
                                  ,new Parameter("DisposalLimitAlarmPercentage",res.dumpsterFullHighLevel)
                                  ,new Parameter("MaxDisposalNumber",res.dumpsterFullMaxBookings)];
    
    let dumpsterFullCommand = new SetParametersCommand("DumpsterFull"
                                                        ,res.dumpsterFullEnabled
                                                        ,dumpsterFullparameterss);

    //lastEmptying
    let lastEmptyingparameterss = [new Parameter("LowerThreshold",res.lastEmptyingLowLevel)
                                  ,new Parameter("HigherThreshold",res.lastEmptyingHighLevel)];

    let lastEmptyingCommand = new SetParametersCommand("RuleForMissingTruck"
                                                      ,res.lastEmptyingEnabled
                                                      ,lastEmptyingparameterss);

    //lastBooking
    let lastBookingparameterss = [new Parameter("LowerThreshold",res.lastBookingLowLevel)
                                  ,new Parameter("HigherThreshold",res.lastBookingHighLevel)];

    let lastBookingCommand = new SetParametersCommand("RuleForMissingBooking"
                                                        ,res.lastBookingEnabled
                                                        ,lastBookingparameterss);

    //lastBooking
    let lastConnectionparameterss = [new Parameter("LowerThreshold",res.lastConnectionLowLevel)
                                    ,new Parameter("HigherThreshold",res.lastConnectionHighLevel)];

    let lastConnectionCommand = new SetParametersCommand("CheckLastConnection"
                                                          ,res.lastConnectionEnabled
                                                          ,lastConnectionparameterss);

    return new CommandConfigurationRequest(dumpsterId
                                          ,[dumpsterFullCommand,lastEmptyingCommand,lastBookingCommand,lastConnectionCommand]);
  }

  cancel = (e: any): boolean => true;
  back = (e: any): boolean => true;
  save = (e: any): Observable<any> => {
    // if (!this.formRegister.valid) {
    //   this.commonHelper.validateAllFormFields(this.formRegister);
    //   return;
    // }
    let res = this.configForm.getRawValue();

    return of({
      // dumpsterFullLowLevel: res.dumpsterFullLowLevel,
      // dumpsterFullHighLevel: res.dumpsterFullHighLevel,
      // lastBookingLowLevel: res.lastBookingLowLevel,
      // lastBookingHighLevel: res.lastBookingHighLevel,
      // lastEmptyingLowLevel: res.lastEmptyingLowLevel,
      // lastEmptyingHighLevel: res.lastEmptyingHighLevel,
      // lastConnectionLowLevel: res.lastConnectionLowLevel,
      // lastConnectionHighLevel: res.lastConnectionHighLevel
    } as object);
  }
}

export class DumpsterConfigurationDialogInput implements IDialogInput {  
  constructor(
    public dumpster: Dumpster
  ) {}
}
