import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectableMode, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { CustomGridComponent } from '@veritas-shared/components/custom-grid/custom-grid.component';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { GroupListOutputGroup } from '../../models/group-list-output';

@Component({
  selector: 'app-group-grid',
  templateUrl: './group-grid.component.html',
  styleUrls: ['./group-grid.component.scss']
})
export class GroupGridComponent implements OnInit {
  
  @ViewChild(CustomGridComponent) grid: CustomGridComponent;

  @Input() public items: GroupListOutputGroup[];
  @Input() public actions: Action[];
  @Input() public toolbarActions: Action[] = [];
  @Input() public readonly: boolean = false;
  @Input() public selectableMode: SelectableMode = 'multiple';

  @Input() public set selectedGroups(val: GroupListOutputGroup[]) {
    this._selectedGroups = val;
    if (this._selectedGroups)
      this.selectedGroupsId = this._selectedGroups.map(d => d.id);
    else
      this.selectedGroupsId = [];
  };

  @Output() public selectedGroupsChange = new EventEmitter();

  public get selectedGroups(): GroupListOutputGroup[] {
    return this._selectedGroups;
  }

  private _selectedGroups: GroupListOutputGroup[];
  public selectedGroupsId: number[];

  public columns: GridColumn[] = [];

  public selectableSettings: SelectableSettings;

  constructor(private router: Router, private route: ActivatedRoute, public constantHelper: ConstantHelper) {

    this.view = this.view.bind(this);

    this.onSelectionChange = this.onSelectionChange.bind(this);
  }

  ngOnInit() {

    // Set columns here because column template 
    this.columns = [
      {
        field: 'name',
        title: this.constantHelper.LBL_COMMON_NAME,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'link',
        callback: this.view
      },
      {
        field: 'description',
        title: this.constantHelper.LBL_COMMON_DESCRIPTION,
        sortable: true,
        resizable: true,
        filterable: true
      },
    ];

    this.selectableSettings = {
      enabled: !this.readonly,
      mode: this.selectableMode
    };

  }

  private view(group: GroupListOutputGroup) {
    this.router.navigate([group.id], { relativeTo: this.route });
  }

  public refreshSelection() {
    if (this._selectedGroups)
      this.selectedGroupsId = this._selectedGroups.map(d => d.id);
    else
      this.selectedGroupsId = [];
  }

  public onSelectionChange(e: SelectionEvent) {
    let selected = Object.assign([], this._selectedGroups);
    if (e.selectedRows.length > 0) {
      e.selectedRows.forEach(r => selected.push(r.dataItem));
    }
    if (e.deselectedRows.length > 0) {
      e.deselectedRows.forEach(r => {
        let i = selected.findIndex(d => d.id == r.dataItem.id);
        if (i > -1) {
          selected.splice(i, 1);
        }
      });
    }
    this._selectedGroups = selected;
    this.selectedGroupsChange.emit(this._selectedGroups);
  }

  public goToPage(page: number) {
    this.grid.goToPage(page);
  }
}
