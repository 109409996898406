export class Action {
  name: string;
  resourceKey: string;
  icon: string;
  callback: Function;
  applyOnSelected: boolean | null;
  separatorNext: boolean;
  show: Function;

  constructor(name: string, resourceKey: string, icon: string, callback: Function, applyOnSelected?: boolean | null, separatorNext = false, show: Function = () => true) {
    this.name = name;
    this.resourceKey = resourceKey;
    this.icon = icon;
    this.callback = callback;
    this.applyOnSelected = applyOnSelected;
    this.separatorNext = separatorNext;
    this.show = show;
  }
}
