<h1 class="page-title"><fa-icon icon="ban"></fa-icon>{{ constantHelper.LBL_PAGE_DEASSOCIATED_DUMPSTERS_TITLE | translate }}</h1>
<div class="page-content">

  <div class="mt-10">
    <app-group-box [pageTitle]="'Elenco Piazzole'">
      <div class="standard-padding">
        <div class="my-2">
          <app-dumpster-search [items]="dumpsters" [(filteredItems)]="filteredDumpsters" (searchChange)="onSearchChanged()" [labels]="labels"></app-dumpster-search>
        </div>
        <div class="my-2">
          <div class="row">
            <div class="col">
              <span>{{ constantHelper.LBL_PAGE_DUMPSTERS_NUMBEROFFILTERED | translate:{'0': filteredDumpsters ? filteredDumpsters.length : '0', '1': dumpsters ? dumpsters.length : '0', '2': selectedDumpsters?.length} }}</span>
            </div>
            <div class="col">
              <kendo-switch [(ngModel)]="isShowOnlySelected" (valueChange)="toggleShowOnlySelected()" id="switchSelected"></kendo-switch>
              <label class="switch-label" for="switchSelected">{{ constantHelper.LBL_COMMON_SHOW_SELECTED | translate }}</label>
            </div>
          </div>
        </div>
        <kendo-tabstrip (tabSelect)="onTabChange($event)" [keepTabContent]="true">
          <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_DUMPSTERS_TAB_LIST | translate }}" [selected]="true">
            <ng-template kendoTabContent>
              <div class="grid-container">
                <app-dumpster-grid [items]="filteredDumpsters" [(selectedDumpsters)]="selectedDumpsters" [actions]="actions" [toolbarActions]="toolbarActions"></app-dumpster-grid>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_DUMPSTERS_TAB_MAP | translate }}">
            <ng-template kendoTabContent *loadOnDemand>
              <div class="grid-container">
                <app-dumpster-map [items]="filteredDumpsters" [(selectedDumpsters)]="selectedDumpsters"></app-dumpster-map>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
    </app-group-box>
  </div>
</div>

<ng-template windowVisualizer></ng-template>
