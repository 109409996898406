import {
  Component, ElementRef, Input, Output, ViewChild, EventEmitter
} from '@angular/core';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { MapEntity, MapEntityType } from '@veritas-shared/models/map-entity';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { marker, icon, Marker, popup, Popup, Icon } from "leaflet";
import { MarkerClusterGroup } from 'leaflet.markercluster';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { LeafletMapContainerComponent } from '../leaflet-map-container/leaflet-map-container.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent {
  @ViewChild('mapWrapper', { read: LeafletMapContainerComponent }) mapWrapper: LeafletMapContainerComponent;
  @ViewChild('confirmSelection', { static: true }) confirmSelection: ElementRef;

  @Input() getCustomStatus?: (data: Dumpster) => string;
  @Input() entityType: MapEntityType;
  @Input() isPopupBtnVisible: boolean;
  @Input() isTitleNavigable: boolean;
  @Input() zoom: number;
  @Input() mode: 'single' | 'multiple' = 'multiple';
  @Input() set entities(entities: MapEntity[]) {
    if (!this._isMapInitialized) {
      this._entities = entities;
      return;
    }

    this.setEntitiesMarkers(entities);
  };

  @Output() selectedEntitiesChange: EventEmitter<MapEntity[]>;

  public infoWindowData: any;
  public coordination: any[];

  private _entities?: MapEntity[];
  private _selectedEntities?: MapEntity[];
  private _isMapInitialized: boolean;
  private _markers: any;

  constructor(
    public constantHelper: ConstantHelper,
    private library: FaIconLibrary,
    private translateService: TranslateService,
  ) {
    this.library.addIcons(faExpand, faPlusSquare);

    this.entities = [];
    this.getCustomStatus = null;
    this.entityType = MapEntityType.Dumpster;
    this.isPopupBtnVisible = true;
    this.isTitleNavigable = true;
    this.zoom = 3.5;

    this.selectedEntitiesChange = new EventEmitter();

    this.coordination = [];

    this._selectedEntities = [];
    this._isMapInitialized = false;

    // Configure marker cluster
    this._markers = new MarkerClusterGroup({
	    zoomToBoundsOnClick: true
    });
  }

  public setDefaultEntities = (isMapInitialized: boolean) => {
    if (this._entities) { this.setEntitiesMarkers(this._entities); }
    this._isMapInitialized = isMapInitialized;
  }

  public centerAndZoomOnMarkers = () => this.mapWrapper.setCenterAndZoomOnMarkers(this.coordination);

  private setEntitiesMarkers = (entities: MapEntity[]) => {
    entities.map((entity) => {
      if (!entity) return

      let marker: Marker = this.getEntityMarker(entity);
      const popup: Popup = this.getEntityPopup(entity);

      marker = this.getMarkerEntityClick(marker, entity);

      if(entities?.length === 1) {
        marker.bindPopup(popup).addTo(this.mapWrapper.map);
        return;
      }

      marker.bindPopup(popup);
      this._markers.addLayer(marker);
    });

    if(entities?.length !== 1) this.mapWrapper.map.addLayer(this._markers);
    this.mapWrapper.setCenterAndZoomOnMarkers(this.coordination);
  }

  private getEntityMarker = (entity: MapEntity): Marker => {
    const { id } = entity;

    let dumpster = <Dumpster>entity.entity;
    let {
      location: { latitude, longitude }
    } = dumpster

    let location = [latitude, longitude];

    if (latitude && longitude) {
      this.coordination.push(location);
    }

    return marker(
      location,
      { icon: this.getMarkerIcon(id, entity.entity) }
    );
  }

  private getMarkerIcon = (entityId: number, entity: Dumpster): Icon => icon({
    iconUrl: this.getMarkerUrlIcon(entityId, entity),
    popupAnchor: [0, -10],
    iconSize: [30, 30],
  });

  private getMarkerUrlIcon = (entityId: number, entity: Dumpster): string => {
    if (this.getCustomStatus) return this.getCustomStatus(entity);
    // Check if current marker entity is selected
    if (this._selectedEntities.find((entity) => entity.id == entityId)) {
      return `./assets/img/${this.constantHelper.IMG_GREEN_DOT}`;
    }
    return `./assets/img/${this.constantHelper.IMG_RED_DOT}`;
  }

  private getMarkerEntityClick = (
    marker: Marker, entity: MapEntity
  ): Marker => marker.on('click', () => {
    const i = this._selectedEntities.findIndex(d => d?.id == entity?.id);
    this.infoWindowData = entity.entity;

    if (i > -1) {
      this._selectedEntities.splice(i, 1);
    }
    else {
      this._selectedEntities.push(entity)
    }

    marker.setIcon(this.getMarkerIcon(entity.id, entity.entity));

    this.selectedEntitiesChange.emit(this._selectedEntities);
  });

  private getEntityPopup = (entity: MapEntity): Popup => {
    this.infoWindowData = entity.entity;

    // TODO find better way to use popup
    return popup({
      closeButton: true,
      autoClose: true,
    }).setContent(() => `
      <div>
        <div class="info">
          ${this.isTitleNavigable && !this.isPopupBtnVisible
        ? `<a class="title mb-2" href="dumpsters/list/${this.infoWindowData?.id}">${this.infoWindowData?.code}</a>`
        : `<p class="title mb-2"><strong>${this.infoWindowData?.code}</strong></p>`
      }
          <table>
            <tr>
              <td class="label">${this.translateService.instant(this.constantHelper.LBL_VOCABULARY_DUMPSTER_SUPPLIER)}:</td>
              <td>${this.infoWindowData?.supplierName}</td>
            </tr>
            <tr>
              <td class="label">${this.translateService.instant('common.Type')}:</td>
              <td>${this.infoWindowData?.binType} - ${this.infoWindowData?.garbageType}</td>
            </tr>
          </table>
          ${this.isPopupBtnVisible && this.entityType !== MapEntityType.CollectionPoint
        ? `<a class="btn toolbar-item" href="dumpsters/list/${this.infoWindowData?.id}">Vedi Dispositivo</a>`
        : ''
      }
        </div>
      </div>`
    );
  }
}
