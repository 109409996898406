import { Component, Input, ViewChild } from '@angular/core';
import { IDumpsterRelation } from '@veritas-dumpsters/models/dumpster-relation.model';
import { DumpsterService } from '@veritas-dumpsters/services/dumpster.service';
import { RelationService } from '@veritas-dumpsters/services/relation.service';
import { Dumpster } from '@veritas-shared/models/dumpster';
import {
  BeanDialogDimension, BeanKendoDialogService, WindowContainerDimension, WindowVisualizerDirective
} from 'bean-kendo-dialog';
import { ChildRemoveDialogComponent } from '../child-remove-dialog/child-remove-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

@Component({
  selector: 'app-relation-detail',
  templateUrl: './relation-detail.component.html',
  styleUrls: ['./relation-detail.component.scss']
})
export class RelationDetailComponent {
  @ViewChild(WindowVisualizerDirective, { static: true })
  public windowVisualizer: WindowVisualizerDirective;

  @Input() label: string;
  @Input() dumpsterDisplayed: Dumpster;
  @Input() dumpster: IDumpsterRelation;

  constructor(
    private translate: TranslateService,
    private relationService: RelationService,
    private dumpsterService: DumpsterService,
    private dialogService: BeanKendoDialogService,
  ) {
    this.label = '';
  }

  public getConfirmDialog = () => this.dialogService.openDialog(
    this.translate.instant('$lbl.confirmRemove'),
    { },
    ChildRemoveDialogComponent,
    this.windowVisualizer,
    (action) => action && this._setRemoveRelation(),
    () => of(true),
    true,
    WindowContainerDimension.Small,
    { mediumHeight: 600, mediumWidth: 1000 } as BeanDialogDimension
  );

  private _setRemoveRelation = () => this.dumpsterService.removeChildDumpster(
    this.dumpsterDisplayed.deviceExternalId, { childDumpsterId: this.dumpster.dumpsterId }
  ).subscribe(() => this.relationService.setRelation(null));
}
