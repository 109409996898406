import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCubes, faShieldAlt, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { ModalService } from '@veritas-shared/services/modal.service';
import { SecurityService } from '@veritas-shared/services/security.service';
import { RoleListOutputRole } from '../../models/role-list-output';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  @ViewChild('isSystemColumn', { static: true }) public isSystemColumn: TemplateRef<any>;
  @ViewChild('isGlobalColumn', { static: true }) public isGlobalColumn: TemplateRef<any>;

  public columns: GridColumn[] = [];
  public actions: Action[] = [];
  public toolbarActions: Action[] = [];
  public gridData: RoleListOutputRole[];

  constructor(
    private roleService: RoleService,
    private router: Router,
    private translate: TranslateService,
    private modalService: ModalService,
    private securityService: SecurityService,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper
  ) {

    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);

    this.library.addIcons(faUserShield, faShieldAlt, faCubes);

    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_ROLE_WRITE).subscribe(res => {
      if (res) {

        // Write
        this.actions = [
          new Action('edit', this.constantHelper.LBL_ACTION_COMMON_EDIT, 'pencil-alt', this.edit),
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DELETE, 'trash-alt', this.delete, null, false, (role: RoleListOutputRole) => !role.isGlobal)
        ];

        this.toolbarActions = [
          new Action('add', this.constantHelper.LBL_ACTION_COMMON_ADD, 'plus', this.add)
        ];
      } else {

        // Only Read
        this.actions = [
          new Action('view', this.constantHelper.LBL_ACTION_COMMON_VIEW, 'eye', this.view),
        ];
      }
    });
  }

  ngOnInit() {

    this.columns = [
      {
        field: 'name',
        title: this.constantHelper.LBL_COMMON_NAME,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'link',
        callback: this.view
      },
      {
        field: 'description',
        title: this.constantHelper.LBL_COMMON_DESCRIPTION,
        sortable: true,
        resizable: true,
        filterable: false
      },
      {
        field: 'isGlobal',
        title: this.constantHelper.LBL_VOCABULARY_ROLE_ISGLOBAL,
        sortable: true,
        resizable: false,
        filterable: false,
        template: this.isGlobalColumn,
        width: '70px'
      },
      {
        field: 'isSystem',
        title: this.constantHelper.LBL_COMMON_ISSYSTEM,
        sortable: true,
        resizable: false,
        filterable: false,
        template: this.isSystemColumn,
        width: '70px'
      },
      {
        field: 'autorizationsCount',
        title: this.constantHelper.LBL_VOCABULARY_ROLE_AUTHORIZATIONS_COUNT,
        sortable: true,
        resizable: false,
        filterable: false,
        width: '70px'
      }
    ];

    this.loadData();
  }

  public add() {
    this.router.navigate(['administration', 'roles', 'new']);
  }

  public edit(role: RoleListOutputRole) {
    this.router.navigate(['administration', 'roles', role.id], { queryParams: { edit: true } });
  }

  public view(role: RoleListOutputRole) {
    this.router.navigate(['administration', 'roles', role.id]);
  }

  public delete(role: RoleListOutputRole) {
    this.roleService.getRoleDelete(role.id).subscribe(result => {
      if (result.authorizationsCount > 0 || result.permissionsCount > 0) {
        this.modalService.showAlert(
          this.translate.instant(this.constantHelper.LBL_PAGE_ROLES_DELETEROLE_ERROR_MESSAGE, { '0': result.authorizationsCount, '1': result.permissionsCount }),
          () => { });
      } else {
        this.modalService.showConfirm(
          this.translate.instant(this.constantHelper.LBL_COMMON_CONFIRM_DELETE),
          () => {
            this.roleService.deleteRole(role.id).subscribe(() => {
              this.loadData();
            });
          });
      }
    });
  }

  private loadData() {
    this.roleService.getRoles().subscribe(result => {
      this.gridData = result.roles;
    });
  }

}
