import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { BreadCrumb } from '../../models/breadcrumb';
import { SessionService } from '@veritas-shared/services/session.service';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { combineLatest, Subscription } from 'rxjs';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Routes } from '@veritas-shared/models/routes';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  public subscription: Subscription;
  public breadcrumbs: BreadCrumb[];
  public pageTitle: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private commonHelper: CommonHelper,
    private constantHelper: ConstantHelper
  ) { }

  ngOnInit() {

    // Each time router changes from route, we capture NavigationEnd event.
    // buildBreadCrumb() method starts with the root of the current route (activatedRoute), which is the same as the route we are actually on.
    // We recursively builds the elements of the breadcrumb by enumerating children routes (declared in module) that matches the current route
    this.subscription = combineLatest(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        map(event => this.buildBreadCrumbV2(this.activatedRoute.root))
      ),
      this.sessionService.changePageTitleEvent
    ).subscribe(value => {

      var breadcrumbs = value[0];
      var sessionPageTitle = value[1];

      if (!this.commonHelper.compareObjects(this.breadcrumbs, breadcrumbs)) {
        this.breadcrumbs = this.commonHelper.clone(breadcrumbs);
        this.pageTitle = undefined;

        if (this.breadcrumbs && this.breadcrumbs.length > 0) {

          // Last
          var lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

          if (lastBreadcrumb.resourceKey === "" || !this.commonHelper.isNullOrWhiteSpace(sessionPageTitle as string)) {
            this.pageTitle = sessionPageTitle as string;
          } else {
            this.pageTitle = lastBreadcrumb.resourceKey;
          }

          // All otthers without resources
          var withoutResources = this.breadcrumbs.filter((el, index) => el.resourceKey == "" && (index != this.breadcrumbs.length - 1));
          if (withoutResources && withoutResources.length > 0) {
            withoutResources.forEach(res => {
              var splitted = res.url.split("/").map(item => item != "" ? item : undefined).filter(item => item != undefined);

              var parentRoute = Routes.routerPaths.find(el => el.path === splitted[0]);
              if (parentRoute) {
                var sonRoute = parentRoute.children.find(el => el.path === splitted[1]);
                if (sonRoute) {
                  var resKey = sonRoute.data['resourceKey'];
                  res.resourceKey = resKey;
                }
              }
            });
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  //private buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []) : Array<BreadCrumb> {

  //    this.sessionService.pageTitle = null;

  //    // If routeConfig is available and there's data associated, then try get resourceKey from data, else fallback to menu.Home
  //    const resourceKey = route.routeConfig ? route.routeConfig.data ? route.routeConfig.data['resourceKey'] : '' : 'menu.Home';

  //    // If routeConfig is available than get the path, else fallback on root path
  //    const path = route.routeConfig ? route.routeConfig.path : '';

  //    //----------------------
  //    // if (current iteration) route has parts, process them (else add path to Breadcrumbs and recursively go to path first child
  //    if (path.indexOf('/') > -1) {

  //        // Split path
  //        const pathParts = path.split('/');

  //        // Parent Path
  //        const parentPath = route.parent.routeConfig.path;
  //        // 
  //        const parentConfig = this.router.config.find(c => c.path === parentPath);

  //        // Current route has a Parent Path defined
  //        if (parentConfig) {

  //            // Enumerates route childrens as they are defined in module  
  //            const childrenRoutes = parentConfig.children;

  //            // Loop on current route path "parts" 
  //            pathParts.forEach(pathPart => {

  //                // ... exclude parameters, that is, parts beginning with ":"
  //              if (pathParts.indexOf(':') === -1) {

  //                      let childRoute = childrenRoutes.find(cRoute => (cRoute.path === pathPart));

  //                      // if childRoute find in path parts
  //                      if (childRoute) {
  //                          // Add current child route to BreadCrumbs
  //                          breadcrumbs.push(
  //                              new BreadCrumb(
  //                                  childRoute.data['resourceKey'], // resourcekey
  //                                  parentPath + '/' + childRoute.path // url
  //                                  )
  //                          );
  //                      }
  //                  }
  //            });
  //        }
  //    }
  //    //----------------------

  //    // In the routeConfig the complete path is not available, so we rebuild it each time
  //    const nextUrl = `${url}${path}/`;

  //    const breadcrumb = new BreadCrumb(resourceKey, nextUrl);

  //    let newBreadcrumbs;
  //    if (breadcrumb.url)
  //        newBreadcrumbs = [...breadcrumbs, breadcrumb];
  //    else
  //        newBreadcrumbs = breadcrumbs;

  //    // If we are not on our current path yet,
  //    // there will be more children to look after, to build our breadcumb
  //    if (route.firstChild) {
  //        // Recursive buildBreadCrumb call
  //        return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
  //    }

  //    return newBreadcrumbs;
  //}

  private buildBreadCrumbV2(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {

    // @@@DEBUG Print all configured routes -- console.log('configured routes: ', this.router.config);
    this.sessionService.setPageTitle(null);

    // If routeConfig is available and there's data associated, then try get resourceKey from data, else fallback to menu.Home
    let resourceKey = route.routeConfig ? route.routeConfig.data ? route.routeConfig.data['resourceKey'] : '' : this.constantHelper.LBL_MENU_HOME;

    // If the current route is in edit mode, for the last child set the ResourceEditKey label
    const editQueryParams = route.snapshot.queryParams.edit;
    if (editQueryParams && route.firstChild == undefined) {
      resourceKey = route.routeConfig && route.routeConfig.data ? route.routeConfig.data['resourceEditKey'] : '';
    }

    // If routeConfig is available than get the path, else fallback on root path
    const path = route.routeConfig ? route.routeConfig.path : '';

    // In the routeConfig the complete path is not available, so we rebuild it each time
    const nextUrl = `${url}${path}/`;

    const breadcrumb = new BreadCrumb(resourceKey, nextUrl);

    let newBreadcrumbs;
    if (breadcrumb.url && (route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumbHidden != true : true)) {
      if (path.indexOf('/') != -1) {
        newBreadcrumbs = [...breadcrumbs, new BreadCrumb("", `${url}${path.split('/')[0]}/`), breadcrumb];
      }
      else {
        newBreadcrumbs = [...breadcrumbs, breadcrumb];
      }
    }
    else {
      newBreadcrumbs = this.commonHelper.clone(breadcrumbs);
    }

    // If we are not on our current path yet,
    // there will be more children to look after, to build our breadcumb
    if (route.firstChild && route.firstChild.routeConfig.path != "") {
      // Recursive buildBreadCrumb call
      return this.buildBreadCrumbV2(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }
}
