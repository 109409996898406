import { Component, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { ConstantHelper } from '../../helpers/constant.helper';
import { CollectionPointListOutputCollectionPoint } from '../../models/collectionpoint-list-output';
import { MapEntity, MapEntityType } from '../../models/map-entity';

@Component({
  selector: 'app-collectionpoint-map',
  templateUrl: './collectionpoint-map.component.html',
  styleUrls: ['./collectionpoint-map.component.scss']
})
export class CollectionPointMapComponent {

  @ViewChild('map', { static: true }) mapElement: any;

  @Input() public set items(val: CollectionPointListOutputCollectionPoint[]) {
    if (val) {
      this._items = val;
      this.entities = val.map(el => new MapEntity(el.id, el.address?.location?.latitude, el.address?.location?.longitude, el));
    }
  };

  @Input() public mode: 'single' | 'multiple' = 'multiple';

  @Input() public set selectedCollectionPoints(val: CollectionPointListOutputCollectionPoint[]) {
    if (val) {
      this.selectedEntities = val.map(el => new MapEntity(el.id, el.address?.location?.latitude, el.address?.location?.longitude, el));
    }
  };

  public get selectedCollectionPoints(): CollectionPointListOutputCollectionPoint[] {
    return this.selectedEntities.map(el => el.entity);
  }

  @Input() public zoom: number;

  @Output() public selectedCollectionPointsChange = new EventEmitter<CollectionPointListOutputCollectionPoint[]>();
  @Output() public selectedMarkerChange = new EventEmitter<google.maps.LatLng>();

  private _items: CollectionPointListOutputCollectionPoint[];
  public entities: MapEntity[] = [];
  public selectedEntities: MapEntity[] = [];

  public MapEntityType = MapEntityType;
  public context: CollectionPointMapComponent;

  constructor(public constantHelper: ConstantHelper) {
    this.context = this;
  }

  public centerAndZoomOnMarkers() {
    this.mapElement.centerAndZoomOnMarkers();
  }

  public onSelectionChange(data: MapEntity[]) {
    this.selectedCollectionPointsChange.emit(data?.map(el => el.entity));
  }

  public onMarkerSelectionChange(data: google.maps.LatLng) {
    this.selectedMarkerChange.emit(data);
  }

}
