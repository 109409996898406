import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faPlay } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { BeanDialogComponentBase, IDialogInput } from 'bean-kendo-dialog';
import { Observable, of } from 'rxjs';
import { CommandListOutputCommand } from '../../../commands/models/command-list-output';
import { CommandParameter } from '../../../commands/models/command-parameter';
import { RunCommandInput } from '../../../commands/models/run-command-input';
import { CommandService } from '../../../commands/services/command.service';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { ToastService } from '@veritas-shared/services/toast.service';
import { ParameterType } from '../../../commands/models/parametertype.enum';
import { MaxItemsValidator } from '@veritas-shared/utils/validators';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { SupportValue } from '../../../commands/models/edit-command-output';

@Component({
  selector: 'app-run-command-dialog',
  templateUrl: './run-command-dialog.component.html',
  styleUrls: ['./run-command-dialog.component.scss']
})
export class RunCommandDialogComponent extends BeanDialogComponentBase<RunCommandDialogInput>{

  public formRunCommand: FormGroup;

  public data: RunCommandDialogInput;

  public selectedCommand: CommandListOutputCommand;
  public commandParametersLoaded: boolean = false;
  public selectedCommandParameters: CommandParameter[] = [];

  private allCommands: CommandListOutputCommand[];
  private allSupportValues: Map<string, SupportValue[]>;
  public supportValues: Map<string, SupportValue[]>;

  public ParameterType = ParameterType;

  constructor(
    private commandService: CommandService,
    private toastService: ToastService,
    private translate: TranslateService,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper,
    private fb: FormBuilder
  ) {
    super();

    this.init();
  
    this.library.addIcons(faChevronLeft, faPlay);
  }

  ngAfterViewInitCallback(): void {
    this.allCommands = this.data.commands;
  }

  cancel(e: any): boolean {
    return true;
  }

  back(e: any): boolean {
    return true;
  }

  save(e: any): Observable<RunCommandInput> {

    if (!this.formRunCommand.valid) {
      this.commonHelper.validateAllFormFields(this.formRunCommand);

      //
      const errors = this.commonHelper.showErrors(
        this.formRunCommand,
        [
          this.constantHelper.LBL_COMMON_ERROR_REQUIRED, this.constantHelper.LBL_COMMON_ERROR_MAX_ITEMS
        ],
        (label) => {
          return this.translate.instant(label);
        }
      );
      this.toastService.showWarning(errors);
      return;
    }

    const input = <RunCommandInput>this.formRunCommand.getRawValue();
    input.commandId = +input.commandId;

    return of(input);
  }

  private init() {
    this.supportValues = undefined;
    this.allSupportValues = undefined;
    this.selectedCommandParameters = undefined;

    this.formRunCommand = this.fb.group({
      commandId: [null, Validators.required]
    });
  }

  public onCommandSelected(commandId: number) {

    if (commandId != undefined) {
      this.commandService.getEditCommand(commandId, this.data.wasteType).subscribe(result => {

        this.supportValues = result.supportValues;
        this.allSupportValues = this.commonHelper.clone(result.supportValues);
        this.selectedCommandParameters = result.command.parameters;

        if (this.selectedCommandParameters && this.selectedCommandParameters.length > 0) {
          var commandParameters = this.fb.array([]);

          this.selectedCommandParameters.forEach(par => {

            let group = this.fb.group({
              label: [{ value: par.label, disabled: true }],
              name: [{ value: par.name, disabled: true }],
              hidden: [{ value: par.hidden, disabled: true }],
              type: [{ value: par.type, disabled: true }],
              value: [{ value: par.value, disabled: false }],
            });

            if (!par.hidden) {
              group.get('value').setValidators([Validators.required]);
            }

            if (par.type == ParameterType.Multiselect) {
              group.get('value').setValidators([Validators.required, MaxItemsValidator(3)]);
            }

            commandParameters.push(group);
          })

          this.formRunCommand.addControl('commandParameters', commandParameters);
        }
      });
    } else {
      this.init();
    }
  }

  public handleListFilter(value: string, type: string) {
    this.supportValues[type] = this.allSupportValues[type].filter((s) => s.description.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public handleCommandFilter(value: string) {
    this.data.commands = this.allCommands.filter((s) => s.description.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  get commandParameters() {
    return this.formRunCommand.get('commandParameters') as FormArray;
  }
}

export class RunCommandDialogInput implements IDialogInput {
  constructor(public commands: CommandListOutputCommand[], public wasteType: string) { }
}
