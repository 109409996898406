<h1 class="page-title"><fa-icon icon="list"></fa-icon>{{ 'page.CollectionPoints.Title' | translate }}</h1>

<div class="page-content">

  <div class="mt-10">
    <app-group-box [pageTitle]="'Elenco Piazzole'">
      <div class="standard-padding">

        <div class="my-2">
          <app-collectionpoint-search [items]="collectionPoints" [(filteredItems)]="filteredCollectionPoints" (searchChange)="onSearchChanged()"></app-collectionpoint-search>
        </div>
        <div class="my-2">
          <div class="row">
            <div class="col">
              <span>{{'page.CollectionPoints.NumberOfFiltered' | translate:{'0': filteredCollectionPoints ? filteredCollectionPoints.length : '0', '1': collectionPoints ? collectionPoints.length : '0', '2': selectedCollectionPoints?.length} }}</span>
            </div>
            <div class="col">
              <kendo-switch [(ngModel)]="isShowOnlySelected" (valueChange)="toggleShowOnlySelected()" id="switchSelected"></kendo-switch>
              <label class="switch-label" for="switchSelected">{{ constantHelper.LBL_COMMON_SHOW_SELECTED | translate }}</label>
            </div>
          </div>
        </div>
        <kendo-tabstrip (tabSelect)="onTabChange($event)" [keepTabContent]="true">
          <kendo-tabstrip-tab title="{{ 'component.CollectionPoints.Tab.List' | translate }}" [selected]="true">
            <ng-template kendoTabContent>
              <div class="grid-container">
                <app-collectionpoint-grid [items]="filteredCollectionPoints" [(selectedCollectionPoints)]="selectedCollectionPoints" [actions]="actions" [toolbarActions]="toolbarActions"></app-collectionpoint-grid>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="{{ 'component.CollectionPoints.Tab.Map' | translate }}">
            <ng-template kendoTabContent *loadOnDemand>
              <div class="grid-container">
                <app-collectionpoint-map [items]="filteredCollectionPoints" [(selectedCollectionPoints)]="selectedCollectionPoints"></app-collectionpoint-map>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
    </app-group-box>
  </div>
</div>

<!--Upload dialog-->
<kendo-dialog title="{{ 'page.CollectionPoints.Dialog.Import.Title' | translate }}" *ngIf="isImportDialogOpen" (close)="closeImportDialog()" [minWidth]="800" [width]="1000" [height]="600">
  <div class="row">
    <div class="col">
      <kendo-switch [(ngModel)]="isMergeEnabled" (valueChange)="toggleMergeEnabled()" id="switchImportBehavior"></kendo-switch>
      <label class="switch-label" for="switchImportBehavior">{{ 'page.CollectionPoints.Dialog.Import.Merge' | translate }}</label>
    </div>
  </div>

  <kendo-upload [autoUpload]="true"
                [saveUrl]="uploadSaveUrl"
                [restrictions]="uploadRestrictions"
                [multiple]="false"
                (success)="successEventHandler($event)"
                (error)="errorEventHandler($event)">
  </kendo-upload>

  <app-import-log title="{{ 'page.CollectionPoints.Dialog.Import.Result' | translate }}" [importResult]="importResult"></app-import-log>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeImportDialog()" primary="true">{{ 'action.Common.Close' | translate }}</button>
  </kendo-dialog-actions>
</kendo-dialog>

<ng-template windowVisualizer></ng-template>
