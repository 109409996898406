import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckedState, TreeItemLookup } from '@progress/kendo-angular-treeview';
import { EditDumpsterUpdateLabelsInput } from '../../models/edit-dumpster-update-labels-input';
import { LabelListOutputLabel } from '../../models/label-list-output';
import { NewLabelInput } from '../../models/new-label-input';
import { LabelService } from '../../services/label.service';
import { Dumpster } from '@veritas-shared/models/dumpster';

@Component({
  selector: 'app-label-select',
  templateUrl: './label-select.component.html',
  styleUrls: ['./label-select.component.scss']
})
export class LabelSelectComponent implements OnInit {

  @Input() public set dumpsters(val: Dumpster[]) {
    this.editLabelsInput = [];
    if (val) {
      val.forEach(v => this.editLabelsInput.push(new EditDumpsterUpdateLabelsInput(v.id, v.labels.map(l => l.id))));
    }
  }

  private labels: LabelListOutputLabel[];
  public filteredLabels: LabelListOutputLabel[];

  public editLabelsInput: EditDumpsterUpdateLabelsInput[];

  public get newLabelInput(): NewLabelInput {
    let newLabelInput = <NewLabelInput>this.formLabel.getRawValue();
    newLabelInput.dumpsterIds = this.editLabelsInput.map(d => d.dumpsterId);
    return newLabelInput;
  }

  public isNew: boolean = false;

  public formLabel: FormGroup;

  constructor(private labelService: LabelService, private fb: FormBuilder) {

    this.isLabelChecked = this.isLabelChecked.bind(this);

    this.formLabel = this.fb.group({
      name: ['', Validators.required],
      //type: [''],
      color: ['']
    });

  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.labelService.getLabels().subscribe(result => {
      this.labels = result.labels;
      this.filteredLabels = result.labels;
    });
  }

  private search(items: any[], term: string): any[] {
    return items.reduce((acc, item) => {
      if (this.contains(item.name, term)) {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  private contains(text: string, term: string): boolean {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
  }

  public filterLabels(value: string): void {
    this.filteredLabels = this.search(this.labels, value);
  }

  public onChecking(itemLookup: TreeItemLookup) {
    const label = itemLookup.item.dataItem;
    let labelDumpsters = this.editLabelsInput.filter(d => d.labels != null && d.labels.findIndex(l => l == label.id) > -1);
    if (labelDumpsters.length > 0) {
      // Unselect
      this.editLabelsInput.forEach(d => {
        let i = d.labels.findIndex(l => l == label.id);
        if (i > -1)
          d.labels.splice(i, 1);
      });
    }
    else {
      // Select
      this.editLabelsInput.forEach(d => {
        d.labels.push(label.id);
      });
    }
  }

  public isLabelChecked(dataItem: any, index: string): CheckedState {
    let labelDumpsters = this.editLabelsInput.filter(d => d.labels != null && d.labels.findIndex(l => l == dataItem.id) > -1);
    if (labelDumpsters && labelDumpsters.length > 0) {
      if (labelDumpsters.length == this.editLabelsInput.length) {
        return 'checked';
      }
      else {
        return 'indeterminate';
      }
    }
    else {
      return 'none';
    }
  }

  public onNew() {
    this.isNew = true;
  }

  public onEdit() {
    // TODO ???
  }
}
