
<app-custom-grid [columns]="columns"
                 [showActions]="true"
                 [disableActions]="readonly"
                 [actions]="actions"
                 [toolbarActions]="toolbarActions"
                 [showSelectAll]="true"
                 [selectable]="selectableSettings"
                 [selectedKeys]="selectedTicketsId"
                 [selectCallback]="onSelectionChange"
                 [selectionKey]="'ticketId'"
                 [pageable]="true"
                 [pageSizes]="true"
                 [hasServerPagination]="true"
                 [dataStateChangeCallback]="onStateChange"
                 [filterable]="true"
                 [defaultFilter]="defaultFilter"
                 [items]="items"
                 (stateChange)="onStateChange($event)">
</app-custom-grid>
<ng-template #columnAccessTemplate
             let-dataItem>
  <a href="{{baseUrl}}/tickets/list/{{dataItem.ticketId}}/{{dataItem.entityId}}">{{dataItem.ticketId}}</a>
</ng-template>
<ng-template #columnStateTemplate
             let-dataItem>
  {{ dataItem.state | state:entities?.ticketStates }}
</ng-template>
