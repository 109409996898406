import { Injectable } from '@angular/core';
import { TicketService } from './ticket.service';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { Observable, Subject } from 'rxjs';
import { TicketBodyOutput, TicketListOutput, TicketListRequestInput } from '../models/ticket-list-output';
import { DateService } from '@veritas-shared/services/date.service';
import { DateType } from '@veritas-shared/enums/date.enum';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TicketInfoService {
  private _saveTicket = new Subject<Dumpster>();
  public saveTicket$ = this._saveTicket.asObservable();

  private _openBanner = new Subject<TicketBodyOutput>();
  public openBanner$ = this._openBanner.asObservable();

  constructor(
    private ticketService: TicketService,
    private dateService: DateService,
  ) { }

  public setTicketCheck = (dumpster: Dumpster) => this._getTicketCheck$(dumpster).subscribe()

  private _getTicketCheck$ = (
    dumpster: Dumpster
  ):Observable<any> => this.ticketService.getTickets(this._getFilter(dumpster)).pipe(
    map((items: TicketListOutput) => {
      if(!items?.tickets?.length) return [];
      if(Boolean(items?.tickets[0]?.closureTimestamp)) return items?.tickets;
      return []
    }),
    tap((items: TicketBodyOutput[]) => {
      this._setBanner(items[0]);
      this._setTicket(dumpster);
    })
  );

  private _setTicket = (dumpster: Dumpster) => this._saveTicket.next(dumpster);
  private _setBanner = (ticket: TicketBodyOutput) => this._openBanner.next(ticket);

  private _getFilter = (item: Dumpster):TicketListRequestInput => ({
    assignedTo: null,
    startDate: this._getStartDate(),
    endDate: new Date(),
    assignedFrom: "Veritas",
    currentDeviceId: item?.code,
    currentBinTag: null,
    metadataFilter: { ClosingDeviceId: item?.code },
    orderByCreationDateDescending: true,
    pageNumber: 0,
    pageSize: 1000
  });

  private _getStartDate = ():Date => this.dateService.getDiff(new Date(), 72, DateType.hour);
}
