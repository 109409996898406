<h1 class="page-title"><fa-icon icon="list"></fa-icon>{{ 'page.Incidents.Title' | translate }}</h1>
<div class="page-content">
  <div class="mt-10">
    <app-group-box [pageTitle]="'Elenco Problematiche'">
      <div class="standard-padding">

        <!--<div class="my-2">
          <app-collectionpoint-search [items]="collectionPoints" [(filteredItems)]="filteredCollectionPoints" (searchChange)="onSearchChanged()"></app-collectionpoint-search>
        </div>-->
        <!--<div class="my-2">
          <div class="row">
            <div class="col">
              <span>{{'page.CollectionPoints.NumberOfFiltered' | translate:{'0': filteredCollectionPoints ? filteredCollectionPoints.length : '0', '1': collectionPoints ? collectionPoints.length : '0', '2': selectedCollectionPoints?.length} }}</span>
            </div>
            <div class="col">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" [checked]="isShowOnlySelected" (change)="toggleShowOnlySelected()" id="switchSelected">
                <label class="custom-control-label" for="switchSelected">Mostra solo selezionati</label>
              </div>
            </div>
          </div>
        </div>-->
        <kendo-tabstrip (tabSelect)="onTabChange($event)" [keepTabContent]="true">
          <kendo-tabstrip-tab title="{{ 'component.Incidents.Tab.List' | translate }}" [selected]="true">
            <ng-template kendoTabContent>
              <div class="grid-container">
                <app-incident-grid [items]="filteredIncidents" [(selectedIncidents)]="selectedIncidents" [actions]="actions" [toolbarActions]="toolbarActions"></app-incident-grid>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="{{ 'component.Incidents.Tab.Map' | translate }}">
            <!--<ng-template kendoTabContent>
              <div class="grid-container">
                <app-incident-map [items]="filteredIncidents" [(selectedIncidents)]="selectedIncidents"></app-incident-map>
              </div>
            </ng-template>-->
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
    </app-group-box>

  </div>
</div>
