<kendo-dropdownlist 
  [data]="labels"
  [value]="labelSelected"
  textField="name"
  valueField="name"
>
  <ng-template kendoDropDownListItemTemplate let-dataItem>
    <div class="w-100 labels">
      <div 
        (click)="setSelectedLabel(dataItem)"
        [ngClass]="{'selected': dataItem?.name === labelSelected?.name}"
        class="d-flex align-items-center"
      >
        <fa-icon icon="tag" [style.color]="dataItem?.color || 'inherit'" class="mr-1"></fa-icon>
        <div class="w-100 mr-2">{{ dataItem?.name }}</div>
        <fa-icon 
          *ngIf="dataItem?.name === labelSelected?.name"
          icon="times"
          class="close"
          (click)="setUnselectedLabel($event)"
        ></fa-icon>
      </div>
    </div>
  </ng-template>
  <ng-template kendoDropDownListValueTemplate let-dataItem>
    <div *ngIf="dataItem" class="labels">
      <div class="d-flex selected" >
        <fa-icon icon="tag" [style.color]="dataItem?.color || 'inherit'" class="mr-1"></fa-icon>
        <div class="mr-2">{{ dataItem?.name }}</div>
      </div>
    </div>
  </ng-template>
  <ng-template kendoDropDownListNoDataTemplate>
    <span>Nessun dato presente</span>
  </ng-template>
</kendo-dropdownlist>
