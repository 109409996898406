import { AbstractControl, ValidatorFn } from "@angular/forms";

export function MaxItemsValidator(val: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (val == null) {
      return null;
    }

    if (!control || !control.value) {
      return null;
    }

    const forbidden = control.value.length > val;
    return forbidden ? { maxItems: val } : null;
  };
}
