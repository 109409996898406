import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConstantHelper } from "@veritas-shared/helpers/constant.helper";
import { ApiService } from "@veritas-shared/services/api.service";
import { ToastService } from "@veritas-shared/services/toast.service";
import { WaitService } from "@veritas-shared/services/wait.service";
import { NewTicketOuput } from "../models/new-ticket-output";
import { Ticket, TicketRequestInput } from "../models/ticket";
import { TicketCloseInput } from "../models/ticket-close-input";
import { TicketEditOutput } from "../models/ticket-edit-output";
import { TicketListOutput, TicketListRequestInput } from "../models/ticket-list-output";
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { GetDeviceGroupsOutputModel } from 'src/app/group/models/group';

@Injectable({
  providedIn: 'root'
})
export class TicketService extends ApiService {

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    http: HttpClient,
    toastService: ToastService,
    waitService: WaitService,
    private constantHelper: ConstantHelper,
    commonHelper: CommonHelper,
    newToastService: BeanToastService
  ) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  // Tickets
  public getTickets(input: TicketListRequestInput): Observable<TicketListOutput> {
    return this.postBase<TicketListRequestInput, TicketListOutput>(this.constantHelper.TICKETS_BASE, input);
  }

  public getNewTicket(): Observable<NewTicketOuput> {
    return this.getBase<NewTicketOuput>(this.constantHelper.TICKETS_GET_NEW);
  }

  public getTicket(input: TicketRequestInput): Observable<TicketEditOutput> {
    return this.postBase<TicketRequestInput, TicketEditOutput>(this.constantHelper.TICKETS_GET_DETAIL, input);
  }

  public openTicket(input: FormData) {
    return this.postBase<FormData, Ticket>(this.constantHelper.TICKETS_OPEN, input);
  }

  public updateTicket(input: FormData) {
    return this.patchBase<FormData, Ticket>(this.constantHelper.TICKETS_UPDATE, input);
  }

  public closeTicket(input: TicketCloseInput) {
    return this.patchBase<TicketCloseInput, Ticket>(this.constantHelper.TICKETS_CLOSE, input);
  }

  public getDeviceGroups(deviceExternalId: string): Observable<GetDeviceGroupsOutputModel[]> {
    const url = this.commonHelper.replaceRouteParams(this.constantHelper.GET_DEVICE_GROUPS, { deviceexternalid: deviceExternalId });
    return this.getBase<GetDeviceGroupsOutputModel[]>(url);
  }
}
