import { Route } from "@angular/router";
import { AuthorizationDetailComponent } from "../../administration/pages/authorization-detail/authorization-detail.component";
import { AuthorizationsComponent } from "../../administration/pages/authorizations/authorizations.component";
import { RoleDetailComponent } from "../../administration/pages/role-detail/role-detail.component";
import { RolesComponent } from "../../administration/pages/roles/roles.component";
import { CollectionPointDetailComponent } from "../../collectionpoints/pages/collectionpoint-detail/collectionpoint-detail.component";
import { CollectionPointGroupDetailComponent } from "../../collectionpoints/pages/collectionpointgroup-detail/collectionpointgroup-detail.component";
import { CollectionPointGroupsComponent } from "../../collectionpoints/pages/collectionpointgroups/collectionpointgroups.component";
import { CollectionPointsDeactivatedComponent } from "../../collectionpoints/pages/collectionpoints-deactivated/collectionpoints-deactivated.component";
import { CollectionPointsComponent } from "../../collectionpoints/pages/collectionpoints/collectionpoints.component";
import { DumpstersDeassociatedComponent } from "../../collectionpoints/pages/dumpsters-deassociated/dumpsters-deassociated.component";
import { CommandDetailComponent } from "../../commands/pages/command-detail/command-detail.component";
import { CommandEventDetailComponent } from "../../commands/pages/commandevent-detail/commandevent-detail.component";
import { CommandEventsComponent } from "../../commands/pages/commandevents/commandevents.component";
import { CommandsComponent } from "../../commands/pages/commands/commands.component";
import { CommandTaskDetailComponent } from "../../commands/pages/commandtask-detail/commandtask-detail.component";
import { CommandTasksComponent } from "../../commands/pages/commandtasks/commandtasks.component";
import { EmptyComponent } from "@veritas-core/pages/empty/empty.component";
import { HomeComponent } from "@veritas-core/pages/home/home.component";
import { DumpsterGroupDetailComponent } from "@veritas-dumpsters/pages/dumpstergroup-detail/dumpstergroup-detail.component";
import { DumpsterGroupsComponent } from "@veritas-dumpsters/pages/dumpstergroups/dumpstergroups.component";
import { DumpsterDetailComponent } from "@veritas-dumpsters/pages/dumpster-detail/dumpster-detail.component";
import { DumpstersDeactivatedComponent } from "@veritas-dumpsters/pages/dumpsters-deactivated/dumpsters-deactivated.component";
import { DumpstersComponent } from "@veritas-dumpsters/pages/dumpsters/dumpsters.component";
import { LabelDetailComponent } from "@veritas-dumpsters/pages/label-detail/label-detail.component";
import { LabelsComponent } from "@veritas-dumpsters/pages/labels/labels.component";
import { IncidentsComponent } from "../../incidents/pages/incidents/incidents.component";
import { TicketDetailComponent } from "../../tickets/pages/ticket-detail/ticket-detail.component";
import { TicketsComponent } from "../../tickets/pages/tickets/tickets.component";
import { AuthGuardService } from "../services/auth-guard.service";
import { UnsavedGuardService } from "../services/unsaved-guard.service";
import { DumpstersUnregisteredComponent } from "@veritas-dumpsters/pages/dumpsters-unregistered/dumpsters-unregistered.component";
import { MsalGuard } from "@azure/msal-angular";

export class Routes {

  static routerPaths: Route[] = [
    { path: '', component: HomeComponent, canActivate: [MsalGuard] },
    // Administration
    {
      path: 'administration', canActivate: [MsalGuard, AuthGuardService], component: EmptyComponent, data: { breadcrumbHidden: true, resourceKey: 'menu.Administration' }, children: [
        { path: 'roles', component: RolesComponent, data: { resourceKey: 'menu.Administration.Roles', permissions: ['role'] }, canActivate: [AuthGuardService] },
        { path: 'roles/new', component: RoleDetailComponent, data: { resourceKey: 'menu.Administration.Roles.Add', permissions: ['role'] }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'roles/:roleId', component: RoleDetailComponent, data: { resourceEditKey: 'menu.Administration.Roles.Edit', permissions: ['role'] }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'authorizations', component: AuthorizationsComponent, data: { resourceKey: 'menu.Administration.Authorizations', permissions: ['authorization'] }, canActivate: [AuthGuardService] },
        { path: 'authorizations/new', component: AuthorizationDetailComponent, data: { resourceKey: 'menu.Administration.Authorizations.Add', permissions: ['authorization'] }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'authorizations/:authorizationId', component: AuthorizationDetailComponent, data: { resourceEditKey: 'menu.Administration.Authorizations.Edit', permissions: ['authorization'] }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
      ]
    },
    // Collection points
    {
      path: 'collectionpoints', canActivate: [MsalGuard, AuthGuardService], component: EmptyComponent, data: { breadcrumbHidden: true, resourceKey: 'menu.CollectionPoints', permissions: ['collectionPoint'] }, children: [
        { path: 'list', component: CollectionPointsComponent, data: { resourceKey: 'menu.CollectionPoints.Registry' }, canActivate: [AuthGuardService] },
        { path: 'list/new', component: CollectionPointDetailComponent, data: { resourceKey: 'menu.CollectionPoints.Registry.Add' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'list/:collectionPointId', component: CollectionPointDetailComponent, data: { resourceEditKey: 'menu.CollectionPoints.Registry.Edit' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'list/:collectionPointId/dumpsters/:dumpsterId', component: DumpsterDetailComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'groups', component: CollectionPointGroupsComponent, data: { resourceKey: 'menu.CollectionPoints.Groups', permissions: ['group'] }, canActivate: [AuthGuardService] },
        { path: 'groups/new', component: CollectionPointGroupDetailComponent, data: { resourceKey: 'menu.CollectionPoints.Groups.Add', permissions: ['group'] }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'groups/:groupId', component: CollectionPointGroupDetailComponent, data: { resourceEditKey: 'menu.CollectionPoints.Groups.Edit', permissions: ['group'] }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'deactivated', component: CollectionPointsDeactivatedComponent, data: { resourceKey: 'menu.CollectionPoints.Registry.Deactivated' }, canActivate: [AuthGuardService] },
      ]
    },
    // Monitoring
    {
      path: 'monitoring', canActivate: [MsalGuard, AuthGuardService], component: EmptyComponent, data: { breadcrumbHidden: true, resourceKey: 'menu.Monitoring', permissions: ['monitoring'] }, children: [
        { path: 'incidents', component: IncidentsComponent, data: { resourceKey: 'menu.Monitoring.Incidents.Registry' }, canActivate: [AuthGuardService] },
        { path: 'incidents/active', component: IncidentsComponent, data: { resourceKey: 'menu.Monitoring.Incidents.Active' }, canActivate: [AuthGuardService] }
      ]
    },
    // Dumpsters
    {
      path: 'dumpsters', canActivate: [MsalGuard, AuthGuardService], component: EmptyComponent, data: { breadcrumbHidden: true, resourceKey: 'menu.Dumpsters', permissions: ['dumpster'] }, children: [
        { path: 'list', component: DumpstersComponent, data: { resourceKey: 'menu.Dumpsters.Registry' }, canActivate: [AuthGuardService], },
        { path: 'list/new', component: DumpsterDetailComponent, data: { resourceKey: 'menu.Dumpsters.Registry.Add' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'list/:dumpsterId', component: DumpsterDetailComponent, data: { resourceEditKey: 'menu.Dumpsters.Registry.Edit' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'groups', component: DumpsterGroupsComponent, data: { resourceKey: 'menu.Dumpsters.Groups' }, canActivate: [AuthGuardService] },
        { path: 'groups/new', component: DumpsterGroupDetailComponent, data: { resourceKey: 'menu.Dumpsters.Groups.Add' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'groups/:groupId', component: DumpsterGroupDetailComponent, data: { resourceEditKey: 'menu.Dumpsters.Groups.Edit' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'deactivated', component: DumpstersDeactivatedComponent, data: { resourceKey: 'menu.Dumpsters.Registry.Deactivated' }, canActivate: [AuthGuardService] },
        { path: 'deassociated', component: DumpstersDeassociatedComponent, data: { resourceKey: 'menu.Dumpsters.Registry.Deassociated' }, canActivate: [AuthGuardService] },
        { path: 'unregistered', component: DumpstersUnregisteredComponent, data: { resourceKey: 'menu.Dumpsters.Registry.Unregistered' }, canActivate: [AuthGuardService] },
        { path: 'labels', component: LabelsComponent, data: { resourceKey: 'menu.Dumpsters.Labels' }, canActivate: [AuthGuardService] },
        { path: 'labels/new', component: LabelDetailComponent, data: { resourceKey: 'menu.Dumpsters.Labels.Add' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'labels/:labelId', component: LabelDetailComponent, data: { resourceEditKey: 'menu.Dumpsters.Labels.Edit' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
      ]
    },
    // Commands
    {
      path: 'commands', canActivate: [MsalGuard, AuthGuardService], component: EmptyComponent, data: { breadcrumbHidden: true, resourceKey: 'menu.Commands', permissions: ['command'] }, children: [
        { path: 'management', component: CommandsComponent, data: { resourceKey: 'menu.Commands.Registry' }, canActivate: [AuthGuardService] },
        //{ path: 'management/new', component: CommandDetailComponent, data: { resourceKey: 'menu.Commands.Registry.Add' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'management/:id', component: CommandDetailComponent, canActivate: [AuthGuardService] },
        //{ path: 'management/:id/edit', component: CommandDetailComponent, data: { resourceKey: 'menu.Commands.Registry.Edit' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'tasks', component: CommandTasksComponent, data: { resourceKey: 'menu.Commands.Tasks' }, canActivate: [AuthGuardService] },
        { path: 'tasks/new', component: CommandTaskDetailComponent, data: { resourceKey: 'menu.Commands.Tasks.Add' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'tasks/:id', component: CommandTaskDetailComponent, data: { resourceEditKey: 'menu.Commands.Tasks.Edit' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'lastevents', component: CommandEventsComponent, data: { resourceKey: 'menu.Commands.LastEvents' }, canActivate: [AuthGuardService] },
        { path: 'tasks/:id/events', component: CommandEventsComponent, canActivate: [AuthGuardService] }, // id refers to commandTaskId
        { path: 'event/:id', component: CommandEventDetailComponent, canActivate: [AuthGuardService] }
      ]
    },
    // Tickets
    {
      path: 'tickets', canActivate: [MsalGuard, AuthGuardService], component: EmptyComponent, data: { breadcrumbHidden: true, resourceKey: 'menu.Tickets', permissions: ['ticket'] }, children: [
        { path: 'list', component: TicketsComponent, data: { resourceKey: 'menu.Tickets.Registry' }, canActivate: [AuthGuardService] },
        { path: 'list/new', component: TicketDetailComponent, data: { resourceKey: 'menu.Tickets.Registry.Add' }, canActivate: [AuthGuardService], canDeactivate: [UnsavedGuardService] },
        { path: 'list/:ticketId/:dumpsterId', component: TicketDetailComponent, canActivate: [AuthGuardService] },
      ]
    }
  ];
}
