<app-custom-grid [columns]="columns"
                 [items]="items"
                 [showActions]="true"
                 [disableActions]="readonlyData"
                 [toolbarActions]="toolbarActions"
                 [selectable]="selectableSettings"
                 [showSelectAll]="true"
                 [selectedKeys]="selectedCollectionPointsId"
                 [selectCallback]="onSelectionChange"
                 [pageable]="true"
                 [pageSizes]="true"
                 [filterable]="true">
</app-custom-grid>

<ng-template #labelColumn let-dataItem>
  <app-labels-badge [labels]="dataItem.labels"></app-labels-badge>
</ng-template>
