<app-leaflet-map #map
  mode="single"
  [entities]="entities"
  [isTitleNavigable]="isTitleNavigable"
  [getCustomStatus]="getMarker()"
  [entityType]="MapEntityType.Dumpster"
  [isPopupBtnVisible]="useInfoWindowWithBtn"
  [zoom]="4"
  (selectedEntitiesChange)="onSelectionChange($event)"
></app-leaflet-map>
