import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Align } from '@progress/kendo-angular-popup';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCaretDown, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GroupListOutputGroup } from '../../models/group-list-output';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';

@Component({
  selector: 'app-group-search',
  templateUrl: './group-search.component.html',
  styleUrls: ['./group-search.component.scss']
})
export class GroupSearchComponent {

  @ViewChild('searchButtons', { static: true }) public anchor: ElementRef;
  @ViewChild('advSearch', { read: ElementRef }) public popup: ElementRef;

  @Input() public set items(val: GroupListOutputGroup[]) {

    this._items = val;

    //if (this._items) {
    //  // Cities
    //  let cities = this._items.map(d => d.address.city);
    //  cities = cities.filter((item, i, ar) => ar.indexOf(item) === i);
    //  this.cities = cities.sort();
    //}
  };
  
  @Input() public filteredItems: GroupListOutputGroup[];
  @Output() public filteredItemsChange = new EventEmitter();

  @Output() public searchChange = new EventEmitter();

  public formSimpleSearch: FormGroup;
  public formAdvSearch: FormGroup;

  public showAdvSearch: boolean = false;
  public advSearchAnchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  public advSearchAlign: Align = { horizontal: 'right', vertical: 'top' };

  public searched: boolean = false;

  public cities: string[] = [];

  private _items: GroupListOutputGroup[];

  constructor(
    private fb: FormBuilder,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper
  ) {

    this.library.addIcons(faTimes, faCaretDown, faSearch);

    this.formSimpleSearch = this.fb.group({
      search: null
    });

    this.formAdvSearch = this.fb.group({
      name: '',
      cities: null,
    });
  }


  public toggleAdvSearch(show?: boolean) {
    this.showAdvSearch = show !== undefined ? show : !this.showAdvSearch;
  }

  public onSimpleSearch() {
    if (this._items) {
      let value = this.formSimpleSearch.value.search;
      if (value) {
        value = value.toLowerCase();
        this.filteredItems = this._items.filter(d => {
          return this.textLowerContains(d.name, value);
        });
        this.searched = true;
      }
      else {
        this.filteredItems = this._items;
        this.searched = false;
      }
      this.filteredItemsChange.emit(this.filteredItems);
      this.searchChange.emit();
    }
  }

  public onAdvancedSearch() {
    const searchValue = this.formAdvSearch.value;

    let searchGroups = this._items;
    this.searched = false;

    // Name
    if (searchValue.name) {
      searchGroups = searchGroups.filter(d => {
        return this.textLowerContains(d.name, searchValue.name);
      });
      this.searched = true;
    }

    this.filteredItems = searchGroups;
    this.filteredItemsChange.emit(this.filteredItems);
    this.searchChange.emit();
  }

  public onClearSearch() {
    this.formSimpleSearch.reset();
    this.formAdvSearch.reset();
    this.filteredItems = this._items;
    this.searched = false;
    this.filteredItemsChange.emit(this.filteredItems);
    this.searchChange.emit();
  }

  private textLowerContains(text: string, value: string): boolean {
    if (text) {
      return text.toLowerCase().indexOf(value) > -1;
    }
    return false;
  }

  private contains(target: any): boolean {
    return this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false);
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target)) {
      this.toggleAdvSearch(false);
    }
  }

}
