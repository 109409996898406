import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectableMode, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { CustomGridComponent } from '../custom-grid/custom-grid.component';
import { GridColumn } from '../../models/grid-column';
import { ConstantHelper } from '../../helpers/constant.helper';
import { Action } from '../../models/action';
import { ActiveDirectoryUser } from '../../../administration/models/active-directory';

@Component({
  selector: 'app-user-grid',
  templateUrl: './user-grid.component.html',
  styleUrls: ['./user-grid.component.scss']
})
export class UserGridComponent implements OnInit {

  @ViewChild(CustomGridComponent) grid: CustomGridComponent;

  @Input() public items: ActiveDirectoryUser[];
  @Input() public actions: Action[];
  @Input() public toolbarActions: Action[] = [];
  @Input() public selectableMode: SelectableMode = 'multiple';
  @Input() public defaultFilter: CompositeFilterDescriptor;

  @Input() public set selectedUsers(val: ActiveDirectoryUser[]) {
    this._selectedUsers = val;

    this.refreshSelection();
  };

  public get selectedUsers(): ActiveDirectoryUser[] {
    return this._selectedUsers;
  }

  @Input() public set readonly(data: boolean) {
    this.readonlyData = data;

    if (this.selectableSettings) {
      this.selectableSettings.enabled = !data;
    }
  }

  public readonlyData: boolean = false;

  @Output() public selectedUsersChange = new EventEmitter();
  @Output() public visibleRowsCountChange = new EventEmitter<any>();

  private _selectedUsers: ActiveDirectoryUser[];
  public selectedUsersId: string[];

  public columns: GridColumn[] = [];

  public selectableSettings: SelectableSettings;

  constructor(private router: Router, private route: ActivatedRoute, public constantHelper: ConstantHelper) {
    this.onSelectionChange = this.onSelectionChange.bind(this);
  }

  ngOnInit() {

    // Set columns here because column template 
    this.columns = [
      {
        field: 'name',
        title: this.constantHelper.LBL_COMMON_NAME,
        sortable: true,
        resizable: true,
        filterable: true
      }
    ];

    this.selectableSettings = {
      enabled: !this.readonlyData,
      mode: this.selectableMode
    };
  }

  public refreshSelection() {
    if (this._selectedUsers)
      this.selectedUsersId = this._selectedUsers.map(d => d.id);
    else
      this.selectedUsersId = [];
  }

  public onSelectionChange(e: SelectionEvent) {
    let selected = Object.assign([], this._selectedUsers);
    if (e.selectedRows.length > 0) {
      e.selectedRows.forEach(r => selected.push(r.dataItem));
    }
    if (e.deselectedRows.length > 0) {
      e.deselectedRows.forEach(r => {
        let i = selected.findIndex(d => d.id == r.dataItem.id);
        if (i > -1) {
          selected.splice(i, 1);
        }
      });
    }
    this._selectedUsers = selected;
    this.selectedUsersChange.emit(this._selectedUsers);
  }
}
