import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FileContent } from '../models/file-content';

@Injectable()
export class CommonHelper {

  public dateTimeReviver(key, value) {
    let dateRegex = new RegExp(/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/);
    if (typeof value === 'string' && value.match(dateRegex)) {
      return new Date(value);
    }
    return value;
  }

  public convertToDate(obj): void {
    if (obj === null || obj === undefined) {
      return obj;
    }

    if (typeof obj !== 'object') {
      return obj;
    }

    for (const key of Object.keys(obj)) {
      const value = obj[key];
      if (this.isIso8601(value)) {
        obj[key] = new Date(value);
      } else if (typeof value === 'object') {
        this.convertToDate(value);
      }
    }
  }

  public isIso8601(value): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?/.test(value);
  }

  public isNullOrWhiteSpace(str: string): boolean {
    return str == null || str.replace(/\s/g, '').length < 1;
  }

  public stringFormat(s: string, args: string[]): string {
    for (var i = 0; i < args.length; i++) {
      s = s.replace('{' + i + '}', args[i]);
    }
    return s;
  }

  public clone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object), this.dateTimeReviver) as T;
  }

  public compareObjects(obj, objToCompare): boolean {
    if (JSON.stringify(obj) != JSON.stringify(objToCompare)) {
      return false;
    }
    return true;
  }

  public replaceRouteParams(url: string, routeParams: any) {
    var param, placeholder, result;

    result = url;
    for (param in routeParams) {
      placeholder = ':' + param;
      result = result.replace(placeholder, routeParams[param]);
    }
    return result;
  }

  public downloadAttachment(blob: FileContent): void {
    const element: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    if (typeof element.download !== 'undefined') {
      const objectUrl: string = URL.createObjectURL(blob.file);
      element.href = objectUrl;
      element.download = blob.name;
      element.style.display = 'none';

      document.body.appendChild(element);
      element.click(); // download the file
      document.body.removeChild(element); // remove the <a> element
      URL.revokeObjectURL(objectUrl);
    } else { // IE 11
      window.navigator.msSaveOrOpenBlob(blob.file, blob.name);
    }
  }

  public validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public enableValidFormFields(oldFormGroup: FormGroup | FormArray, newFormGroup: FormGroup | FormArray): void {
    oldFormGroup.enable();

    Object.keys(oldFormGroup.controls).forEach(key => {
      if (newFormGroup.controls[key] instanceof FormArray) {
        this.enableValidFormFields(oldFormGroup.controls[key], newFormGroup.controls[key] as FormArray)
      } else {
        if (newFormGroup.controls[key].disabled) {
          oldFormGroup.controls[key].disable({ emitEvent: false });
        }
      }
    });
  }

  public showErrors(formGroup: FormGroup, resources: string[], labelFunction: (label: string) => string, fromArray: boolean = false) {
    const validationErrors = [];

    for (const control of Object.keys(formGroup.controls)) {
      const formControl = formGroup.get(control);
      if (!formControl.valid && !formControl.disabled) {
        if (formControl instanceof FormArray) {
          formControl.controls.forEach(controlArray => {
            var arrErrors = this.showErrors(controlArray as FormGroup, resources, labelFunction, true);
            if (arrErrors.length > 0) {
              validationErrors.push(arrErrors);
            }
          });
        } else {
          for (const err of Object.keys(formControl.errors)) {
            const resourceKey = resources.find((r) => r == 'common.Error.' + err);
            let errorToPush;

            if (resourceKey) {
              const req = labelFunction(resourceKey);
              let errorParam = null;
              switch (err) {
                case 'maxItems':
                  errorParam = formControl.errors[err];
                  break;
              }

              var toShow = control;
              if (fromArray) {
                toShow = formControl.parent.get('label').value;
              }

              errorToPush = this.stringFormat(req, [labelFunction(toShow), errorParam]);
            } else {
              errorToPush = labelFunction(`${control}${err.substring(0, 1).toUpperCase()}${err.substring(1)}`);
            }

            validationErrors.push(errorToPush);
          }
        }
      }
    }

    return validationErrors;
  }
}
