import { Injectable, EventEmitter } from '@angular/core';
import { ConfirmEvent } from '../models/confirm-event';
import { AlertEvent } from '../models/alert-event';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public confirm: EventEmitter<ConfirmEvent> = new EventEmitter();
  public alert: EventEmitter<AlertEvent> = new EventEmitter();

  constructor() { }

  public showConfirm(message: string, onConfirm: Function, onCancel?: Function | null) {

    let event = new ConfirmEvent(message, onConfirm, onCancel);
    this.confirm.emit(event);
  }

  public showAlert(message: string, onOk?: Function | null) {

    let event = new AlertEvent(message, onOk);
    this.alert.emit(event);
  }

}
