import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { IncidentGridComponent } from '../../components/incident-grid/incident-grid.component';
import { IncidentListOutputIncident } from '../../models/incident-list-output';
import { ImportResultOutput } from '@veritas-shared/models/import-result-output';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IncidentService } from '../../services/incident.service';
import { ToastService } from '@veritas-shared/services/toast.service';
import { SessionService } from '@veritas-shared/services/session.service';
import { FormBuilder } from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { Action } from '@veritas-shared/models/action';

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.scss']
})
export class IncidentsComponent implements OnInit {

  @ViewChild(IncidentGridComponent, { read: IncidentGridComponent }) incidentGrid: IncidentGridComponent;
  //@ViewChild(IncidentMapComponent, { read: IncidentMapComponent, static: false }) incidentMap: IncidentMapComponent;

  public filteredIncidents: IncidentListOutputIncident[];
  private _tempFilteredIncidents: IncidentListOutputIncident[]; // used for temporary store incidents when show only selected is checked

  public incidents: IncidentListOutputIncident[];
  public selectedIncidents: IncidentListOutputIncident[] = [];

  public actions: Action[];
  public toolbarActions: Action[] = [];

  public isLabelsOpen: boolean = true;
  public isLabelsDialogOpen: boolean = false;
  public isShowOnlySelected: boolean = false;

  // upload section
  public isImportDialogOpen: boolean = false;
  public isMergeEnabled: boolean = false;
  public uploadSaveUrl: string = '';
  public importResult: ImportResultOutput;
  public uploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.csv']
  };

  constructor(private incidentService: IncidentService, private toastService: ToastService, private fb: FormBuilder, private library: FaIconLibrary,
    private route: ActivatedRoute, private translate: TranslateService, private router: Router, private sessionService: SessionService) {

    //// TODO
    //// **G** workaround for AdalService AuthenticationContext
    //try {
    //  this.authService.getResourceForEndpoint(this.uploadSaveUrl);
    //}
    //catch {
    //  this.authService.init(environment.config);
    //}

    library.addIcons(faAngleDoubleLeft, faAngleDoubleRight);

    //this.add = this.add.bind(this);
    //this.edit = this.edit.bind(this);
    //this.deactivateBulk = this.deactivateBulk.bind(this);
    //this.openImportDialog = this.openImportDialog.bind(this);
    //this.closeImportDialog = this.closeImportDialog.bind(this);

    //const userProfile = this.sessionService.getUserProfile();

    this.toolbarActions = [
      //userProfile != null && userProfile.permissions.indexOf('collectionPoint.write') > -1 ? new GridAction('add', 'action.Common.Add', 'plus', this.add) : null,
      //new GridAction('delete', 'action.Common.Deactivate', 'trash-alt', this.deactivateBulk, true),
      //new GridActionSeparator(),
      //new GridAction('import', 'action.Common.Import', 'upload', this.openImportDialog)
    ];

    this.actions = [
      //new GridAction('edit', 'action.Common.Edit', 'pencil-alt', this.edit),
      //new GridAction('delete', 'action.Common.Deactivate', 'trash-alt', this.deactivateBulk),
    ];

    //this.uploadSaveUrl = this.collectionpointService.getUploadUrl(ImportBehavior.Insert);
    //this.importResult = new ImportResultOutput(0, 0, []);
  }

  ngOnInit() {
    this.loadData();
  }

  //public toggleLabels() {
  //  this.isLabelsOpen = !this.isLabelsOpen;
  //}

  public onTabChange(event: SelectEvent) {
    if (event.index == 0) {
      this.incidentGrid.refreshSelection();
    }
    //if (event.index == 1) {
    //  window.setTimeout(() => this.incidentMap.centerAndZoomOnMarkers(), 200);
    //}
  }

  public onSearchChanged() {
    this.selectedIncidents = [];
    this.isShowOnlySelected = false;
    this.incidentGrid.goToPage(0);
  }

  public toggleShowOnlySelected() {
    if (this.isShowOnlySelected) {
      this._tempFilteredIncidents = this.filteredIncidents;
      this.filteredIncidents = this.selectedIncidents;
      this.incidentGrid.goToPage(0);
    }
    else {
      this.filteredIncidents = this._tempFilteredIncidents;
      this._tempFilteredIncidents = [];
    }
  }

  public add() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  public edit(incident: IncidentListOutputIncident) {
    this.router.navigate([incident.id, 'edit'], { relativeTo: this.route });
  }

  public goBack() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private loadData() {
    this.incidentService.getIncidents().subscribe(result => {
      this.incidents = result.incidents;
      this.filteredIncidents = result.incidents;
    });
  }

}
