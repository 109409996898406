export class AlertEvent {

  public message: string;
  public onOk: Function | null;

  constructor(message: string, onOk: Function | null) {
    this.message = message;
    this.onOk = onOk;
  }
}
