import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DeactivatedComponent } from '@veritas-shared/components/deactivated.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViewMode } from '@veritas-shared/models/view-mode';
import { ToastService } from '@veritas-shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave, faDumpster, faInfoCircle, faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { ViewModeHelper } from '@veritas-shared/helpers/view-mode-helper';
import { EditCollectionPointInput } from '../../models/edit-collectionpoint-input';
import { CollectionPointListDeactivateCollectionPointsInput } from '../../models/collectionpoint-list-deactivate-collectionpoints-input';
import { CollectionPointService } from '../../services/collectionpoint.service';
import { Group, EditCollectionPointOutputDumpster, EditCollectionPointOutputCollectionPoint } from '../../models/edit-collectionpoint-output';
import { DumpsterService } from '@veritas-dumpsters/services/dumpster.service';
import { EditCollectionPointAddDumpstersInput } from '../../models/edit-collectionpoint-add-dumpsters-input';
import { EditCollectionPointRemoveDumpstersInput } from '../../models/edit-collectionpoint-remove-dumpsters-input';
import { SecurityService } from '@veritas-shared/services/security.service';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { Label } from '@veritas-shared/models/label';
import { SessionService } from '@veritas-shared/services/session.service';
import { CollectionPoint } from '@veritas-shared/models/collectionpoint';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { BeanKendoDialogService, WindowContainerDimension, WindowVisualizerDirective } from 'bean-kendo-dialog';
import { DumpsterSelectDialogComponent, DumpsterSelectDialogInput } from '@veritas-dumpsters/components/dumpster-select-dialog/dumpster-select-dialog.component';
import { BeanDialogDimension } from 'bean-kendo-dialog/lib/classes/bean-dialog-dimension.class';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { NavigationService } from '@veritas-shared/services/navigation.service';

@Component({
  selector: 'app-collectionpoint-detail',
  templateUrl: './collectionpoint-detail.component.html',
  styleUrls: ['./collectionpoint-detail.component.scss']
})
export class CollectionPointDetailComponent extends DeactivatedComponent implements OnInit, OnDestroy {

  @ViewChild(WindowVisualizerDirective, { static: true }) public windowVisualizer: WindowVisualizerDirective;

  public hasWritePermission: boolean;

  public formCollectionPoint: FormGroup;
  private collectionPointId: number;
  public collectionPoint: EditCollectionPointOutputCollectionPoint;

  public collectionPointDumpsters: EditCollectionPointOutputDumpster[] = [];

  public allDumpsters: Dumpster[] = [];
  public allLabels: Label[] = [];

  public selectedDumpsters: Dumpster[] = [];

  public groups: Group[] = [];

  public actions: Action[];
  public toolbarActions: Action[] = [];

  public viewMode: ViewMode;

  public ViewMode = ViewMode;

  public isDumpstersListReadonly: boolean = false;

  public collectionPoints: CollectionPoint[] = [];
  public selectedCollectionPoints: CollectionPoint[] = [];

  private querySubscription: Subscription;

  constructor(
    private collectionPointService: CollectionPointService,
    private dumpsterService: DumpsterService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private translate: TranslateService,
    private securityService: SecurityService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper,
    private dialogService: BeanKendoDialogService,
    private navigationService: NavigationService
  ) {
    super();

    this.onEdit = this.onEdit.bind(this);
    this.onView = this.onView.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.add = this.add.bind(this);
    this.delete = this.delete.bind(this);
    this.deleteBulk = this.deleteBulk.bind(this);

    this.library.addIcons(faSave, faChevronLeft, faDumpster, faInfoCircle, faCrosshairs);

    // Actions
    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_COLLECTION_POINT_WRITE).subscribe(res => {

      this.hasWritePermission = res;

      if (res) {
        this.toolbarActions = [
          new Action('add', this.constantHelper.LBL_ACTION_COLLPOINT_ADD_DUMPSTER, 'plus', this.add),
          new Action('delete', this.constantHelper.LBL_ACTION_COLLPOINT_DELETE_DUMPSTER, 'trash-alt', this.deleteBulk, true),
        ];

        this.actions = [
          //new GridAction('edit', 'action.Common.Edit', 'pencil-alt', this.edit),
          new Action('delete', this.constantHelper.LBL_ACTION_COLLPOINT_DELETE_DUMPSTER, 'trash-alt', this.deleteBulk),
        ];
      }
    });

    // Params
    this.route.params.subscribe(params => {
      if (params) {
        this.collectionPointId = +params.collectionPointId;
      }
    });

    // Query Params
    this.querySubscription = combineLatest(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
      ),
      this.route.queryParams
    ).subscribe(res => {

      // Set ViewMode
      this.viewMode = ViewModeHelper.getViewMode(this.route, 'collectionPointId');

      this.isDumpstersListReadonly = this.viewMode == ViewMode.View;

      // Enalbe / Disable the form
      if (this.formCollectionPoint) {
        let newForm = this.setFormGroup();
        this.commonHelper.enableValidFormFields(this.formCollectionPoint, newForm);
      }

      // Set PageTitle
      if (this.viewMode == ViewMode.View && this.collectionPoint) {
        this.sessionService.setPageTitle(this.collectionPoint.name);
      }
    });
  }

  canDeactivate(): boolean {
    return !this.formCollectionPoint.dirty;
  }

  ngOnInit() {

    this.formCollectionPoint = this.setFormGroup();

    switch (this.viewMode) {
      case ViewMode.Edit:
      case ViewMode.View:
        this.collectionPointService.getEditCollectionPoint(this.collectionPointId).subscribe(result => {
          this.collectionPoint = result.collectionPoint;
          this.collectionPoints = [result.collectionPoint];
          this.sessionService.setPageTitle(result.collectionPoint.name);
          this.formCollectionPoint.patchValue(result.collectionPoint);
          this.formCollectionPoint.patchValue(result.collectionPoint.address);
          this.formCollectionPoint.patchValue(result.collectionPoint.address.location);
          this.collectionPointDumpsters = result.dumpsters;
          this.groups = result.groups;
        });
        break;
      case ViewMode.New:
        this.collectionPointService.getNewCollectionPoint().subscribe(result => {
          this.formCollectionPoint.patchValue(result.collectionPoint);
        });
        break;
    }
  }

  ngOnDestroy() {
    if (this.querySubscription != null) {
      this.querySubscription.unsubscribe();
    }
  }

  private setFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      collectionPointCode: [{ value: '', disabled: this.viewMode != ViewMode.New }, Validators.required],
      name: [{ value: '', disabled: this.viewMode != ViewMode.New }, Validators.required],
      description: [{ value: '', disabled: this.viewMode == ViewMode.View }],
      country: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
      province: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
      city: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
      note: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
      street: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
      streetNumber: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
      latitude: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
      longitude: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
    });
  }

  public onTabChange(event: SelectEvent) {
    // TODO ???

    //if (event.index == 0) {
    //  this.collectionPointGrid.refreshSelection();
    //}
    //if (event.index == 1) {
    //  window.setTimeout(() => this.collectionPointMap.centerAndZoomOnMarkers(), 200);
    //}
  }

  public onEdit() {
    this.router.navigate([], { queryParams: { edit: true }, relativeTo: this.route });
  }

  public onView() {
    this.router.navigate([], { relativeTo: this.route });
  }

  public onBack() {
    this.goBack();
  }

  public goBack() {
    this.navigationService.back('/collectionpoints/list');
  }

  public onCancel() {
    this.formCollectionPoint.reset(this.collectionPoint);
  }

  public onDelete() {
    var collectionPointsToDeactivate = new CollectionPointListDeactivateCollectionPointsInput([this.collectionPointId]);
    this.collectionPointService.deactivateCollectionPoints(collectionPointsToDeactivate).subscribe(result => {
      this.toastService.showSuccess(
        this.translate.instant('page.CollectionPointDetail.Dialog.Deactivate.ConfirmationMessage', { '0': 1 })
      );
      this.goBack();
    });
  }

  public onSave() {
    if (this.formCollectionPoint.valid) {
      let collectionPointInput = <EditCollectionPointInput>this.formCollectionPoint.getRawValue();

      // TODO mettere il NumericTextBox, non servirebbe qyesto
      collectionPointInput.latitude = +collectionPointInput.latitude;
      collectionPointInput.longitude = +collectionPointInput.longitude;

      if (this.viewMode == ViewMode.Edit) {
        this.collectionPointService.updateCollectionPoint(collectionPointInput).subscribe(() => {
          this.formCollectionPoint.reset();
          this.goBack();
        });
      }
      else if (this.viewMode == ViewMode.New) {
        this.collectionPointService.addCollectionPoint(collectionPointInput).subscribe(() => {
          this.formCollectionPoint.reset();
          this.goBack();
        });
      }

    }
  }

  public add() {
    let observable: Observable<any>;

    if (this.allDumpsters.length == 0) {
      observable = this.loadAllDumpsters();
    }
    else {
      this.setSelectableDumpstersToAdd();
      observable = of(this.allDumpsters);
    }

    observable.subscribe(res => {
      this.dialogService.openDialog(
        this.translate.instant(this.constantHelper.LBL_PAGE_COLL_POINT_DETAIL_SELECTED_DUMPSTER_TITLE),
        new DumpsterSelectDialogInput(this.allDumpsters, this.allLabels),
        DumpsterSelectDialogComponent,
        this.windowVisualizer,
        () => { },
        (res: Dumpster[]) => {

          // Add selected devices
          const addedDumpstersIds = res.map(d => d.id);
          const input = new EditCollectionPointAddDumpstersInput(this.collectionPointId, addedDumpstersIds);

          return this.collectionPointService.addDumpsters(input).pipe(map((result) => {
            this.toastService.showSuccess(
              this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_ADDDUMPSTERS_CONFIRM_MESSAGE, { '0': addedDumpstersIds.length })
            );
            // Reload collection point devices
            this.collectionPointService.getDumpsters(this.collectionPointId).subscribe(result => {
              this.collectionPointDumpsters = result.dumpsters;
            });
          }));
        },
        true,
        WindowContainerDimension.Medium,
        {
          mediumHeight: 600,
          mediumWidth: 1000,
        } as BeanDialogDimension
      );
    });
  }

  public delete(dumpster: any) {
    var dumpstersToRemove = new EditCollectionPointRemoveDumpstersInput(this.collectionPointId, [dumpster.id]);
    this.collectionPointService.removeDumpsters(dumpstersToRemove).subscribe(result => {
      this.toastService.showSuccess(
        this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DELETEDUMPSTERS_CONFIRM_MESSAGE, { '0': 1 })
      );

      // Reload collection point devices
      this.collectionPointService.getDumpsters(this.collectionPointId).subscribe(result => {
        this.collectionPointDumpsters = result.dumpsters;
      });
    });
  }

  public deleteBulk() {
    if (this.selectedDumpsters.length > 0) {
      var dumpstersToRemove = new EditCollectionPointRemoveDumpstersInput(this.collectionPointId, this.selectedDumpsters.map(d => d.id));
      this.collectionPointService.removeDumpsters(dumpstersToRemove).subscribe(result => {
        this.selectedDumpsters = [];
        this.toastService.showSuccess(
          this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DELETEDUMPSTERS_CONFIRM_MESSAGE, { '0': this.selectedDumpsters.length })
        );
        // Reload collection point devices
        this.collectionPointService.getDumpsters(this.collectionPointId).subscribe(result => {
          this.collectionPointDumpsters = result.dumpsters;
        });
      });
    }
  }

  private loadAllDumpsters() {
    let obs = this.dumpsterService.getDeassociatedDumpsters();

    obs.subscribe(result => {
      this.allDumpsters = result.dumpsters;
      this.allLabels = result.labels;

      this.setSelectableDumpstersToAdd();
    });

    return obs;
  }

  private setSelectableDumpstersToAdd() {
    // Remove collection point devices
    const collectionPointDumpsterIds = this.collectionPointDumpsters.map(d => d.id);
    this.allDumpsters = this.allDumpsters.filter(d => collectionPointDumpsterIds.indexOf(d.id) == -1 && d.regionCode && d.groupCount > 0);
  }

  //
  public goToGroupDetail(group: Group) {
    this.router.navigate(['collectionpoints', 'groups', group.id]);
  }
}
