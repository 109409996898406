<h1 class="page-title"><fa-icon icon="list"></fa-icon>{{ constantHelper.LBL_PAGE_TICKETS_TITLE | translate }}</h1>

<div class="list-container">
  <div class="page-content">

    <div class="mt-10">
      <app-ticket-search (searchChange)="onSearchChanged($event)" (collapseChange)="onCollapseChange($event)" [entities]="entities" [dumpsters]="dumpsters"></app-ticket-search>
    </div>

    <div class="mt-10">
        <app-group-box [pageTitle]="'Elenco Ticket'">

          <!-- Button to create a new ticket  -->
          <div class="d-flex flex-row-reverse ticket-button-row">
            <ng-container *ngFor="let action of toolbarActions">
              <button type="button" class="btn ticket-toolbar-item btn-primary" [disabled]="disableActions || (action.applyOnSelected && (!selectedKeys || selectedKeys.length == 0))" (click)="onActionClick(action)">
                <fa-icon class="ticket-button-icon" icon="{{action.icon}}"></fa-icon>
                <span>{{action.resourceKey | translate}}</span>
              </button>
              <span *ngIf="action.separatorNext" class="toolbar-separator"></span>
            </ng-container>
          </div>

        <!-- Ticket grid  -->
        <div class="d-flex flex-row-reverse">
            <div class="standard-padding">    
              <div class="grid-container" [ngClass]="{'large-grid': isCollapsed}">
                <app-ticket-grid #ticketGrid
                  [items]="filteredTickets"
                  [selectableMode]="'single'"
                  [entities]="entities"
                  [selectedPage]="selectedPage"
                  (stateChange)="loadDataByPagination($event)" 
                >
                </app-ticket-grid>
              </div>
            </div>  
          </div>  
        </app-group-box>
        
    </div>
  </div>
</div>

<ng-template windowVisualizer></ng-template>
