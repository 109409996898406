import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { eventTranslates } from '@veritas-dumpsters/constants/event-translate.const';
import { DumpsterEventsOutputItem } from '@veritas-dumpsters/models/dumpster-events';
import { IEventTranslate } from '@veritas-dumpsters/models/event-translate.models';

@Injectable({
  providedIn: 'root'
})
export class EventTranslateService {
  constructor(
    private translate: TranslateService
  ){}

  public getConfiguredEvents = (events: DumpsterEventsOutputItem[]) => events.map((event) => {
    let translatedEvent = JSON.parse(JSON.stringify(event));

    translatedEvent.payloadType = this.translate.instant(event.payloadType);

    if (event.messageType) {
      let messageToTranslate = event.messageType
        .replace(/[(,-]/g, ' ')
        .replace(/[),:]/g, '')
        .split(' ');
      let index = 0;
      messageToTranslate.forEach((messagePiece) => {
        messageToTranslate[index] = this.translate.instant(messagePiece);
        index++;
      });
      translatedEvent.messageType = this._getMessageType(messageToTranslate, this._getTranslation(event.messageType));
    }

    return translatedEvent;
  });

  private _getTranslation = (message: string): string => {
    const event: IEventTranslate = eventTranslates.find((event: IEventTranslate) => event.type === message);
    
    if(!event) return message;
    return event?.translate;
  };

  private _getMessageType = (message: string[], messageType: string): string => {
    if (message.length == 2) {
      return `${message[0]} : ${message[1]}`;
    } 
    if (message.length == 3) {
      return `${message[0]} (${message[1]} - ${message[2]})`;
    } 
    return this.translate.instant(messageType);
  }
}
