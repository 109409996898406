import { Pipe, PipeTransform } from '@angular/core';
import { ParameterType } from '../models/parametertype.enum';

@Pipe({
  name: 'parameterType'
})
export class ParameterTypePipe implements PipeTransform {

  transform(value: ParameterType): any {
    if (value == null) {
      return null;
    }

    switch (value) {
      case ParameterType.Integer:
        return "Numero";

      case ParameterType.String:
        return "Stringa";

      case ParameterType.Dropdown:
        return "Selezione";

      case ParameterType.Multiselect:
        return "Multiselezione";

      default:
        return "Non conosciuto";
    }
  }
}
