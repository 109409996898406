<h5 class="section-title">{{ title }}</h5>
<app-custom-grid [columns]="columns" [items]="importResult.rows" [showActions]="false" [actions]="actions" [toolbarActions]="toolbarActions"></app-custom-grid>

<ng-template #typeColumn let-dataItem>
  <ng-container [ngSwitch]="dataItem.type">
    <span *ngSwitchCase="'Info'" class="badge badge-info">{{ dataItem.typeDescription | translate }}</span> <!-- Info -->
    <span *ngSwitchCase="'Warning'" class="badge badge-warning">{{ dataItem.typeDescription | translate }}</span> <!-- Warning -->
    <span *ngSwitchCase="'Error'" class="badge badge-danger">{{ dataItem.typeDescription | translate }}</span> <!-- Error -->
  </ng-container>
</ng-template>
