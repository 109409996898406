import { Injectable, Inject } from '@angular/core';
import { ApiService } from '@veritas-shared/services/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { UserProfileOutput } from '@veritas-shared/models/user-profile-output';
import { ToastService } from '@veritas-shared/services/toast.service';
import { WaitService } from '@veritas-shared/services/wait.service';
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from '@veritas-shared/helpers/common.helper';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, toastService: ToastService, waitService: WaitService, commonHelper: CommonHelper, newToastService: BeanToastService) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  public getUserProfile(): Observable<UserProfileOutput> {
    return this.getBase<UserProfileOutput>('api/user');
  }
}
