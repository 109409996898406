import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { BeanDialogComponentBase, IDialogInput } from 'bean-kendo-dialog';
import { Observable, of } from 'rxjs';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { FileContent } from '@veritas-shared/models/file-content';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { TicketEventContent } from '../../models/ticket';
import { TicketAttachment } from '../../models/ticket-attachment';
import { TicketUpdateInput } from '../../models/ticket-update-input';

@Component({
  selector: 'app-ticket-history-dialog',
  templateUrl: './ticket-history-dialog.component.html',
  styleUrls: ['./ticket-history-dialog.component.scss']
})
export class TicketHistoryDialogComponent extends BeanDialogComponentBase<TicketHistoryDialogInput> {

  public data: TicketHistoryDialogInput;

  public formHistory: FormGroup;

  public attachmentToolbarActions: Action[] = [];
  public attachmentActions: Action[] = [];
  public attachments: TicketAttachment[] = [];
  public attachmentColumns: GridColumn[] = [];

  constructor(
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper,
    private fb: FormBuilder
  ) {
    super();

    this.onFilesInput = this.onFilesInput.bind(this);
    this.onFileDownload = this.onFileDownload.bind(this);

    this.library.addIcons(faChevronLeft, faSave);
  }

  ngAfterViewInitCallback(): void {

    this.formHistory = this.fb.group({
      note: [{ value: this.data.event ? this.data.event.detail.note : "", disabled: this.data.isView }],
    });

    if (!this.data.isView) {
      this.attachmentToolbarActions = [
        new Action('add', "Aggiungi", 'plus', this.onFilesInput),
      ];
    }

    this.attachmentActions = [
      new Action('download', "Scarica", 'download', this.onFileDownload),
    ];

    this.attachmentColumns = [
      {
        field: 'fileName',
        title: this.constantHelper.LBL_VOCABULARY_DUMPSTER_NAME,
        sortable: true,
        resizable: true,
        filterable: false,
        display: 'text',
      },
    ];

    if (this.data.event) {
      this.attachments = this.data.event.detail.attachments;
    }
  }

  cancel(e: any): boolean {
    return true;
  }

  back(e: any): boolean {
    return true;
  }

  save(e: any): Observable<any> {

    let res = this.formHistory.getRawValue();

    return of({
      note: res.note,
      attachments: this.attachments.map(el => el.blob)
    } as TicketUpdateInput);
  }

  private onFilesInput(event) {

    var input = document.getElementById("attachmentUploader") as HTMLInputElement;
    let that = this;

    input.onchange = (e: Event) => {

      that.attachments = [];

      Array.from((<HTMLInputElement>e.target).files).forEach(file => {
        that.attachments.push({
          fileName: file.name,
          blob: file as File
        } as TicketAttachment);
      });
    }

    input.value = null;

    // Click to open popup
    input.click();
  }

  private onFileDownload(file: TicketAttachment) {
    if (this.commonHelper.isNullOrWhiteSpace(file.downloadLink)) {
      this.commonHelper.downloadAttachment(new FileContent(file.blob, file.fileName));
    } else {

      // Use downloadLink
      const element: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      if (typeof element.download !== 'undefined') {
        element.href = file.downloadLink;
        element.download = file.fileName; // https://www.samanthaming.com/tidbits/38-html-download-attribute/ 
        element.style.display = 'none';

        document.body.appendChild(element);
        element.click(); // download the file
        document.body.removeChild(element); // remove the <a> element
      }
    }
  }
}

export class TicketHistoryDialogInput implements IDialogInput {
  constructor(public event: TicketEventContent, public isView: boolean = true) { }
}

