var adalConfig: any = {
  tenant: '85ba389f-3bfe-47ae-8dd0-132ba863a0fb',
  clientId: 'fd7545a6-c337-49d3-83f7-7fcd33ef0bc2',
  anonymousEndpoints: ['/api/resource'],
/*  redirectUri: 'https://portale-calotte.gruppoveritas.it/',*/
  expireOffsetSeconds: 300,
  popUp: false,
}

export const environment = {
  production: true,
  config: adalConfig
};
