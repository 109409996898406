import { ActivatedRoute } from "@angular/router";
import { ViewMode } from "../models/view-mode";

export class ViewModeHelper {
  public static getViewMode(route: ActivatedRoute, entityId: string = 'id'): ViewMode {

    let id = +route.snapshot.paramMap.get(entityId);
    let isEdit = route.snapshot.url.findIndex(u => u.path == 'edit') > -1 || route.snapshot.queryParamMap.get('edit') != null;

    if (id > 0) {
      if (isEdit) {
        return ViewMode.Edit;
      }
      else {
        return ViewMode.View;
      }
    }

    return ViewMode.New;
  }
}
