<h1 class="page-title" *ngIf="viewMode != ViewMode.New"><fa-icon icon="users"></fa-icon>{{ group?.name }}</h1>
<h1 class="page-title" *ngIf="viewMode == ViewMode.New"><fa-icon icon="users"></fa-icon>{{ 'page.CollectionPointGroupNew.Title' | translate }}</h1>
<app-toolbar [formGroup]="formGroup" [viewMode]="viewMode" (view)="onView()" (back)="onBack()" (edit)="onEdit()" (delete)="onDelete()" (save)="onSave()" (cancel)="onCancel()"  [hideOperations]="!hasWritePermission"></app-toolbar>

<div class="page-content">

  <!-- GENERAL -->
  <div class="mt-10">
    <app-group-box pageTitle="{{ 'page.AuthorizationGroupDetail.Section.General.Title' | translate }}">
      <form [formGroup]="formGroup" class="box-standard-padding" novalidate>
        <div class="row">
          <label class="col-xl-2 col-md-2 col-12 col-form-label">{{ constantHelper.LBL_COMMON_NAME | translate}}</label>
          <div class="col-xl-6 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="name" id="inputName">
          </div>
        </div>
        <div class="row">
          <label class="col-xl-2 col-md-2 col-12 col-form-label">{{  constantHelper.LBL_COMMON_DESCRIPTION | translate}}</label>
          <div class="col-xl-10 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="description">
          </div>
        </div>
        <div class="row" *ngIf="groupTypeVisible">
          <label class="col-xl-2 col-md-2 col-12 col-form-label">{{ 'vocabulary.AuthorizationGroup.Type' | translate}}</label>
          <div class="col-xl-6 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="groupTypeDescription">
          </div>
        </div>
      </form>
    </app-group-box>
  </div>

  <!-- COLLECTION POINT -->
  <div class="">
    <app-group-box pageTitle="{{ 'page.AuthorizationGroupDetail.Section.CollectionPoints.Title' | translate }}">
      <div class="box-standard-padding coll-points-data">
        <kendo-tabstrip (tabSelect)="onTabChange($event)" [keepTabContent]="true">
          <kendo-tabstrip-tab title="{{ 'component.CollectionPoints.Tab.List' | translate }}" [selected]="true">
            <ng-template kendoTabContent>
              <div class="tab-body">
                <app-collectionpoint-grid [items]="groupCollectionPoints" [(selectedCollectionPoints)]="selectedCollectionPoints" [readonly]="isCollectionPointsListReadonly" [actions]="actions" [toolbarActions]="toolbarActions"></app-collectionpoint-grid>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="{{ 'component.CollectionPoints.Tab.Map' | translate }}">
            <ng-template kendoTabContent *loadOnDemand>
              <div class="tab-body">
                <app-collectionpoint-map [items]="groupCollectionPoints" [(selectedCollectionPoints)]="selectedCollectionPoints"></app-collectionpoint-map>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
    </app-group-box>
  </div>

  <!-- AUDITING -->
  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-auditing-box [item]="group"></app-auditing-box>
  </div>

  <!-- GROUPS -->
  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-info-sequence title="{{ 'page.AuthorizationGroupDetail.Section.Authorizations.Title' | translate }}"
                       [items]="groupAuthorizations"
                       (onClick)="goToAuthorizationDetail($event)">
    </app-info-sequence>
  </div>
</div>

<ng-template windowVisualizer></ng-template>
