export enum DateType {
  date = 'date',
  month = 'mont',
  hour = 'hour',
  minute = 'minute',
}

export enum DateFormat {
  shortDate = 'shortDate',
  shortTime = 'shortTime',
}