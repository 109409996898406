import { Injectable, Inject } from '@angular/core';
import { ApiService } from '@veritas-shared/services/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { ToastService } from '@veritas-shared/services/toast.service';
import { WaitService } from '@veritas-shared/services/wait.service';
import { CommandListOutput } from '../models/command-list-output';
import { NewCommandOutput } from '../models/new-command-output';
import { EditCommandOutput } from '../models/edit-command-output';
import { EditCommandInput } from '../models/edit-command-input';
import { NewCommandInput } from '../models/new-command-input';
import { CommandDeleteOutput } from '../models/command-delete-output';
import { RunCommandInput } from '../models/run-command-input';
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { CommandConfigurationRequest, GetConfigurationResponse } from '@veritas-dumpsters/models/dumpster-configuration';
import { DumpsterEventDetailInput } from '@veritas-dumpsters/models/dumpster-event-detail';

@Injectable({
  providedIn: 'root'
})
export class CommandService extends ApiService {

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, toastService: ToastService, waitService: WaitService, commonHelper: CommonHelper, newToastService: BeanToastService) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  public getCommands(): Observable<CommandListOutput> {
    return this.getBase<CommandListOutput>('api/command');
  }

  public getNewCommand(): Observable<NewCommandOutput> {
    return this.getBase<NewCommandOutput>('api/command/new');
  }

  public getEditCommand(id: number, wasteType?: string): Observable<EditCommandOutput> {
    var url = `api/command/${id}/edit`;
    if (wasteType != undefined) {
      url += `?wasteType=${wasteType}`;
    }
    return this.getBase<EditCommandOutput>(url);
  }

  public getCommandDelete(id: number): Observable<CommandDeleteOutput> {
    return this.getBase<CommandDeleteOutput>('api/command/' + id + '/delete');
  }

  public addCommand(command: NewCommandInput): Observable<void> {
    return this.postBase<NewCommandInput, void>('api/command', command);
  }

  public updateCommand(command: EditCommandInput): Observable<void> {
    return this.putBase<EditCommandInput, void>('api/command', command);
  }

  public deleteCommand(id: number): Observable<void> {
    return this.deleteBase<void>('api/command/' + id);
  }

  public runCommand(item: RunCommandInput): Observable<string> {
    return this.postBase<RunCommandInput, string>('api/command/run', item);
  }

  public sendConfiguration(item: CommandConfigurationRequest): Observable<void> {
    return this.postBase<CommandConfigurationRequest, void>('api/command/sendconfiguration', item);
  }

  public getConfiguration(item: DumpsterEventDetailInput): Observable<GetConfigurationResponse> {
    return this.postBase<DumpsterEventDetailInput, GetConfigurationResponse>('api/command/getconfiguration', item);
  }

  public getConfigurationV2(item: DumpsterEventDetailInput): Observable<GetConfigurationResponse> {
    return this.postBase<DumpsterEventDetailInput, GetConfigurationResponse>('api/command/get/configuration/v2', item);
  }
}
