import { Component, ViewChild } from '@angular/core';
import { BeanDialogComponentBase, IDialogInput } from 'bean-kendo-dialog';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { CollectionPointListOutputCollectionPoint } from '../../models/collectionpoint-list-output';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { CollectionPointGridComponent } from '../collectionpoint-grid/collectionpoint-grid.component';
import { CollectionPointMapComponent } from '../collectionpoint-map/collectionpoint-map.component';

@Component({
  selector: 'app-collection-point-select-dialog',
  templateUrl: './collection-point-select-dialog.component.html',
  styleUrls: ['./collection-point-select-dialog.component.scss']
})
export class CollectionPointSelectDialogComponent extends BeanDialogComponentBase<CollectionPointSelectDialogInput > {

  @ViewChild(CollectionPointGridComponent, { read: CollectionPointGridComponent }) grid: CollectionPointGridComponent;
  @ViewChild(CollectionPointMapComponent, { read: CollectionPointMapComponent }) map: CollectionPointMapComponent;

  public filteredAllCollectionPoints: CollectionPointListOutputCollectionPoint[] = [];
  public selectedCollectionPointsToAdd: CollectionPointListOutputCollectionPoint[] = [];

  public data: CollectionPointSelectDialogInput;

  constructor(
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper
  ) {
    super();

    this.library.addIcons(faChevronLeft, faSave);
  }

  ngAfterViewInitCallback(): void {
    this.filteredAllCollectionPoints = this.data.allCollectionPoints;
  }

  cancel(e: any): boolean {
    return true;
  }

  back(e: any): boolean {
    return true;
  }

  save(e: any): Observable<CollectionPointListOutputCollectionPoint[]> {
    return of(this.selectedCollectionPointsToAdd);
  }

  public onTabChange(event: SelectEvent) {
    if (event.index === 0) {
      this.grid.refreshSelection();
    }
    if (event.index === 1) {
      window.setTimeout(() => this.map.centerAndZoomOnMarkers(), 200);
    }
  }
}


export class CollectionPointSelectDialogInput implements IDialogInput {
  constructor(public allCollectionPoints: CollectionPointListOutputCollectionPoint[], public selectableMode: ('single'| 'multiple') = 'multiple') { }
}
