<div class="relation-wrapper">
  <app-field-action
    [option]="{
      text: label,
      icon: 'fas fa-trash',
      isActionHide: !dumpsterDisplayed?.childDumpsterId
    }"
    (emitClick)="getConfirmDialog()"
  >
    <a
      class="field-body"
      href="dumpsters/list/{{dumpster?.dumpsterSqlId}}"
    >{{ dumpster?.currentDeviceId }}</a>
  </app-field-action>
</div>

<ng-template windowVisualizer></ng-template>
