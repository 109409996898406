import { Pipe, PipeTransform } from '@angular/core';
import { ClosureReason } from '../models/entities/closure-reason';

@Pipe({
  name: 'closureReason'
})
export class ClosureReasonPipe implements PipeTransform {

  transform(value: string, args: ClosureReason[]): any {
    if (value == null || args == null) {
      return null;
    }

    var result = args.find(el => el.closureReason == value)
    if (result) {
      return result.description;
    } else {
      return null;
    }
  }
}
