import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCubes, faShieldAlt, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { ModalService } from '@veritas-shared/services/modal.service';
import { ToastService } from '@veritas-shared/services/toast.service';
import { CommandTaskListOutputCommandTask } from '../../models/commandtask-list-output';
import { CommandTaskService } from '../../services/commandtask.service';
import { DisableCommandTaskInput } from '../../models/disable-commandtask-input';
import { SendCommandTaskInput } from '../../models/send-commandtask-input';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';

@Component({
  selector: 'app-commandtasks',
  templateUrl: './commandtasks.component.html',
  styleUrls: ['./commandtasks.component.scss']
})
export class CommandTasksComponent implements OnInit {

  @ViewChild('isEnabledColumn', { static: true }) public isEnabledColumn: TemplateRef<any>;
  @ViewChild('dateColumn', { static: true }) public dateColumn: TemplateRef<any>;

  public columns: GridColumn[] = [];
  public actions: Action[] = [];
  public toolbarActions: Action[] = [];
  public gridData: CommandTaskListOutputCommandTask[];

  constructor(private commandTaskService: CommandTaskService, private toastService: ToastService, private router: Router, private translate: TranslateService, private modalService: ModalService, private library: FaIconLibrary,
    public constantHelper: ConstantHelper
  ) {

    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.viewEvents = this.viewEvents.bind(this);
    this.delete = this.delete.bind(this);
    this.enable = this.enable.bind(this);
    this.disable = this.disable.bind(this);
    this.sendCommand = this.sendCommand.bind(this);

    this.library.addIcons(faUserShield, faShieldAlt, faCubes);

    this.actions = [
      new Action('edit', 'action.Common.Edit', 'pencil-alt', this.edit),
      new Action('delete', 'action.Common.Delete', 'trash-alt', this.delete),
      new Action('disable', 'action.Common.Disable', 'eye-slash', this.disable, null, false, (commandTask: CommandTaskListOutputCommandTask) => commandTask.isEnabled),
      new Action('enable', 'action.Common.Enable', 'eye', this.enable, null, false, (commandTask: CommandTaskListOutputCommandTask) => !commandTask.isEnabled),
      new Action('runCommand', 'action.CommandTask.SendCommand', 'play', this.sendCommand)
    ];

    this.toolbarActions = [
      new Action('add', 'action.Common.Add', 'plus', this.add)
    ];
  }

  ngOnInit() {

    this.columns = [
      {
        field: 'isEnabled',
        title: 'common.IsEnabled',
        sortable: true,
        resizable: false,
        filterable: false,
        template: this.isEnabledColumn,
        width: "70px"
      },
      {
        field: 'commandName',
        title: 'page.CommandTasks.CommandName',
        sortable: true,
        resizable: true,
        filterable: true
      },
      {
        field: 'name',
        title: 'page.CommandTasks.CommandTaskName',
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'link',
        callback: this.view
      },
      {
        field: 'triggerType',
        title: 'vocabulary.CommandTask.TriggerType',
        sortable: true,
        resizable: false,
        filterable: false,
      },
      {
        field: 'plannedAt',
        title: 'vocabulary.CommandTask.PlannedAt',
        sortable: true,
        resizable: false,
        filterable: false,
        display: 'datetime'
      },
      {
        field: 'dumpsterCount',
        title: this.constantHelper.LBL_PAGE_COMMAND_TASK_DUMPSTER_COUNT,
        sortable: true,
        resizable: false,
        filterable: false,
        width: "70px"
      },
      {
        field: 'commandEventsCount',
        title: 'page.CommandTasks.LinkEvents',
        sortable: false,
        resizable: true,
        filterable: false,
        display: 'link',
        callback: this.viewEvents
      }
    ];

    this.loadData();
  }

  public add() {
    this.router.navigate(['commands', 'tasks', 'new']);
  }

  public edit(commandTask: CommandTaskListOutputCommandTask) {
    this.router.navigate(['commands', 'tasks', commandTask.id], { queryParams: { edit: true } });
  }

  public view(commandTask: CommandTaskListOutputCommandTask) {
    this.router.navigate(['commands', 'tasks', commandTask.id]);
  }

  public viewEvents(commandTask: CommandTaskListOutputCommandTask) {
    this.router.navigate(['commands', 'tasks', commandTask.id, 'events']);
  }

  public delete(commandTask: CommandTaskListOutputCommandTask) {
    this.commandTaskService.getCommandTaskDelete(commandTask.id).subscribe(result => {
      if (result.commandEventsCount > 0) {
        this.modalService.showAlert(
          this.translate.instant('exception.CommandTask.Validator.UsedCommandTask', { '0': result.commandEventsCount }));
      } else {
        this.modalService.showConfirm(
          this.translate.instant('common.Confirm.Delete'),
          () => {
            this.commandTaskService.deleteCommandTask(commandTask.id).subscribe(() => {
              this.loadData();
            });
          });
      }
    });
  }

  public enable(commandTask: CommandTaskListOutputCommandTask) {
    const input = new DisableCommandTaskInput(commandTask.id, true);
    this.commandTaskService.disableCommandTask(input).subscribe(() => {
      this.loadData();
    });
  }

  public disable(commandTask: CommandTaskListOutputCommandTask) {
    const input = new DisableCommandTaskInput(commandTask.id, false);
    this.commandTaskService.disableCommandTask(input).subscribe(() => {
      this.loadData();
    });
  }

  private loadData() {
    this.commandTaskService.getCommandTasks().subscribe(result => {
      this.gridData = result.commandTasks;
    });
  }

  public sendCommand(commandTask: CommandTaskListOutputCommandTask) {
    const commandTaskToSend: SendCommandTaskInput = { commandId: commandTask.commandId, commandTaskId: commandTask.id };

    this.commandTaskService.sendCommandTask(commandTaskToSend).subscribe(() => {
      const message = this.translate.instant('page.CommandTasks.CommandSent');
      this.toastService.showSuccess(message);
    });
  }

}
