import { Injectable } from '@angular/core';
import { IDumpsterRelation } from '@veritas-dumpsters/models/dumpster-relation.model';
import { IRelationChild } from '@veritas-dumpsters/models/relation-child.model';
import { Dumpster } from '@veritas-shared/models/dumpster';

@Injectable({
  providedIn: 'root'
})
export class RelationMappingService {
  public getFromDumpster = (dumpster: Dumpster | IRelationChild): IDumpsterRelation => ({
    currentDeviceId: dumpster?.code,
    dumpsterSqlId: dumpster?.id,
    dumpsterId: dumpster?.deviceExternalId,
  });
}
