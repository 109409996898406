import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-text',
  templateUrl: './info-text.component.html',
  styleUrls: ['./info-text.component.scss']
})
export class InfoTextComponent implements OnInit {

  @Input() public text: string;
  @Input() public tooltip: string;

  constructor() { }

  ngOnInit() { }

}
