import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { RelationSelectComponent } from '../relation-select/relation-select.component';
import { RelationService } from '@veritas-dumpsters/services/relation.service';
import { DumpsterService } from './../../services/dumpster.service';
import { IDumpsterRelation } from '@veritas-dumpsters/models/dumpster-relation.model';
import { RelationMappingService } from '@veritas-dumpsters/services/relation-mapping.service';

@Component({
  selector: 'app-dumpster-relation',
  templateUrl: './dumpster-relation.component.html',
  styleUrls: ['./dumpster-relation.component.scss']
})
export class DumpsterRelationComponent implements OnInit {
  @ViewChild('templateSelect', { read: TemplateRef, static: true })
  public templateSelect: TemplateRef<RelationSelectComponent>;
  @ViewChild('templateFather', { read: TemplateRef, static: true })
  public templateFather: TemplateRef<any>;
  @ViewChild('templateChild', { read: TemplateRef, static: true })
  public templateChild: TemplateRef<any>;

  public dumpsterDisplayed?: Dumpster;
  public dumpsterRelation?: IDumpsterRelation;
  
  @Input() public set dumpster(item: Dumpster) {
    this.dumpsterDisplayed = item;
    this._setRelation(item);
  };

  @Output() public emitDumpster: EventEmitter<Dumpster>;

  constructor(
    private relationService: RelationService,
    private dumpsterService: DumpsterService,
    private convertService: RelationMappingService,
  ) {
    this.emitDumpster = new EventEmitter<Dumpster>();
  }

  ngOnInit(): void {
    this._setRelationLocally();
  }

  public getTemplate = (selectedDumpster: Dumpster): TemplateRef<any> => {
    if(!selectedDumpster?.parentDumpsterId && !selectedDumpster?.childDumpsterId) return this.templateSelect;
    if(Boolean(selectedDumpster?.childDumpsterId)) return this.templateFather;
    return this.templateChild;
  }

  private _setRelation = (dumpster: Dumpster) => {
    if(!dumpster?.childDumpsterId && !dumpster?.parentDumpsterId) return;
    this._setRelationByApi(
      dumpster?.childDumpsterId || dumpster?.parentDumpsterId
    );
  }

  private _setRelationByApi = (id: string) => this.dumpsterService.getByDumpsterIdV2(id).subscribe({
    next: (item: Dumpster) => this._setRelationProps(this.convertService.getFromDumpster(item)),
    error: () => this._setRelationProps(null)
  });

  private _setRelationLocally = () => this.relationService.dumpsterRelation$.subscribe(
    (child: IDumpsterRelation) => {
      this._setRelationProps(child);
      this.emitDumpster.emit({ ...this.dumpsterDisplayed, childDumpsterId: child?.dumpsterId });
    }
  );

  private _setRelationProps = (dumpster: IDumpsterRelation | null) => { this.dumpsterRelation = dumpster };
}
