<form #gridForm="ngForm"
      kendoTooltip
      showOn="none"
      [tooltipTemplate]="tooltipTemplate"
      filter=".k-grid td"
      (mouseover)="showTooltip($event)">

  <kendo-grid [data]="gridData"
              [pageSize]="state.take"
              [skip]="state.skip"
              [sort]="state.sort"
              [filter]="state.filter"
              [sortable]="true"
              [pageable]="pageable == undefined ? {
                       buttonCount: buttonCount,
                       info: info,
                       type: type,
                       pageSizes: pageSizes,
                       previousNext: previousNext
                     } : pageable"
              [resizable]="true"
              [group]="state.group"
              [groupable]="groupable"
              (groupChange)="groupChange($event)"
              [filterable]="filterable == undefined ? 'menu' : filterable"
              (pageChange)="pageChange($event)"
              (dataStateChange)="dataStateChange($event)"
              [selectable]="selectable"
              [kendoGridSelectBy]="selectionKey"
              [selectedKeys]="selectedKeys || []"
              (selectionChange)="selectCallback ? selectCallback($event) : selectionChange.emit($event)"
              [kendoGridTemplateEditing]="editingCallback">

    <kendo-grid-messages columnMenu="{{'component.grid.COLUMNMENU' | translate}}"
                         columns="{{'component.grid.COLUMS' | translate}}"
                         columnsReset="{{'component.grid.COLUMNSRESET' | translate}}"
                         filter="{{'component.grid.FILTER' | translate}}"
                         filterAfterOperator="{{'component.grid.FILTERAFTEROPERATOR' | translate}}"
                         filterAfterOrEqualOperator="{{'component.grid.FILTERAFTEROREQUALOPERATOR' | translate}}"
                         filterAndLogic="{{'component.grid.FILTERANDLOGIC' | translate}}"
                         filterBeforeOperator="{{'component.grid.FILTERBEFOREOPERATOR' | translate}}"
                         filterBeforeOrEqualOperator="{{'component.grid.FILTERBEFOREOREQUALOPERATOR' | translate}}"
                         filterBooleanAll="{{'component.grid.FILTERBOOLEANALL' | translate}}"
                         filterClearButton="{{'component.grid.FILTERCLEARBUTTON' | translate}}"
                         filterContainsOperator="{{'component.grid.FILTERCONTAINSOPERATOR' | translate}}"
                         filterEndsWithOperator="{{'component.grid.FILTERENDSWITHOPERATOR' | translate}}"
                         filterEqOperator="{{'component.grid.FILTEREQOPERATOR' | translate}}"
                         filterFilterButton="{{'component.grid.FILTERFILTERBUTTON' | translate}}"
                         filterGtOperator="{{'component.grid.FILTERGTOPERATOR' | translate}}"
                         filterGteOperator="{{'component.grid.FILTERGTEOPERATOR' | translate}}"
                         filterIsEmptyOperator="{{'component.grid.FILTERISEMPTYOPERATOR' | translate}}"
                         filterIsFalse="{{'component.grid.FILTERISFALSE' | translate}}"
                         filterIsNotEmptyOperator="{{'component.grid.FILTERISNOTEMPTYOPERATOR' | translate}}"
                         filterIsNotNullOperator="{{'component.grid.FILTERISNOTNULLOPERATOR' | translate}}"
                         filterIsNullOperator="{{'component.grid.FILTERISNULLOPERATOR' | translate}}"
                         filterIsTrue="{{'component.grid.FILTERISTRUE' | translate}}"
                         filterLtOperator="{{'component.grid.FILTERLTOPERATOR' | translate}}"
                         filterLteOperator="{{'component.grid.FILTERLTEOPERATOR' | translate}}"
                         filterNotContainsOperator="{{'component.grid.FILTERNOTCONTAINSOPERATOR' | translate}}"
                         filterNotEqOperator="{{'component.grid.FILTERNOTEQOPERATOR' | translate}}"
                         filterOrLogic="{{'component.grid.FILTERORLOGIC' | translate}}"
                         filterStartsWithOperator="{{'component.grid.FILTERSTARTSWITHOPERATOR' | translate}}"
                         groupPanelEmpty="{{'component.grid.groupPanelEmpty' | translate}}"
                         loading="{{'component.grid.LOADING' | translate}}"
                         lock="{{'component.grid.LOCK' | translate}}"
                         noRecords="{{'component.grid.NORECORDS' | translate}}"
                         pagerFirstPage="{{'component.grid.PAGERFIRSTPAGE' | translate}}"
                         pagerItems="{{'component.grid.pagerItems' | translate}}"
                         pagerItemsPerPage="{{'component.grid.ItemsPerPage' | translate}}"
                         pagerLastPage="{{'component.grid.PAGERLASTPAGE' | translate}}"
                         pagerNextPage="{{'component.grid.PAGERNEXTPAGE' | translate}}"
                         pagerOf="{{'component.grid.pagerOf' | translate}}"
                         pagerPage="{{'component.grid.PAGERPAGE' | translate}}"
                         pagerPreviousPage="{{'component.grid.PAGERPREVIOUSPAGE' | translate}}"
                         unlock="{{'component.grid.UNLOCK' | translate}}">
    </kendo-grid-messages>

    <!-- Toolbar -->
    <ng-template *ngIf="toolbarActions && toolbarActions.length > 0" kendoGridToolbarTemplate>
      <ng-container *ngFor="let action of toolbarActions">
        <button type="button" class="btn toolbar-item" [disabled]="disableActions || (action.applyOnSelected && (!selectedKeys || selectedKeys.length == 0))" (click)="onActionClick(action)">
          <fa-icon icon="{{action.icon}}"></fa-icon>
          <span>{{action.resourceKey | translate}}</span>
        </button>
        <span *ngIf="action.separatorNext" class="toolbar-separator"></span>
      </ng-container>
    </ng-template>

    <!-- Checkbox column fors selection -->
    <kendo-grid-checkbox-column *ngIf="selectable && selectable.mode == 'multiple'" [showSelectAll]="showSelectAll" [width]="40">

      <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
          <input type="checkbox"
          class="k-checkbox"
          [ngClass]="{
            'k-state-disabled': selectableCheckboxSettings && selectableCheckboxSettings.isSelectable && (selectableCheckboxSettings.isSelectable(dataItem, selectableCheckboxSettings.context, selectedKeys) !== true),
            'no-pointer-events': selectableCheckboxSettings && selectableCheckboxSettings.isSelectable && (selectableCheckboxSettings.isSelectable(dataItem, selectableCheckboxSettings.context, selectedKeys) !== true) && selectable.checkboxOnly
          }"
          title="{{(selectableCheckboxSettings && selectableCheckboxSettings.isSelectable && selectableCheckboxSettings.disabledTooltip && (selectableCheckboxSettings.isSelectable(dataItem, selectableCheckboxSettings.context, selectedKeys) !== true) && selectable.checkboxOnly ? selectableCheckboxSettings.disabledTooltip(dataItem, selectableCheckboxSettings.context, selectedKeys) : '')}}"
          id="checkbox-{{idx}}"
          [kendoGridSelectionCheckbox]="idx" />
      </ng-template>

    </kendo-grid-checkbox-column>

    <!-- Grouped columns -->
    <kendo-grid-column-group *ngFor="let columnGroup of columnGroups" title="{{ columnGroup.title | translate }}">
      <kendo-grid-column *ngFor="let column of columnGroup.columns"
                         field="{{ column.field }}"
                         title="{{ column.title | translate }}"
                         width="{{ column.width }}"
                         minResizableWidth="{{ column.minResizableWidth }}"
                         format="{{ column.format }}"
                         filter="{{ column.filter }}"
                         [sortable]="column.sortable"
                         [filterable]="column.filterable"
                         [resizable]="column.resizable"
                         [style]="column.style"
                         [headerStyle]="column.headerStyle"
                         [editable]="!column.readonly">

        <!-- Custom template -->
        <ng-template *ngIf="column.template" kendoGridCellTemplate let-dataItem>
          <ng-container [ngTemplateOutlet]="column.template" [ngTemplateOutletContext]="{$implicit:dataItem}"></ng-container>
        </ng-template>

        <!-- Default boolean template -->
        <ng-template *ngIf="!column.template && column.display == 'boolean'" kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <input type="checkbox" id="shared-checkbox-{{ rowIndex }}" class="k-checkbox" [checked]="dataItem[column.field]" disabled />
          <label class="k-checkbox-label" for="shared-checkbox-{{ rowIndex }}"></label>
        </ng-template>

        <!-- Default date template -->
        <ng-template *ngIf="!column.template && column.display == 'date'" kendoGridCellTemplate let-dataItem="dataItem">
          {{ dataItem[column.field] | date: column.dateType }}
        </ng-template>

        <!-- Custom edit template -->
        <ng-template *ngIf="column.editTemplate" kendoGridEditTemplate let-dataItem="dataItem" let-form="gridForm">
          <ng-container [ngTemplateOutlet]="column.editTemplate" [ngTemplateOutletContext]="{parent: { dataItem: dataItem }, dataItem: dataItem, form: gridForm}"></ng-container>
        </ng-template>

        <!-- Edit templates -->
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <ng-container [ngSwitch]="column.editor">
            <input *ngSwitchCase="'boolean'" [(ngModel)]="dataItem[column.field]" kendoGridFocusable name="{{ column.field }}" [required]="column.required" type="checkbox" />
            <input *ngSwitchCase="'numeric'" [(ngModel)]="dataItem[column.field]" kendoGridFocusable name="{{ column.field }}" [required]="column.required" type="number" />
            <kendo-dropdownlist *ngSwitchCase="'list'" [data]="column.editListItems || column.listItems" [(ngModel)]="dataItem[column.field]" [textField]="column.listText" [valueField]="column.listValue" [valuePrimitive]="true" name="{{column.field}}" (valueChange)="column.listOnChange ? column.listOnChange($event, dataItem) : null" [required]="column.required"> </kendo-dropdownlist>
            <input *ngSwitchDefault [(ngModel)]="dataItem[column.field]" kendoGridFocusable name="{{ column.field }}" [required]="column.required" type="text" />
          </ng-container>
        </ng-template>

      </kendo-grid-column>
    </kendo-grid-column-group>

    <!-- Non grouped columns -->
    <kendo-grid-column *ngFor="let column of columns"
                       field="{{ column.field }}"
                       title="{{ column.title | translate }}"
                       width="{{ column.width}}"
                       minResizableWidth="{{ column.minResizableWidth }}"
                       format="{{ column.format }}"
                       filter="{{ column.filter }}"
                       [sortable]="column.sortable"
                       [filterable]="column.filterable"
                       [resizable]="column.resizable"
                       [headerClass]="'grid-header'"
                       [class]="column.class || 'grid-cell'"
                       [editable]="!column.readonly">

      <!-- Custom filter -->
      <ng-template *ngIf="column?.filterTemplate" kendoGridFilterCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="column.filterTemplate"></ng-container>
      </ng-template>

      <!-- Custom template -->
      <ng-template *ngIf="column.template" kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="column.template" [ngTemplateOutletContext]="{$implicit:dataItem}"></ng-container>
      </ng-template>

      <!-- Default boolean template -->
      <ng-template *ngIf="!column.template && column.display == 'boolean'" kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <input type="checkbox" id="shared-checkbox-{{ rowIndex }}" class="k-checkbox" [checked]="dataItem[column.field]" disabled />
        <label class="k-checkbox-label" for="shared-checkbox-{{ rowIndex }}"></label>
      </ng-template>

      <!-- Default link template -->
      <ng-template *ngIf="!column.template && column.display == 'link'" kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <a (click)="onColumnCallback(column, dataItem);" href="">{{dataItem[column.field]}}</a>
      </ng-template>

      <!-- Default date template -->
      <ng-template *ngIf="!column.template && column.display == 'date'" kendoGridCellTemplate let-dataItem="dataItem">
        {{ dataItem[column.field] | date: column.dateType }}
      </ng-template>

      <!-- Custom edit template -->
      <ng-template *ngIf="column.editTemplate" kendoGridEditTemplate let-dataItem="dataItem" let-form="gridForm">
        <ng-container [ngTemplateOutlet]="column.editTemplate" [ngTemplateOutletContext]="{parent: { dataItem: dataItem }, dataItem: dataItem, form: gridForm}"></ng-container>
      </ng-template>

      <!-- Edit templates -->
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <ng-container [ngSwitch]="column.editor">
          <input *ngSwitchCase="'boolean'" [(ngModel)]="dataItem[column.field]" kendoGridFocusable name="{{ column.field }}" [required]="column.required" type="checkbox" />
          <input *ngSwitchCase="'numeric'" [(ngModel)]="dataItem[column.field]" kendoGridFocusable name="{{ column.field }}" [required]="column.required" type="number" />
          <kendo-dropdownlist *ngSwitchCase="'list'" [data]="column.editListItems || column.listItems" [(ngModel)]="dataItem[column.field]" [textField]="column.listText" [valueField]="column.listValue" [valuePrimitive]="true" name="{{column.field}}" (valueChange)="column.listOnChange ? column.listOnChange($event, dataItem) : null" [required]="column.required"> </kendo-dropdownlist>
          <input *ngSwitchDefault [(ngModel)]="dataItem[column.field]" kendoGridFocusable name="{{ column.field }}" [required]="column.required" type="text" />
        </ng-container>
      </ng-template>

    </kendo-grid-column>

    <!-- Actions column in cell -->
    <kendo-grid-command-column *ngIf="showActions && editingInCell">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <div class="a-button-container-row">
          <div class="a-button-container a-button-container-right">
            <button kendoGridEditCommand type="button" title="{{ 'action.Common.Edit' | translate }}"><fa-icon icon="pencil-alt"></fa-icon></button>
            <button kendoGridRemoveCommand type="button" title="{{ 'action.Common.Delete' | translate }}"><fa-icon icon="trash"></fa-icon></button>
            <button kendoGridSaveCommand type="button" [disabled]="gridForm?.invalid" title="{{ 'action.Common.Save' | translate }}"><fa-icon icon="check"></fa-icon></button>
            <button kendoGridCancelCommand type="button" title="{{ 'action.Common.Cancel' | translate }}"><fa-icon icon="ban"></fa-icon></button>
          </div>
        </div>
      </ng-template>
    </kendo-grid-command-column>

    <!-- Actions column -->
    <kendo-grid-column *ngIf="showActions && actions && actions.length > 0 && !editingInCell" width="40">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <div class="a-button-container-row">
          <kendo-dropdownbutton [data]="actions | filterBy:dataItem" [icon]="'more-horizontal'" [popupSettings]="actionPopupSettings" [class]="'grid-actions'">
            <ng-template kendoDropDownButtonItemTemplate let-action>
              <span (click)="onActionClick(action, dataItem)">
                <span>{{action.resourceKey | translate}}</span>
                <fa-icon class="text-primary" icon="{{action.icon}}"></fa-icon>
              </span>
            </ng-template>
          </kendo-dropdownbutton>
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- Row detail -->
    <ng-template *ngIf="detailTemplate" kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
      <ng-container [ngTemplateOutlet]="detailTemplate" [ngTemplateOutletContext]="{$implicit:dataItem}"></ng-container>
    </ng-template>

    <!-- Export -->
    <kendo-grid-excel [fileName]="exportFileName"></kendo-grid-excel>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
    </ng-template>

  </kendo-grid>
</form>

<ng-template #tooltipTemplate let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

