
<div class="dialog-body">
  <div class="my-2">
    <app-collectionpoint-search [items]="data?.allCollectionPoints" [(filteredItems)]="filteredAllCollectionPoints"></app-collectionpoint-search>
  </div>
  <div class="my-2">
    <span>{{ constantHelper.LBL_PAGE_COLL_POINTS_NUMBEROFFILTERED | translate:{'0': filteredAllCollectionPoints ? filteredAllCollectionPoints.length : '0', '1': data?.allCollectionPoints ? data?.allCollectionPoints.length : '0', '2': selectedCollectionPointsToAdd?.length} }}</span>
  </div>

  <kendo-tabstrip class="dialog-grid-body" (tabSelect)="onTabChange($event)" [keepTabContent]="true">
    <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_COLLP_TAB_LIST | translate}}" [selected]="true">
      <ng-template kendoTabContent>
        <app-collectionpoint-grid [items]="filteredAllCollectionPoints" [(selectedCollectionPoints)]="selectedCollectionPointsToAdd" [selectableMode]="data?.selectableMode"></app-collectionpoint-grid>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_COLLP_TAB_MAP | translate }}">
      <ng-template kendoTabContent *loadOnDemand>
        <app-collectionpoint-map [items]="filteredAllCollectionPoints" [(selectedCollectionPoints)]="selectedCollectionPointsToAdd"></app-collectionpoint-map>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>

<div class="dialog-footer">
  <button type="button" class="btn btn-outline-secondary mt-2" (click)="backButtonClick()">
    <fa-icon icon="chevron-left"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_CANCEL | translate }}
  </button>
  <button type="button" class="btn btn-primary float-right mt-2" [disabled]="selectedCollectionPointsToAdd.length == 0" (click)="saveButtonClick()">
    <fa-icon icon="save"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_SAVE | translate }}
  </button>
</div>
