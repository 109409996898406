import { Component, EventEmitter, Output, Input, AfterViewInit, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TicketState } from '../../enums/ticket-state.enum';
import { EntityListOutput } from '@veritas-shared/models/entities/entity-list-output';
import { TicketListRequestInput } from '../../models/ticket-list-output';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { EntityService } from '@veritas-shared/services/entity.service';
import { Dumpster } from '@veritas-shared/models/dumpster';

@Component({
  selector: 'app-ticket-search',
  templateUrl: './ticket-search.component.html',
  styleUrls: ['./ticket-search.component.scss']
})
export class TicketSearchComponent implements OnInit {
  @Input() public entities: EntityListOutput;

  @Output() public searchChange = new EventEmitter();
  @Output() public collapseChange = new EventEmitter<boolean>();

  public selectedValues: Array<string> = ["Opened"];
  
  private allAssigments: string[] = ["Veritas", "Kgn"];
  public assignmentList = ["Veritas", "Kgn"];

  public formSearch: FormGroup;

  //public stateList: any = [{Name: "Ticket aperto", Id : 1}, {Name: "Ticket chiuso", Id: 2}];
  public test: TicketState[] = [TicketState.Open, TicketState.Closed];

  @Input() public dumpsters: Dumpster[];
  public allDumpsters : Dumpster[];

  constructor(
    private fb: FormBuilder,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private entityService: EntityService
  ) {

    library.addIcons(faTimes, faSearch);

    this.formSearch = this.fb.group({
      //ticketType: [{ value: "Dumpster", disabled: true }],
      //senderType: [{ value: "", disabled: true }],
      //ticketId: null,
      //municipality: null,
      assignedTo: null,
      startDate: null,
      endDate: null,
      assignedFrom: "Veritas",
      currentDeviceId: null,
      currentBinTag: null,
      metadataFilter: null,
      //tags: null,
      //ticketOwnerEmail: null,
      state: null,
      orderByCreationDateDescending: [{ value: true, disabled: false }],
    });
  }
  
  ngOnInit(): void {
    this.onSearch();
    this.setDefaultDateRangeAndSearch();
  }

  ngOnChanges(){
    this.allDumpsters = this.dumpsters; // mettendolo nell'onInit non funziona, ho dovuto mettelo qui
  }

  public onSearch() {
    
    // forzo data inizio alle 00:00 e data fine alle 23:59
    let dateFrom = new Date(this.formSearch.get('startDate').value)
    let dateTo = new Date(this.formSearch.get('endDate').value)
    dateFrom.setHours(0,0,0)
    dateTo.setHours(23,59,59)

    this.formSearch.get('startDate').setValue(dateFrom);
    this.formSearch.get('endDate').setValue(dateTo);

    var request = this.formSearch.getRawValue() as TicketListRequestInput;

    interface keyPair {
      [key: string]: string;
    }


    let bins: keyPair = {}
    //var currentBinTag = this.binID;
    
    let codes: keyPair = {}

    if (request.currentDeviceId || request.currentBinTag) {
      if (request.currentDeviceId && request.currentBinTag) {
        request.metadataFilter = {
          "OpeningDeviceId": request.currentDeviceId, // la maiuscola è necessaria per querare correttamente cosmos
          "OpeningBinTag": request.currentBinTag // la maiuscola è necessaria per querare correttamente cosmos
        }
      }
      else if (request.currentDeviceId) {
        request.metadataFilter = {
          "OpeningDeviceId": request.currentDeviceId // la maiuscola è necessaria per querare correttamente cosmos
        }
      }
      else {
        request.metadataFilter = {
          "OpeningBinTag": request.currentBinTag // la maiuscola è necessaria per querare correttamente cosmos
        }
      }
    }
    
    this.searchChange.emit(request);
  }

  public onClearSearch() {
    this.formSearch.reset();
    this.searchChange.emit();
  }

  public onCollapseChange(value: boolean) {
    this.collapseChange.emit(value);
  }

  private setDefaultDateRangeAndSearch() {
    const todayDateUTC = new Date();
    const dayLastMonthUTC = new Date(new Date().setMonth(todayDateUTC.getMonth()-1));

    this.formSearch.get('startDate').setValue(dayLastMonthUTC);
    this.formSearch.get('endDate').setValue(todayDateUTC);
    this.onSearch();
  }

  public handleAssigmentFilter(value: string) {
    this.assignmentList = this.allAssigments.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  private handleTimeZone(date: Date) {
    let time = date.getTime();
    if (date.getTimezoneOffset() <= 0) {
      let final = time + (Math.abs(date.getTimezoneOffset() * 60000));
      return new Date(final);
    } else {
      let final = time + (-Math.abs(date.getTimezoneOffset() * 60000));
      return new Date(final)
    }
  }

  public handleDumpstersFilter(value: string){
    this.dumpsters = this.allDumpsters.filter(
      (s) => (s.code + s.binId + s.address).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public onDumpsterSelected(id: string) {
    let dumpster = this.allDumpsters.find((d) => d.deviceExternalId == id);
    this.formSearch.get("currentDeviceId").setValue(dumpster.code);
  }
}
