<h1 class="page-title"><fa-icon icon="user-friends"></fa-icon>{{ constantHelper.LBL_PAGE_ROLES_TITLE | translate }}</h1>
<div class="page-content">
  <div class="mt-10">
    <app-group-box [pageTitle]="'Elenco Ruoli'">
      <div class="grid-container standard-padding">
        <app-custom-grid [columns]="columns" [items]="gridData" [filterable]="true" [showActions]="true" [actions]="actions" [toolbarActions]="toolbarActions"></app-custom-grid>
      </div>
    </app-group-box>
  </div>
</div>

<ng-template #isGlobalColumn let-dataItem>
  <app-info-icon *ngIf="dataItem.isGlobal" [icon]="'cubes'" class="text-primary" tooltip="{{ constantHelper.LBL_VOCABULARY_ROLE_ISGLOBAL | translate}}"></app-info-icon>
</ng-template>

<ng-template #isSystemColumn let-dataItem>
  <app-info-icon *ngIf="dataItem.isSystem" [icon]="'user-shield'" class="text-primary" tooltip="{{ constantHelper.LBL_COMMON_ISSYSTEM | translate}}"></app-info-icon>
</ng-template>
