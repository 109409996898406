import { Component } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { BeanDialogComponentBase, IDialogInput } from 'bean-kendo-dialog';
import { Observable, of } from 'rxjs';
import { GroupListOutputGroup } from '../../../group/models/group-list-output';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';

@Component({
  selector: 'app-group-select-dialog',
  templateUrl: './group-select-dialog.component.html',
  styleUrls: ['./group-select-dialog.component.scss']
})
export class GroupSelectDialogComponent extends BeanDialogComponentBase<GroupSelectDialogInput>{

  public filteredAllGroups: GroupListOutputGroup[] = [];
  public selectedGroupsToAdd: GroupListOutputGroup[] = [];

  public data: GroupSelectDialogInput;

  constructor(
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
  ) {
    super();

    this.library.addIcons(faChevronLeft, faSave);
  }

  ngAfterViewInitCallback(): void {

    this.filteredAllGroups = this.data.allGroups;
  }

  cancel(e: any): boolean {
    return true;
  }

  back(e: any): boolean {
    return true;
  }

  save(e: any): Observable<GroupListOutputGroup[]> {
    return of(this.selectedGroupsToAdd);
  }
}

export class GroupSelectDialogInput implements IDialogInput {
  constructor(public allGroups: GroupListOutputGroup[]) { }
}
