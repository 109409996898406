
<div class="form-height">
  <div class="height-100">
    <form *ngIf="formTicket" [formGroup]="formTicket" class="height-100" novalidate>

      <label class="col-form-label">Motivo</label>
      <div class="form-group">
        <kendo-combobox [data]="data?.closureReasons"
                        formControlName="closureReason"
                        textField="description"
                        valueField="closureReason"
                        [valuePrimitive]="true"
                        [filterable]="true"
                        (filterChange)="handleClosureFilter($event)">
        </kendo-combobox>
      </div>
    </form>
  </div>
</div>

<div class="dialog-footer">
  <button type="button" class="btn btn-outline-secondary mt-2" (click)="backButtonClick()">
    <fa-icon icon="chevron-left"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_CANCEL | translate }}
  </button>
  <button type="button" class="btn btn-primary float-right mt-2" (click)="saveButtonClick()">
    <fa-icon icon="save"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_SAVE | translate }}
  </button>
</div>
