import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Align } from '@progress/kendo-angular-popup';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCaretDown, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dumpster } from '../../models/dumpster';
import { Label } from '../../models/label';
import { ConstantHelper } from '../../helpers/constant.helper';
import { EntityService } from '@veritas-shared/services/entity.service';
import { EntitySearchInput } from '@veritas-shared/models/entities/entity-search-input';
import { EntityListOutput } from '@veritas-shared/models/entities/entity-list-output';

@Component({
  selector: 'app-dumpster-search',
  templateUrl: './dumpster-search.component.html',
  styleUrls: ['./dumpster-search.component.scss']
})
export class DumpsterSearchComponent implements OnInit {

  @ViewChild('searchButtons', { static: true }) public anchor: ElementRef;
  @ViewChild('advSearch', { read: ElementRef }) public popup: ElementRef;

  @Input() public set items(val: Dumpster[]) {

    this._items = val;

    if (this._items) {
      // Suppliers
      let supplierNames = this._items.map(d => d.supplierName);
      supplierNames = supplierNames.filter((item, i, ar) => ar.indexOf(item) === i);
      this.suppliers = supplierNames.sort();
      // Garbage Types
      let garbageTypes = this._items.map(d => d.garbageType);
      garbageTypes = garbageTypes.filter((item, i, ar) => ar.indexOf(item) === i);
      this.garbageTypes = garbageTypes.sort();
    }
  };

  @Input() public labels: Label[];
  @Input() public filteredItems: Dumpster[];
  @Output() public filteredItemsChange = new EventEmitter();

  @Output() public searchChange = new EventEmitter();

  public formSimpleSearch: FormGroup;
  public formAdvSearch: FormGroup;

  public showAdvSearch: boolean = false;
  public advSearchAnchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  public advSearchAlign: Align = { horizontal: 'right', vertical: 'top' };

  public searched: boolean = false;

  public suppliers: string[] = [];
  public garbageTypes: string[] = [];

  private _items: Dumpster[];

  public entities: EntityListOutput;

  constructor(private fb: FormBuilder, private library: FaIconLibrary, public constantHelper: ConstantHelper, private entityService: EntityService) {

    library.addIcons(faTimes, faCaretDown, faSearch);

    this.formSimpleSearch = this.fb.group({
      search: null
    });

    this.formAdvSearch = this.fb.group({
      name: '',
      labels: null,
      suppliers: null,
      garbageTypes: null
    });

  }

  ngOnInit() {
    this.entityService.getEntities({ wasteType: true } as EntitySearchInput).subscribe(res => {
      this.entities = res;
    })
  }

  public toggleAdvSearch(show?: boolean) {
    this.showAdvSearch = show !== undefined ? show : !this.showAdvSearch;
  }

  public onSimpleSearch() {
    if (this._items) {
      let value = this.formSimpleSearch.value.search;
      if (value) {
        value = value.toLowerCase();
        this.filteredItems = this._items.filter(d => {
          return this.textLowerContains(d.code, value) || this.textLowerContains(d.supplierName, value);
        });
        this.searched = true;
      }
      else {
        this.filteredItems = this._items;
        this.searched = false;
      }
      this.filteredItemsChange.emit(this.filteredItems);
      this.searchChange.emit();
    }
  }

  public onAdvancedSearch() {
    const searchValue = this.formAdvSearch.value;

    let searchDumpsters = this._items;
    this.searched = false;

    // Labels
    if (searchValue.labels && searchValue.labels.length > 0) {
      const selectedLabels = searchValue.labels.map(l => l.name);
      searchDumpsters = searchDumpsters.filter(d => {
        return d.labels.filter(l => selectedLabels.indexOf(l.name) > -1).length > 0;
      });
      this.searched = true;
    }

    // Supplier
    if (searchValue.suppliers && searchValue.suppliers.length > 0) {
      searchDumpsters = searchDumpsters.filter(d => {
        return searchValue.suppliers.indexOf(d.supplierName) > -1;
      });
      this.searched = true;
    }

    // Garbage Type
    if (searchValue.garbageTypes && searchValue.garbageTypes.length > 0) {
      searchDumpsters = searchDumpsters.filter(d => {
        return searchValue.garbageTypes.indexOf(d.garbageType) > -1;
      });
      this.searched = true;
    }

    this.filteredItems = searchDumpsters;
    this.filteredItemsChange.emit(this.filteredItems);
    this.searchChange.emit();
  }

  public onClearSearch() {
    this.formSimpleSearch.reset();
    this.formAdvSearch.reset();
    this.filteredItems = this._items;
    this.searched = false;
    this.filteredItemsChange.emit(this.filteredItems);
    this.searchChange.emit();
  }

  private textLowerContains(text: string, value: string): boolean {
    if (text) {
      return text.toLowerCase().indexOf(value) > -1;
    }
    return false;
  }

  private contains(target: any): boolean {
    return this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false);
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target)) {
      this.toggleAdvSearch(false);
    }
  }

}
