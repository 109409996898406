import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { BeanDialogComponentBase, IDialogInput } from 'bean-kendo-dialog';
import { Observable, of } from 'rxjs';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { DumpsterRegisterInput } from '../../models/dumpster-register-input';
import { DumpsterRegisterOutput } from '../../models/dumpster-register-output';
import { UserType } from '@veritas-shared/models/entities/user-type';
import { DeviceMode } from '@veritas-shared/models/entities/device-mode';
import { MaxItemsValidator } from '@veritas-shared/utils/validators';

@Component({
  selector: 'app-dumpster-register',
  templateUrl: './dumpster-register-dialog.component.html',
  styleUrls: ['./dumpster-register-dialog.component.scss']
})
export class DumpsterRegisterDialogComponent extends BeanDialogComponentBase<DumpsterRegisterDialogInput> {

  public data: DumpsterRegisterDialogInput;
  public formRegister: FormGroup;

  private allDeviceModes: DeviceMode[];
  private allUserTypes: UserType[];

  constructor(
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper,
    private fb: FormBuilder
  ) {
    super();

    this.library.addIcons(faChevronLeft, faSave);

    this.formRegister = this.fb.group({
      regionCodes: [{ value: "", disabled: false }, [Validators.required, MaxItemsValidator(3)]],
      groups: [{ value: "", disabled: false }, [Validators.required]],
      deviceMode: [{ value: "", disabled: false }, [Validators.required]],
      userType: [{ value: "", disabled: false }, [Validators.required]],
      labels: [{ value: undefined, disabled: false }],
    });
  }

  ngAfterViewInitCallback(): void {
    this.allDeviceModes = this.data.registerData.deviceModes;
    this.allUserTypes = this.data.registerData.userTypes;
  }

  cancel(e: any): boolean {
    return true;
  }

  back(e: any): boolean {
    return true;
  }

  save(e: any): Observable<any> {
    if (!this.formRegister.valid) {
      this.commonHelper.validateAllFormFields(this.formRegister);
      return;
    }

    let res = this.formRegister.getRawValue();

    return of({
      regionCodes: res.regionCodes.map(el => el.code),
      groups: res.groups,
      deviceMode: res.deviceMode,
      userType: res.userType,
      labels: res.labels
    } as DumpsterRegisterInput);
  }

  public handleModeFilter(value: string) {
    this.data.registerData.deviceModes = this.allDeviceModes.filter((s) => s.description.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public handleUserFilter(value: string) {
    this.data.registerData.userTypes = this.allUserTypes.filter((s) => s.description.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
}

export class DumpsterRegisterDialogInput implements IDialogInput {
  constructor(public registerData: DumpsterRegisterOutput) { }
}
