<form [formGroup]="dumpsterForm">
  <app-field-action
    [option]="{
      text: translate.instant('$lbl.dumpsterChild'),
      icon: 'fas fa-save'
    }"
    [isDisable]="!dumpsterForm.value.child"
    (emitClick)="setActionSave()"
  >
    <kendo-dropdownlist #dropdownlist
      class="field-body"
      formControlName="child"
      textField="code"
      valueField="deviceExternalId"
      [data]="options"
      [filterable]="true"
      (filterChange)="setFilter($event)"
      (open)="setListOpen()"
      (click)="setDefaultDropDown()"
    >
      <ng-template kendoComboBoxItemTemplate let-dataItem>
        {{ dataItem.code}} ({{dataItem.binId}}) - {{dataItem.address}}
      </ng-template>
    </kendo-dropdownlist>
  </app-field-action>
</form>
