import { ElementRef, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tooltip'
})
export class TooltipPipe implements PipeTransform {

  transform(anchor: ElementRef): any {
    if (anchor != null) {
      let title = anchor.nativeElement.getAttribute("data-title");
      if (title != null) {
        return title;
      } else {
        return anchor.nativeElement.innerText;
      }
    }
    return "";
  }
}
