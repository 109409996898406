import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SecurityService } from '../services/security.service';

@Directive({
  selector: '[ngIfPermission]'
})
export class PermissionDirective {

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private securityService: SecurityService) { }

  @Input() set ngIfPermission(permission: string) {

    this.securityService.userHasPermission(permission).subscribe(result => {
      if (result) {
        // Add template to DOM
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
      else {
        // Remove template from DOM
        this.viewContainer.clear();
      }
    },
      error => {
        // Remove template from DOM
        this.viewContainer.clear();
      });
  }
}
