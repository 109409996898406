import { Component, OnInit, ViewChild, Input, TemplateRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { GridComponent, GridDataResult, SelectableSettings, DataStateChangeEvent, PageChangeEvent, GroupableSettings, PageSizeItem } from '@progress/kendo-angular-grid';
import { process, State, SortDescriptor, CompositeFilterDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { GridColumnGroup } from '../../models/grid-column-group';
import { GridColumn } from '../../models/grid-column';
import { SessionService } from '../../services/session.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import * as fas from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt, faTrash, faCheck, faBan, faPlus } from '@fortawesome/free-solid-svg-icons';
import { PopupSettings } from '@progress/kendo-angular-buttons';
import { IconHelper } from '../../helpers/icon-helper';
import { Action } from '../../models/action';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ConstantHelper } from '../../helpers/constant.helper';
import { CommonHelper } from '../../helpers/common.helper';
import { ISelectableCheckboxSettings } from '../../models/selectablecheckboxsettings.interface';

@Component({
  selector: 'app-custom-grid',
  templateUrl: './custom-grid.component.html',
  styleUrls: ['./custom-grid.component.scss']
})
export class CustomGridComponent implements OnInit, AfterViewInit {

  private readonly SKIP: number = 0;
  private readonly PAGE_SIZE: number = 50;

  @ViewChild(GridComponent) grid: GridComponent;
  @ViewChild(TooltipDirective, { static: true }) public tooltipDirective: TooltipDirective;

  @Input() public columnGroups: GridColumnGroup[];
  @Input() public columns: GridColumn[];

  @Input() public gridData: GridDataResult;
  @Input() public gridKey: string;

  @Input() public pageable: boolean = false;
  @Input() private hasServerPagination: boolean;

  @Input() public filterable: boolean | string;
  @Input() public selectable: SelectableSettings;
  @Input() public selectionKey: any = 'id';

  @Input() public group: Array<GroupDescriptor>;
  @Input() public groupable: GroupableSettings;

  @Input() public state: State;
  @Input() public showActions: boolean;
  @Input() public showSelectAll: boolean;
  @Input() public isRowSelected: Function | null;
  @Input() public addCallback: Function | null;
  @Input() public editCallback: Function | null;
  @Input() public deleteCallback: Function | null;
  @Input() public actions: Action[];
  @Input() public toolbarActions: Action[];
  @Input() public selectCallback: Function;
  @Input() public selectedKeys: number[];
  @Input() public dataStateChangeCallback: Function;

  @Input() public groupChangeCallback: Function;

  @Input() public detailTemplate: TemplateRef<any>;
  @Input() public exportFileName: string = 'export.xlsx';
  @Input() public disableActions: boolean = false;

  @Input() public editingInCell: boolean = false;
  @Input() public editingCallback: Function;

  @Input() set items(items: any[]) {
    this.gridItems = items;
    this.loadItems();
  }

  @Input() set defaultSort(defaultSort: SortDescriptor) {
    if (defaultSort) {
      this.state.sort = [defaultSort];
    }
  }

  @Input() set defaultFilter(defaultFilter: CompositeFilterDescriptor) {
    if (defaultFilter) {
      this.state.filter = defaultFilter;
    }
  }

  @Input() set defaultGroup(defaultGroup: GroupDescriptor) {
    if (defaultGroup) {
      this.state.group = [defaultGroup];
    }
  }

  @Output() public selectionChange = new EventEmitter<any>();
  @Output() public visibleRowsCountChange = new EventEmitter<any>();

  @Input() public pageSizes: boolean | Array<PageSizeItem>;

  @Input() public selectableCheckboxSettings: ISelectableCheckboxSettings;

  //---------------------------------
  public editedColIndex: number; // Col index for editing
  public selectedRow: any; // Selected row
  public buttonCount = 10;
  public info = true;
  public type = 'numeric';
  public previousNext = true;

  private gridItems: any[];
  private editedRowIndex: number;
  public actionPopupSettings: PopupSettings;

  constructor(
    private sessionService: SessionService,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    public commonHelper: CommonHelper
  ) {

    if (this.selectedKeys == undefined) {
      this.selectedKeys = [];
    }

    library.addIcons(faPencilAlt, faTrash, faCheck, faBan, faPlus);

    this.actionPopupSettings = {
      animate: true,
      align: 'right',
      popupClass: 'popup-actions'
    };

    if (this.state == undefined) {
      this.state = {
        skip: this.SKIP,
        take: this.PAGE_SIZE,
        sort: [{
          field: 'id', // default
          dir: 'asc'
        }]
      };
    }
  }

  ngOnInit() {

    if (this.selectable == undefined) {
      this.setSelectableSettings();
    }

    this.pageSizes = [{ text: '20', value: 20 }, { text: '50', value: 50 }, { text: '100', value: 100 }, { text: 'Tutti', value: 'all' }];

    if (this.toolbarActions) {
      this.toolbarActions.forEach(a => {
        if (a instanceof Action) {
          this.library.addIcons(fas[IconHelper.normalizeIconName((<Action>a).icon)]);
        }
      });
    }

    if (this.actions) {
      this.actions.forEach(a => {
        if (a instanceof Action) {
          this.library.addIcons(fas[IconHelper.normalizeIconName((<Action>a).icon)]);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.loadState();
  }

  private loadItems(): void {
    if (this.gridItems) {
      this.gridData = process(this.gridItems, this.state);
      this.visibleRowsCountChange.emit(this.gridData.total);
    }
  }

  private setSelectableSettings(): void {
    this.selectable = {
      enabled: true,
      mode: 'single'
    };
  }

  private loadState() {
    if (this.gridKey) {
      let state = this.sessionService.getGridState(this.gridKey);
      if (state) {
        this.dataStateChange(state);
      }
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    if (!this.hasServerPagination && this.gridItems) {
      this.gridData = process(this.gridItems, this.state);
      this.visibleRowsCountChange.emit(this.gridData.total);
    }
    if (this.dataStateChangeCallback)
      this.dataStateChangeCallback(state);
  }

  public groupChange(groups: Array<GroupDescriptor>): void {
    this.state.group = groups;
    this.loadItems();
  }

  public saveState() {
    if (this.gridKey)
      this.sessionService.setGridState(this.gridKey, this.state);
  }

  public clearState() {
    if (this.gridKey)
      this.sessionService.setGridState(this.gridKey, null);
  }

  public exportToExcel() {
    this.grid.saveAsExcel();
  }

  public pageChange(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    if (!this.hasServerPagination) {
      this.loadItems();
    }
  }

  public onColumnCallback(column: GridColumn, dataItem: any) {
    if (column.callback) {
      column.callback(dataItem);
    }
    return false;
  }

  public onActionClick(action: Action, dataItem?: any): void {
    if (action.applyOnSelected) {
      action.callback(this.selectedKeys);
    }
    else {
      action.callback(dataItem);
    }
  }

  public goToPage(page: number) {
    this.state.skip = page;
  }

  public showTooltip(e: MouseEvent): void {
    let element = e.target as HTMLElement;
    if (element.nodeName === 'SPAN' || element.nodeName === 'A') {
      element = element.parentElement;
    }

    if ((element.nodeName === 'TD' || element.nodeName === 'TH') && element.offsetWidth <= element.scrollWidth && !this.commonHelper.isNullOrWhiteSpace(element.innerText)) {
      this.tooltipDirective.toggle(element);
    } else {
      this.tooltipDirective.hide();
    }
  }

  //public editHandler({ sender, rowIndex }): void {
  //  // close the previously edited item
  //  this.closeEditor(sender);

  //  // track the most recently edited row
  //  // it will be used in `closeEditor` for closing the previously edited row
  //  this._editedRowIndex = rowIndex;

  //  if (this.editCallback) {
  //    this.editCallback(event);
  //  }
  //}
}
