import { Component, OnInit, Input } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { IconHelper } from '../../helpers/icon-helper';

@Component({
  selector: 'app-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent implements OnInit {

  @Input() public icon: string;
  @Input() public tooltip: string;
  @Input() public color: string;

  constructor(private faLibrary: FaIconLibrary) { }

  ngOnInit() {

    this.faLibrary.addIcons(fas[IconHelper.normalizeIconName(this.icon)]);

  }

}
