<header>
  <nav class='navbar navbar-expand navbar-light bg-white border-bottom'>
    <a class="navbar-brand" [routerLink]='["/"]'>
      <img src="./assets/img/logo_veritas.png" height="30" class="d-inline-block align-top mr-2" alt="">
      IoT Platform
    </a>
    <div class="company-text">
      <span class="footer-text">powered by </span><a class="font-weight-bold" href="https://www.beantech.it">beanTech</a>   
    </div>
    <ul class="navbar-nav ml-auto">
      <!-- <li class="nav-item">
        <span class="nav-link text-dark c-pointer">
          <fa-icon icon="bell"></fa-icon>
        </span>
      </li> -->
      <li class="nav-item">
        <span #userPanelButton class="nav-link text-dark c-pointer" [ngClass]="{'active': userPanel.isOpen}" (click)="toggleUserPanel()">
          <fa-icon icon="user"></fa-icon>
          <span class="ml-2">{{ userDisplayName }}</span>
        </span>
      </li>
    </ul>
  </nav>
</header>

<app-user-panel #userPanel></app-user-panel>
