<div class="sidebar no-print" [ngClass]="{'closed': !isMenuOpen}">

  <div id="sidebar-menu">
    <ul>
      <li *ngFor="let item of menuItems">
        <div (click)="menuItemClick(item)" [routerLink]="item.menuItems?.length > 0 ? null : item.routerLink" [ngClass]="{'subdrop': item.isOpen, 'uppercase': item.menuItems?.length > 0 }">
          <fa-icon class="float-left" icon="{{ item.icon | iconName }}"></fa-icon>
          <div class="menu-item-div"><span *ngIf="isMenuOpen">{{ item.resourceKey | translate }}</span></div>
          <fa-icon *ngIf="item.menuItems?.length > 0 && isMenuOpen" icon="chevron-right" class="menu-arrow"></fa-icon>
        </div>
        <ul *ngIf="item.menuItems?.length > 0" #submenu [@openClose]="{value: item.isOpen ? 'open' : 'closed', params: { menuItemHeight: (submenu | elementHeight:item.isOpen) }}" class="list-unstyled">
          <li *ngFor="let subitem of item.menuItems">
            <div [routerLinkActive]="['active']" [routerLink]="subitem.routerLink">
              <fa-icon icon="{{ subitem.icon | iconName }}"></fa-icon>
              <span>{{ subitem.resourceKey | translate }}</span>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="menu-toggle">
    <div class="gui-version" *ngIf="isMenuOpen">
      <span>{{ guiVersion }}</span>
    </div>
    <div (click)="toggleMenu()">
      <fa-icon *ngIf="isMenuOpen" icon="chevron-left"></fa-icon>
      <fa-icon *ngIf="!isMenuOpen" icon="chevron-right"></fa-icon>
    </div>
  </div>

</div>

