import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDumpsterRelation } from '@veritas-dumpsters/models/dumpster-relation.model';
import { DumpsterService } from '@veritas-dumpsters/services/dumpster.service';
import { RelationService } from '@veritas-dumpsters/services/relation.service';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { RelationMappingService } from '@veritas-dumpsters/services/relation-mapping.service';
import { IPaginationItem } from '@veritas-shared/models/pagination-item.mode.';
import { IRelationChild } from '@veritas-dumpsters/models/relation-child.model';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { PaginatedFilterService } from '@veritas-shared/services/paginated-filter.service';
import { map, tap } from 'rxjs/operators';
import { DropDownPaginated } from '@veritas-shared/classes/drop-down-paginated.class';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-relation-select',
  templateUrl: './relation-select.component.html',
  styleUrls: ['./relation-select.component.scss']
})
export class RelationSelectComponent extends DropDownPaginated<IRelationChild> implements OnInit {
  @ViewChild('dropdownlist', { static: true })
  public dropdownlist: DropDownListComponent;

  public dumpsters: IRelationChild[];
  public dumpsterForm: FormGroup;
  
  @Input() public dumpster: Dumpster;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private dumpsterService: DumpsterService,
    private relationService: RelationService,
    private convertService: RelationMappingService,
    protected paginationService: PaginatedFilterService,
    protected renderer: Renderer2, 
    protected elRef: ElementRef
  ) {
    super(paginationService, renderer, elRef);

    this.dumpsters = [];
    this.dumpsterForm = fb.group({});
  }

  ngOnInit(): void {
    this._setDumpsterFilter();
    this._setDumpsterReset();
    this._setDumpsterForm();
  }

  public setActionSave = () => {
    const dumpsterSelected: IDumpsterRelation = this.convertService.getFromDumpster(this.dumpsterForm.value.child)

    this.dumpsterService.setChildDumpster(
      this.dumpster.deviceExternalId, { childDumpsterId: dumpsterSelected?.dumpsterId }
    ).subscribe(() => this.relationService.setRelation(dumpsterSelected));
  }

  private _setDumpsterFilter = () => this.paginationService.filterItem$.subscribe(
    (pagination: IPaginationItem) => this._setDumpsters(pagination)
  )

  private _setDumpsterReset = () => this.paginationService.filterReset$.subscribe(
    (isReset: boolean) => isReset && this._setDumpsterOptions([])
  );

  private _setDumpsters = (
    request: IPaginationItem
  ) => this.dumpsterService.getRelationChild(request).pipe(
    map((items: IRelationChild[]) => items?.filter((item: IRelationChild) => item?.code !== this.dumpster?.code)),
    tap(() => this._setSearchedPlant()),
  ).subscribe({
    next: (filtered: IRelationChild[]) => this._setDumpsterOptions(filtered),
    error: () => this._setDumpsterOptions([]),
  });

  private _setDumpsterOptions = (options: IRelationChild[]) => {
    this.options = options;
    this.dumpsters = options;
  }

  private _setDumpsterForm = () => {
    this.dumpsterForm = this.fb.group({ child: [null, [Validators.required]] })
  }
}
