
<app-custom-grid [columns]="columns"
                 [items]="items"
                 [showActions]="true"
                 [disableActions]="readonly"
                 [actions]="actions"
                 [toolbarActions]="toolbarActions"
                 [selectable]="selectableSettings"
                 [showSelectAll]="true"
                 [selectedKeys]="selectedGroupsId"
                 [selectCallback]="onSelectionChange"
                 [pageable]="true"
                 [pageSizes]="true"
                 [filterable]="true">
</app-custom-grid>

<ng-template #labelColumn let-dataItem>
  <span *ngIf="dataItem.labels && dataItem.labels.length > 0" class="badge badge-secondary mr-2" [style.background-color]="dataItem.labels[0].color">{{dataItem.labels[0].name}}</span>
  <span *ngIf="dataItem.labels && dataItem.labels.length > 1" class="badge badge-secondary">{{dataItem.labels.length}}</span>
</ng-template>
