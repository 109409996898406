import { Component, ViewChild } from '@angular/core';
import { BeanDialogComponentBase, IDialogInput } from 'bean-kendo-dialog';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { Label } from '@veritas-shared/models/label';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { DumpsterMapComponent } from '@veritas-shared/components/dumpster-map/dumpster-map.component';

@Component({
  selector: 'app-dumpster-select-dialog',
  templateUrl: './dumpster-select-dialog.component.html',
  styleUrls: ['./dumpster-select-dialog.component.scss']
})
export class DumpsterSelectDialogComponent extends BeanDialogComponentBase<DumpsterSelectDialogInput>{

  @ViewChild(DumpsterMapComponent, { read: DumpsterMapComponent }) map: DumpsterMapComponent;

  public filteredAllDumpsters: Dumpster[] = [];
  public selectedDumpstersToAdd: Dumpster[] = [];

  public data: DumpsterSelectDialogInput;

  constructor(
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
  ) {
    super();

    this.library.addIcons(faChevronLeft, faSave);
  }

  ngAfterViewInitCallback(): void {
    this.filteredAllDumpsters = this.data.allDumpsters;
  }

  cancel(e: any): boolean {
    return true;
  }

  back(e: any): boolean {
    return true;
  }

  save(e: any): Observable<Dumpster[]> {
    return of(this.selectedDumpstersToAdd);
  }

  public onTabChange(index: number) {
    if (index == 1) {
      window.setTimeout(() => this.map.centerAndZoomOnMarkers(), 200);
    }
  }
}

export class DumpsterSelectDialogInput implements IDialogInput {
  constructor(public allDumpsters: Dumpster[], public allLabels: Label[]) { }
}
