import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCubes, faShieldAlt, faUserShield, faDumpster, faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '@veritas-shared/services/modal.service';
import { GroupListOutputGroup } from '../../../group/models/group-list-output';
import { GroupService } from '../../../group/services/group.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { GroupType } from '../../../group/models/grouptype.enum';
import { SecurityService } from '@veritas-shared/services/security.service';
import { NavigationService } from '@veritas-shared/services/navigation.service';

@Component({
  selector: 'app-dumpstergroups',
  templateUrl: './dumpstergroups.component.html',
  styleUrls: ['./dumpstergroups.component.scss']
})
export class DumpsterGroupsComponent implements OnInit {

  @ViewChild('isDeactivatedColumn', { static: true }) public isDeactivatedColumn: TemplateRef<any>;
  @ViewChild('isSystemColumn', { static: true }) public isSystemColumn: TemplateRef<any>;
  @ViewChild('groupTypeColumn', { static: true }) public groupTypeColumn: TemplateRef<any>;

  public columns: GridColumn[] = [];

  public actions: Action[] = [];

  public toolbarActions: Action[] = [];

  public gridData: GroupListOutputGroup[];
  public GroupType = GroupType;

  constructor(
    private groupService: GroupService,
    private router: Router,
    private translate: TranslateService,
    private modalService: ModalService,
    private faLibrary: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private securityService: SecurityService,
    private navigationService: NavigationService
  ) {

    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);

    this.faLibrary.addIcons(faUserShield, faShieldAlt, faCubes, faDumpster, faCrosshairs);

    // Actions
    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_GROUP_WRITE).subscribe(result => {
      if (result) {
        this.actions = [
          new Action('edit', this.constantHelper.LBL_ACTION_COMMON_EDIT, 'pencil-alt', this.edit),
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DELETE, 'trash-alt', this.delete, null, false, (group: GroupListOutputGroup) => group && !group.isSystem),
        ];

        this.toolbarActions = [
          new Action('add', this.constantHelper.LBL_ACTION_COMMON_ADD, 'plus', this.add)
        ];
      } else {
        this.actions = [
          new Action('view', this.constantHelper.LBL_ACTION_COMMON_VIEW, 'eye', this.view),
        ];
      }
    });

  }

  ngOnInit() {

    this.columns = [
      {
        field: 'name',
        title: this.constantHelper.LBL_COMMON_NAME,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'link',
        callback: this.view
      },
      {
        field: 'description',
        title: this.constantHelper.LBL_COMMON_DESCRIPTION,
        sortable: true,
        resizable: true,
        filterable: true
      },
      {
        field: 'isSystem',
        title: this.constantHelper.LBL_COMMON_ISSYSTEM,
        sortable: true,
        resizable: false,
        filterable: false,
        template: this.isSystemColumn,
        width: "70px"
      },
      {
        field: 'groupType',
        title: this.constantHelper.LBL_VOCABULARY_AUTHGROUP_TYPE,
        sortable: true,
        resizable: false,
        filterable: false,
        template: this.groupTypeColumn,
        width: "70px"
      },
      {
        field: 'dumpstersCount',
        title: this.constantHelper.LBL_VOCABULARY_AUTHGROUP_DUMPSTERSCOUNT,
        sortable: true,
        resizable: false,
        filterable: false,
        width: "70px"
      }
    ];

    this.loadData();
  }

  public add() {
    this.router.navigate(['dumpsters', 'groups', 'new']);
  }

  public edit(group: any) {
    this.router.navigate(['dumpsters', 'groups', group.id], { queryParams: { edit: true } });
  }

  public view(group: any) {
    this.router.navigate(['dumpsters', 'groups', group.id]);
  }

  public delete(group: any) {
    this.groupService.getGroupDelete(group.id).subscribe(result => {
      if (result.authorizationsCount > 0 || result.dumpstersCount > 0 || result.collectionPointsCount > 0) {
        if (result.groupType.toString() === 'CollectionPointGroup') {
          this.modalService.showConfirm(
            this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUPS_DIALOG_DELETECOLLECTION_POINTGROUP_WARNING, { '0': result.authorizationsCount, '1': result.collectionPointsCount }),
            () => {
              this.groupService.deleteGroup(group.id).subscribe(result => {
                this.loadData();
              });
            });
        } else {
          this.modalService.showConfirm(
            this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUPS_DIALOG_DELETEDUMPSTERGROUP_WARNING, { '0': result.authorizationsCount, '1': result.dumpstersCount }),
            () => {
              this.groupService.deleteGroup(group.id).subscribe(result => {
                this.loadData();
              });
            });
        }

      } else {
        this.modalService.showConfirm(
          this.translate.instant(this.constantHelper.LBL_COMMON_CONFIRM_DELETE),
          () => {
            this.groupService.deleteGroup(group.id).subscribe(result => {
              this.loadData();
            });
          });
      }
    });
  }

  private loadData() {
    this.groupService.getGroups().subscribe(result => {
      this.gridData = result.groups;
    });
  }

}
