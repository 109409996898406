import { Directive } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationService } from '@veritas-shared/services/navigation.service';
import { EditDumpsterInput } from "@veritas-dumpsters/models/edit-dumpster-input";
import { DumpsterService } from "@veritas-dumpsters/services/dumpster.service";
import { DeactivatedComponent } from "@veritas-shared/components/deactivated.component";
import { CollectionPointListOutputCollectionPoint } from "@veritas-shared/models/collectionpoint-list-output";
import { Dumpster } from "@veritas-shared/models/dumpster";
import { Location } from "@veritas-shared/models/location";
import { ViewMode } from "@veritas-shared/models/view-mode";
import { DumpsterLocationService } from "@veritas-dumpsters/services/dumpster-location.service";

@Directive()
export class DumpsterGeneralForm extends DeactivatedComponent {
  public dumpsters: Dumpster[];
  public formDumpster: FormGroup;
  public viewMode: ViewMode;
  protected allCollectionPoints: CollectionPointListOutputCollectionPoint[] = [];

  constructor(
    protected fb: FormBuilder,
    protected navigationService: NavigationService,
    protected dumpsterService: DumpsterService,
    protected locationService: DumpsterLocationService,
  ){
    super();
  }

  canDeactivate(): boolean { return !this.formDumpster.dirty; }

  public goBack = () => this.navigationService.back('/dumpsters/list');
  public onSave = () => {
    if (!this.formDumpster.valid) return;

    const rawValue = this.formDumpster.getRawValue();
    const location: Location = this.locationService.getLocation(rawValue?.location);
    const input = rawValue as EditDumpsterInput;

    input.latitude = location.latitude;
    input.longitude = location.longitude;

    new Promise<EditDumpsterInput>((resolve, reject) => {
      if (input.collectionPointId !== null) {
        resolve(input);
        return;
      }
      // If the collection point is not set, find the nearest
      var result = this.locationService.getNearestCollectionPoint(
        input.latitude,
        input.longitude,
        rawValue.collectionPointDistance,
        this.allCollectionPoints
      );

      if (result !== null) {
        input.collectionPointId = result.id;
        resolve(input);
      }

      // Otherwise find the address of the location by latitude/longitude
      this.locationService.getAddressByLocation(input.latitude, input.longitude).then(
        (el) => {
          input.address = el;
          resolve(input);
        },
        (err) => {
          input.address = '';
          reject(input);
        }
      );
    }).then((input) => {
      switch (this.viewMode) {
        case ViewMode.Edit:
          this.dumpsterService.updateDumpster(input).subscribe((result) => {
            this.formDumpster.reset();
            this.goBack();
          });
          break;
        case ViewMode.New: default:
          break;
      }
    });
  }

  protected getFormGroup = (): FormGroup => this.fb.group({
    id: [''],
    code: [
      { value: '', disabled: this.viewMode !== ViewMode.New },
      Validators.required,
    ],
    binId: [{ value: '', disabled: this.viewMode === ViewMode.View }],
    regionCode: [{ value: '', disabled: true }, Validators.required],
    regionCodeSecond: [{ value: '', disabled: true }],
    regionCodeThird: [{ value: '', disabled: true }],
    supplierName: [{ value: '', disabled: true }, Validators.required],
    binType: [{ value: '', disabled: true }],
    garbageType: [{ value: '', disabled: true }],
    location: [
      { value: '', disabled: this.viewMode === ViewMode.View },
      Validators.required,
    ],
    collectionPointId: [
      { value: undefined, disabled: this.viewMode === ViewMode.View },
    ],
    collectionPointName: [
      { value: '', disabled: this.viewMode === ViewMode.View },
    ],
    collectionPointDistance: [
      { value: 30, disabled: this.viewMode === ViewMode.View },
    ],
    address: [{ value: '', disabled: true }, Validators.required],
    userType: [{ value: undefined, disabled: true }],
    imei: [{ value: '', disabled: true }],
    tag: [{ value: '', disabled: true }],
  });

  protected _setFormGroup = (dumpster: Dumpster, location: Location) => {
    this.formDumpster.patchValue(dumpster);
    this.setRegionCode(dumpster);
    this._setLocation(location);
  };

  protected _setLocation = (location: Location) => {
    const coordination: string = `${location?.latitude} - ${location?.longitude}`;
    this.formDumpster.get('location').setValue(coordination);
  };

  protected _setLocationDisable = (isDisable: boolean) => {
    if(isDisable) {
      this.formDumpster.get('location').disable();
      return;
    }
    this.formDumpster.get('location').enable();
  }

  protected _setLocationUpdate = () => this.formDumpster.get('location').valueChanges.subscribe(
    (item: string) => {
      if(typeof item !== 'string') return;
      const location = this.locationService.getLocation(item);
      const dumpster = { location } as Dumpster;
      this.dumpsters = [this.dumpsters?.length ? { ...this.dumpsters[0], ...dumpster } : dumpster];
    }
  );

  private setRegionCode = (dumpster: Dumpster) => {
    if(!dumpster?.regionCode?.length) return;
    const { regionCode } = dumpster;

    this.formDumpster.get('regionCode').setValue(regionCode[0]);
    if(regionCode?.length >= 2) {
      this.formDumpster.get('regionCodeSecond').setValue(regionCode[1]);
    }
    if(regionCode?.length === 3) {
      this.formDumpster.get('regionCodeThird').setValue(regionCode[2]);
    }
  }
}