export class ConfirmEvent {

  public message: string;
  public onConfirm: Function | null;
  public onCancel: Function | null;

  constructor(message: string, onConfirm: Function, onCancel?: Function | null) {
    this.message = message;
    this.onConfirm = onConfirm;
    this.onCancel = onCancel;
  }
}
