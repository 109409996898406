import { Component, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { ConstantHelper } from '../../helpers/constant.helper';
import { Dumpster } from '../../models/dumpster';
import { MapEntity, MapEntityType } from '../../models/map-entity';
import { MapIconService } from '@veritas-dumpsters/services/map-icon.service';
import { accessibleIcon, dumpsterIcon } from '@veritas-dumpsters/constants/dumpster-icon-path.const';
import { MapPathIcon } from '@veritas-shared/models/map/map-path-icon.model';

@Component({
  selector: 'app-dumpster-map',
  templateUrl: './dumpster-map.component.html',
  styleUrls: ['./dumpster-map.component.scss']
})
export class DumpsterMapComponent {
  @ViewChild('map', { static: true }) mapElement: any;

  @Input() public set items(dumpsters: Dumpster[]) {
    if (!dumpsters?.length) return;

    this._items = dumpsters;
    this.entities = dumpsters.filter((dumpster: Dumpster) => this._getIsLocation(dumpster)).map(el => this.convertDumpsterToMapEntity(el));
  };

  @Input() public mode: 'single' | 'multiple' = 'multiple';

  @Input() public onClickSetMarker: boolean = false;

  @Input() public set selectedDumpsters(val: Dumpster[]) {
    if (val) {
      this.selectedEntities = val.map(el => this.convertDumpsterToMapEntity(el));
    }
  };

  public get selectedDumpsters(): Dumpster[] {
    return this.selectedEntities.map(el => el.entity);
  }

  @Input() public zoom: number;
  @Input() public useInfoWindowWithBtn: boolean = false;
  @Input() public markerColorByStatus: boolean = false;
  @Input() public isTitleNavigable: boolean;
  @Input() public markerCustom?: (item: Dumpster) => any; 

  @Output() public selectedDumpstersChange = new EventEmitter<Dumpster[]>();
  @Output() public selectedMarkerChange = new EventEmitter<google.maps.LatLng>();

  private _items: Dumpster[];
  public entities: MapEntity[] = [];
  public selectedEntities: MapEntity[] = [];

  public MapEntityType = MapEntityType;
  public context: DumpsterMapComponent;

  constructor(
    public constantHelper: ConstantHelper,
    private iconService: MapIconService
  ) {
    this.context = this;
    this.isTitleNavigable = true;
  }

  private convertDumpsterToMapEntity(data: Dumpster) {
    let latitude = data.location?.latitude;
    let longitude = data.location?.longitude;

    return new MapEntity(data.id, latitude, longitude, data);
  }

  public centerAndZoomOnMarkers() {
    this.mapElement.centerAndZoomOnMarkers();
  }

  public getMarker = () => {
    if(this.markerColorByStatus) return this.getMarkerByStatus;
    if(this.markerCustom) return this.markerCustom;
    return false;
  }

  public getMarkerByStatus = (data: Dumpster) => {
    this.iconService.path = this._getIconPath(data);
    return this.iconService.getIcon(data);
  }

  public onSelectionChange(data: MapEntity[]) {
    this.selectedDumpstersChange.emit(data?.map(el => el.entity));
  }

  public onMarkerSelectionChange(data: google.maps.LatLng) {
    this.selectedMarkerChange.emit(data);
  }

  private _getIconPath = (data: Dumpster): MapPathIcon => {
    if(Boolean(data?.childDumpsterId)) return accessibleIcon;
    return dumpsterIcon;
  }

  private _getIsLocation = (dumpster: Dumpster): boolean => dumpster.location !== null && Boolean(dumpster.location?.latitude) && Boolean(dumpster.location?.longitude);
}
