
export class EditAuthUsersInput {
  authorizationId: number;
  users: string[];

  constructor(authId: number, users: string[]) {
    this.authorizationId = authId;
    this.users = users;
  }
}
