<h1 class="page-title">
  <fa-icon icon="user-friends"></fa-icon>{{ 'page.CommandTasks.Title' | translate }}
</h1>
<div class="page-content">
  <div class="mt-10">
    <app-group-box [pageTitle]="'Elenco Schedulazioni'">

      <div class="grid-container standard-padding">
        <app-custom-grid [columns]="columns" [items]="gridData" [filterable]="true" [showActions]="true" [actions]="actions"
                         [toolbarActions]="toolbarActions"></app-custom-grid>
      </div>
    </app-group-box>

  </div>
</div>

<ng-template #isEnabledColumn let-dataItem>
  <app-info-icon *ngIf="!dataItem.isEnabled" [icon]="'eye-slash'" class="text-primary" tooltip="{{'common.IsEnabled' | translate}}">
  </app-info-icon>
</ng-template>
