import { Component } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { BeanDialogComponentBase, IDialogInput } from 'bean-kendo-dialog';
import { Observable, of } from 'rxjs';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { PayloadType } from '@veritas-shared/models/entities/payload-type';
import { WasteType } from '@veritas-shared/models/entities/waste-type';
import { DumpsterEventDetailInput } from '../../models/dumpster-event-detail';
import { DumpsterService } from '../../services/dumpster.service';
import { CommonHelper } from '../../../shared/helpers/common.helper';

@Component({
  selector: 'app-dumpster-event-detail-dialog',
  templateUrl: './dumpster-event-detail-dialog.component.html',
  styleUrls: ['./dumpster-event-detail-dialog.component.scss']
})
export class DumpsterEventDetailDialogComponent extends BeanDialogComponentBase<DumpsterEventDetailDialogInput> {

  constructor(
    private library: FaIconLibrary,
    private dumpsterService: DumpsterService,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper
  ) {
    super();

    this.library.addIcons(faChevronLeft, faFileExport);
  }

  ngAfterViewInitCallback(): void {
  }

  cancel(e: any): boolean {
    return true;
  }

  back(e: any): boolean {
    return true;
  }

  save(e: any): Observable<any> {
    return of(false);
  }

  public downloadFile(): void {
    this.dumpsterService.downloadDumpsterEventDetail(this.data.event)
      .subscribe(res => {

        if (res) {
          this.commonHelper.downloadAttachment(res);
        }
      });
  }
}

export class DumpsterEventDetailDialogInput implements IDialogInput {
  constructor(public event: DumpsterEventDetailInput, public payloadTypes: PayloadType[], public wasteTypes: WasteType[]) { }
}
