import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTag, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Label } from '@veritas-shared/models/label';

@Component({
  selector: 'app-filter-labels',
  templateUrl: './filter-labels.component.html',
  styleUrls: ['./filter-labels.component.scss']
})
export class FilterLabelsComponent {
  @Input() labels: Label[];

  @Output() labelSelect: EventEmitter<string>;

  public labelSelected: Label;

  constructor(
    private library: FaIconLibrary
  ) {
    this.labels = [];
    this.library.addIcons(faTag, faTimes);
    this.labelSelect = new EventEmitter<string>()
  }

  public setSelectedLabel(labelName: Label) {
    const { name } = labelName;
    this.labelSelected = labelName;
    this.labelSelect.emit(name);
  }

  public setUnselectedLabel(event) {
    this.labelSelected = null;
    this.labelSelect.emit(null);
    event.stopPropagation();
  }
}
