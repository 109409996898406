<div class="toolbar">
  <button type="button" class="btn toolbar-item" (click)="centerAndZoomOnMarkers()">
    <fa-icon class="text-primary" icon="expand"></fa-icon>
    <span>Centra</span>
  </button>
  <button *ngIf="mode == 'multiple'" type="button" class="btn toolbar-item" (click)="addSelection()">
    <fa-icon class="text-primary" [icon]="['far', 'plus-square']"></fa-icon>
    <span>Aggiungi Selezione</span>
  </button>
</div>
<input #searchBox class="maps-search form-control" type="text" placeholder="Inserisci un indirizzo..."/>
<div #map class="map"></div>
<div id="infowindow-content">
  <span id="place-name" class="title"></span><br />
  <span id="place-address"></span>
</div>
<div #confirmSelection class="confirm">
  <div (click)="onConfirmSelection()">{{ constantHelper.LBL_COMMON_OK | translate}}</div>
  <div (click)="onCancelSelection()">{{ constantHelper.LBL_ACTION_COMMON_CANCEL | translate}}</div>
</div>

<!-- DUMPSTER InfoWindow -->
<div [hidden]="true" #infoWindowDumpsterTemplate>
  <div class="info">
    <a class="title mb-2">{{infoWindowData?.code}}</a>
    <table>
      <tr>
        <td class="label">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_SUPPLIER | translate}}:</td>
        <td>{{ infoWindowData?.supplierName }}</td>
      </tr>
      <tr>
        <td class="label">{{ 'common.Type' | translate }}:</td>
        <td>{{ infoWindowData?.binType }} - {{ infoWindowData?.garbageType }}</td>
      </tr>
    </table>
  </div>
</div>

<!-- DUMPSTER InfoWindow with Btn-->
<div [hidden]="true" #infoWindowDumpsterBtnTemplate>
  <div class="info">
    <a class="title mb-2">{{infoWindowData?.code}}</a>
    <table>
      <tr>
        <td class="label">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_SUPPLIER | translate}}:</td>
        <td>{{ infoWindowData?.supplierName }}</td>
      </tr>
      <tr>
        <td class="label">{{ 'common.Type' | translate }}:</td>
        <td>{{ infoWindowData?.binType }} - {{ infoWindowData?.garbageType }}</td>
      </tr>
    </table>
    <button class="btn toolbar-item" id="sendToDetail">Vedi Dispositivo</button>
  </div>
</div>

<!-- COLLECTIONPOINT InfoWindow -->
<div [hidden]="true" #infoWindowCollectionPointTemplate>
  <div class="info">
    <a class="title mb-2" href="">{{infoWindowData?.collectionPointCode}} - {{infoWindowData?.name}}</a>
    <table>
      <tr>
        <td class="label">{{ 'vocabulary.CollectionPoint.Country' | translate}}:</td>
        <td>{{ infoWindowData?.country }}</td>
      </tr>
      <tr>
        <td class="label">{{ 'vocabulary.CollectionPoint.Province' | translate }}:</td>
        <td>{{ infoWindowData?.province }} - {{ infoWindowData?.city }}</td>
      </tr>
    </table>
  </div>
</div>
