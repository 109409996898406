import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn } from '../../models/grid-column';
import { ImportResultOutput } from '../../models/import-result-output';
import { ImportBehavior } from '../../models/import-behavior';
import { Action } from '../../models/action';

@Component({
  selector: 'app-import-log',
  templateUrl: './import-log.component.html',
  styleUrls: ['./import-log.component.scss']
})
export class ImportLogComponent implements OnInit {

  @Input() title: string;
  @Input() importResult: ImportResultOutput;

  @ViewChild('typeColumn', { static: true }) public typeColumn: TemplateRef<any>;

  public columns: GridColumn[] = [];
  public actions: Action[] = [];
  public toolbarActions: Action[] = [];
  public importBehavior = ImportBehavior;

  constructor() {
  }

  ngOnInit() {
    this.columns = [
      {
        field: 'row',
        title: 'component.ImportLog.Number',
        sortable: false,
        resizable: false,
        filterable: false,
        display: 'text',
        width: "70px"
      },
      {
        field: 'typeDescription',
        title: 'component.ImportLog.TypeDescription',
        sortable: false,
        resizable: true,
        filterable: false,
        template: this.typeColumn
      },
      {
        field: 'message',
        title: 'component.ImportLog.Message',
        sortable: false,
        resizable: true,
        filterable: false,
        display: 'text'
      }
    ];
  }

}
