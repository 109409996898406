<h1 class="page-title"><fa-icon icon="users"></fa-icon>{{ constantHelper.LBL_PAGE_AUTHGROUPS_TITLE | translate }}</h1>

<div class="page-content">
  <div class="mt-10">
    <app-group-box [pageTitle]="'Elenco Gruppi'"> <!-- constantHelper.LBL_PAGE_AUTHGROUPS_BOX_TITLE -->

      <div class="grid-container standard-padding">
        <app-custom-grid [columns]="columns" [items]="gridData" [pageable]="true" [pageSizes]="true" [filterable]="true" [showActions]="true" [actions]="actions" [toolbarActions]="toolbarActions"></app-custom-grid>
      </div>

    </app-group-box>

  </div>
</div>

<ng-template #isSystemColumn let-dataItem>
  <app-info-icon *ngIf="dataItem.isSystem" [icon]="'user-shield'" class="text-primary" tooltip="{{ constantHelper.LBL_COMMON_ISSYSTEM | translate}}"></app-info-icon>
</ng-template>

<ng-template #groupTypeColumn let-dataItem>
  <app-info-icon *ngIf="dataItem.groupType === GroupType.DumpsterGroup" [icon]="'dumpster'" class="text-primary" tooltip="{{dataItem.groupTypeDescription | translate}}"></app-info-icon>
  <app-info-icon *ngIf="dataItem.groupType === GroupType.CollectionPointGroup" [icon]="'crosshairs'" class="text-primary" tooltip="{{dataItem.groupTypeDescription | translate}}"></app-info-icon>
</ng-template>
