
<app-map #map
         [context]="context"
         [mode]="'single'"
         [entities]="entities"
         [selectedEntities]="selectedEntities"
         [zoom]="4"
         [entityType]="MapEntityType.CollectionPoint"
         (selectdEntitiesChange)="onSelectionChange($event)"
         (selectedMarkerChange)="onMarkerSelectionChange($event)">
</app-map>
