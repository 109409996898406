import { TemplateRef } from "@angular/core";
import { ConstantHelper } from "@veritas-shared/helpers/constant.helper";
import { GridColumn } from "@veritas-shared/models/grid-column";

export class DumpsterColumns {
  public code = (
    constantHelper: ConstantHelper, 
    callback: Function,
    template: TemplateRef<any>,
  ): GridColumn => ({
    field: 'code',
    title: constantHelper.LBL_VOCABULARY_DUMPSTER_CODE,
    sortable: true,
    resizable: true,
    filterable: true,
    display: 'link',
    width: '150px',
    callback,
    template
  });

  public binId = (
    constantHelper: ConstantHelper, 
    callback: Function
  ): GridColumn => ({
    field: 'binId',
    title: constantHelper.LBL_VOCABULARY_DUMPSTER_TAG,
    sortable: true,
    resizable: true,
    filterable: true,
    display: 'link',
    width: '120px',
    callback
  });

  public labels = (
    constantHelper: ConstantHelper, 
    template: TemplateRef<any>,
    filterTemplate: TemplateRef<any>,
  ): GridColumn => ({
    field: 'labels',
    title: constantHelper.LBL_VOCABULARY_DUMPSTER_LABELS,
    sortable: true,
    resizable: true,
    filterable: true,
    class: 'text-nowrap',
    template,
    filterTemplate,
    width: '120px'
  });

  public supplierName = (
    constantHelper: ConstantHelper, 
  ): GridColumn => ({
    field: 'supplierName',
    title: constantHelper.LBL_VOCABULARY_DUMPSTER_SUPPLIER,
    sortable: true,
    resizable: true,
    filterable: true,
    width: '70px'
  });

  public binType = (
    constantHelper: ConstantHelper, 
  ): GridColumn => ({
    field: 'binType',
    title: constantHelper.LBL_VOCABULARY_DUMPSTER_TYPE,
    sortable: true,
    resizable: true,
    filterable: true,
    width: '70px'
  });

  public garbageType = (
    constantHelper: ConstantHelper,
    template: TemplateRef<any>,
  ): GridColumn => ({
    field: 'garbageType',
    title: constantHelper.LBL_VOCABULARY_DUMPSTER_GARBAGE_TYPE,
    sortable: true,
    resizable: true,
    filterable: true,
    template,
    width: '100px'
  });

  public collectionPointCode = (
    constantHelper: ConstantHelper,
    template: TemplateRef<any>,
  ): GridColumn => ({
    field: 'collectionPoint.collectionPointCode',
    title: constantHelper.LBL_VOCABULARY_COLLECTIONPOINT,
    sortable: true,
    resizable: true,
    filterable: true,
    template,
    width: '70px'
  });

  public bookingCount = (
    constantHelper: ConstantHelper,
    template: TemplateRef<any>,
  ): GridColumn => ({
    field: 'bookingCount',
    title: constantHelper.LBL_VOCABULARY_DUMPSTER_BOOKINGNUMBER,
    sortable: true,
    resizable: true,
    filterable: false,
    template,
    width: '70px'
  });

  public errorCount = (
    constantHelper: ConstantHelper, 
  ): GridColumn => ({
    field: 'errorCount',
    title: constantHelper.LBL_VOCABULARY_DUMPSTER_ERROR_COUNT,
    sortable: true,
    resizable: true,
    filterable: false,
    width: '70px'
  });

  public warningCount = (
    constantHelper: ConstantHelper, 
  ): GridColumn => ({
    field: 'warningCount',
    title: constantHelper.LBL_VOCABULARY_DUMPSTER_WARNING_COUNT,
    sortable: true,
    resizable: true,
    filterable: false,
    width: '70px'
  });

  public state = (
    constantHelper: ConstantHelper,
    template: TemplateRef<any>,
  ): GridColumn => ({
    field: 'state',
    title: constantHelper.LBL_VOCABULARY_DUMPSTER_STATUS,
    filterable: true,
    sortable: true,
    resizable: true,
    template,
    width: '100px'
  });
}