<h1 class="page-title"><fa-icon icon="tags"></fa-icon>{{ constantHelper.LBL_PAGE_LABELS_TITLE | translate }}</h1>

<div class="page-content">
  <div class="mt-10">
    <app-group-box [pageTitle]="'Elenco Etichette'">

      <div class="grid-container standard-padding">
        <app-custom-grid [columns]="columns" [items]="gridData" [filterable]="true" [showActions]="true" [actions]="actions" [toolbarActions]="toolbarActions"></app-custom-grid>
      </div>

    </app-group-box>

  </div>
</div>

<ng-template #colorColumn let-dataItem>
  <app-info-icon *ngIf="dataItem.color" [icon]="'square'" [color]="dataItem.color" tooltip="{{ constantHelper.LBL_COMMON_COLOR | translate}}"></app-info-icon>
</ng-template>
<ng-template #isSystemColumn let-dataItem>
  <app-info-icon *ngIf="dataItem.isSystem" [icon]="'user-shield'" tooltip="{{ constantHelper.LBL_COMMON_OK | translate}}"></app-info-icon>
</ng-template>
