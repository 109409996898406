<div class="relation-wrapper form-group">
  <ng-container
    [ngTemplateOutlet]="getTemplate(dumpsterDisplayed)"
    [ngTemplateOutletContext]="{ $implicit: { 
      dumpsterDisplayed: dumpsterDisplayed, 
      dumpsterRelation: dumpsterRelation 
    } }"
  ></ng-container>
</div>

<ng-template #templateSelect let-data>
  <app-relation-select
    [dumpster]="data?.dumpsterDisplayed"
  ></app-relation-select>
</ng-template>

<ng-template #templateFather let-data>
  <app-relation-detail
    label="{{ '$lbl.dumpsterChild' | translate }}"
    [dumpsterDisplayed]="data?.dumpsterDisplayed"
    [dumpster]="data?.dumpsterRelation"
  ></app-relation-detail>
</ng-template>

<ng-template #templateChild let-data>
  <app-relation-detail
    label="{{ '$lbl.dumpsterFather' | translate }}"
    [dumpsterDisplayed]="data?.dumpsterDisplayed"
    [dumpster]="data?.dumpsterRelation"
  ></app-relation-detail>
</ng-template>
