import { Injectable, Inject } from '@angular/core';
import { ApiService } from '@veritas-shared/services/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { ToastService } from '@veritas-shared/services/toast.service';
import { WaitService } from '@veritas-shared/services/wait.service';
import { CommandEventListOutput } from '../models/commandevent-list-output';
import { CommandEventDetailOutput } from '../models/commandevent-detail';
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from '@veritas-shared/helpers/common.helper';

@Injectable({
  providedIn: 'root'
})
export class CommandeventService extends ApiService {

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, toastService: ToastService, waitService: WaitService, commonHelper: CommonHelper, newToastService: BeanToastService) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  public getCommandEvents(): Observable<CommandEventListOutput> {
    return this.getBase<CommandEventListOutput>('api/CommandEvent');
  }

  public getCommandEventsByCommandTask(id: number): Observable<CommandEventListOutput> {
    return this.getBase<CommandEventListOutput>('api/CommandEvent/' + id);
  }

  public getCommandEventDetail(id: number): Observable<CommandEventDetailOutput> {
    return this.getBase<CommandEventDetailOutput>('api/CommandEvent/detail/' + id);
  }
}
