import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BannerIcon, bannerActions } from '@veritas-shared/enums/banner.const';
import { BannerAction, BannerOption } from '@veritas-shared/models/banner-option.model';
import { ViewMode } from '@veritas-shared/models/view-mode';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  public readonly viewMode = ViewMode;

  @Input() option: BannerOption;
  @Input() action: BannerAction;

  @Output() clickAction: EventEmitter<boolean>;

  constructor() {
    this.option = new BannerOption(BannerIcon.create, '');
    this.action = bannerActions.create;
    this.clickAction = new EventEmitter<boolean>()
  }

  public setClickAction = () => this.clickAction.emit(true);
}
