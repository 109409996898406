import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { ResourceService } from "../services/resource.service";

export class ResourceLoader implements TranslateLoader {

  constructor(private resourceService: ResourceService) { }

  getTranslation(lang: string): Observable<any> {
    return this.resourceService.getResources(lang);
  }

}

