import { Injectable, Inject } from '@angular/core';
import { ApiService } from '@veritas-shared/services/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { ToastService } from '@veritas-shared/services/toast.service';
import { WaitService } from '@veritas-shared/services/wait.service';
import { CommandTaskListOutput } from '../models/commandtask-list-output';
import { NewCommandTaskOutput } from '../models/new-commandtask-output';
import { EditCommandTaskOutput } from '../models/edit-commandtask-output';
import { EditCommandTaskInput } from '../models/edit-commandtask-input';
import { NewCommandTaskInput } from '../models/new-commandtask-input';
import { CommandTaskDeleteOutput } from '../models/commandtask-delete-output';
import { DisableCommandTaskInput } from '../models/disable-commandtask-input';
import { SendCommandTaskInput } from '../models/send-commandtask-input';
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from '@veritas-shared/helpers/common.helper';

@Injectable({
  providedIn: 'root'
})
export class CommandTaskService extends ApiService {

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, toastService: ToastService, waitService: WaitService, commonHelper: CommonHelper, newToastService: BeanToastService) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  public getCommandTasks(): Observable<CommandTaskListOutput> {
    return this.getBase<CommandTaskListOutput>('api/commandtask');
  }

  public getNewCommandTask(): Observable<NewCommandTaskOutput> {
    return this.getBase<NewCommandTaskOutput>('api/commandtask/new');
  }

  public getEditCommandTask(id: number): Observable<EditCommandTaskOutput> {
    return this.getBase<EditCommandTaskOutput>('api/commandtask/' + id + '/edit');
  }

  public getCommandTaskDelete(id: number): Observable<CommandTaskDeleteOutput> {
    return this.getBase<CommandTaskDeleteOutput>('api/commandtask/' + id + '/delete');
  }

  public addCommandTask(commandTask: NewCommandTaskInput): Observable<void> {
    return this.postBase<NewCommandTaskInput, void>('api/commandtask', commandTask);
  }

  public updateCommandTask(commandTask: EditCommandTaskInput): Observable<void> {
    return this.putBase<EditCommandTaskInput, void>('api/commandtask', commandTask);
  }

  public disableCommandTask(commandTask: DisableCommandTaskInput): Observable<void> {
    return this.putBase<DisableCommandTaskInput, void>('api/commandtask/disable', commandTask);
  }

  public deleteCommandTask(id: number): Observable<void> {
    return this.deleteBase<void>('api/commandtask/' + id);
  }

  public sendCommandTask(commandTask: SendCommandTaskInput): Observable<void> {
    return this.postBase<SendCommandTaskInput, void>('api/commandtask/sendcommand', commandTask);
  }

}
