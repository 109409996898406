import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConstantHelper } from "@veritas-shared/helpers/constant.helper";
import { EntitySearchInput } from "@veritas-shared/models/entities/entity-search-input";
import { ApiService } from "@veritas-shared/services/api.service";
import { ToastService } from "@veritas-shared/services/toast.service";
import { WaitService } from "@veritas-shared/services/wait.service";
import { EntityListOutput } from "../models/entities/entity-list-output";
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from "../helpers/common.helper";

@Injectable({
  providedIn: 'root'
})
export class EntityService extends ApiService {

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    http: HttpClient,
    toastService: ToastService,
    waitService: WaitService,
    private constantHelper: ConstantHelper,
    commonHelper: CommonHelper,
    newToastService: BeanToastService
  ) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  // Entities
  public getEntities(input: EntitySearchInput): Observable<EntityListOutput> {
    return this.postBase<EntitySearchInput, EntityListOutput>(this.constantHelper.ENTITIES_GET, input);
  }
}
