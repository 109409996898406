import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-group-box',
  templateUrl: './group-box.component.html',
  styleUrls: ['./group-box.component.scss']
})
export class GroupBoxComponent implements AfterViewInit {

  @ViewChild('rightAction', { static: true }) rightActionElement: ElementRef<any>;
  @ContentChild('fields') bodyTemplate: TemplateRef<any>;
  @ViewChild('collapsableElement') collapsableElement: ElementRef<any>;

  @Input() public pageTitle: string;
  @Input() public isHeaderVisible: boolean;
  @Input() public borderColor: string = "#0078D4";
  @Input() public headerRightTemplate: TemplateRef<any>;
  @Input() public isCollapsible: boolean = false;
  @Input() public collapsed: boolean = false;

  @Output() private onCollapse: EventEmitter<boolean> = new EventEmitter<boolean>();

  public headerRightWidth: number = 0;

  constructor(private library: FaIconLibrary) {
    library.addIcons(faAngleDown, faAngleUp);

    this.isHeaderVisible = true;
  }

  ngAfterViewInit() {
    // 65 = border left + margin left + margin right
    setTimeout(() => this.headerRightWidth = 65, 0);
  }

  public collapseClick(): void {
    this.collapsed = !this.collapsed;

    this.onCollapse.emit(this.collapsed);
  }

}
