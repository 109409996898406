import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { ImportResultOutput } from '@veritas-shared/models/import-result-output';
import { FileRestrictions, SelectEvent as UploadSelectEvent, SuccessEvent as UploadSuccessEvent, ErrorEvent as UploadErrorEvent } from '@progress/kendo-angular-upload';
import { ImportBehavior } from '@veritas-shared/models/import-behavior';
import { ToastService } from '@veritas-shared/services/toast.service';
import { CollectionPointService } from '../../services/collectionpoint.service';
import { CollectionPointListDeactivateCollectionPointsInput } from '../../models/collectionpoint-list-deactivate-collectionpoints-input';
import { CollectionPointListOutputCollectionPoint } from '@veritas-shared/models/collectionpoint-list-output';
import { CollectionPointGridComponent } from '@veritas-shared/components/collectionpoint-grid/collectionpoint-grid.component';
import { CollectionPointMapComponent } from '@veritas-shared/components/collectionpoint-map/collectionpoint-map.component';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { SecurityService } from '@veritas-shared/services/security.service';
import { BeanKendoDialogService, WindowContainerDimension, WindowVisualizerDirective } from 'bean-kendo-dialog';
import { CollectionPointImportDialogComponent, CollectionPointImportDialogInput } from '../../components/collection-point-import-dialog/collection-point-import-dialog.component';
import { NavigationService } from '@veritas-shared/services/navigation.service';

@Component({
  selector: 'app-collectionpoints',
  templateUrl: './collectionpoints.component.html',
  styleUrls: ['./collectionpoints.component.scss']
})
export class CollectionPointsComponent implements OnInit {

  @ViewChild(CollectionPointGridComponent, { read: CollectionPointGridComponent }) collectionPointGrid: CollectionPointGridComponent;
  @ViewChild(CollectionPointMapComponent, { read: CollectionPointMapComponent }) collectionPointMap: CollectionPointMapComponent;
  @ViewChild(WindowVisualizerDirective, { static: true }) public windowVisualizer: WindowVisualizerDirective;

  public filteredCollectionPoints: CollectionPointListOutputCollectionPoint[];
  private _tempFilteredCollectionPoints: CollectionPointListOutputCollectionPoint[]; // used for temporary store devices when show only selected is checked

  public collectionPoints: CollectionPointListOutputCollectionPoint[];
  public selectedCollectionPoints: CollectionPointListOutputCollectionPoint[] = [];

  public actions: Action[];
  public toolbarActions: Action[] = [];

  public isLabelsOpen: boolean = true;
  public isLabelsDialogOpen: boolean = false;
  public isShowOnlySelected: boolean = false;

  // upload section
  public isImportDialogOpen: boolean = false;
  public isMergeEnabled: boolean = false;
  public uploadSaveUrl: string = '';
  public importResult: ImportResultOutput;
  public uploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.csv']
  };

  constructor(
    private collectionpointService: CollectionPointService,
    private toastService: ToastService,
    private library: FaIconLibrary,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    public constantHelper: ConstantHelper,
    private securityService: SecurityService,
    private dialogService: BeanKendoDialogService,
    private navigationService: NavigationService
  ) {

    this.library.addIcons(faAngleDoubleLeft, faAngleDoubleRight);

    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.deactivateBulk = this.deactivateBulk.bind(this);
    this.openImportDialog = this.openImportDialog.bind(this);
    this.closeImportDialog = this.closeImportDialog.bind(this);

    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_COLLECTION_POINT_WRITE).subscribe(result => {
      if (result) {

        // Write
        this.toolbarActions = [
          new Action('add', this.constantHelper.LBL_ACTION_COMMON_ADD, 'plus', this.add),
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DEACTIVATE, 'trash-alt', this.deactivateBulk, true, true),
          new Action('import', this.constantHelper.LBL_ACTION_COMMON_IMPORT, 'upload', this.openImportDialog)
        ];

        this.actions = [
          new Action('edit', this.constantHelper.LBL_ACTION_COMMON_EDIT, 'pencil-alt', this.edit),
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DEACTIVATE, 'trash-alt', this.deactivateBulk),
        ];
      }
      else {
        //
        this.toolbarActions = [
          new Action('import', this.constantHelper.LBL_ACTION_COMMON_IMPORT, 'upload', this.openImportDialog)
        ];
      }
    });

    this.uploadSaveUrl = this.collectionpointService.getUploadUrl(ImportBehavior.Insert);
    this.importResult = new ImportResultOutput(0, 0, []);
  }

  ngOnInit() {
    this.loadData();
  }

  public toggleLabels() {
    this.isLabelsOpen = !this.isLabelsOpen;
  }

  public onTabChange(event: SelectEvent) {
    if (event.index == 0) {
      this.collectionPointGrid.refreshSelection();
    }
    if (event.index == 1) {
      window.setTimeout(() => this.collectionPointMap.centerAndZoomOnMarkers(), 200);
    }
  }

  public onSearchChanged() {
    this.selectedCollectionPoints = [];
    this.isShowOnlySelected = false;
    this.collectionPointGrid.goToPage(0);
  }

  public toggleShowOnlySelected() {
    if (this.isShowOnlySelected) {
      this._tempFilteredCollectionPoints = this.filteredCollectionPoints;
      this.filteredCollectionPoints = this.selectedCollectionPoints;
      this.collectionPointGrid.goToPage(0);
    }
    else {
      this.filteredCollectionPoints = this._tempFilteredCollectionPoints;
      this._tempFilteredCollectionPoints = [];
    }
  }

  public add() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  public edit(collectionPoint: CollectionPointListOutputCollectionPoint) {
    this.router.navigate([collectionPoint.id], { queryParams: { edit: true }, relativeTo: this.route });
  }

  public deactivateBulk() {
    if (this.selectedCollectionPoints.length > 0) {
      var toDeactivate = new CollectionPointListDeactivateCollectionPointsInput(this.selectedCollectionPoints.map(d => d.id));
      this.collectionpointService.deactivateCollectionPoints(toDeactivate).subscribe(result => {
        this.toastService.showSuccess(
          this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DELETEDUMPSTERS_CONFIRM_MESSAGE, { '0': this.selectedCollectionPoints.length })
        );
        // Reload devices
        this.collectionpointService.getCollectionPoints().subscribe(result => {
          this.collectionPoints = result.collectionPoints;
          this.filteredCollectionPoints = result.collectionPoints;
        });
      });
    }
  }

  /*
  * Upload
  **/
  public openImportDialog() {
    // reset result
    this.importResult = new ImportResultOutput(0, 0, []);
    this.isMergeEnabled = false;
    this.isImportDialogOpen = true;
    //this.dialogService.openDialog(
    //  this.translate.instant("page.CollectionPoints.Dialog.Import.Title"),
    //  new CollectionPointImportDialogInput(),
    //  CollectionPointImportDialogComponent,
    //  this.windowVisualizer,
    //  () => { },
    //  (res: boolean) => {
    //    this.loadData();

    //    return of(true); 
    //  },
    //  true,
    //  WindowContainerDimension.Medium,
    //);
  }

  public closeImportDialog() {
    this.isImportDialogOpen = false;
  }

  public toggleMergeEnabled() {
    this.uploadSaveUrl = this.collectionpointService.getUploadUrl(this.isMergeEnabled ? ImportBehavior.Merge : ImportBehavior.Insert);
  }

  public successEventHandler(e: UploadSuccessEvent) {
    this.importResult = <ImportResultOutput>e.response.body;
    if (this.importResult.validRows > 0) {
      this.loadData();
    }
  }

  public errorEventHandler(e: UploadErrorEvent) {
    this.toastService.showError(e.response.statusText);
  }

  public goBack() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private loadData() {
    this.collectionpointService.getCollectionPoints().subscribe(result => {
      this.collectionPoints = result.collectionPoints;
      this.filteredCollectionPoints = result.collectionPoints;
    });
  }

}
