import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Marker, Popup, icon, marker, popup } from 'leaflet';

@Component({
  selector: 'app-leaflet-map-search',
  templateUrl: './leaflet-map-search.component.html',
  styleUrls: ['./leaflet-map-search.component.scss']
})
export class LeafletMapSearchComponent {
  @ViewChild('searchBox', { static: true }) searchBox: ElementRef;

  @Output() centerAndZoomMarker: EventEmitter<any[]>;
  @Output() removeMarker: EventEmitter<Marker>;
  @Output() addMarker: EventEmitter<Marker>;

  private _searchMarker: Marker;

  constructor(
    private constantHelper: ConstantHelper,
  ) {
    this.centerAndZoomMarker = new EventEmitter();
    this.removeMarker = new EventEmitter();
    this.addMarker = new EventEmitter();
  }

  public getSearchLocation = () => {
    const searchBox = new google.maps.places.SearchBox(this.searchBox.nativeElement)

    searchBox.addListener(
      'places_changed', () => this.getSearchedLocation(searchBox.getPlaces())
    )
  }

  public getSearchedLocation = (places: google.maps.places.PlaceResult[]) => {
    const lat = places[0].geometry.location.lat();
    const lng = places[0].geometry.location.lng();

    const searchPopup: Popup = popup({
      closeButton: true,
      autoClose: true,
    }).setContent(() => `
      <div class='d-flex flex-column'>
        <span><strong>${places[0].name}</strong></span>
        <span>${places[0].formatted_address}</span>
      </div>
    `);

    if(this._searchMarker){ this.removeMarker.emit(this._searchMarker); }

    this._searchMarker = marker([lat, lng], { 
      icon: icon({
        iconUrl: `./assets/img/${this.constantHelper.IMG_PIN_DOT}`,
        popupAnchor: [0, -10],
        iconSize: [40, 40],
      })
    });

    this.addMarker.emit(this._searchMarker.bindPopup(searchPopup));
    this.centerAndZoomMarker.emit([[lat, lng]]);
  }

}
