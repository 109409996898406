
<div class="form-height">
  <div class="height-100">
    <form *ngIf="formHistory" [formGroup]="formHistory" class="height-100" novalidate>

      <label class="col-form-label">Note</label>
      <div class="form-group">
        <textarea class="form-control" formControlName="note"  rows="7"></textarea>
      </div>

      <div class="sub-title">Allegati</div>
      <div class="attachment-grid">
        <app-custom-grid [columns]="attachmentColumns"
                         [showActions]="true"
                         [actions]="attachmentActions"
                         [toolbarActions]="attachmentToolbarActions"
                         [pageable]="true"
                         [pageSizes]="true"
                         [filterable]="false"
                         [items]="attachments">
        </app-custom-grid>
        <input id="attachmentUploader" hidden multiple type="file" />
      </div>

    </form>
  </div>
</div>

<div class="dialog-footer">
  <button type="button" class="btn btn-outline-secondary mt-2" (click)="backButtonClick()">
    <fa-icon icon="chevron-left"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_CANCEL | translate }}
  </button>
  <button type="button" class="btn btn-primary float-right mt-2" (click)="saveButtonClick()" *ngIf="!data.isView">
    <fa-icon icon="save"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_SAVE | translate }}
  </button>
</div>
