
<app-custom-grid [columns]="columns"
                 [showActions]="true"
                 [disableActions]="readonlyData"
                 
                 [toolbarActions]="toolbarActions"
                 [selectable]="selectableSettings"
                 [selectableCheckboxSettings]="selectableCheckboxSettings"
                 [showSelectAll]="true"
                 [selectedKeys]="selectedDumpstersId"
                 [selectCallback]="onSelectionChange"
                 [pageable]="true"
                 [pageSizes]="true"
                 [filterable]="true"
                 [defaultFilter]="defaultFilter"
                 [items]="items">
                 
</app-custom-grid>

<ng-template #templateCode let-dataItem>
  <app-field-dumpster-code
    [dumpster]="dataItem"
    (emitClick)="view($event)"
  ></app-field-dumpster-code>
</ng-template>

<ng-template #labelColumn let-dataItem>
  <app-labels-badge [labels]="dataItem.labels"></app-labels-badge>
</ng-template>

<ng-template #filterTemplate let-dataItem>
  <app-filter-labels 
    [labels]="labels"
    (labelSelect)="getSelectedLabel($event)"
  ></app-filter-labels>
</ng-template>

<ng-template #collectionPointColumn let-dataItem>
  <app-info-text *ngIf="dataItem.collectionPoint" [text]="dataItem.collectionPoint.collectionPointCode" class="text-primary" tooltip="{{dataItem.collectionPoint.name}}"></app-info-text>
</ng-template>

<ng-template #columnWasteTypeTemplate
             let-dataItem>
  {{ dataItem.garbageType | wasteType:entities?.wasteTypes }}
</ng-template>


<ng-template #columnStateTemplate
             let-dataItem>
  {{ dataItem.state | deviceState:entities?.deviceStates }}
</ng-template>

<ng-template #fillLevelTemplate
             let-dataItem>
  {{dataItem?.bookingCount == null ? '-' : dataItem?.bookingCount}}/{{dataItem?.maxBookingCount == null ? '-' : dataItem?.maxBookingCount}} ({{dataItem?.bookingCount == null || !dataItem?.maxBookingCount ? '-' : (dataItem?.bookingCount/dataItem?.maxBookingCount)*100 | number: '1.0-0'}}%)
</ng-template>
