
<ng-container *ngIf="!isNew">
  <input #filter class="form-control" (keyup)='filterLabels(filter.value)' placeholder="{{ 'action.Common.Search' | translate}} {{ 'vocabulary.Labels' | translate}}" />
  <kendo-treeview [nodes]="filteredLabels"
                  textField="name"
                  (checkedChange)="onChecking($event)"
                  [isChecked]="isLabelChecked"
                  class="label-tree">
  </kendo-treeview>
  <div class="label-actions">
    <a (click)="onNew()">Crea nuova</a>
    <!--<a (click)="onEdit()">Gestisci etichette</a>-->
  </div>
</ng-container>

<ng-container *ngIf="isNew">
  <form [formGroup]="formLabel" novalidate class="new-label">
    <div class="form-group">
      <label>{{ 'page.LabelNew.Title' | translate }}</label>
      <input type="text" class="form-control" formControlName="name">
    </div>
    <div class="form-group">
      <label>{{ 'vocabulary.Label.Color' | translate }}</label>
      <div>
        <kendo-colorpicker #picker
                           [view]="'palette'"
                           [paletteSettings]="{palette: 'basic'}"
                           [format]="'hex'"
                           formControlName="color">
        </kendo-colorpicker>
      </div>
    </div>
  </form>
</ng-container>
