import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { DeactivatedComponent } from '../components/deactivated.component';
import { ModalService } from './modal.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UnsavedGuardService implements CanDeactivate<DeactivatedComponent> {

  constructor(private modalService: ModalService, private translate: TranslateService) {

  }

  canDeactivate(component: DeactivatedComponent): Observable<boolean> | Promise<boolean> | boolean {

    const can = component.canDeactivate();

    if (can) {
      return true;
    }
    else {
      if (confirm(this.translate.instant('common.Dialog.UnsavedChanges.ConfirmationMessage'))) {
        return true;
      } else {
        return false;
      }
      //this.modalService.showConfirm(
      //  this.translate.instant('common.Dialog.UnsavedChanges.ConfirmationMessage'),
      //  () => { return true; }, () => { return false; });
    }
  }
}
