<div>
  <span *ngIf="labels && labels.length > 0" class="badge badge-secondary mr-2" [style.background-color]="labels[0].color">{{labels[0].name}}</span>
  <span *ngIf="labels && labels.length > 1" kendoTooltip [tooltipTemplate]="tooltipTemplate">
    <span class="badge badge-secondary" title="">+{{labels.length-1}}</span>
  </span>
</div>

<ng-template #tooltipTemplate let-anchor>
 <div *ngFor="let l of labels | slice:1">{{l.name}}</div>
</ng-template>
