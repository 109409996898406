export interface IRelationIcon {
  icon: string;
  // definition: IconDefinition;
  svg: string;
}

export const relationIcon: IRelationIcon = {
  icon: 'fab fa-accessible-icon',
  // definition: faUniversalAccess,
  svg: 'accessible-icon.svg'
}
