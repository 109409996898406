import { Pipe, PipeTransform } from '@angular/core';
import { WasteType } from '../models/entities/waste-type';

@Pipe({
  name: 'wasteType'
})
export class WasteTypePipe implements PipeTransform {

  transform(value: string, args: WasteType[]): any {
    if (value == null || args == null) {
      return null;
    }

    var result = args.find(el => el.wasteType == value)
    if (result) {
      return result.description;
    } else {
      return null;
    }
  }
}
