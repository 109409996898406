<div id="main">
  <app-nav-bar></app-nav-bar>
  <app-nav-menu [(isMenuOpen)]="isMenuOpen" [guiVersion]="guiVersion"></app-nav-menu>
  <app-user-panel></app-user-panel>
  <div class="content" [ngClass]="{'expanded': !isMenuOpen}">
    <app-breadcrumb></app-breadcrumb>
    <router-outlet></router-outlet>
  </div>
</div>
<app-confirm></app-confirm>
<app-alert></app-alert>

<kendo-dialog *ngIf="isWaitVisible" [width]="100" class="wait">
  <div class="text-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</kendo-dialog>

<!--<bean-toast></bean-toast>-->
