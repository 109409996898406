import { Pipe, PipeTransform } from '@angular/core';
import { TicketState } from '@veritas-shared/models/entities/ticket-state';

@Pipe({
  name: 'state'
})
export class TicketStatePipe implements PipeTransform {

  transform(value: string, args: TicketState[]): any {
    if (value == null || args == null) {
      return null;
    }

    var result = args.find(el => el.state == value)
    if (result) {
      return result.description;
    } else {
      return null;
    }
  }
}
