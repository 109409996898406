import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-info-sequence',
  templateUrl: './info-sequence.component.html',
  styleUrls: ['./info-sequence.component.scss']
})
export class InfoSequenceComponent {

  @Input() public title: string;
  @Input() public items: any[];

  @Output() public onClick: EventEmitter<any>;

  constructor() {
    this.onClick = new EventEmitter();
  }

  public onInfoClick(item: any) {
    this.onClick.emit(item);
  }

}
