import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GroupService } from '../../../group/services/group.service';
import { ToastService } from '@veritas-shared/services/toast.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faInfoCircle, faSave, faUsers } from '@fortawesome/free-solid-svg-icons';
import { NewGroupInput } from '../../../group/models/new-group-input';
import { EditGroupInput } from '../../../group/models/edit-group-input';
import { DumpsterService } from '../../services/dumpster.service';
import { DeactivatedComponent } from '@veritas-shared/components/deactivated.component';
import { EditGroupAddDumpstersInput } from '../../../group/models/edit-group-add-dumpsters-input';
import { EditGroupRemoveDumpstersInput } from '../../../group/models/edit-group-remove-dumpsters-input';
import { TranslateService } from '@ngx-translate/core';
import { ViewMode } from '@veritas-shared/models/view-mode';
import { ViewModeHelper } from '@veritas-shared/helpers/view-mode-helper';
import { GroupType } from '../../../group/models/grouptype.enum';
import { DumpsterMapComponent } from '@veritas-shared/components/dumpster-map/dumpster-map.component';
import { Authorization, Group } from '../../../group/models/edit-group-output';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { Label } from '@veritas-shared/models/label';
import { SessionService } from '@veritas-shared/services/session.service';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { BeanKendoDialogService, WindowContainerDimension, WindowVisualizerDirective } from 'bean-kendo-dialog';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { DumpsterSelectDialogComponent, DumpsterSelectDialogInput } from '../../components/dumpster-select-dialog/dumpster-select-dialog.component';
import { BeanDialogDimension } from 'bean-kendo-dialog/lib/classes/bean-dialog-dimension.class';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { SecurityService } from '@veritas-shared/services/security.service';
import { NavigationService } from '@veritas-shared/services/navigation.service';
import { ISelectableCheckboxSettings } from '@veritas-shared/models/selectablecheckboxsettings.interface';
import { MapIconService } from '@veritas-dumpsters/services/map-icon.service';

@Component({
  selector: 'app-dumpstergroup-detail',
  templateUrl: './dumpstergroup-detail.component.html',
  styleUrls: ['./dumpstergroup-detail.component.scss']
})
export class DumpsterGroupDetailComponent extends DeactivatedComponent implements OnInit, OnDestroy {
  @ViewChild(DumpsterMapComponent, { read: DumpsterMapComponent }) dumpsterMap: DumpsterMapComponent;
  @ViewChild(WindowVisualizerDirective, { static: true }) public windowVisualizer: WindowVisualizerDirective;

  public lblCheckboxDisabled: string;
  public hasWritePermission: boolean;

  private groupId: number;

  public formGroup: FormGroup;
  public group: Group;

  public groupDumpsters: Dumpster[] = [];
  public groupAuthorizations: Authorization[] = [];

  private allDumpsters: Dumpster[] = [];
  private allLabels: Label[] = [];

  public selectedDumpsters: Dumpster[] = [];
  public selectableCheckboxSettings: ISelectableCheckboxSettings;

  public actions: Action[];
  public toolbarActions: Action[] = [];

  public viewMode: ViewMode;

  public ViewMode = ViewMode;

  public isDumpstersListReadonly: boolean = false;

  public groupTypeVisible: boolean = false;

  private querySubscription: Subscription;

  constructor(
    private groupService: GroupService,
    private dumpsterService: DumpsterService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private faLibrary: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper,
    private dialogService: BeanKendoDialogService,
    private securityService: SecurityService,
    private navigationService: NavigationService,
    private iconService: MapIconService,
  ) {
    super();

    this.onEdit = this.onEdit.bind(this);
    this.onView = this.onView.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.add = this.add.bind(this);
    this.delete = this.delete.bind(this);
    this.deleteBulk = this.deleteBulk.bind(this);

    this.faLibrary.addIcons(faSave, faChevronLeft, faInfoCircle, faUsers);

    this.lblCheckboxDisabled = this.translate.instant(constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DUMPSTERS_DISABLED_CHECKBOX);

    // Actions
    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_GROUP_WRITE).subscribe(res => {

      this.hasWritePermission = res;

      if (res) {
        this.toolbarActions = [
          new Action('add', this.constantHelper.LBL_ACTION_DUMPSTER_ADD_TOGROUP, 'plus', this.add),
          new Action('delete', this.constantHelper.LBL_ACTION_DUMPSTER_REMOVE_FROMGROUP, 'trash-alt', this.deleteBulk, true)
        ];

        //let that = this;
        //this.actions = [
        //  new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DELETE, 'trash-alt', this.delete, null, false, (dumpster) => that.group && !that.group.isGlobal),
        //];
      }
    })

    this.selectableCheckboxSettings = {
      context: this,
      isSelectable: (dataItem: Dumpster, ctx: DumpsterGroupDetailComponent, currentSelectedIds: number[]) => {
        return dataItem.groupCount > 1;
      },
      disabledTooltip: (dataItem: Dumpster, ctx: DumpsterGroupDetailComponent, currentSelectedIds: number[]) => {
        return ctx.lblCheckboxDisabled;
      }
    }

    // Params
    this.route.params.subscribe(params => {
      if (params) {
        this.groupId = +params.groupId;
      }
    });

    // Query Params
    this.querySubscription = combineLatest(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
      ),
      this.route.queryParams
    ).subscribe(res => {

      // Set ViewMode
      this.viewMode = ViewModeHelper.getViewMode(this.route, 'groupId');

      // Enalbe / Disable the form
      if (this.formGroup) {
        let newForm = this.setFormGroup();
        this.commonHelper.enableValidFormFields(this.formGroup, newForm);
      }

      this.isDumpstersListReadonly = (this.viewMode === ViewMode.View) || (this.viewMode === ViewMode.New) || (this.group && this.group.isGlobal);

      // Set PageTitle
      if (this.viewMode == ViewMode.View && this.group) {
        this.sessionService.setPageTitle(this.group.name);
      }
    });
  }

  canDeactivate(): boolean {
    return !this.formGroup.dirty;
  }

  ngOnInit() {
    this.formGroup = this.setFormGroup();

    switch (this.viewMode) {
      case ViewMode.Edit:
      case ViewMode.View:
        this.groupService.getEditGroup(this.groupId).subscribe(result => {

          this.isDumpstersListReadonly = (this.isDumpstersListReadonly || result.group.groupType == "CollectionPointGroup" || result.group.isGlobal);

          this.group = result.group;
          this.sessionService.setPageTitle(result.group.name);

          this.formGroup.patchValue(result.group);
          this.groupDumpsters = result.dumpsters;
          this.groupAuthorizations = result.authorizations;
          this.groupTypeVisible = true;
        });
        break;
      case ViewMode.New:
        this.groupService.getNewGroup().subscribe(result => {
          this.formGroup.patchValue(result.group);
        });
        break;
    }
  }

  ngOnDestroy() {
    if (this.querySubscription != null) {
      this.querySubscription.unsubscribe();
    }
  }

  private setFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      name: [{ value: '', disabled: this.viewMode != ViewMode.New }, Validators.required],
      description: [{ value: '', disabled: this.viewMode == ViewMode.View }],
      groupTypeDescription: [{ value: '', disabled: true }],
      mailingList: [{ value: '', disabled: this.viewMode == ViewMode.View }, [Validators.pattern(this.constantHelper.MAILING_LIST_VALIDATOR_REGEX)]]
    });
  }

  public onTabChange(index: number) {
    if (index == 1) {
      window.setTimeout(() => this.dumpsterMap.centerAndZoomOnMarkers(), 200);
    }
  }

  public onEdit() {
    this.router.navigate([], { queryParams: { edit: true }, relativeTo: this.route });
  }

  public onView() {
    this.router.navigate([], { relativeTo: this.route });
  }

  public onBack() {
    this.goBack();
  }

  public goBack() {
    this.navigationService.back('/dumpsters/groups');
  }

  public onCancel() {
    this.formGroup.reset(this.group);
  }

  public onDelete() {
    this.groupService.deleteGroup(this.groupId).subscribe(result => {
      this.toastService.showSuccess(
        this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DELETED_CONFIRM_MESSAGE, { '0': "1" })
      );
      this.goBack();
    });
  }

  public onSave() {

    if (this.formGroup.valid) {
      if (this.viewMode == ViewMode.Edit) {
        let groupInput = <EditGroupInput>this.formGroup.getRawValue();
        this.groupService.updateGroup(groupInput).subscribe(result => {
          this.formGroup.reset();
          this.goBack();
        });
      }
      else if (this.viewMode == ViewMode.New) {
        let groupInput = <NewGroupInput>this.formGroup.getRawValue();
        groupInput.groupType = GroupType.DumpsterGroup;
        this.groupService.addGroup(groupInput).subscribe(result => {
          this.formGroup.reset();
          this.goBack();
        });
      }
    }
  }

  public add() {
    let observable: Observable<any>;

    if (this.allDumpsters.length == 0) {
      observable = this.loadAllDumpsters();
    }
    else {
      this.setSelectableDumpstersToAdd();
      observable = of(this.allDumpsters);
    }

    observable.subscribe(res => {
      this.dialogService.openDialog(
        "Seleziona i dispositivi",//this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DIALOG_SEL_DUMPSTERS_TITLE),
        new DumpsterSelectDialogInput(this.allDumpsters, this.allLabels),
        DumpsterSelectDialogComponent,
        this.windowVisualizer,
        () => { },
        (res: Dumpster[]) => {

          // Add selected devices
          const addedDumpsterIds = res.map(d => d.id);
          const input = new EditGroupAddDumpstersInput(this.groupId, addedDumpsterIds);

          return this.groupService.addDumpsters(input).pipe(map(() => {
            this.toastService.showSuccess(
              this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_ADDDUMPSTERS_CONFIRM_MESSAGE, { '0': addedDumpsterIds.length })
            );
            // Reload group devices
            this.groupService.getDumpsters(this.groupId).subscribe(result => {
              this.groupDumpsters = result.dumpsters;
            });
          }));
        },
        true,
        WindowContainerDimension.Medium,
        {
          mediumHeight: 600,
          mediumWidth: 1000,
        } as BeanDialogDimension
      );
    });
  }

  public delete(dumpster: any) {
    var dumpstersToRemove = new EditGroupRemoveDumpstersInput(this.groupId, [dumpster.id]);
    this.groupService.removeDumpsters(dumpstersToRemove).subscribe(() => {
      this.toastService.showSuccess(
        this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DELETEDUMPSTERS_CONFIRM_MESSAGE, { '0': 1 })
      );

      // Reset
      this.selectedDumpsters = [];

      // Reload group devices
      this.groupService.getDumpsters(this.groupId).subscribe(result => {
        this.groupDumpsters = result.dumpsters;
      });
    });
  }

  public deleteBulk() {
    if (this.selectedDumpsters.length > 0) {
      var toRemove = new EditGroupRemoveDumpstersInput(this.groupId, this.selectedDumpsters.map(d => d.id));
      this.groupService.removeDumpsters(toRemove).subscribe(result => {
        this.toastService.showSuccess(
          this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DELETEDUMPSTERS_CONFIRM_MESSAGE, { '0': this.selectedDumpsters.length })
        );

        // Reset
        this.selectedDumpsters = [];

        // Reload group devices
        this.groupService.getDumpsters(this.groupId).subscribe(result => {
          this.groupDumpsters = result.dumpsters;
        });
      });
    }
  }

  private loadAllDumpsters() {
    let obs = this.dumpsterService.getDumpsters();
    obs.subscribe(result => {
      this.allDumpsters = result.dumpsters;
      this.allLabels = result.labels;

      this.setSelectableDumpstersToAdd();
    });

    return obs;
  }

  private setSelectableDumpstersToAdd() {
    // Remove group devices
    const groupDumpsterIds = this.groupDumpsters.map(d => d.id);
    this.allDumpsters = this.allDumpsters.filter(d => groupDumpsterIds.indexOf(d.id) == -1);
  }

  public goToAuthorizationDetail(auth: any) {

  }

  public getIconMap = (data: Dumpster) => this.iconService.getDetailIcon(data);
}
