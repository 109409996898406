import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBan, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@veritas-shared/services/toast.service';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { CollectionPointService } from '../../services/collectionpoint.service';
import { DumpsterListAssociateCollectionPointInput } from '@veritas-dumpsters/models/dumpster-list-associate-collectionpoint-input';
import { DumpsterMapComponent } from '@veritas-shared/components/dumpster-map/dumpster-map.component';
import { DumpsterGridComponent } from '@veritas-shared/components/dumpster-grid/dumpster-grid.component';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { Label } from '@veritas-shared/models/label';
import { CollectionPointListOutputCollectionPoint } from '@veritas-shared/models/collectionpoint-list-output';
import { DumpsterService } from '@veritas-dumpsters/services/dumpster.service';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { BeanKendoDialogService, WindowContainerDimension, WindowVisualizerDirective } from 'bean-kendo-dialog';
import { Observable, of } from 'rxjs';
import { CollectionPointSelectDialogComponent, CollectionPointSelectDialogInput } from '@veritas-shared/components/collection-point-select-dialog/collection-point-select-dialog.component';
import { BeanDialogDimension } from 'bean-kendo-dialog/lib/classes/bean-dialog-dimension.class';
import { map } from 'rxjs/operators';
import { SecurityService } from '@veritas-shared/services/security.service';
import { NavigationService } from '@veritas-shared/services/navigation.service';

@Component({
  selector: 'app-dumpsters-deassociated',
  templateUrl: './dumpsters-deassociated.component.html',
  styleUrls: ['./dumpsters-deassociated.component.scss']
})
export class DumpstersDeassociatedComponent implements OnInit {

  @ViewChild(DumpsterGridComponent, { read: DumpsterGridComponent }) dumpsterGrid: DumpsterGridComponent;
  @ViewChild(DumpsterMapComponent, { read: DumpsterMapComponent }) dumpsterMap: DumpsterMapComponent;
  @ViewChild(WindowVisualizerDirective, { static: true }) public windowVisualizer: WindowVisualizerDirective;

  public filteredDumpsters: Dumpster[];
  private _tempFilteredDumpsters: Dumpster[]; // used for temporary store devices when show only selected is checked
  public labels: Label[];

  public dumpsters: Dumpster[];
  public selectedDumpsters: Dumpster[] = [];

  private allCollectionPoints: CollectionPointListOutputCollectionPoint[] = [];

  public actions: Action[];
  public toolbarActions: Action[] = [];

  public isLabelsOpen: boolean = true;

  public isShowOnlySelected: boolean = false;

  constructor(
    private dumpsterService: DumpsterService,
    private toastService: ToastService,
    private library: FaIconLibrary,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private collectionPointService: CollectionPointService,
    public constantHelper: ConstantHelper,
    private dialogService: BeanKendoDialogService,
    private securityService: SecurityService,
    private navigationService: NavigationService
  ) {

    this.library.addIcons(faBan, faAngleDoubleLeft, faAngleDoubleRight);

    this.delete = this.delete.bind(this);
    this.associate = this.associate.bind(this);

    //
    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_COLLECTION_POINT_WRITE).subscribe(res => {
      if (res) {
        this.toolbarActions = [
          new Action('associate', this.constantHelper.LBL_ACTION_DUMPSTER_ADD_TO_COLLPOINT, 'plus', this.associate, true),
          //new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DELETE, 'trash-alt', this.delete, true),
        ];

        this.actions = [
          new Action('associate', this.constantHelper.LBL_ACTION_DUMPSTER_ADD_TO_COLLPOINT, 'plus', this.associate),
          //new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DELETE, 'trash-alt', this.delete),
        ];
      }
    });
  }

  ngOnInit() {
    this.loadData();
  }

  public onTabChange(event: SelectEvent) {
    if (event.index === 0) {
      this.dumpsterGrid.refreshSelection();
    }
    if (event.index === 1) {
      window.setTimeout(() => this.dumpsterMap.centerAndZoomOnMarkers(), 200);
    }
  }

  public onSearchChanged() {
    this.selectedDumpsters = [];
    this.isShowOnlySelected = false;
  }

  public toggleShowOnlySelected() {
    if (this.isShowOnlySelected) {
      this._tempFilteredDumpsters = this.filteredDumpsters;
      this.filteredDumpsters = this.selectedDumpsters;
      this.dumpsterGrid.goToPage(0);
    }
    else {
      this.filteredDumpsters = this._tempFilteredDumpsters;
      this._tempFilteredDumpsters = [];
    }
  }

  public delete() {
    // todo ????
  }

  public associate() {
    let observable: Observable<any>;

    if (this.allCollectionPoints.length == 0) {
      observable = this.loadAllCollectionPoints();
    }
    else {
      observable = of(this.allCollectionPoints);
    }

    observable.subscribe(res => {
      this.dialogService.openDialog(
        this.translate.instant(this.constantHelper.LBL_PAGE_DUMPSTERDETAIL_DIALOG_COLLECTION_POINT_TITLE),
        new CollectionPointSelectDialogInput(this.allCollectionPoints, 'single'),
        CollectionPointSelectDialogComponent,
        this.windowVisualizer,
        () => { },
        (res: CollectionPointListOutputCollectionPoint[]) => {

          const associatedCollectionPointId = res.map(d => d.id);
          const selectedDumpstersIds = this.selectedDumpsters.map(d => d.id);
          const input = new DumpsterListAssociateCollectionPointInput(selectedDumpstersIds, associatedCollectionPointId[0]);
          return this.dumpsterService.associateCollectionPoint(input).pipe(map((result) => {
            this.selectedDumpsters = [];
            this.toastService.showSuccess(
              this.translate.instant(this.constantHelper.LBL_PAGE_DEASSOCIATEDDUMPSTERS_DIALOG_ASSOCIATE_COLLPOINT_CONFIRM_MESSAGE, { '0': associatedCollectionPointId.length })
            );
            // Reload deassociated devices
            this.dumpsterService.getDeassociatedDumpsters().subscribe(result => {
              this.dumpsters = result.dumpsters;
              this.filteredDumpsters = result.dumpsters;
            });
          }));
        },
        true,
        WindowContainerDimension.Medium,
        {
          mediumHeight: 600,
          mediumWidth: 1000,
        } as BeanDialogDimension
      );
    });
  }

  public goBack() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private loadData() {
    this.dumpsterService.getDeassociatedDumpsters().subscribe(result => {
      this.dumpsters = result.dumpsters;
      this.labels = result.labels;
      this.filteredDumpsters = result.dumpsters;
    });
  }

  private loadAllCollectionPoints() {
    let obs = this.collectionPointService.getCollectionPoints();

    obs.subscribe(result => {
      this.allCollectionPoints = result.collectionPoints;
    });

    return obs;
  }
}
