import { Component, OnInit, Output, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { TicketService } from '../../services/ticket.service';
import { SecurityService } from '@veritas-shared/services/security.service';
import { Action } from '@veritas-shared/models/action';
import { TicketBodyOutput, TicketListRequestInput } from '../../models/ticket-list-output';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, toDataSourceRequest } from '@progress/kendo-data-query';
import { BeanKendoDialogService, WindowContainerDimension, WindowVisualizerDirective } from 'bean-kendo-dialog';
import { TicketCloseInput } from '../../models/ticket-close-input';
import { TicketCloseDialogComponent, TicketCloseDialogInput } from '../../components/ticket-close-dialog/ticket-close-dialog.component';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { EntitySearchInput } from '@veritas-shared/models/entities/entity-search-input';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { EntityService } from '@veritas-shared/services/entity.service';
import { EntityListOutput } from '@veritas-shared/models/entities/entity-list-output';
import { DumpsterService } from '@veritas-dumpsters/services/dumpster.service';
import { Dumpster } from '@veritas-shared/models/dumpster';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  @ViewChild(WindowVisualizerDirective, { static: true }) 
  public windowVisualizer: WindowVisualizerDirective;

  @Input() public disableActions: boolean = false;
  @Input() public selectedKeys: number[];

  public selectedPage: number;

  public filteredTickets: TicketBodyOutput[];
  private _tempFilteredTickets: TicketBodyOutput[]; // used for temporary store devices when show only selected is checked

  public tickets: TicketBodyOutput[];
  public selectedTickets: TicketBodyOutput[] = [];

  public actions: Action[];
  public toolbarActions: Action[] = [];

  public isShowOnlySelected: boolean = false;
  public isCollapsed: boolean = false;

  private searchParams: TicketListRequestInput;
  public state: State;

  public dumpsters: Dumpster[];

  @Output() entities: EntityListOutput;

  constructor(
    private ticketService: TicketService,
    private entityService: EntityService,
    private dialogService: BeanKendoDialogService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private securityService: SecurityService,
    public constantHelper: ConstantHelper,
    private library: FaIconLibrary,
    private dumpsterService: DumpsterService
  ) {

    if (this.selectedKeys == undefined) {
      this.selectedKeys = [];
    }

    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.close = this.close.bind(this);

    // Actions & Permissions
    this.actions = [
      new Action('view', this.constantHelper.LBL_ACTION_COMMON_VIEW, 'eye', this.edit),
    ];

    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_TICKET_WRITE).subscribe(result => {
      if (result) {
        this.toolbarActions.splice(0, 0, new Action('add', this.constantHelper.LBL_ACTION_COMMON_ADD, 'plus', this.add));
        this.actions.splice(1, 0, new Action('close', this.constantHelper.LBL_ACTION_TICKET_CLOSE, 'times', this.close));
      }
    });

    this.library.addIcons(faList);
  }
  
  ngOnInit(): void {
    let input = {
      openingReason: true,
      closureReason: true,
      ticketState: true,
      wasteType: true
    } as EntitySearchInput;
    this.entityService.getEntities(input).subscribe(res => {
      this.entities = res;
    });

    this.dumpsterService.getDumpsters().subscribe(result => {
      this.dumpsters = result.dumpsters;
      // this.labels = result.labels; // al momento non serve
    })
  }

  public onSearchChanged = (searchParams: TicketListRequestInput) => {
    this.selectedTickets = [];
    this.isShowOnlySelected = false;

    this.selectedPage = 0;

    // Input
    this.searchParams = searchParams;
    this.searchParams.pageNumber = 0;
    this.searchParams.pageSize = this.state ? this.state.take : 50;

    this.loadData(false);
  }

  public onCollapseChange(collapsed: boolean) {
    this.isCollapsed = collapsed;
  }

  public toggleShowOnlySelected() {
    if (this.isShowOnlySelected) {
      this._tempFilteredTickets = this.filteredTickets;
      this.filteredTickets = this.selectedTickets;
      this.selectedPage = 0;
    }
    else {
      this.filteredTickets = this._tempFilteredTickets;
      this._tempFilteredTickets = [];
    }
  }

  public loadDataByPagination(state: DataStateChangeEvent) {
    this.state = state;

    let newState = toDataSourceRequest(state);

    this.searchParams.pageNumber = (newState.page - 1);
    this.searchParams.pageSize = newState.pageSize;
    this.loadData(false);
  }

  public loadData(preserveFilter?: boolean) {
    this.ticketService.getTickets(this.searchParams).subscribe(result => {
      // Integrate additional fields
      for (let x of result.tickets) {
        x.vwDevice = x.metadata["OpeningDeviceId"] || x.metadata["ClosingDeviceId"];
        x.vwBin = x.metadata["OpeningBinTag"] || x.metadata["ClosingBinTag"];
        x.vwUser = x.metadata["Username"] || "Automatico";
      }

      this.tickets = result.tickets;

      //
      if (preserveFilter) {
        let newFilteredTicket = [];
        this.filteredTickets.forEach(fd => {
          newFilteredTicket.push(result.tickets.find(d => d.ticketId == fd.ticketId));
        });
        this.filteredTickets = newFilteredTicket;
        let newSelectedTicket = [];
        this.selectedTickets.forEach(sd => {
          newSelectedTicket.push(result.tickets.find(d => d.ticketId == sd.ticketId));
        });
        this.selectedTickets = newSelectedTicket;
      }
      else {
        this.filteredTickets = result.tickets;
        this.selectedTickets = [];
      }
    });
  }

  private add() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  private edit(ticket: TicketBodyOutput) {
    this.router.navigate([ticket.ticketId, ticket.entityId], { relativeTo: this.route });
  }

  private close(ticket: TicketBodyOutput) {
    this.entityService.getEntities({ closureReason: true } as EntitySearchInput).subscribe(res => {
      this.dialogService.openDialog(
        this.translate.instant(this.constantHelper.LBL_PAGE_TICKETS_CLOSE_TITLE),
        new TicketCloseDialogInput(res.closureReasons),
        TicketCloseDialogComponent,
        this.windowVisualizer,
        () => { },
        (res: TicketCloseInput) => {
          res.entityId = ticket.entityId;
          res.ticketId = ticket.ticketId;
          res.entityType = ticket.entityType;

          return this.ticketService.closeTicket(res).pipe(map(() => this.loadData()));
        },
        true,
        WindowContainerDimension.Small,
      );
    });   

  }

  public onActionClick(action: Action, dataItem?: any): void {
    if (action.applyOnSelected) {
      action.callback(this.selectedKeys);
    }
    else {
      action.callback(dataItem);
    }
  }
}