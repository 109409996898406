<h1 class="page-title">
  <fa-icon icon="user-friends"></fa-icon>{{ 'page.CommandEvents.Title' | translate }}
</h1>

<div class="page-content">
  <div class="mt-10">
    <app-group-box [pageTitle]="'Elenco Ultimi Comandi'">

      <div class="grid-container standard-padding">
        <app-custom-grid [columns]="columns" [items]="gridData" [filterable]="true" [showActions]="true" [actions]="actions">
        </app-custom-grid>
      </div>

    </app-group-box>
  </div>
</div>

<ng-template #statusColumn let-dataItem>
  <div>
    <span class="badge badge-secondary mr-2" style="background-color: rgb(34, 177, 76);">
      {{dataItem.okCount}}
    </span>
    <span class="badge badge-secondary mr-2" style="background-color: rgb(237, 28, 36);">
      {{dataItem.errorCount}}
    </span>
    <span class="badge badge-secondary" style="background-color: rgb(112, 146, 190);">
      {{dataItem.unknownCount}}
    </span>
  </div>
</ng-template>
