import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataStateChangeEvent, SelectableMode, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { CustomGridComponent } from '@veritas-shared/components/custom-grid/custom-grid.component';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { TicketBodyOutput } from '../../models/ticket-list-output';
import { EntityListOutput } from '@veritas-shared/models/entities/entity-list-output';

@Component({
  selector: 'app-ticket-grid',
  templateUrl: './ticket-grid.component.html',
  styleUrls: ['./ticket-grid.component.scss']
})
export class TicketGridComponent implements OnInit, AfterViewInit {
  @ViewChild(CustomGridComponent) grid: CustomGridComponent;
  @ViewChild('columnAccessTemplate', { static: true }) columnAccessTemplate: TemplateRef<any>;
  @ViewChild('columnStateTemplate', { static: true }) columnStateTemplate: TemplateRef<any>;

  public selectedTicketsId: string[];
  public columns: GridColumn[] = [];
  public selectableSettings: SelectableSettings;
  public baseUrl = window.location.origin;
  public get selectedTickets(): TicketBodyOutput[] {
    return this._selectedTickets;
  }

  private _selectedPage: number;
  private _selectedTickets: TicketBodyOutput[];

  @Input() public items: TicketBodyOutput[];
  @Input() public actions: Action[];
  @Input() public toolbarActions: Action[] = [];
  @Input() public readonly: boolean = false;
  @Input() public selectableMode: SelectableMode = 'multiple';
  @Input() public defaultFilter: CompositeFilterDescriptor;
  @Input() public entities: EntityListOutput;
  @Input() public set selectedPage(page: number) { 
    if(!this.grid) {
      this._selectedPage = page;
      return;
    }
    this.goToPage(page);
  }
  @Input() public set selectedTickets(val: TicketBodyOutput[]) {
    this._selectedTickets = val;
    if (this._selectedTickets)
      this.selectedTicketsId = this._selectedTickets.map(d => d.ticketId);
    else
      this.selectedTicketsId = [];
  };

  @Output() public selectedTicketsChange = new EventEmitter();
  @Output() public visibleRowsCountChange = new EventEmitter<any>();
  @Output() public stateChange = new EventEmitter<any>();

  constructor(private router: Router, public constantHelper: ConstantHelper) {

    this.view = this.view.bind(this);

    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
  }

  ngAfterViewInit(): void {
    if(this._selectedPage === null) return;
    this.goToPage(this._selectedPage);
  }

  ngOnInit() {
    this.columns = [
      {
        field: 'ticketId',
        title: this.constantHelper.LBL_VOCABULARY_TICKET_ID,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'link',
        template: this.columnAccessTemplate
      },
      {
        field: 'vwDevice',
        title: this.constantHelper.LBL_VOCABULARY_TICKET_CODE,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'text',
      },
      {
        field: 'vwBin',
        title: this.constantHelper.LBL_VOCABULARY_TICKET_BIN_ID,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'text',
      },
      {
        field: 'metadata.Address',
        title: this.constantHelper.LBL_VOCABULARY_TICKET_ADDRESS,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'text',
      },
      {
        field: 'state',
        title: this.constantHelper.LBL_VOCABULARY_TICKET_STATE,
        sortable: true,
        resizable: true,
        filterable: true,
        template: this.columnStateTemplate
      },
      {
        field: 'openingTimestamp',
        title: this.constantHelper.LBL_VOCABULARY_TICKET_START_DATE,
        sortable: true,
        resizable: true,
        filterable: true,
        filter: 'date',
        display: 'date',
        dateType: this.constantHelper.DATE_TIME_FORMAT
      },
      {
        field: 'closureTimestamp',
        title: this.constantHelper.LBL_VOCABULARY_TICKET_END_DATE,
        sortable: true,
        resizable: true,
        filterable: true,
        filter: 'date',
        display: 'date',
        dateType: this.constantHelper.DATE_TIME_FORMAT
      },
      {
        field: 'vwUser',
        title: this.constantHelper.LBL_VOCABULARY_TICKET_OWNER,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'text',
      },
    ];


    this.selectableSettings = {
      enabled: true,
      mode: this.selectableMode
    };
  }

  public view(ticket: TicketBodyOutput) {
    this.router.navigate(['tickets', 'list', ticket.ticketId, ticket.entityId]);
  }

  public refreshSelection() {
    if (this._selectedTickets)
      this.selectedTicketsId = this._selectedTickets.map(d => d.ticketId);
    else
      this.selectedTicketsId = [];
  }

  public onSelectionChange(e: SelectionEvent) {
    let selected = Object.assign([], this._selectedTickets);
    if (e.selectedRows.length > 0) {
      e.selectedRows.forEach(r => selected.push(r.dataItem));
    }
    if (e.deselectedRows.length > 0) {
      e.deselectedRows.forEach(r => {
        let i = selected.findIndex(d => d.id == r.dataItem.id);
        if (i > -1) {
          selected.splice(i, 1);
        }
      });
    }
    this._selectedTickets = selected;
    this.selectedTicketsChange.emit(this._selectedTickets);
  }

  public onStateChange = (e: DataStateChangeEvent) => this.stateChange.emit(e);
  public goToPage = (page: number) => this.grid.goToPage(page);
}
