import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastService } from '@veritas-shared/services/toast.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faInfoCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { DeactivatedComponent } from '@veritas-shared/components/deactivated.component';
import { TranslateService } from '@ngx-translate/core';
import { ViewMode } from '@veritas-shared/models/view-mode';
import { ViewModeHelper } from '@veritas-shared/helpers/view-mode-helper';
import { EditGroupInput } from '../../../group/models/edit-group-input';
import { NewGroupInput } from '../../../group/models/new-group-input';
import { CollectionPointService } from '../../../collectionpoints/services/collectionpoint.service';
import { Authorization } from '../../../administration/models/new-authorization-output';
import { EditCollectionPointGroupAddCollectionPointsInput } from '../../models/edit-collectionpointgroup-add-collectionpoints-input';
import { EditCollectionPointGroupRemoveCollectionPointsInput } from '../../models/edit-collectionpointgroup-remove-collectionpoints-input';
import { GroupService } from '../../../group/services/group.service';
import { EditCollectionPointGroupOutputCollectionPoint, Group } from '../../models/edit-collectionpointgroup-output';
import { SessionService } from '../../..//shared/services/session.service';
import { CollectionPointListOutputCollectionPoint } from '@veritas-shared/models/collectionpoint-list-output';
import { GroupType } from '../../../group/models/grouptype.enum';
import { Action } from '@veritas-shared/models/action';
import { BeanKendoDialogService, WindowContainerDimension, WindowVisualizerDirective } from 'bean-kendo-dialog';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { CollectionPointSelectDialogComponent, CollectionPointSelectDialogInput } from '@veritas-shared/components/collection-point-select-dialog/collection-point-select-dialog.component';
import { BeanDialogDimension } from 'bean-kendo-dialog/lib/classes/bean-dialog-dimension.class';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { CollectionPointMapComponent } from '@veritas-shared/components/collectionpoint-map/collectionpoint-map.component';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { SecurityService } from '@veritas-shared/services/security.service';
import { NavigationService } from '@veritas-shared/services/navigation.service';

@Component({
  selector: 'app-collectionpointgroup-detail',
  templateUrl: './collectionpointgroup-detail.component.html',
  styleUrls: ['./collectionpointgroup-detail.component.scss']
})
export class CollectionPointGroupDetailComponent extends DeactivatedComponent implements OnInit, OnDestroy {

  @ViewChild(WindowVisualizerDirective, { static: true }) public windowVisualizer: WindowVisualizerDirective;
  @ViewChild(CollectionPointMapComponent, { read: CollectionPointMapComponent }) collectionPointMap: CollectionPointMapComponent;

  public hasWritePermission: boolean;

  public formGroup: FormGroup;

  public groupCollectionPoints: EditCollectionPointGroupOutputCollectionPoint[] = [];
  public groupAuthorizations: Authorization[] = [];

  private allCollectionPoints: CollectionPointListOutputCollectionPoint[] = [];
  public selectedCollectionPoints: CollectionPointListOutputCollectionPoint[] = [];

  public actions: Action[];
  public toolbarActions: Action[] = [];

  public viewMode: ViewMode;

  public ViewMode = ViewMode;

  public isCollectionPointsListReadonly: boolean = false;

  private groupId: number;
  public group: Group;
  public groupTypeVisible: boolean = false;

  private querySubscription: Subscription;

  constructor(
    private groupService: GroupService,
    private collectionPointService: CollectionPointService,
    private sessionService: SessionService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private translate: TranslateService,
    private securityService: SecurityService,
    private route: ActivatedRoute,
    private router: Router,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper,
    private dialogService: BeanKendoDialogService,
    private navigationService: NavigationService
  ) {
    super();

    this.onEdit = this.onEdit.bind(this);
    this.onView = this.onView.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.add = this.add.bind(this);
    this.delete = this.delete.bind(this);
    this.deleteBulk = this.deleteBulk.bind(this);

    this.library.addIcons(faSave, faChevronLeft, faInfoCircle);

    // Actions
    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_DUMPSTER_WRITE).subscribe(res => {

      this.hasWritePermission = res;

      if (res) {
        this.toolbarActions = [
          new Action('add', this.constantHelper.LBL_ACTION_COMMON_ADD, 'plus', this.add),
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DELETE, 'trash-alt', this.deleteBulk, true)
        ];

        this.actions = [
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DELETE, 'trash-alt', this.delete),
        ];
      }
    })

    // Params
    this.route.params.subscribe(params => {
      if (params) {
        this.groupId = +params.groupId;
      }
    });

    // Query Params
    this.querySubscription = combineLatest(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
      ),
      this.route.queryParams
    ).subscribe(res => {

      // Set ViewMode
      this.viewMode = ViewModeHelper.getViewMode(this.route, 'groupId');
      this.isCollectionPointsListReadonly = (this.viewMode === ViewMode.View) || (this.viewMode === ViewMode.New) || (this.group && this.group.isGlobal);

      // Enalbe / Disable the form
      if (this.formGroup) {
        let newForm = this.setFormGroup();
        this.commonHelper.enableValidFormFields(this.formGroup, newForm);
      }

      // Set PageTitle
      if (this.viewMode == ViewMode.View && this.group) {
        this.sessionService.setPageTitle(this.group.name);
      }
    });
  }

  canDeactivate(): boolean {
    return !this.formGroup.dirty;
  }

  ngOnInit() {

    this.formGroup = this.setFormGroup();

    switch (this.viewMode) {
      case ViewMode.Edit:
      case ViewMode.View:
        this.groupService.getEditCollectionPointGroup(this.groupId).subscribe(result => {
          this.isCollectionPointsListReadonly = (this.isCollectionPointsListReadonly || result.group.isGlobal);

          this.group = result.group;
          this.sessionService.setPageTitle(result.group.name);
          this.formGroup.patchValue(result.group);
          this.groupCollectionPoints = result.collectionPoints;
          this.groupAuthorizations = result.authorizations;
          this.groupTypeVisible = true;
        });
        break;
      case ViewMode.New:
        this.groupService.getNewGroup().subscribe(result => {
          this.formGroup.patchValue(result.group);
        });
        break;
    }
  }

  ngOnDestroy() {
    if (this.querySubscription != null) {
      this.querySubscription.unsubscribe();
    }
  }

  private setFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      name: [{ value: '', disabled: this.viewMode != ViewMode.New }, Validators.required],
      description: [{ value: '', disabled: this.viewMode == ViewMode.View }],
      groupTypeDescription: [{ value: '', disabled: true }]
    });
  }

  public onTabChange(index: number) {
    if (index == 1) {
      window.setTimeout(() => this.collectionPointMap.centerAndZoomOnMarkers(), 200);
    }
  }

  public onEdit() {
    this.router.navigate([], { queryParams: { edit: true }, relativeTo: this.route });
  }

  public onView() {
    this.router.navigate([], { relativeTo: this.route });
  }

  public onBack() {
    this.goBack();
  }

  public goBack() {
    this.navigationService.back('/collectionpoints/groups');
  }

  public onCancel() {
    this.formGroup.reset(this.group);
  }

  public onDelete() {
    this.groupService.deleteGroup(this.groupId).subscribe(result => {
      this.toastService.showSuccess(
        this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DELETED_CONFIRM_MESSAGE, { '0': 1 })
      );
      this.goBack();
    });
  }

  public onSave() {

    if (this.formGroup.valid) {
      if (this.viewMode == ViewMode.Edit) {
        let groupInput = <EditGroupInput>this.formGroup.getRawValue();
        this.groupService.updateGroup(groupInput).subscribe(result => {
          this.formGroup.reset();
          this.goBack();
        });
      }
      else if (this.viewMode == ViewMode.New) {
        let groupInput = <NewGroupInput>this.formGroup.getRawValue();
        groupInput.groupType = GroupType.CollectionPointGroup;
        this.groupService.addGroup(groupInput).subscribe(result => {
          this.formGroup.reset();
          this.goBack();
        });
      }
    }
  }

  public add() {

    let observable: Observable<any>;

    if (this.allCollectionPoints.length == 0) {
      observable = this.loadAllCollectionPoints();
    }
    else {
      observable = of(this.allCollectionPoints);
    }

    observable.subscribe(res => {
      this.dialogService.openDialog(
        this.translate.instant(this.constantHelper.LBL_PAGE_DUMPSTERDETAIL_DIALOG_COLLECTION_POINT_TITLE),
        new CollectionPointSelectDialogInput(this.allCollectionPoints),
        CollectionPointSelectDialogComponent,
        this.windowVisualizer,
        () => { },
        (res: CollectionPointListOutputCollectionPoint[]) => {

          const addedCollectionPointsIds = res.map(d => d.id);
          const input = new EditCollectionPointGroupAddCollectionPointsInput(this.groupId, addedCollectionPointsIds);

          return this.groupService.addCollectionPoints(input).pipe(map((result) => {
            this.toastService.showSuccess(
              this.translate.instant('page.AuthorizationGroupDetail.Dialog.AddCollectionPoints.ConfirmationMessage', { '0': addedCollectionPointsIds.length })
            );
            // Reload group collection points
            this.groupService.getCollectionPoints(this.groupId).subscribe(result => {
              this.groupCollectionPoints = result.collectionPoints;
            });
          }));
        },
        true,
        WindowContainerDimension.Medium,
        {
          mediumHeight: 600,
          mediumWidth: 1000,
        } as BeanDialogDimension
      );
    });
  }

  public delete(collectionPoint: any) {
    var collectionPointsToRemove = new EditCollectionPointGroupRemoveCollectionPointsInput(this.groupId, [collectionPoint.id]);
    this.groupService.removeCollectionPoints(collectionPointsToRemove).subscribe(() => {
      this.toastService.showSuccess(
        this.translate.instant('page.AuthorizationGroupDetail.Dialog.DeleteCollectionPoints.ConfirmationMessage', { '0': 1 })
      );

      // Reload group collection points
      this.groupService.getCollectionPoints(this.groupId).subscribe(result => {
        this.groupCollectionPoints = result.collectionPoints;
      });
    });
  }

  public deleteBulk() {
    if (this.selectedCollectionPoints.length > 0) {
      var collectionPointsToRemove = new EditCollectionPointGroupRemoveCollectionPointsInput(this.groupId, this.selectedCollectionPoints.map(d => d.id));
      this.groupService.removeCollectionPoints(collectionPointsToRemove).subscribe(result => {
        this.toastService.showSuccess(
          this.translate.instant('page.AuthorizationGroupDetail.Dialog.DeleteCollectionPoints.ConfirmationMessage', { '0': this.selectedCollectionPoints.length })
        );
        // Reload group collection points
        this.groupService.getCollectionPoints(this.groupId).subscribe(result => {
          this.groupCollectionPoints = result.collectionPoints;
        });
      });
    }
  }

  private loadAllCollectionPoints() {
    let obs = this.collectionPointService.getCollectionPoints();
    obs.subscribe(result => {
      this.allCollectionPoints = result.collectionPoints;

      this.setSelectableCollectionPointsToAdd();
    });

    return obs;
  }

  private setSelectableCollectionPointsToAdd() {
    // Remove group collection points
    const groupCollectionPointIds = this.groupCollectionPoints.map(d => d.id);
    this.allCollectionPoints = this.allCollectionPoints.filter(d => groupCollectionPointIds.indexOf(d.id) == -1);
  }

  public goToAuthorizationDetail(auth: Authorization) {
    this.router.navigate(['administration', 'authorizations', auth.id]);
  }

}
