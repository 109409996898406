import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'elementHeight'
})
export class ElementHeightPipe implements PipeTransform {

  transform(element: HTMLElement, isOpen: boolean): any {

    // If element doesn't exist or the menu is closed -> exit
    if (element == null || !isOpen) {
      return null;
    }

    let height = 0;
    for (let i = 0; i < element.children.length; i++) {
      height += (element.children[i] as HTMLElement).offsetHeight;
    }

    return height;
  }
}
