import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectableMode, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { CustomGridComponent } from '@veritas-shared/components/custom-grid/custom-grid.component';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { ConstantHelper } from '../../helpers/constant.helper';
import { Action } from '../../models/action';
import { CollectionPointListOutputCollectionPoint } from '../../models/collectionpoint-list-output';

@Component({
  selector: 'app-collectionpoint-grid',
  templateUrl: './collectionpoint-grid.component.html',
  styleUrls: ['./collectionpoint-grid.component.scss']
})
export class CollectionPointGridComponent implements OnInit {
  
  @ViewChild(CustomGridComponent) grid: CustomGridComponent;

  @Input() public items: CollectionPointListOutputCollectionPoint[];
  @Input() public actions: Action[];
  @Input() public toolbarActions: Action[] = [];
  @Input() public set readonly(data: boolean) {
    this.readonlyData = data;
    if (this.selectableSettings) {
      this.selectableSettings.enabled = !data;
    }
  }
  public readonlyData: boolean = false;

  @Input() public selectableMode: SelectableMode = 'multiple';

  @Input() public set selectedCollectionPoints(val: CollectionPointListOutputCollectionPoint[]) {
    this._selectedCollectionPoints = val;
    if (this._selectedCollectionPoints)
      this.selectedCollectionPointsId = this._selectedCollectionPoints.map(d => d.id);
    else
      this.selectedCollectionPointsId = [];
  };

  @Output() public selectedCollectionPointsChange = new EventEmitter();

  public get selectedCollectionPoints(): CollectionPointListOutputCollectionPoint[] {
    return this._selectedCollectionPoints;
  }

  private _selectedCollectionPoints: CollectionPointListOutputCollectionPoint[];
  public selectedCollectionPointsId: number[];

  public columns: GridColumn[] = [];

  public selectableSettings: SelectableSettings;

  constructor(private router: Router, private route: ActivatedRoute, public constantHelper: ConstantHelper) {

    this.view = this.view.bind(this);

    this.onSelectionChange = this.onSelectionChange.bind(this);
  }

  ngOnInit() {

    // Set columns here because column template 
    this.columns = [
      {
        field: 'collectionPointCode',
        title: this.constantHelper.LBL_VOCABULARY_COLLECTIONPOINT_CODE,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'link',
        callback: this.view
      },
      {
        field: 'name',
        title: this.constantHelper.LBL_VOCABULARY_COLLECTIONPOINT_NAME,
        sortable: true,
        resizable: true,
        filterable: true
      },
      {
        field: 'address.city',
        title: this.constantHelper.LBL_VOCABULARY_COLLECTIONPOINT_CITY,
        sortable: true,
        resizable: true,
        filterable: true
      },
      {
        field: 'description',
        title: this.constantHelper.LBL_VOCABULARY_COLLECTIONPOINT_DESCRIPTION,
        sortable: true,
        resizable: true,
        filterable: true
      },
      {
        field: 'dumpstersCount',
        title: this.constantHelper.LBL_VOCABULARY_COLLECTIONPOINT_DUMPSTERS_COUNT,
        sortable: true,
        resizable: false,
        filterable: false,
        width: "70px"
      }
    ];

    this.selectableSettings = {
      enabled: !this.readonlyData,
      mode: this.selectableMode
    };

  }

  private view(collectionPoint: CollectionPointListOutputCollectionPoint) {
    this.router.navigate(['collectionpoints', 'list', collectionPoint.id]);
  }

  public refreshSelection() {
    if (this._selectedCollectionPoints)
      this.selectedCollectionPointsId = this._selectedCollectionPoints.map(d => d.id);
    else
      this.selectedCollectionPointsId = [];
  }

  public onSelectionChange(e: SelectionEvent) {
    let selected = Object.assign([], this._selectedCollectionPoints);
    if (e.selectedRows.length > 0) {
      e.selectedRows.forEach(r => selected.push(r.dataItem));
    }
    if (e.deselectedRows.length > 0) {
      e.deselectedRows.forEach(r => {
        let i = selected.findIndex(d => d.id == r.dataItem.id);
        if (i > -1) {
          selected.splice(i, 1);
        }
      });
    }
    this._selectedCollectionPoints = selected;
    this.selectedCollectionPointsChange.emit(this._selectedCollectionPoints);
  }

  public goToPage(page: number) {
    this.grid.goToPage(page);
  }
}
