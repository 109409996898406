import { ConstantHelper } from "@veritas-shared/helpers/constant.helper";
import { BannerAction, IBannerActionType } from "@veritas-shared/models/banner-option.model";

export enum BannerIcon {
  create = 'plus', 
  ticket = 'ticket-alt', 
  dumpster = 'dumpster',
  empty = '',
}

export const bannerActions: IBannerActionType = {
  create: new BannerAction(BannerIcon.create, new ConstantHelper().LBL_COMMON_TK_CREATE),
  open: new BannerAction(BannerIcon.ticket, new ConstantHelper().LBL_COMMON_TK_OPEN),
}
