import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private sessionService: SessionService) { }

  public userInRole(role: string) {
    return this.sessionService.userProfile.pipe(map(userProfile => {
      return userProfile && userProfile.roles ? userProfile.roles.indexOf(role) > -1 : false;
    }));
  }

  public userInRoles(roles: string[]) {
    return this.sessionService.userProfile.pipe(map(userProfile => {
      return userProfile && userProfile.roles ? userProfile.roles.some(x => roles.indexOf(x) > -1) : false;
    }));
  }

  public userHasPermission(permission: string) {
    return this.sessionService.userProfile.pipe(map(userProfile => {
      return userProfile && userProfile.permissions ? userProfile.permissions.indexOf(permission) > -1 : false;
    }));
  }

  public userHasPermissions(permissions: string[]) {
    return this.sessionService.userProfile.pipe(map(userProfile => {
      return userProfile && userProfile.permissions ? userProfile.permissions.some(x => permissions.indexOf(x) > -1) : false;
    }));
  }
}
