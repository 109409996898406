import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { DateFormat, DateType } from '@veritas-shared/enums/date.enum';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  public getDateByString = (item: string):Date => {
    const date: string[] = item?.split('-');
    if(date?.length !== 3) return new Date();

    const [day, month, year] = date;
    return new Date(parseInt(year, 10), parseInt(month, 10) + 2, parseInt(day, 10));
  }

  public getFormat = (date: Date, format: DateFormat):string => {
    if(!date) return '';
    return formatDate(date, format, 'it');
  }

  public getDiff = (date: Date, elapse: number, type: DateType):Date => {
    if(!date || !elapse) return date;
    const value: Date = new Date(date); // create a new instance of date to maintain date params unchanged

    if(type === DateType.month) return this._getDiffMonth(value, elapse);
    if(type === DateType.hour) return this._getDiffHour(value, elapse);
    return this._getDiffMinutes(value, elapse);
  }

  public getDiffDate = (reducing: Date, subtracting: Date):number => {
    if(!reducing || !subtracting) return 0;
    const dateReducing: Date = new Date(reducing); // create a new instance of date to maintain date params unchanged
    const dateSubtracting: Date = new Date(subtracting); // create a new instance of date to maintain date params unchanged

    return this._getHours(dateReducing.getTime() - dateSubtracting.getTime());
  }

  private _getHours = (milliseconds: number):number => !milliseconds ? 0 : this._getTruncated(this._getMinutes(milliseconds) / 60);
  private _getMinutes = (milliseconds: number):number => !milliseconds? 0 : this._getTruncated(this._getSeconds(milliseconds) / 60);
  private _getSeconds = (milliseconds: number):number => !milliseconds ? 0 : this._getTruncated(milliseconds / 1000);

  private _getTruncated = (value: number):number => !value ? 0 : Math.trunc(value);

  private _getDiffMonth = (date: Date, months: number):Date => new Date(date?.setMonth(date.getMonth() - months));
  private _getDiffHour = (date: Date, hours: number):Date => new Date(date?.setHours(date.getHours() - hours));
  private _getDiffMinutes = (date: Date, minutes: number):Date => new Date(date?.setMinutes(date.getMinutes() - minutes));
}
