import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@veritas-shared/services/api.service';
import { EditRoleInput } from '../models/edit-role-input';
import { EditRoleOutput } from '../models/edit-role-output';
import { NewRoleInput } from '../models/new-role-input';
import { NewRoleOutput } from '../models/new-role-output';
import { RoleListOutput } from '../models/role-list-output';
import { ToastService } from "@veritas-shared/services/toast.service";
import { WaitService } from "@veritas-shared/services/wait.service";
import { RoleDeleteOutput } from "../models/role-delete-output";
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from "@veritas-shared/helpers/common.helper";

@Injectable({
  providedIn: 'root'
})
export class RoleService extends ApiService {

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, toastService: ToastService, waitService: WaitService, commonHelper: CommonHelper, newToastService: BeanToastService) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  public getRoles(): Observable<RoleListOutput> {
    return this.getBase<RoleListOutput>('api/role');
  }

  //public getRole(id: number): Observable<EditRoleOutput> {
  //  return this.getBase<EditRoleOutput>('api/role/' + id);
  //}

  public getRoleDelete(id: number): Observable<RoleDeleteOutput> {
    return this.getBase<RoleDeleteOutput>('api/role/' + id + '/delete');
  }

  public getNewRole(): Observable<NewRoleOutput> {
    return this.getBase<NewRoleOutput>('api/role/new');
  }

  public getEditRole(id: number): Observable<EditRoleOutput> {
    return this.getBase<EditRoleOutput>('api/role/' + id + '/edit');
  }

  public addRole(role: NewRoleInput): Observable<void> {
    return this.postBase<NewRoleInput, void>('api/role', role);
  }

  public updateRole(role: EditRoleInput): Observable<void> {
    return this.putBase<EditRoleInput, void>('api/role', role);
  }

  public deleteRole(id: number): Observable<void> {
    return this.deleteBase<void>('api/role/' + id);
  }
}
