import { Component, OnInit } from '@angular/core';
import { DeactivatedComponent } from '@veritas-shared/components/deactivated.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViewMode } from '@veritas-shared/models/view-mode';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave, faDumpster } from '@fortawesome/free-solid-svg-icons';
import { ViewModeHelper } from '@veritas-shared/helpers/view-mode-helper';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { CommandeventService } from '../../services/commandevent.service';
import { DumpsterDetail } from '../../models/commandevent-detail';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';

@Component({
  selector: 'app-commandevent-detail',
  templateUrl: './commandevent-detail.component.html',
  styleUrls: ['./commandevent-detail.component.scss']
})
export class CommandEventDetailComponent extends DeactivatedComponent implements OnInit {

  public formCommandEventDetail: FormGroup;

  public viewMode: ViewMode;
  public ViewMode = ViewMode;

  private commandEventId: number;
  private commandTaskId: number;

  public commandEventDate: Date;

  public gridColumns: GridColumn[] = [];
  public gridData: DumpsterDetail[];

  constructor(private commandeventService: CommandeventService, private fb: FormBuilder, private route: ActivatedRoute, private library: FaIconLibrary, private router: Router,
    public constantHelper: ConstantHelper
  ) {

    super();

    // route.
    console.log(route);

    this.onBack = this.onBack.bind(this);

    this.library.addIcons(faSave, faChevronLeft, faDumpster);
  }

  canDeactivate(): boolean {
    return !this.formCommandEventDetail.dirty;
  }

  ngOnInit() {

    this.gridColumns = [
      {
        field: 'dumpsterName',
        title: this.constantHelper.LBL_PAGE_COMMAND_EVENT_DETAIL_DUMPSTER_NAME,
        sortable: false,
        resizable: false,
        filterable: false,
      },
      {
        field: 'dumpsterCode',
        title: this.constantHelper.LBL_VOCABULARY_DUMPSTER_CODE,
        sortable: false,
        resizable: false,
        filterable: false
      },
      {
        field: 'dumpsterStatus',
        title: this.constantHelper.LBL_PAGE_COMMAND_EVENT_DUMPSTER_STATUS,
        sortable: false,
        resizable: false,
        filterable: false
      }
    ];

    this.viewMode = ViewModeHelper.getViewMode(this.route);

    this.commandEventId = +this.route.snapshot.paramMap.get('id');

    this.formCommandEventDetail = this.fb.group({
      createdBy: [{ value: '', disabled: this.viewMode != ViewMode.New }, Validators.required],
      createdAt: [{ value: '', disabled: this.viewMode == ViewMode.View }]
    });

    switch (this.viewMode) {
      // case ViewMode.Edit:
      case ViewMode.View:
        this.commandeventService.getCommandEventDetail(this.commandEventId).subscribe(result => {
          this.gridData = result.dumpstersDetail;
          this.commandEventDate = result.createdAt;
          this.commandTaskId = result.commandTaskId;
          this.formCommandEventDetail.patchValue(result);
        });
        break;
      // case ViewMode.New:
      //   this.waitService.showWait();
      //   this.commandeventService.getNewCommand().subscribe(result => {
      //     this.formCommand.patchValue(result.command);
      //     this.gridData = result.command.parameters;
      //     this.waitService.hideWait();
      //   });
      //   break;
    }
  }

  public onBack() {
    this.goBack();
  }

  public goBack() {
    //history.back();
    this.router.navigate(['commands', 'tasks', this.commandTaskId, 'events']);
  }
}
