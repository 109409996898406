<h1 class="page-title"><fa-icon icon="list"></fa-icon>{{ constantHelper.LBL_PAGE_DUMPSTERS_TITLE | translate }}</h1>

<div class="list-container">
  <div class="page-content">

    <div class="mt-10" [ngClass]="{'large-page': !isLabelsOpen}">
      <app-group-box [isHeaderVisible]="false">
        <!-- constantHelper.LBL_PAGE_DUMPSTERS_BOX_TITLE -->

        <div class="standard-padding">

          <!-- <div class="my-2">
            <app-dumpster-search [items]="dumpsters" [(filteredItems)]="filteredDumpsters" (searchChange)="onSearchChanged()" [labels]="labels"></app-dumpster-search>
          </div> -->
          
          <div class="my-2">
            <div class="row">
              <div class="col">
                <span>{{ constantHelper.LBL_PAGE_DUMPSTERS_NUMBEROFFILTERED | translate:{'0': filteredDumpsters ? filteredDumpsters.length : '0', '1': dumpsters ? dumpsters.length : '0', '2': selectedDumpsters?.length} }}</span>
              </div>
              <div class="col">
                <kendo-switch [(ngModel)]="isShowOnlySelected" (valueChange)="toggleShowOnlySelected()" id="switchSelected"></kendo-switch>
                <label class="switch-label" for="switchSelected">{{ constantHelper.LBL_COMMON_SHOW_SELECTED | translate }}</label>
              </div>
            </div>
          </div>

          <kendo-tabstrip height="calc(100% - 30px)" (tabSelect)="onTabChange($event)" [keepTabContent]="true">
            <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_DUMPSTERS_TAB_LIST | translate }}" [selected]="true">
              <ng-template kendoTabContent>
                <div class="grid-container">
                  <app-dumpster-grid [items]="filteredDumpsters"
                                     [labels]="labels"
                                     [(selectedDumpsters)]="selectedDumpsters"
                                     [actions]="actions"
                                     [toolbarActions]="toolbarActions"
                                     [defaultFilter]="defaultGridFilter"
                                     [checkboxOnly]="true"
                                     [selectableCheckboxSettings]="selectableCheckboxSettings"
                                     (labelSelect)="onLabelSelected($event)">
                  </app-dumpster-grid>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{ constantHelper.LBL_COMPONENT_DUMPSTERS_TAB_MAP | translate }}">
              <ng-template kendoTabContent *loadOnDemand>
                <div class="grid-container">
                  <app-dumpster-map [items]="filteredDumpsters" [(selectedDumpsters)]="selectedDumpsters"></app-dumpster-map>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </div>

      </app-group-box>
    </div>

  </div>
</div>

<!--select labels dialog-->
<kendo-dialog title="{{ constantHelper.LBL_COMPONENT_DEVICE_DIALOG_SELECTLABELS | translate }}" *ngIf="isLabelsDialogOpen" (close)="closeLabelsDialog('cancel', null)" [minWidth]="250" [width]="500">
  <app-label-select #labelSelect [dumpsters]="selectedDumpsters"></app-label-select>
  <div class="dialog-actions">
    <button *ngIf="!labelSelect.isNew" class="btn btn-primary" (click)="closeLabelsDialog('edit', labelSelect.editLabelsInput)">{{ constantHelper.LBL_ACTION_COMMON_SAVE | translate }}</button>
    <button *ngIf="labelSelect.isNew" class="btn btn-primary" (click)="closeLabelsDialog('new', labelSelect.newLabelInput)">{{ constantHelper.LBL_ACTION_COMMON_ADD | translate }}</button>
    <button class="btn btn-outline-secondary mr-2" (click)="closeLabelsDialog('no',  null)">{{ constantHelper.LBL_ACTION_COMMON_CANCEL | translate }}</button>
  </div>
</kendo-dialog>

<!--upload dialog-->
<kendo-dialog title="{{ constantHelper.LBL_PAGE_DUMPSTERS_DIALOG_IMPORT_TITLE | translate }}" *ngIf="isImportDialogOpen" (close)="closeImportDialog()" [minWidth]="800" [width]="1000" [height]="600">

  <div class="dialog-import-body">
    <kendo-switch [(ngModel)]="isMergeEnabled" (valueChange)="toggleMergeEnabled()" id="switchImportBehavior"></kendo-switch>
    <label class="switch-label" for="switchImportBehavior">{{ constantHelper.LBL_PAGE_DUMPSTERS_DIALOG_IMPORT_MERGE | translate }}</label>

    <kendo-upload [autoUpload]="true"
                  [saveUrl]="uploadSaveUrl"
                  [restrictions]="uploadRestrictions"
                  [multiple]="false"
                  (success)="successEventHandler($event)"
                  (error)="errorEventHandler($event)">
    </kendo-upload>
    <app-import-log title="{{ constantHelper.LBL_PAGE_DUMPSTERS_DIALOG_IMPORT_RESULT | translate }}" [importResult]="importResult"></app-import-log>
  </div>

  <kendo-dialog-actions>
    <button kendoButton (click)="closeImportDialog()" primary="true">{{ constantHelper.LBL_ACTION_COMMON_CLOSE | translate }}</button>
  </kendo-dialog-actions>

</kendo-dialog>

<ng-template windowVisualizer></ng-template>
