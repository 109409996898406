import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSave, faChevronLeft, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '@veritas-shared/services/toast.service';
import { EditRoleInput } from '../../models/edit-role-input';
import { RoleService } from '../../services/role.service';
import { NewRoleInput } from '../../models/new-role-input';
import { PermissionNodeOutput } from '../../models/permission-tree-output';
import { CheckableSettings, TreeItemLookup } from '@progress/kendo-angular-treeview';
import { ViewMode } from '@veritas-shared/models/view-mode';
import { ViewModeHelper } from '@veritas-shared/helpers/view-mode-helper';
import { combineLatest, of, Subscription } from 'rxjs';
import { DeactivatedComponent } from '@veritas-shared/components/deactivated.component';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@veritas-shared/services/session.service';
import { Action } from '@veritas-shared/models/action';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { UserService } from '@veritas-core/services/user.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { SecurityService } from '@veritas-shared/services/security.service';
import { EditRoleOutputRole } from '../../models/edit-role-output';

@Component({
  selector: 'app-role-detail',
  templateUrl: './role-detail.component.html',
  styleUrls: ['./role-detail.component.scss']
})
export class RoleDetailComponent extends DeactivatedComponent implements OnInit, OnDestroy {

  public formRole: FormGroup;
  public role: EditRoleOutputRole;
  private roleId: number;

  public toolbarActions: Action[] = [];

  public hasWritePermission: boolean;
  public viewMode: ViewMode;
  public ViewMode = ViewMode;

  // Tree stuff
  public checkableSettings: CheckableSettings;
  private initialPermissions: PermissionNodeOutput[];
  public permissions: PermissionNodeOutput[];
  public checkedKeys: string[];

  private querySubscription: Subscription;

  constructor(
    private securityService: SecurityService,
    private roleService: RoleService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private translate: TranslateService,
    private sessionService: SessionService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper
  ) {
    super();

    this.onEdit = this.onEdit.bind(this);
    this.onView = this.onView.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.isNodeChecked = this.isNodeChecked.bind(this);

    this.library.addIcons(faSave, faChevronLeft, faUserFriends);

    //
    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_ROLE_WRITE).subscribe(res => {
      this.hasWritePermission = res;
    });

    // Tree
    this.initialPermissions = [];
    this.permissions = [];
    this.checkedKeys = [''];

    this.checkableSettings = {
      checkChildren: true,
      checkParents: true,
      enabled: true,
      mode: 'multiple'
    };

    // Params
    this.route.params.subscribe(params => {
      if (params) {
        this.roleId = +params.roleId;
      }
    });

    // Query Params
    this.querySubscription = combineLatest(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
      ),
      this.route.queryParams
    ).subscribe(res => {

      // Set ViewMode
      this.viewMode = ViewModeHelper.getViewMode(this.route, 'roleId');

      // Enalbe / Disable the form
      if (this.formRole) {
        const newForm = this.setFormGroup();
        this.commonHelper.enableValidFormFields(this.formRole, newForm);
      }

      // Set PageTitle
      if (this.viewMode == ViewMode.View && this.role) {
        this.sessionService.setPageTitle(this.role.name);
      }
    });
  }

  canDeactivate(): boolean {
    return !this.formRole.dirty;
  }

  ngOnInit() {
    this.formRole = this.setFormGroup();

    switch (this.viewMode) {
      case ViewMode.Edit:
      case ViewMode.View:
        this.roleService.getEditRole(this.roleId).subscribe(result => {
          this.role = result.role;
          this.sessionService.setPageTitle(result.role.name);
          this.formRole.patchValue(result.role);

          this.initialPermissions = this.commonHelper.clone(result.permissionTree.permissions);
          this.permissions = result.permissionTree.permissions;

          //
          this.checkedKeys = this.flattenByEnabledKey(this.permissions);
        });
        break;
      case ViewMode.New:
        this.roleService.getNewRole().subscribe(result => {
          this.formRole.patchValue(result.role);

          this.initialPermissions = this.commonHelper.clone(result.permissionTree.permissions);
          this.permissions = result.permissionTree.permissions;
        });
        break;
    }
  }

  ngOnDestroy() {
    if (this.querySubscription != null) {
      this.querySubscription.unsubscribe();
    }
  }

  private setFormGroup() {
    return this.fb.group({
      id: [''],
      name: [{ value: '', disabled: this.viewMode != ViewMode.New }, Validators.required],
      description: [{ value: '', disabled: this.viewMode == ViewMode.View }, null]
    });
  }

  public onEdit() {
    this.router.navigate([], { queryParams: { edit: true }, relativeTo: this.route });
  }

  public onView() {
    this.router.navigate([], { relativeTo: this.route });
  }

  public onBack() {
    this.goToList();
  }

  public onCancel() {
    this.formRole.reset(this.role);
    this.permissions = this.commonHelper.clone(this.initialPermissions);
    this.checkedKeys = this.flattenByEnabledKey(this.permissions);
  }

  public onDelete() {
    this.roleService.deleteRole(this.roleId).subscribe(result => {
      this.toastService.showSuccess(
        this.translate.instant(this.constantHelper.LBL_PAGE_ROLE_DETAIL_DELETE_CONFIR_MESSAGE, { '0': 1 })
      );
      this.goToList();
    });
  }

  public onSave() {
    let enabledPermissions: PermissionNodeOutput[];
    let enabledPermissionNames: string[];

    if (this.formRole.valid) {
      enabledPermissions = this.flatten(this.permissions).filter(item => item.enabled);
      enabledPermissionNames = enabledPermissions.map(x => x.name);

      if (this.viewMode == ViewMode.Edit) {
        let roleInput = <EditRoleInput>this.formRole.getRawValue();
        roleInput.permissions = enabledPermissionNames;
        this.roleService.updateRole(roleInput).subscribe(() => {
          this.formRole.reset();

          //
          this.sessionService.updateUserRoles();
          this.userService.getUserProfile().subscribe(result => {
            this.sessionService.setUserProfile(result);
          });

          this.goToList();
        });
      } else if (this.viewMode == ViewMode.New) {
        let roleInput = <NewRoleInput>this.formRole.getRawValue();
        roleInput.permissions = enabledPermissionNames;
        this.roleService.addRole(roleInput).subscribe(() => {
          this.formRole.reset();
          this.sessionService.updateUserRoles();
          this.goToList();
        });
      }
    }
  }

  public goToList() {
    this.router.navigate(['administration', 'roles']);
  }

  //
  // Tree functions
  //
  public hasChildren(node: PermissionNodeOutput) {
    return node.permissions && node.permissions.length > 0;
  }

  public fetchChildren(node: PermissionNodeOutput) {
    return of(node.permissions);
  }

  public isNodeChecked(node: PermissionNodeOutput) {
    if (node.permissions && node.permissions.length > 0) {

      let childPermissions = this.flatten(node.permissions);
      let permissionsEnabled = childPermissions.filter(item => item.enabled).length;
      if (permissionsEnabled == childPermissions.length) {
        return 'checked';
      } else if (permissionsEnabled == 0) {
        return 'none';
      } else {
        return 'indeterminate';
      }
    } else {
      return node.enabled ? 'checked' : 'none';
    }
  }

  public nodeChecking(itemLookup: TreeItemLookup) {
    // Trigger save button
    this.formRole.markAsDirty();

    let permission = <PermissionNodeOutput>itemLookup.item.dataItem;
    if (this.checkedKeys.indexOf(permission.indexKey) == -1) {
      // Check
      permission.enabled = true;
      this.checkedKeys.push(permission.indexKey);
      // Check parents
      let parent = itemLookup.parent;
      while (parent != null) {
        parent.item.dataItem.enabled = true;
        if (this.checkedKeys.indexOf(parent.item.dataItem.indexKey) == -1) {
          this.checkedKeys.push(parent.item.dataItem.indexKey);
        }
        parent = parent.parent;
      }
      // Check children
      this.checkChildren(permission.permissions, this.checkedKeys);
    }
    else {
      // Uncheck
      permission.enabled = false;
      this.checkedKeys.splice(this.checkedKeys.indexOf(permission.indexKey), 1);
      // Uncheck parent
      let parent = itemLookup.parent;
      if (parent && parent.children.filter(c => c.dataItem.enabled).length == 0) {
        parent.item.dataItem.enabled = false;
        this.checkedKeys.splice(this.checkedKeys.indexOf(parent.item.dataItem.indexKey), 1);
      }
      // Uncheck children
      this.uncheckChildren(permission.permissions, this.checkedKeys);
    }
  }

  public isDisabled = () => {
    return this.viewMode == ViewMode.View;
  }

  private checkChildren(children: PermissionNodeOutput[], keys: any[]) {
    children.forEach(c => {
      c.enabled = true;
      if (keys.indexOf(c.indexKey) == -1) {
        keys.push(c.indexKey);
        if (c.permissions) {
          this.checkChildren(c.permissions, keys);
        }
      }
    });
  }

  private uncheckChildren(children: PermissionNodeOutput[], keys: any[]) {
    children.forEach(c => {
      c.enabled = false;
      if (keys.indexOf(c.indexKey) > -1) {
        keys.splice(keys.indexOf(c.indexKey), 1);
        if (c.permissions) {
          this.uncheckChildren(c.permissions, keys);
        }
      }
    });
  }

  private flatten(obj) {
    const array = Array.isArray(obj) ? obj : [obj];
    return array.reduce((acc, value) => {
      acc.push(value);
      if (value.permissions) {
        acc = acc.concat(this.flatten(value.permissions));
      }
      return acc;
    }, []);
  }

  private flattenByEnabledKey(obj) {
    const array = Array.isArray(obj) ? obj : [obj];
    return array.reduce((acc, value) => {
      if (value.enabled) {
        acc.push(value.indexKey);
      }
      if (value.permissions) {
        acc = acc.concat(this.flattenByEnabledKey(value.permissions));
      }
      return acc;
    }, []);
  }
}
