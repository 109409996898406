import { Component, OnInit } from '@angular/core';
import { BeanDialogComponentBase, IDialogInput } from 'bean-kendo-dialog';
import { Observable } from 'rxjs';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent extends BeanDialogComponentBase<AlertDialogInput> {

  public data: AlertDialogInput;
  public baseUrl = window.location.origin;
  
  ngAfterViewInitCallback(): void {
    throw new Error('Method not implemented.');
  }
  cancel(e: any): boolean {
    return true;
  }
  back(e: any): boolean {
    return true;
  }
  save(e: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  goToTicketPage(){

  }

  constructor(
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper) {
      
    super();

    this.library.addIcons(faChevronLeft, faSave);
  }

}

export class AlertDialogInput implements IDialogInput {
  constructor(public msg: string, public other?: any) { }
}

export interface ExistingTicketAlertInfo {
  ticketId: string;
  openingDate: Date;
  ticketLink: string;
}