<h1 class="page-title" *ngIf="viewMode != ViewMode.New"><fa-icon icon="ticket-alt"></fa-icon>{{ ticket?.ticketId }}</h1>
<h1 class="page-title" *ngIf="viewMode == ViewMode.New"><fa-icon icon="ticket-alt"></fa-icon>{{ constantHelper.LBL_PAGE_TICKETDETAIL_NEW_TITLE | translate }}</h1>
<app-ticket-banner></app-ticket-banner>
<app-toolbar [formGroup]="formTicket" [hideEdit]="true" [actions]="ticketActions" [viewMode]="viewMode" (view)="onView()" (back)="onBack()" (save)="onSave()" (cancel)="onCancel()"></app-toolbar>

<div class="page-content">

  <!-- GENERAL -->
  <div class="mt-10" *ngIf="viewMode == ViewMode.New || dataReady">
    <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_TICKETDETAIL_GENERAL_TITLE | translate }}">
      <form [formGroup]="formTicket" class="box-standard-padding" novalidate>

        <!---->
        <div class="row">
          <div class = "col-6">
            <div class="row">
              <label *ngIf="viewMode == ViewMode.New" [title]="constantHelper.LBL_PAGE_TICKETDETAIL_OPENING_REASON | translate" class="col-3 col-form-label">{{ constantHelper.LBL_PAGE_TICKETDETAIL_OPENING_REASON | translate }} *</label>
              <label *ngIf="viewMode == ViewMode.View" [title]="constantHelper.LBL_PAGE_TICKETDETAIL_OPENING_REASON | translate" class="col-3 col-form-label">{{ constantHelper.LBL_PAGE_TICKETDETAIL_OPENING_REASON | translate }}</label>
              <div class="col-9 form-group">
                <input *ngIf="viewMode == ViewMode.View" type="text" class="form-control" [value]="formTicket.get('openingReason').value | openingReason:entities?.openingReasons" formControlName="openingReason" />
    
                <kendo-combobox *ngIf="viewMode == ViewMode.New"
                                [data]="entities?.openingReasons"
                                formControlName="openingReason"
                                textField="description"
                                valueField="openingReason"
                                [valuePrimitive]="true"
                                [filterable]="true"
                                (filterChange)="handleOpeningFilter($event)">
                </kendo-combobox>
              </div>
            </div>
          </div>
          

          <label [title]="constantHelper.LBL_VOCABULARY_TICKET_ASSIGNED_FROM | translate" class="col-xl-1 col-md-2 col-12 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_ASSIGNED_FROM | translate }}</label>
          <div class="col-xl-2 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="assignedFrom">
          </div>

          <label [title]="constantHelper.LBL_VOCABULARY_TICKET_ASSIGNED_TO | translate" class="col-xl-1 col-md-2 col-12 col-form-label">{{ constantHelper.LBL_VOCABULARY_TICKET_ASSIGNED_TO | translate }}</label>
          <div class="col-xl-2 col-md-4 col-12 form-group">
            <input *ngIf="viewMode == ViewMode.View" type="text" class="form-control" formControlName="assignedTo" />

            <kendo-combobox *ngIf="viewMode == ViewMode.New"
                            [data]="assignmentList"
                            formControlName="assignedTo"
                            [valuePrimitive]="true"
                            [filterable]="true"
                            (filterChange)="handleAssigmentFilter($event)">
            </kendo-combobox>
          </div>
        </div>
          
        <div class="row">
          <div class = "col-6">
            <div class="row">
              <label class="col-3 col-form-label" title="Tags">{{ constantHelper.LBL_COMMON_TAGS | translate }}</label>
              <div class="col-9 form-group">
                <kendo-multiselect formControlName="tags"
                                   [data]="[]"
                                   [allowCustom]="true">
                </kendo-multiselect>
              </div>
            </div>
          </div>
          <div class = "col-6">
            <div class="row">
              <label class="col-2 col-form-label" title="Priorità">{{ constantHelper.LBL_COMMON_PROPERTY | translate }}</label>
              <div class="col-10 form-group">
                <input type="number" class="form-control" formControlName="priority">
              </div>
            </div>
          </div>
        </div>
        <!-- -->
        <div class="row" *ngIf="viewMode == ViewMode.New">
          <label class="col-2 m-0 col-form-label" title="{{ 'vocabulary.Dumpster' | translate }}">{{ 'vocabulary.Dumpster' | translate }}*</label>
          <div class="col-10 form-group">

            <kendo-combobox *ngIf="dumpsters"
                            formControlName="entityId"
                            [data]="dumpsters"
                            [textField]="'code'"
                            [valueField]="'deviceExternalId'"
                            [valuePrimitive]="true"
                            [filterable]="true"
                            (filterChange)="handleDumpstersFilter($event)"
                            (valueChange)="onDumpsterSelected($event)">
              <ng-template kendoComboBoxItemTemplate let-dataItem>
                {{ dataItem.code}} ({{dataItem.binId}}) - {{dataItem.address}}
              </ng-template>
            </kendo-combobox>
            
          </div>
        </div>

        <div class="row" *ngIf="viewMode == ViewMode.New">
          <label class="col-2 col-form-label" [title]="constantHelper.LBL_VOCABULARY_NOTE | translate">
            {{ constantHelper.LBL_VOCABULARY_NOTE | translate }}
          </label>
          <div class="col-10 form-group">
            <textarea class="form-control" formControlName="note" rows="3"></textarea>
          </div>
        </div>

        <div class="row" *ngIf="viewMode != ViewMode.New">
          <div class="col-6">
            <div class="row">
              <label class="col-3 col-form-label" [title]="constantHelper.LBL_VOCABULARY_NOTE | translate">
                {{ constantHelper.LBL_VOCABULARY_NOTE | translate }}
              </label>
              <div class="col-9 form-group">
                <!-- <input type="text" class="form-control" formControlName="note"> -->
                <textarea class="form-control" formControlName="note" rows="3"></textarea>
              </div>
            </div>
          </div>
          
          <div class="col-6">
            <div class="row">
              <label [title]="constantHelper.LBL_PAGE_TICKETDETAIL_CLOSURE_REASON | translate" class="col-2 col-form-label">
                {{ constantHelper.LBL_PAGE_TICKETDETAIL_CLOSURE_REASON | translate }}
              </label>
              <div class="col-10 form-group">
                <input type="text" class="form-control" [value]="formTicket.get('closureReason').value | closureReason:entities?.closureReasons" formControlName="closureReason" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-xl-2 col-sm-3 col-form-label" [title]="constantHelper.LBL_COMMON_DESTINATION_DEFAULT | translate">
            {{ constantHelper.LBL_COMMON_DESTINATION_DEFAULT | translate }}
          </label>
          <div class="col-xl-10 col-sm-4 form-group">
            <input
              class="form-control"
              [value]="defaultMailingList"
              [disabled]="true"
              [title]="defaultMailingList"
            >
          </div>
        </div>

        <div class="row">
          <label class="col-xl-2 col-sm-3 col-form-label" [title]="constantHelper.LBL_COMMON_DESTINATION_ADD | translate">
            {{ constantHelper.LBL_COMMON_DESTINATION_ADD | translate }}
          </label>
          <div class="col-xl-10 col-sm-4 form-group">
            <input *ngIf="viewMode == ViewMode.New" formControlName="recipients" class="form-control" placeholder = 'Inserire una o più mail valide separate da ;' title='Inserire una o più mail valide separate da ;'>
            <input *ngIf="viewMode != ViewMode.New" class="form-control" [value]="calculateAdditionalMail()" [disabled]="true" />
          </div>
        </div>
          

        <div class="row">
          <p *ngIf="viewMode == ViewMode.New" class="col-xl-12 col-md-12 col-12 col-form-label">* Campi obbligatori</p>
        </div>

        <!-- ATTACHMENTS -->
        <div class="row" *ngIf="viewMode == ViewMode.New">
          <div class="col-12">
            <div class="sub-title">Allegati</div>
          </div>
          <div class="col-12 attachment-grid">
            <app-custom-grid [columns]="attachmentColumns"
                             [toolbarActions]="attachmentToolbarActions"
                             [pageable]="true"
                             [pageSizes]="true"
                             [filterable]="false"
                             [items]="attachments">
            </app-custom-grid>
            <input id="attachmentUploader" hidden multiple type="file" />
          </div>
        </div>

      </form>
    </app-group-box>
  </div>

  <!-- VIEW - DUMPSTER -->
  <!--<div class="" *ngIf="viewMode != ViewMode.New">
    <app-group-box pageTitle="Cassonetto">
      <form [formGroup]="formDumpster" class="box-standard-padding" novalidate>

         
        <div class="row">
          <label [title]="constantHelper.LBL_VOCABULARY_DUMPSTER_CODE | translate" class="col-xl-1 col-sm-2 col-form-label">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_CODE | translate }}</label>
          <div class="col-xl-3 col-sm-4 form-group">
            <input type="text" class="form-control" formControlName="code">
          </div>

          <label [title]="constantHelper.LBL_VOCABULARY_DUMPSTER_REGION_CODE | translate" class="col-xl-1 col-sm-2 col-form-label">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_REGION_CODE | translate }}</label>
          <div class="col-xl-3 col-sm-10 form-group">
            <input type="text" class="form-control" formControlName="regionCode">
          </div>

          <label [title]="constantHelper.LBL_VOCABULARY_DUMPSTER_SUPPLIER | translate" class="col-xl-1 col-sm-2 col-form-label">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_SUPPLIER | translate }}</label>
          <div class="col-xl-3 col-sm-4 form-group">
            <input type="text" class="form-control" formControlName="supplierName">
          </div>
        </div>

         
        <div class="row">
          <label [title]="constantHelper.LBL_VOCABULARY_DUMPSTER_TYPE | translate" class="col-xl-1 col-sm-2 col-form-label">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_TYPE | translate }}</label>
          <div class="col-xl-3 col-sm-4 form-group">
            <input type="text" class="form-control" formControlName="binType">
          </div>

          <label [title]="constantHelper.LBL_VOCABULARY_COLLECTIONPOINT | translate" class="col-xl-1 col-sm-2 col-form-label">{{ constantHelper.LBL_VOCABULARY_COLLECTIONPOINT | translate }}</label>
          <div class="col-xl-7 col-sm-10 form-group">
            <input type="text" class="form-control" formControlName="collectionPointName">
          </div>
        </div>

         
        <div class="row" formGroupName="location">
          <label [title]="constantHelper.LBL_VOCABULARY_LOCATION_LATITUDE | translate" class="col-xl-1 col-sm-2 col-form-label">{{ constantHelper.LBL_VOCABULARY_LOCATION_LATITUDE | translate }}</label>
          <div class="col-xl-3 col-sm-4 form-group">
            <input type="text" class="form-control" formControlName="latitude">
          </div>
          <label [title]="constantHelper.LBL_VOCABULARY_LOCATION_LONGITUDE | translate" class="col-xl-1 col-sm-2 col-form-label">{{ constantHelper.LBL_VOCABULARY_LOCATION_LONGITUDE | translate }}</label>
          <div class="col-xl-3 col-sm-4 form-group">
            <input type="text" class="form-control" formControlName="longitude">
          </div>
        </div>

      </form>
    </app-group-box>
  </div>-->

  <!-- Dumpster details -->
  <div class="mt-10" *ngIf="viewMode != ViewMode.New && dataReady">
    <app-group-box pageTitle="Cassonetto">
      <form [formGroup]="formDumpster" class="box-standard-padding" novalidate>
        <!-- -->
        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{constantHelper.LBL_VOCABULARY_DUMPSTER_CODE | translate}}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_CODE | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control k-text-box" formControlName="code">
          </div>

          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_DUMPSTER_SUPPLIER | translate }}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_SUPPLIER | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="supplierName">
          </div>

          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_DUMPSTER | translate }}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER | translate }}</label>
          <div class="col-xl-3 col-md-10 col-12 form-group">
            <input type="text" class="form-control k-text-box" formControlName="binId">
          </div>
        </div>


        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_FIRST | translate }}">{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_FIRST | translate }}</label>
          <div class="col-xl-3 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="regionCode">
          </div>

          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_SECOND | translate }}">{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_SECOND | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="regionCodeSecond">
          </div>
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_THIRD | translate }}">{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_THIRD | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="regionCodeThird">
          </div>
        </div>

        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_COLLECTIONPOINT | translate }}">{{ constantHelper.LBL_VOCABULARY_COLLECTIONPOINT | translate }}</label>
          <div class="col-xl-3 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="collectionPointId" />
          </div>

          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_DUMPSTER_TYPE | translate }}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_TYPE | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="binType">
          </div>
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_DUMPSTER_GARBAGE_TYPE | translate }}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_GARBAGE_TYPE | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" [value]="formDumpster.get('garbageType').value | wasteType:entities?.wasteTypes" formControlName="garbageType" />
          </div>
        </div>

        
        <!-- -->
        <div class="row">
          <div class="col-xl-4 col-md-6 col-sm-12">
            <div class="row" formGroupName="location">
              <label class="col-xl-3 col-md-4 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_LOCATION_LATITUDE | translate }}">{{ constantHelper.LBL_VOCABULARY_LOCATION_LATITUDE | translate }}</label>
              <div class="col-xl-9 col-md-8 col-12 form-group">
                <input type="number" step="any" class="form-control" formControlName="latitude" />
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6 col-sm-12">
            <div class="row" formGroupName="location">
              <label class="col-xl-3 col-md-4 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_LOCATION_LONGITUDE | translate }}">{{ constantHelper.LBL_VOCABULARY_LOCATION_LONGITUDE | translate }}</label>
              <div class="col-xl-9 col-md-8 col-12 form-group">
                <input type="number" step="any" class="form-control" formControlName="longitude" />
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6 col-sm-12">
            <div class="row">
              <label class="col-xl-3 col-md-4 col-12 col-form-label" title="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_COLLECTION_POINT_DISTANCE | translate }}">{{ constantHelper.LBL_PAGE_DEVICEDETAIL_COLLECTION_POINT_DISTANCE | translate }}</label>
              <div class="col-xl-9 col-md-8 form-group">
                <input type="number" class="form-control" formControlName="collectionPointDistance">
              </div>
            </div>
          </div>
        </div>

        <!-- -->
        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ 'vocabulary.CollectionPoint.Street' | translate }}">{{ 'vocabulary.CollectionPoint.Street' | translate }}</label>
          <div class="col-xl-11 col-md-2 form-group">
            <input type="text" class="form-control" formControlName="address" />
          </div>
        </div>

        <!-- <label class="col-xl-1 col-md-2 col-12 col-form-label">Tags</label>
          <div class="col-xl-8 col-md-4 col-12 form-group">
            <kendo-multiselect formControlName="tags"
                               [data]="[]"
                               [allowCustom]="true">
            </kendo-multiselect>
          </div> -->

      </form>
      </app-group-box>
  </div>

  <!-- NOTES -->
  <div class="" *ngIf="viewMode != ViewMode.New && dataReady">
    <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_TICKETDETAIL_HISTORY_TITLE | translate }}">

      <div class="box-standard-padding form-group history-grid">
        <app-custom-grid *ngIf="ticket"
                         [columns]="historyColumns"
                         [showActions]="true"
                         [selectable]="{ enabled: false }"
                         [toolbarActions]="historyToolbarActions"
                         [actions]="historyActions"
                         [pageable]="true"
                         [pageSizes]="true"
                         [filterable]="false"
                         [items]="ticket.history">
        </app-custom-grid>
      </div>
    </app-group-box>
  </div>
</div>

<ng-template #columnMessageTypeTemplate
             let-dataItem>
  {{ dataItem.messageType | messageType:entities?.ticketMessageTypes }}
</ng-template>

<ng-template windowVisualizer></ng-template>

<ng-template #columnNotesTemplate
             let-dataItem>
<pre>{{ dataItem.detail.note}}</pre></ng-template>