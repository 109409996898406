import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@veritas-shared/services/api.service';
import { AuthorizationListOutput } from '../models/authorization-list-output';
import { NewAuthorizationOutput } from '../models/new-authorization-output';
import { NewAuthorizationInput } from "../models/new-authorization-input";
import { EditAuthorizationOutput } from "../models/edit-authorization-output";
import { EditAuthorizationInput } from "../models/edit-authorization-input";
import { ToastService } from "@veritas-shared/services/toast.service";
import { WaitService } from "@veritas-shared/services/wait.service";
import { DisableAuthorizationInput } from "../models/disable-authorization-input";
import { EditAuthUsersInput } from "../models/edit-authorization-users-input";
import { AuthorizationUserListOutput } from "../models/authorization-user-list-output";
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from "@veritas-shared/helpers/common.helper";
import { ActiveDirectoryUser } from "../models/active-directory";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService extends ApiService {

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, toastService: ToastService, waitService: WaitService, commonHelper: CommonHelper, newToastService: BeanToastService) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  public getAuthorizations(): Observable<AuthorizationListOutput> {
    return this.getBase<AuthorizationListOutput>('api/authorization');
  }

  public getNewAuthorization(): Observable<NewAuthorizationOutput> {
    return this.getBase<NewAuthorizationOutput>('api/authorization/new');
  }

  public getEditAuthorization(id: number): Observable<EditAuthorizationOutput> {
    return this.getBase<EditAuthorizationOutput>('api/authorization/' + id + '/edit');
  }

  public addAuthorization(authorization: NewAuthorizationInput): Observable<void> {
    return this.postBase<NewAuthorizationInput, void>('api/authorization', authorization);
  }

  public updateAuthorization(authorization: EditAuthorizationInput): Observable<void> {
    return this.putBase<EditAuthorizationInput, void>('api/authorization', authorization);
  }

  public disableAuthorization(authorization: DisableAuthorizationInput): Observable<void> {
    return this.putBase<DisableAuthorizationInput, void>('api/authorization/disable', authorization);
  }

  public deleteAuthorization(id: number): Observable<void> {
    return this.deleteBase('api/authorization/' + id);
  }

  //
  public getActiveDirectoryUsers(): Observable<ActiveDirectoryUser[]> {
    return this.getBase<ActiveDirectoryUser[]>('api/authorization/users');
  }

  public getUsers(id: number): Observable<AuthorizationUserListOutput> {
    return this.getBase<AuthorizationUserListOutput>('api/authorization/' + id + '/user');
  }

  public addUsers(item: EditAuthUsersInput): Observable<void> {
    return this.putBase<EditAuthUsersInput, void>('api/authorization/user/add', item);
  }

  public removeUsers(item: EditAuthUsersInput): Observable<void> {
    return this.putBase<EditAuthUsersInput, void>('api/authorization/user/remove', item);
  }

}
