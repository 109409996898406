<div #userPanel [@openClose]="isOpen ? 'open' : 'closed'" class="sidebar no-print">
  <button class="btn btn-sm float-right p-1 c-pointer close" (click)="close()">
    <fa-icon icon="times"></fa-icon>
  </button>
  <section class="mb-4">
    <h3>{{ 'page.UserProfile.Account' | translate }}</h3>
    <div>{{ userProfile?.name }}</div>
    <div>{{ userProfile?.userName }}</div>
    <button type="button" class="btn btn-sm btn-outline-secondary mt-2 float-right" (click)="logout()">
      <fa-icon icon="sign-out-alt"></fa-icon>
      {{ 'action.Common.Logout' | translate }}
    </button>
  </section>
  <section>
    <h3>{{ 'page.UserProfile.Roles' | translate }}</h3>
    <ul>
      <li *ngFor="let role of userProfile?.roles">{{ role }}</li>
    </ul>
  </section>
</div>
