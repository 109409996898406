import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { googleMapLayer, openStreetMapLayer, satelliteMapLayer } from '@veritas-shared/enums/leaflet-map.enum';
import { 
  LatLngBounds, Map, Marker, control, map, tileLayer,
} from 'leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-pegman";

@Component({
  selector: 'app-leaflet-map-container',
  templateUrl: './leaflet-map-container.component.html',
  styleUrls: ['./leaflet-map-container.component.scss']
})
export class LeafletMapContainerComponent implements AfterViewInit {
  @ViewChild('mapContainer', { static: true }) mapContainer: ElementRef;
  
  @Input() defaultZoom: number;

  @Output() isMapInitialized: EventEmitter<boolean>;

  public map?: Map;
  public isSatelliteMapSelected: boolean;
  public maxZoom: number;
  public minZoom: number;

  constructor() {
    this.defaultZoom = 3.5;
    this.maxZoom = 21;
    this.minZoom = 3.5;
    this.isMapInitialized = new EventEmitter();
    this.isSatelliteMapSelected = false;
  }

  ngAfterViewInit(): void {
    // To set zoom with CTRL + scroll
    Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    
    this.map = map(this.mapContainer.nativeElement, {
      center: [45.46866552215852, 12.214754901027348],
      zoom: this.defaultZoom,
      gestureHandling: true, // To set zoom with CTRL + scroll
      zoomControl: false,
      minZoom: 3.5,
      maxZoom: this.maxZoom,
      layers: [tileLayer(
        'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}?lang=it',
        {
          maxNativeZoom: this.maxZoom,
          minZoom: 3.5,
          maxZoom: this.maxZoom,
          subdomains:['mt0','mt1','mt2','mt3']
        }
      )]
    });

    // Set zoom button position
    control.zoom({ position: "bottomright"}).addTo(this.map);

    // Configure street view
    control.pegman({
      position: 'bottomright',
      theme: "leaflet-pegman-v3-small", // or "leaflet-pegman-v3-default"
    }).addTo(this.map);

    this.setMapTopology();
    this.isMapInitialized.emit(true);
  }

  public removeMarker = (marker: Marker) => this.map.removeLayer(marker);
  public addMarker = (newMarker: Marker) => newMarker.addTo(this.map);

  public setCenterAndZoomOnMarkers = (entitiesCoordination: any[]) => {
    if(!entitiesCoordination.length) return;
    const bounds: any = new LatLngBounds(entitiesCoordination);

    this.map.fitBounds(bounds); // Auto zoom
  }

  private setMapTopology = (): void => {
    if(!this.map) return;

    control.layers({
      "Google Maps": googleMapLayer(this.maxZoom).addTo(this.map),
      OpenStreetMap: openStreetMapLayer(this.maxZoom),
      "Google Satellite": satelliteMapLayer(this.maxZoom)
    }).addTo(this.map);
  }

}
