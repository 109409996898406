import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import localeit from '@angular/common/locales/it'; //it-IT
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ButtonsModule, DropDownButtonModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule, DropDownListModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { ColorPickerModule, TextBoxModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { UploadModule } from '@progress/kendo-angular-upload';
import { MsalModule, MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalInterceptorConfiguration, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { AuthorizationDetailComponent } from './administration/pages/authorization-detail/authorization-detail.component';
import { AuthorizationsComponent } from './administration/pages/authorizations/authorizations.component';
import { RoleDetailComponent } from './administration/pages/role-detail/role-detail.component';
import { RolesComponent } from './administration/pages/roles/roles.component';
import { AppComponent } from './app.component';
import { CollectionPointMapComponent } from '@veritas-shared/components/collectionpoint-map/collectionpoint-map.component';
import { CollectionPointDetailComponent } from './collectionpoints/pages/collectionpoint-detail/collectionpoint-detail.component';
import { CollectionPointGroupDetailComponent } from './collectionpoints/pages/collectionpointgroup-detail/collectionpointgroup-detail.component';
import { CollectionPointGroupsComponent } from './collectionpoints/pages/collectionpointgroups/collectionpointgroups.component';
import { CollectionPointsDeactivatedComponent } from './collectionpoints/pages/collectionpoints-deactivated/collectionpoints-deactivated.component';
import { CollectionPointsComponent } from './collectionpoints/pages/collectionpoints/collectionpoints.component';
import { DumpstersDeassociatedComponent } from './collectionpoints/pages/dumpsters-deassociated/dumpsters-deassociated.component';
import { AlertComponent } from '@veritas-core/components/alert/alert.component';
import { BreadcrumbComponent } from '@veritas-core/components/breadcrumb/breadcrumb.component';
import { ConfirmComponent } from '@veritas-core/components/confirm/confirm.component';
import { NavBarComponent } from '@veritas-core/components/nav-bar/nav-bar.component';
import { NavMenuComponent } from '@veritas-core/components/nav-menu/nav-menu.component';
import { UserPanelComponent } from '@veritas-core/components/user-panel/user-panel.component';
import { ResourceLoader } from '@veritas-core/helpers/resource-loader';
import { EmptyComponent } from '@veritas-core/pages/empty/empty.component';
import { HomeComponent } from '@veritas-core/pages/home/home.component';
import { GroupGridComponent } from './group/components/group-grid/group-grid.component';
import { GroupSearchComponent } from './group/components/group-search/group-search.component';
import { LabelListComponent } from '@veritas-dumpsters/components/label-list/label-list.component';
import { LabelSelectComponent } from '@veritas-dumpsters/components/label-select/label-select.component';
import { DumpsterGroupDetailComponent } from '@veritas-dumpsters/pages/dumpstergroup-detail/dumpstergroup-detail.component';
import { DumpsterGroupsComponent } from '@veritas-dumpsters/pages/dumpstergroups/dumpstergroups.component';
import { DumpsterDetailComponent } from '@veritas-dumpsters/pages/dumpster-detail/dumpster-detail.component';
import { DumpstersDeactivatedComponent } from '@veritas-dumpsters/pages/dumpsters-deactivated/dumpsters-deactivated.component';
import { DumpstersComponent } from '@veritas-dumpsters/pages/dumpsters/dumpsters.component';
import { LabelDetailComponent } from '@veritas-dumpsters/pages/label-detail/label-detail.component';
import { LabelsComponent } from '@veritas-dumpsters/pages/labels/labels.component';
import { CustomGridComponent } from '@veritas-shared/components/custom-grid/custom-grid.component';
import { DumpsterGridComponent } from '@veritas-shared/components/dumpster-grid/dumpster-grid.component';
import { DumpsterMapComponent } from '@veritas-shared/components/dumpster-map/dumpster-map.component';
import { DumpsterSearchComponent } from '@veritas-shared/components/dumpster-search/dumpster-search.component';
import { ImportLogComponent } from '@veritas-shared/components/import-log/import-log.component';
import { InfoIconComponent } from '@veritas-shared/components/info-icon/info-icon.component';
import { InfoTextComponent } from '@veritas-shared/components/info-text/info-text.component';
import { LabelsBadgeComponent } from '@veritas-shared/components/labels-badge/labels-badge.component';
import { ToastComponent } from '@veritas-shared/components/toast/toast.component';
import { ToolbarComponent } from '@veritas-shared/components/toolbar/toolbar.component';
import { EnableOnPermissionDirective } from '@veritas-shared/directives/enable-on-permission.directive';
import { PermissionDirective } from '@veritas-shared/directives/permission.directive';
import { FilterActionsPipe } from '@veritas-shared/pipes/filter-actions.pipe';
import { AuthGuardService } from '@veritas-shared/services/auth-guard.service';
import { UnsavedGuardService } from '@veritas-shared/services/unsaved-guard.service';
import { CollectionPointGridComponent } from '@veritas-shared/components/collectionpoint-grid/collectionpoint-grid.component';
import { CollectionPointSearchComponent } from '@veritas-shared/components/collectionpoint-search/collectionpoint-search.component';
import { IncidentsComponent } from './incidents/pages/incidents/incidents.component';
import { IncidentGridComponent } from './incidents/components/incident-grid/incident-grid.component';
import { IncidentMapComponent } from './incidents/components/incident-map/incident-map.component';
import { IncidentSearchComponent } from './incidents/components/incident-search/incident-search.component';
import { CommandsComponent } from './commands/pages/commands/commands.component';
import { CommandDetailComponent } from './commands/pages/command-detail/command-detail.component';
import { CommandTasksComponent } from './commands/pages/commandtasks/commandtasks.component';
import { CommandTaskDetailComponent } from './commands/pages/commandtask-detail/commandtask-detail.component';
import { CommandEventsComponent } from './commands/pages/commandevents/commandevents.component';
import { CommandEventDetailComponent } from './commands/pages/commandevent-detail/commandevent-detail.component';
import { GroupBoxComponent } from '@veritas-shared/components/group-box/group-box.component';
import { AuditingBoxComponent } from '@veritas-shared/components/auditing-box/auditing-box.component';
import { InfoSequenceComponent } from '@veritas-shared/components/info-sequence/info-sequence.component';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { Routes } from '@veritas-shared/models/routes';
import { TicketGridComponent } from './tickets/components/ticket-grid/ticket-grid.component';
import { TicketsComponent } from './tickets/pages/tickets/tickets.component';
import { TicketSearchComponent } from './tickets/components/ticket-search/ticket-search.component';
import { TicketDetailComponent } from './tickets/pages/ticket-detail/ticket-detail.component';
import { UserGridComponent } from '@veritas-shared/components/user-grid/user-grid.component';
import { UserSearchComponent } from '@veritas-shared/components/user-search/user-search.component';
import { DisableActionsPipe } from '@veritas-shared/pipes/disable-actions.pipe';
import { environment } from '../environments/environment';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TabContentLoadOnDemandDirective } from '@veritas-shared/directives/tab-lazy-load.directive';
import { TicketHistoryDialogComponent } from './tickets/components/ticket-history-dialog/ticket-history-dialog.component';
import { BeanKendoDialogModule, BeanKendoDialogService } from 'bean-kendo-dialog';
import { TicketCloseDialogComponent } from './tickets/components/ticket-close-dialog/ticket-close-dialog.component';
import { DumpstersUnregisteredComponent } from '@veritas-dumpsters/pages/dumpsters-unregistered/dumpsters-unregistered.component';
import { IconNamePipe } from '@veritas-core/pipes/icon-name.pipe';
import { ElementHeightPipe } from '@veritas-core/pipes/element-height.pipe';
import { MessageTypePipe } from './tickets/pipes/message-type.pipe';
import { DumpsterRegisterDialogComponent } from '@veritas-dumpsters/components/dumpster-register-dialog/dumpster-register-dialog.component';
import { GroupSelectDialogComponent } from '@veritas-dumpsters/components/group-select-dialog/group-select-dialog.component';
import { DumpsterSelectDialogComponent } from '@veritas-dumpsters/components/dumpster-select-dialog/dumpster-select-dialog.component';
import { CollectionPointSelectDialogComponent } from '@veritas-shared/components/collection-point-select-dialog/collection-point-select-dialog.component';
import { CollectionPointImportDialogComponent } from './collectionpoints/components/collection-point-import-dialog/collection-point-import-dialog.component';
import { RunCommandDialogComponent } from '@veritas-dumpsters/components/run-command-dialog/run-command-dialog.component';
import { UserSelectDialogComponent } from './administration/components/user-select-dialog/user-select-dialog.component';
import { LabelSelectDialogComponent } from '@veritas-dumpsters/components/label-select-dialog/label-select-dialog.component';
import { DeviceStatePipe } from '@veritas-shared/pipes/device-state.pipe';
import { WasteTypePipe } from '@veritas-shared/pipes/waste-type.pipe';
import { ParameterTypePipe } from './commands/pipes/parameter-type.pipe';
import { DumpsterEventDetailDialogComponent } from '@veritas-dumpsters/components/dumpster-event-detail-dialog/dumpster-event-detail-dialog.component';
import { ResourceService } from '@veritas-core/services/resource.service';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { PayloadTypePipe } from '@veritas-shared/pipes/payload-type.pipe';
import { TooltipPipe } from '@veritas-shared/pipes/tooltip.pipe';
import { UserTypePipe } from '@veritas-shared/pipes/user-type';
import { MapComponent } from '@veritas-shared/components/map/map.component';
import { ClosureReasonPipe } from '@veritas-shared/pipes/closure-reason.pipe';
import { OpeningReasonPipe } from '@veritas-shared/pipes/opening-reason.pipe';

import '@progress/kendo-angular-intl/locales/it/all';
import { TicketStatePipe } from './tickets/pipes/ticket-state.pipe';
import { DumpsterConfigurationComponent } from './dumpsters/components/dumpster-configuration-dialog/dumpster-configuration-dialog.component';
import { ConfigurationStatusTranslatePipe } from '@veritas-shared/pipes/config-status.pipe';
import { AlertDialogComponent } from '@veritas-shared/components/alert-dialog/alert-dialog.component';
import { LeafletMapComponent } from './shared/components/leaflet-map/leaflet-map.component';
import { LeafletMapContainerComponent } from './shared/components/leaflet-map-container/leaflet-map-container.component';
import { LeafletMapSearchComponent } from './shared/components/leaflet-map-search/leaflet-map-search.component';
import { FilterLabelsComponent } from './shared/components/filter-labels/filter-labels.component';
import { BannerComponent } from './shared/components/banner/banner.component';
import { TicketBannerComponent } from './tickets/components/ticket-banner/ticket-banner.component';
import { DumpsterRelationComponent } from './dumpsters/components/dumpster-relation/dumpster-relation.component';
import { RelationSelectComponent } from './dumpsters/components/relation-select/relation-select.component';
import { RelationDetailComponent } from './dumpsters/components/relation-detail/relation-detail.component';
import { FieldDumpsterCodeComponent } from './dumpsters/components/field-dumpster-code/field-dumpster-code.component';
import { RelationIconComponent } from './dumpsters/components/relation-icon/relation-icon.component';
import { ChildRemoveDialogComponent } from './dumpsters/components/child-remove-dialog/child-remove-dialog.component';
import { FieldActionComponent } from './shared/components/field-action/field-action.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function createTranslateLoader(resourceService: ResourceService) {
  return new ResourceLoader(resourceService);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.config.clientId,
      authority: 'https://login.microsoftonline.com/' + environment.config.tenant,
      /*validateAuthority: true,*/
      redirectUri: environment.config.redirectUri,
      postLogoutRedirectUri: environment.config.redirectUri,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback: (logLevel: LogLevel, message: string) => { console.log(message) },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map<string, Array<string>>([
      ['https://graph.microsoft.com/v1.0/me', ['user.read']],
      ['/api/resource', null],
      ['/api/', ['api://' + environment.config.clientId + '/access_as_user']],
    ])
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        'user.read', //'openid', 'profile',
      ],
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    NavMenuComponent,
    HomeComponent,
    UserPanelComponent,
    ConfirmComponent,
    AlertComponent,
    BreadcrumbComponent,
    CustomGridComponent,
    ToastComponent,
    ToolbarComponent,
    ImportLogComponent,
    InfoIconComponent,
    InfoTextComponent,
    PermissionDirective,
    DumpsterGridComponent,
    DumpsterMapComponent,
    DumpsterSearchComponent,
    LabelsBadgeComponent,
    EnableOnPermissionDirective,
    FilterActionsPipe,
    DisableActionsPipe,
    IconNamePipe,
    ElementHeightPipe,
    MessageTypePipe,
    TicketStatePipe,
    DeviceStatePipe,
    WasteTypePipe,
    PayloadTypePipe,
    ParameterTypePipe,
    TooltipPipe,
    UserTypePipe,
    OpeningReasonPipe,
    ClosureReasonPipe,
    ConfigurationStatusTranslatePipe,
    EmptyComponent,
    RolesComponent,
    RoleDetailComponent,
    AuthorizationsComponent,
    AuthorizationDetailComponent,
    CollectionPointGridComponent,
    CollectionPointMapComponent,
    CollectionPointsComponent,
    CollectionPointsDeactivatedComponent,
    CollectionPointDetailComponent,
    CollectionPointGroupsComponent,
    CollectionPointGroupDetailComponent,
    CollectionPointSearchComponent,
    DumpstersDeassociatedComponent,
    DumpstersComponent,
    DumpstersDeactivatedComponent,
    LabelListComponent,
    DumpsterDetailComponent,
    LabelSelectComponent,
    DumpsterGroupsComponent,
    DumpsterGroupDetailComponent,
    GroupGridComponent,
    GroupSearchComponent,
    LabelsComponent,
    LabelDetailComponent,
    IncidentsComponent,
    IncidentGridComponent,
    IncidentMapComponent,
    IncidentSearchComponent,
    CommandsComponent,
    CommandDetailComponent,
    CommandTasksComponent,
    CommandTaskDetailComponent,
    CommandEventsComponent,
    CommandEventDetailComponent,
    GroupBoxComponent,
    AuditingBoxComponent,
    InfoSequenceComponent,
    TicketGridComponent,
    TicketsComponent,
    TicketSearchComponent,
    TicketDetailComponent,
    UserGridComponent,
    UserSearchComponent,
    TabContentLoadOnDemandDirective,
    TicketHistoryDialogComponent,
    TicketCloseDialogComponent,
    DumpstersUnregisteredComponent,
    DumpsterRegisterDialogComponent,
    GroupSelectDialogComponent,
    DumpsterSelectDialogComponent,
    CollectionPointSelectDialogComponent,
    CollectionPointImportDialogComponent,
    RunCommandDialogComponent,
    UserSelectDialogComponent,
    LabelSelectDialogComponent,
    DumpsterEventDetailDialogComponent,
    MapComponent,
    DumpsterConfigurationComponent,
    AlertDialogComponent,
    LeafletMapComponent,
    LeafletMapContainerComponent,
    LeafletMapSearchComponent,
    FilterLabelsComponent,
    BannerComponent,
    TicketBannerComponent,
    DumpsterRelationComponent,
    RelationSelectComponent,
    RelationDetailComponent,
    FieldDumpsterCodeComponent,
    RelationIconComponent,
    ChildRemoveDialogComponent,
    FieldActionComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    UploadModule,
    DialogsModule,
    ButtonsModule,
    GridModule,
    PopupModule,
    MultiSelectModule,
    TooltipModule,
    ExcelModule,
    DropDownButtonModule,
    DropDownListModule,
    DropDownsModule,
    NotificationModule,
    TreeViewModule,
    TabStripModule,
    ColorPickerModule, SwitchModule,
    TextBoxModule,
    DateInputsModule,
    BeanKendoDialogModule,
    //BeanNotificationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        //useClass: ResourceLoader,
        deps: [ResourceService]
      },
      /*      defaultLanguage: 'it'*/
    }),
    RouterModule.forRoot(Routes.routerPaths, { enableTracing: false, relativeLinkResolution: 'legacy' }),
    MsalModule
  ],
  entryComponents: [
    ToastComponent,
    TicketHistoryDialogComponent,
    TicketCloseDialogComponent,
    DumpsterRegisterDialogComponent,
    GroupSelectDialogComponent,
    DumpsterSelectDialogComponent,
    CollectionPointSelectDialogComponent,
    CollectionPointImportDialogComponent,
    RunCommandDialogComponent,
    UserSelectDialogComponent,
    LabelSelectDialogComponent,
    DumpsterEventDetailDialogComponent
  ],
  providers: [
    AuthGuardService,
    UnsavedGuardService,
    ConstantHelper,
    CommonHelper,
    BeanKendoDialogService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    { provide: LOCALE_ID, useValue: getLanguageCode(localeit) },
    MsalGuard,
    MsalService,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }

function getLanguageCode(...args: any[]) {
  const ssLanguage = 'CQ_SS_Language';
  let localStorageLanguage = localStorage.getItem(ssLanguage);
  let languageCode: string;

  if (!localStorageLanguage) {
    localStorageLanguage = "it-IT";
    localStorage.setItem(ssLanguage, localStorageLanguage);
  }

  languageCode = localStorageLanguage;

  const lang = languageCode.slice(0, 2);
  const localeData = args.find(x => x[0] === languageCode || x[0] === lang);

  registerLocaleData(localeData);

  return languageCode;
}
