import { Component, Input } from '@angular/core';
import { ConstantHelper } from '../../helpers/constant.helper';

@Component({
  selector: 'app-auditing-box',
  templateUrl: './auditing-box.component.html',
  styleUrls: ['./auditing-box.component.scss']
})
export class AuditingBoxComponent {

  @Input() public item: any;

  constructor(public constantHelper: ConstantHelper) { }
}
