import { Component } from '@angular/core';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { ConfirmEvent } from '@veritas-shared/models/confirm-event';
import { ModalService } from '@veritas-shared/services/modal.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

  public message: string;
  public isConfirmOpen: boolean;
  private confirmEvent: ConfirmEvent;

  constructor(private modalService: ModalService, public constantHelper: ConstantHelper) {

    this.modalService.confirm.subscribe((e: ConfirmEvent) => {
      this.confirmEvent = e;
      this.message = e.message;
      this.isConfirmOpen = true;
    });
  }

  public close(confirm: boolean = false) {
    if (confirm) {
      if (this.confirmEvent.onConfirm) {
        this.confirmEvent.onConfirm();
      }
    }
    else {
      if (this.confirmEvent.onCancel) {
        this.confirmEvent.onCancel();
      }
    }
    this.isConfirmOpen = false;
  }
}
