import { Component, OnInit, ViewChild } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@veritas-shared/services/toast.service';
import { DumpsterService } from '../../services/dumpster.service';
import { SessionService } from '@veritas-shared/services/session.service';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { DumpsterGridComponent } from '@veritas-shared/components/dumpster-grid/dumpster-grid.component';
import { DumpsterMapComponent } from '@veritas-shared/components/dumpster-map/dumpster-map.component';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { Label } from '@veritas-shared/models/label';
import { DumpsterListDeleteDumpstersInput } from '../../models/dumpster-list-delete-dumpsters-input';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Action } from '@veritas-shared/models/action';
import { BeanKendoDialogService, WindowContainerDimension, WindowVisualizerDirective } from 'bean-kendo-dialog';
import { DumpsterRegisterDialogComponent, DumpsterRegisterDialogInput } from '../../components/dumpster-register-dialog/dumpster-register-dialog.component';
import { DumpsterInitRegisterInput, DumpsterRegisterInput } from '../../models/dumpster-register-input';
import { map } from 'rxjs/operators';
import { SecurityService } from '@veritas-shared/services/security.service';
import { BeanDialogDimension } from 'bean-kendo-dialog/lib/classes/bean-dialog-dimension.class';
import { ISelectableCheckboxSettings } from '@veritas-shared/models/selectablecheckboxsettings.interface';
import { NavigationService } from '@veritas-shared/services/navigation.service';
import { MapIconService } from '@veritas-dumpsters/services/map-icon.service';

@Component({
  selector: 'app-dumpsters-unregistered',
  templateUrl: './dumpsters-unregistered.component.html',
  styleUrls: ['./dumpsters-unregistered.component.scss']
})
export class DumpstersUnregisteredComponent implements OnInit {

  @ViewChild(DumpsterGridComponent, { read: DumpsterGridComponent }) dumpsterGrid: DumpsterGridComponent;
  @ViewChild(DumpsterMapComponent, { read: DumpsterMapComponent }) dumpsterMap: DumpsterMapComponent;

  @ViewChild(WindowVisualizerDirective, { static: true }) public windowVisualizer: WindowVisualizerDirective;

  public filteredDumpsters: Dumpster[];
  private _tempFilteredDumpsters: Dumpster[]; // used for temporary store devices when show only selected is checked
  public labels: Label[];

  public dumpsters: Dumpster[];
  public selectedDumpsters: Dumpster[] = [];
  public selectableCheckboxSettings: ISelectableCheckboxSettings;

  public actions: Action[];
  public toolbarActions: Action[] = [];

  public isLabelsOpen: boolean;
  public isShowOnlySelected: boolean = false;

  constructor(
    private dumpsterService: DumpsterService,
    private toastService: ToastService,
    private library: FaIconLibrary,
    private translate: TranslateService,
    private sessionService: SessionService,
    private dialogService: BeanKendoDialogService,
    public constantHelper: ConstantHelper,
    private securityService: SecurityService,
    private iconService: MapIconService,
  ) {

    this.isLabelsOpen = !this.sessionService.getDisabledDumpsterLabelPaneHidden();

    this.library.addIcons(faAngleDoubleLeft, faAngleDoubleRight);

    this.deleteBulk = this.deleteBulk.bind(this);
    this.register = this.register.bind(this);

    this.selectableCheckboxSettings = {
      context: this,
      isSelectable: (dataItem: Dumpster, ctx: DumpstersUnregisteredComponent, currentSelectedIds: number[]) => {

        // GarbageType deve essere uguale
        if (currentSelectedIds && currentSelectedIds.length > 0) {
          let first = ctx.dumpsters.find(el => el.id == currentSelectedIds[0]);
          if (first)
            return dataItem.garbageType === first.garbageType;
        }

        return true;
      }
    }

    // Actions
    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_DUMPSTER_WRITE).subscribe(res => {
      if (res) {
        this.toolbarActions = [
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DELETE, 'trash-alt', this.deleteBulk, true),
          new Action('register', 'Registra', 'check', this.register, true),
        ];
      }
    });
  }

  ngOnInit() {
    this.loadData();
  }

  public toggleLabels() {
    this.isLabelsOpen = !this.isLabelsOpen;
    this.sessionService.setDisabledDumpsterLabelPaneHidden(this.isLabelsOpen == false);
  }

  public onTabChange(event: SelectEvent) {
    if (event.index == 0) {
      this.dumpsterGrid.refreshSelection();
    }
    if (event.index == 1) {
      window.setTimeout(() => this.dumpsterMap.centerAndZoomOnMarkers(), 200);
    }
  }

  public onSearchChanged() {
    this.selectedDumpsters = [];
    this.isShowOnlySelected = false;
  }

  public onLabelSelected(label: string) {
    if (label) {
      this.filteredDumpsters = this.dumpsters.filter(d => {
        return d.labels.findIndex(l => l.name == label) > -1;
      });
    }
    else {
      this.filteredDumpsters = this.dumpsters;
    }
  }

  public toggleShowOnlySelected() {
    if (this.isShowOnlySelected) {
      this._tempFilteredDumpsters = this.filteredDumpsters;
      this.filteredDumpsters = this.selectedDumpsters;
      this.dumpsterGrid.goToPage(0);
    }
    else {
      this.filteredDumpsters = this._tempFilteredDumpsters;
      this._tempFilteredDumpsters = [];
    }
  }

  public deleteBulk() {
    if (this.selectedDumpsters.length > 0) {
      const toDelete = new DumpsterListDeleteDumpstersInput(this.selectedDumpsters.map(d => d.id));
      this.dumpsterService.deleteDumpsters(toDelete).subscribe(result => {
        this.selectedDumpsters = [];
        this.toastService.showSuccess(
          this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DELETEDUMPSTERS_CONFIRM_MESSAGE, { '0': this.selectedDumpsters.length })
        );
        // Reload devices
        this.dumpsterService.getUnregisteredDumpsters().subscribe(result => {
          this.dumpsters = result.dumpsters;
          this.labels = result.labels;
          this.filteredDumpsters = result.dumpsters;
        });
      });
    }
  }

  public register(dumpsters: number[]) {

    let wasteType = this.dumpsters.find(el => el.id == dumpsters[0]).garbageType;
    this.dumpsterService.getRegisterData({ wasteType: wasteType } as DumpsterInitRegisterInput).subscribe(registerData => {
      this.dialogService.openDialog(
        "Registra",
        new DumpsterRegisterDialogInput(registerData),
        DumpsterRegisterDialogComponent,
        this.windowVisualizer,
        () => {

        },
        (res: DumpsterRegisterInput) => {

          // Set Dumpster Ids
          res.dumpsterIds = dumpsters;

          return this.dumpsterService.registerDumpster(res).pipe(map(() => {
            this.loadData();
          }));
        },
        true,
        WindowContainerDimension.Small,
        {
          smallHeight: 400,
          smallWidth: 650
        } as BeanDialogDimension
      );
    })
  }

  public getIconMap = (data: Dumpster) => this.iconService.getDetailIcon(data);

  private loadData() {
    this.dumpsterService.getUnregisteredDumpsters().subscribe(result => {
      this.selectedDumpsters = [];
      this.dumpsters = result.dumpsters;
      this.labels = result.labels;
      this.filteredDumpsters = result.dumpsters;
    });
  }
}
