import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IconHelper } from '../../helpers/icon-helper';
import * as fas from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FormGroup } from '@angular/forms';
import { ViewMode } from '../../models/view-mode';
import { Action } from '../../models/action';
import { ConstantHelper } from '../../helpers/constant.helper';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() public formGroup: FormGroup;
  @Input() public viewMode: ViewMode;
  @Input() public backAction: Action;
  @Input() public hideEdit: boolean = false;
  @Input() public hideOperations: boolean = false;


  @Input() public actions: Action[] = [];

  @Output() public edit = new EventEmitter();
  @Output() public view = new EventEmitter();
  @Output() public back = new EventEmitter();
  @Output() public save = new EventEmitter();
  @Output() public delete = new EventEmitter();
  @Output() public cancel = new EventEmitter();


  public ViewMode = ViewMode;

  constructor(
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper
  ) { }

  ngOnInit() {
    
    this.library.addIcons(fas.faPencilAlt, fas.faSlidersH, fas.faAngleLeft, fas.faSave, fas.faTimes, fas.faTrashAlt);
    
    if (this.backAction) {
      this.library.addIcons(fas[IconHelper.normalizeIconName(this.backAction.icon)])
    }

    if (this.actions) {
      this.actions.forEach(a => {
        if (a instanceof Action) {
          this.library.addIcons(fas[IconHelper.normalizeIconName((<Action>a).icon)])
        }
      });
    }
  }
}
