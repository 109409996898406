import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GridColumn } from "@veritas-shared/models/grid-column";
import { CommandeventService } from "../../services/commandevent.service";
import { CommandEvent } from "../../models/commandevent-list-output";
import { ConstantHelper } from "@veritas-shared/helpers/constant.helper";
import { Action } from "@veritas-shared/models/action";

@Component({
  selector: "app-commandevents",
  templateUrl: "./commandevents.component.html",
  styleUrls: ["./commandevents.component.scss"],
})
export class CommandEventsComponent implements OnInit {

  @ViewChild('statusColumn', { static: true }) public statusColumn: TemplateRef<any>;
  @ViewChild('dateColumn', { static: true }) public dateColumn: TemplateRef<any>;

  public columns: GridColumn[] = [];
  public gridData: CommandEvent[];
  private commandTaskId: number;
  public actions: Action[] = [];

  constructor(private commandEventService: CommandeventService, private route: ActivatedRoute, private router: Router, public constantHelper: ConstantHelper) {

    this.viewDumpsters = this.viewDumpsters.bind(this);

    this.actions = [
      new Action('viewDevices', this.constantHelper.LBL_ACTION_COMMON_VIEW_DUMPSTERS, 'sliders-h', this.viewDumpsters)
    ];
  }

  ngOnInit() {

    this.commandTaskId = +this.route.snapshot.paramMap.get('id');

    this.columns = [
      {
        field: 'commandName',
        title: 'page.CommandEvents.CommandName',
        sortable: true,
        resizable: true,
        filterable: true
      },
      {
        field: 'commandTaskName',
        title: 'page.CommandEvents.CommandTaskName',
        sortable: true,
        resizable: true,
        filterable: true
      },
      {
        field: 'createdAt',
        title: 'common.CreatedAt',
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'datetime',
      },
      {
        field: 'createdBy',
        title: 'common.CreatedBy',
        sortable: true,
        resizable: true,
        filterable: true
      },
      {
        field: 'dumpsterStatus',
        title: this.constantHelper.LBL_PAGE_COMMAND_EVENTS_DUMPSTERS_STATUS,
        sortable: true,
        resizable: true,
        filterable: false,
        template: this.statusColumn,
        width: "165em"
      }
    ];

    this.loadCommandEvents();
  }

  public viewDumpsters(commandEvents: CommandEvent) {
    this.router.navigate(['commands', 'event', commandEvents.id]);
  }


  private loadCommandEvents() {
    if (this.commandTaskId) {
      this.commandEventService.getCommandEventsByCommandTask(this.commandTaskId).subscribe(result => {
        this.gridData = result.commandEvents;
      });
    }
    else {
      this.commandEventService.getCommandEvents().subscribe(result => {
        this.gridData = result.commandEvents;
      });
    }
  }
}
