import { Injectable } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { SessionService } from '@veritas-shared/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router, private sessionSvc: SessionService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let history = this.sessionSvc.getNavigationHistory();
        if (history.length == 0 || history[history.length - 1] != event.urlAfterRedirects) {
          this.sessionSvc.addToNavigationHistory(event.urlAfterRedirects);
        }
      }
    });
  }

  back(defaultUrl: string = '/'): void {
    let currentPath = this.sessionSvc.popFromNavigationHistory();
    let history = this.sessionSvc.getNavigationHistory();
    if (history.length > 0) {

      let index = 0;
      let reversed = history.slice().reverse();

      for (var i = 0; i < history.length; i++) {
        index--;

        // Trova il primo path che è diverso dall'attuale
        // Rimuovo i pathParams
        if (reversed[i].replace(new RegExp(/\?.*/), "") != currentPath.replace(new RegExp(/\?.*/), "")) {
          break;
        }
      }

      window.history.go(index);
    } else {

      // Navigate to DefaultUrl
      this.router.navigateByUrl(defaultUrl);
    }
  }
}
