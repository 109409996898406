////////////////////////////////////////////////// Oggetti configurazione a front-end //////////////////////////////////////////////////

export interface CommandConfig{
    lowLevel: number;
    highLevel: number;
    enabled: boolean;
    forwardTimestamp?: Date;
    lastForwardStatus?: string;
    feedbackTimestamp?: Date;
}

export class DumpsterFullConfig implements CommandConfig {
    lowLevel: number = 80;
    highLevel: number = 90;
    maxBookings: number = 0;
    enabled: boolean = false;
    forwardTimestamp?: Date;
    lastForwardStatus?: string = 'none';
    feedbackTimestamp?: Date;

  }

export class LastEmptyingConfig implements CommandConfig {
    lowLevel: number = 3;
    highLevel: number = 5;
    enabled: boolean = true;
    forwardTimestamp?: Date;
    lastForwardStatus?: string  = 'none';
    feedbackTimestamp?: Date;

}

export class LastBookingConfig implements CommandConfig {
    lowLevel: number = 12;
    highLevel: number = 12;
    enabled: boolean = true;
    forwardTimestamp?: Date;
    lastForwardStatus?: string  = 'none';
    feedbackTimestamp?: Date;

}

export class LastConnectionConfig implements CommandConfig {
    lowLevel: number = 3;
    highLevel: number = 5;
    enabled: boolean = true;
    forwardTimestamp?: Date;
    lastForwardStatus?: string = "none";
    feedbackTimestamp?: Date;

}

////////////////////////////////////////////////// Request da inviare a backend //////////////////////////////////////////////////

export class CommandConfigurationRequest {
    dumpsterId: number;
    setParametersCommands: SetParametersCommand[];

    constructor(dumpsterId: number, setParametersCommands: SetParametersCommand[]) {
        this.dumpsterId = dumpsterId;
        this.setParametersCommands = setParametersCommands
    }
}

export class SetParametersCommand {
    ruleName: string;
    enabled: boolean; //togliere
    parameters: Parameter[]

    constructor(ruleName: string, enabled: boolean, parameters: Parameter[]) {
        this.ruleName = ruleName;
        this.enabled = enabled;
        this.parameters = parameters
    }
}

export class Parameter {
    parameterName: string;
    value: number; //any

    constructor(parameterName: string, value: number) { //value: any
        this.parameterName = parameterName;
        this.value = value
    }
}

///////////////////////////////////////////////// Response ricevuta dalla getConfiguration

export class GetConfigurationResponse{
    ruleName: string;
    enabled: boolean; //togliere
    parameters: Parameter[];
    forwardTimestamp?: Date;
    lastForwardStatus?: string;
    feedbackTimestamp?: Date;
}