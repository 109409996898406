import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaitService {
  private wait = new Subject<boolean>();
  private _callNumber: number;

  constructor() {
    this._callNumber = 0;
  }

  public showWait() {
    this._callNumber = this._callNumber + 1;
    this.wait.next(true);
  }

  public hideWait() {
    // stop loading only if all call are completed
    this._callNumber = this._callNumber - 1;
    if(this._callNumber > 0) return;
    
    this.wait.next(false);
  }

  public getWait(): Observable<boolean> {
    return this.wait.asObservable();
  }
}
