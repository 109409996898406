import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SelectableMode, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { CustomGridComponent } from '../custom-grid/custom-grid.component';
import { GridColumn } from '../../models/grid-column';
import { Dumpster } from '../../models/dumpster';
import { ConstantHelper } from '../../helpers/constant.helper';
import { Action } from '../../models/action';
import { EntityListOutput } from '../../models/entities/entity-list-output';
import { EntityService } from '../../services/entity.service';
import { EntitySearchInput } from '../../models/entities/entity-search-input';
import { ISelectableCheckboxSettings } from '../../models/selectablecheckboxsettings.interface';
import { Label } from '@veritas-shared/models/label';
import { DumpsterColumns } from '@veritas-dumpsters/constants/dumpster-column.const';
import { FieldDumpsterCodeComponent } from '../../../dumpsters/components/field-dumpster-code/field-dumpster-code.component';

@Component({
  selector: 'app-dumpster-grid',
  templateUrl: './dumpster-grid.component.html',
  styleUrls: ['./dumpster-grid.component.scss']
})
export class DumpsterGridComponent implements OnInit {
  @ViewChild('labelColumn', { static: true }) public labelColumn!: TemplateRef<any>;
  @ViewChild('filterTemplate', { static: true }) public filterTemplate!: TemplateRef<any>;
  @ViewChild('collectionPointColumn', { static: true }) public collectionPointColumn!: TemplateRef<any>;
  @ViewChild('columnStateTemplate', { static: true }) columnStateTemplate!: TemplateRef<any>;
  @ViewChild('columnWasteTypeTemplate', { static: true }) columnWasteTypeTemplate!: TemplateRef<any>;
  @ViewChild(CustomGridComponent) grid: CustomGridComponent;
  @ViewChild('fillLevelTemplate', { static: true }) fillLevelTemplate!: TemplateRef<any>;
  @ViewChild('templateCode', { static: true }) 
  public templateCode!: TemplateRef<FieldDumpsterCodeComponent>;
  
  public readonlyData: boolean = false;
  public checkboxOnlyData: boolean = false;
  public columns: GridColumn[] = []; 
  public selectableSettings: SelectableSettings;
  public entities: EntityListOutput;
  public selectedDumpstersId: number[];

  private _selectedDumpsters: Dumpster[];
  private columnDetail: DumpsterColumns;

  @Input() public items: Dumpster[];
  @Input() public labels: Label[];
  @Input() public actions: Action[];
  @Input() public toolbarActions: Action[] = [];
  @Input() public set readonly(data: boolean) {
    this.readonlyData = data;
    if (!this.selectableSettings) return;
    this.selectableSettings.enabled = !data;
  }
  @Input() public set checkboxOnly(data: boolean) {
    this.checkboxOnlyData = data;
    if (!this.selectableSettings) return;
    this.selectableSettings.checkboxOnly = data;
  }

  @Input() public selectableMode: SelectableMode = 'multiple';
  @Input() public defaultFilter: CompositeFilterDescriptor;
  @Input() public selectableCheckboxSettings: ISelectableCheckboxSettings;
  @Input() public set selectedDumpsters(val: Dumpster[]) {
    this._selectedDumpsters = val;
    if (this._selectedDumpsters)
      this.selectedDumpstersId = this._selectedDumpsters.map(d => d.id);
    else
      this.selectedDumpstersId = [];
  };

  public get selectedDumpsters(): Dumpster[] { return this._selectedDumpsters; }

  @Output() public selectedDumpstersChange = new EventEmitter();
  @Output() public visibleRowsCountChange = new EventEmitter<any>();
  @Output() public labelSelect: EventEmitter<string>;

  constructor(
    private router: Router,
    public constantHelper: ConstantHelper,
    private entityService: EntityService,
  ) {
    this.labelSelect = new EventEmitter<string>();
    this.columnDetail = new DumpsterColumns();
  }

  ngOnInit() {
    this._setColumns();
    this._setSelectableSettings();
    this._getEntities();
  }

  public getSelectedLabel = (label: string) => this.labelSelect.emit(label);

  public refreshSelection = () => {
    if (this._selectedDumpsters)
      this.selectedDumpstersId = this._selectedDumpsters.map(d => d.id);
    else
      this.selectedDumpstersId = [];
  }
  
  public onSelectionChange = (e: SelectionEvent) => {
    let selected = Object.assign([], this._selectedDumpsters);
    if (e.selectedRows.length > 0) {
      e.selectedRows.forEach(r => selected.push(r.dataItem));
    }
    if (e.deselectedRows.length > 0) {
      e.deselectedRows.forEach(r => {
        let i = selected.findIndex(d => d.id == r.dataItem.id);
        if (i > -1) {
          selected.splice(i, 1);
        }
      });
    }
    this._selectedDumpsters = selected;
    this.selectedDumpstersChange.emit(this._selectedDumpsters);
  };

  public goToPage = (page: number)  => this.grid.goToPage(page);

  public view = (dumpster: Dumpster) => this.router.navigate(['dumpsters', 'list', dumpster.id]);

  private _getEntities = () => this.entityService.getEntities({ deviceState: true, wasteType: true } as EntitySearchInput).subscribe(
    (res: EntityListOutput) => { this.entities = res; }
  )

  private _setColumns = () => {
    this.columns = [
      this.columnDetail.code(this.constantHelper, this.view, this.templateCode),
      this.columnDetail.binId(this.constantHelper, this.view),
      this.columnDetail.labels(this.constantHelper, this.labelColumn, this.filterTemplate),
      this.columnDetail.supplierName(this.constantHelper),
      this.columnDetail.binType(this.constantHelper),
      this.columnDetail.garbageType(this.constantHelper, this.columnWasteTypeTemplate),
      this.columnDetail.collectionPointCode(this.constantHelper, this.collectionPointColumn),
      this.columnDetail.bookingCount(this.constantHelper, this.fillLevelTemplate),
      this.columnDetail.errorCount(this.constantHelper),
      this.columnDetail.warningCount(this.constantHelper),
      this.columnDetail.state(this.constantHelper, this.columnStateTemplate),
    ];
  }

  private _setSelectableSettings = () => {
    this.selectableSettings = {
      enabled: !this.readonlyData,
      mode: this.selectableMode,
      checkboxOnly: this.checkboxOnlyData
    };
  }
}
