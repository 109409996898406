<div class="row">
  <div class="col">
    <form [formGroup]="formSimpleSearch" (keyup.enter)="onSimpleSearch()" novalidate>
      <input type="text" class="form-control" formControlName="search" placeholder="{{ constantHelper.LBL_ACTION_COMMON_SEARCH | translate}} {{ 'vocabulary.AuthorizationGroups' | translate}}" />
    </form>
  </div>
  <div class="col">
    <div #searchButtons class="btn-group">
      <button type="button" class="btn btn-outline-primary" (click)="onSimpleSearch()">
        <fa-icon icon="search"></fa-icon>
        {{ constantHelper.LBL_ACTION_COMMON_SEARCH | translate }}
      </button>
      <div class="btn-group" placement="bottom-right">
        <button class="btn btn-outline-primary" (click)="toggleAdvSearch()">
          <fa-icon icon="caret-down"></fa-icon>
        </button>
      </div>
    </div>
    <kendo-popup #advSearch [anchor]="searchButtons" (anchorViewportLeave)="showAdvSearch = false" [anchorAlign]="advSearchAnchorAlign" [popupAlign]="advSearchAlign" *ngIf="showAdvSearch">
      <div>
        <form [formGroup]="formAdvSearch" class="form-search px-4 py-3" novalidate>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">{{ 'vocabulary.AuthorizationGroup.Name' | translate}}</label>
            <div class="col-sm-9">
              <input type="text" formControlName="name" class="form-control">
            </div>
          </div>
          <!--<div class="form-group row">
            <label class="col-sm-3 col-form-label">{{ 'vocabulary.AuthorizationGroup.City' | translate}}</label>
            <div class="col-sm-9">
              <kendo-multiselect [data]="cities"
                                 [filterable]="true"
                                 placeholder=""
                                 class="w-100"
                                 formControlName="cities">
              </kendo-multiselect>
            </div>
          </div>-->
          <div>
            <button type="button" class="btn btn-primary float-right" (click)="onAdvancedSearch()">
              <fa-icon icon="search"></fa-icon>
              {{ constantHelper.LBL_ACTION_COMMON_SEARCH | translate }}
            </button>
          </div>
        </form>
      </div>
    </kendo-popup>
    <button *ngIf="searched" type="button" class="btn btn-outline-primary ml-2" (click)="onClearSearch()">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>
</div>
