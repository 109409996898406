import { Directive, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { DropDownListComponent } from "@progress/kendo-angular-dropdowns";
import { minCharts } from "@veritas-shared/constants/paginated-filter.model";
import { PaginatedFilterService } from "@veritas-shared/services/paginated-filter.service";

@Directive()
export class DropDownPaginated<T> {
  @ViewChild('dropdownlist', { static: true })
  public dropdownlist!: DropDownListComponent;

  public options: T[];
  public itemSearch: string;

  constructor(
    protected paginationService: PaginatedFilterService,
    protected renderer: Renderer2, 
    protected elRef: ElementRef
  ) {
    this.options = [];
  }

  public setFilter = (value: string) => {
    const isDirty: boolean = this._getIsDirty(value);
    if(value === null || isDirty) return;

    this.itemSearch = value;
    if(value.length < minCharts) {
      this.paginationService.setResetFilter();
      return;
    }
    this.paginationService.getPaginatedFilter(value);
  }

  // Configure dropDown to close list on click outside
  public setListOpen = () => this.renderer.listen('document', 'click', (event: MouseEvent) => {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.dropdownlist.toggle(false);
      this.setDefaultDropDown();
    }
  });

  public setDefaultDropDown = () => {
    this.itemSearch = '';
    this.dropdownlist.filterText = '';
    this.options = [];
  };

  protected _setSearchedPlant = () => { 
    this.dropdownlist.filterText = this.itemSearch;
    this.dropdownlist.toggle(true);
  }

  private _getIsDirty = (value: string): boolean => value === '' && this.itemSearch?.length !== 1;
}