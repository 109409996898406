import { Pipe, PipeTransform } from '@angular/core';
import * as fas from '@fortawesome/free-solid-svg-icons';

@Pipe({
  name: 'iconName'
})
export class IconNamePipe implements PipeTransform {

  transform(value: string): any {
    if (value == null) {
      return null;
    }

    let iconDefinition = fas[value];
    if (iconDefinition) {
      return iconDefinition.iconName;
    }
    else {
      return null;
    }
  }
}
