export class IconHelper {

  public static normalizeIconName(iconName: string): string {
    if (!iconName.startsWith('fa')) {
      iconName = 'fa' + this.toPascalCase(iconName);
      iconName = iconName.replace(/-/g, '');
    }
    return iconName;
  }

  private static toPascalCase(s: string) {
    s = s.replace(/\w+/g,
      function (w) { return w[0].toUpperCase() + w.slice(1).toLowerCase(); });
    return s;
  }
}
