
<div class="dialog-body">
  <div class="my-2">
    <app-group-search [items]="data?.allGroups" [(filteredItems)]="filteredAllGroups"></app-group-search>
  </div>
  <div class="my-2">
    <span>{{ constantHelper.LBL_PAGE_AUTHGROUPS_NUMBEROFFILTERED | translate:{'0': filteredAllGroups ? filteredAllGroups.length : '0', '1': data?.allGroups ? data?.allGroups.length : '0', '2': selectedGroupsToAdd?.length} }}</span>
  </div>
  <div class="grid-container-groups">
    <app-group-grid [items]="filteredAllGroups" [(selectedGroups)]="selectedGroupsToAdd"></app-group-grid>
  </div>
</div>

<div class="dialog-footer">
  <button type="button" class="btn btn-outline-secondary mt-2" (click)="backButtonClick()">
    <fa-icon icon="chevron-left"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_CANCEL | translate }}
  </button>
  <button type="button" class="btn btn-primary float-right mt-2" [disabled]="selectedGroupsToAdd.length == 0" (click)="saveButtonClick()">
    <fa-icon icon="save"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_SAVE | translate }}
  </button>
</div>
