import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'configurationStatusTranslate'
})
export class ConfigurationStatusTranslatePipe implements PipeTransform {

  transform(value: string): string {
    if (value == null) {
      return null;
    }

    switch (value.toLowerCase()){
      case "failed": return "Fallito"
      case "successful": return "Successo"
      case "sent": return "Inviato"
      default: return null
    }
  }
}
