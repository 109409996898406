<div class="dialog-body">
  <div class="my-2">
    <app-user-search [items]="data?.allUsers" [(filteredItems)]="filteredAllUsers"></app-user-search>
  </div>
  <div class="my-2">
    <span>{{ constantHelper.LBL_PAGE_USERS_NUMBEROFFILTERED | translate:{'0': filteredAllUsers ? filteredAllUsers.length : '0', '1': data?.allUsers ? data?.allUsers.length : '0', '2': selectedUsersToAdd?.length} }}</span>
  </div>

  <div class="dialog-grid-body">
    <app-user-grid [items]="filteredAllUsers"
                   [(selectedUsers)]="selectedUsersToAdd"
                   [readonly]="data?.isUsersListReadonly">
    </app-user-grid>
  </div>
</div>

<div class="dialog-footer">
  <button type="button" class="btn btn-outline-secondary mt-2" (click)="backButtonClick()">
    <fa-icon icon="chevron-left"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_CANCEL | translate }}
  </button>
  <button type="button" class="btn btn-primary float-right mt-2" (click)="saveButtonClick()">
    <fa-icon icon="save"></fa-icon>
    {{ constantHelper.LBL_ACTION_COMMON_SAVE | translate }}
  </button>
</div>
