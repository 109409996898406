import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DeactivatedComponent } from '@veritas-shared/components/deactivated.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViewMode } from '@veritas-shared/models/view-mode';
import { ToastService } from '@veritas-shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave, faDumpster } from '@fortawesome/free-solid-svg-icons';
import { ViewModeHelper } from '@veritas-shared/helpers/view-mode-helper';
import { SecurityService } from '@veritas-shared/services/security.service';
import { SessionService } from '@veritas-shared/services/session.service';
import { CommandService } from '../../services/command.service';
import { EditCommandInput } from '../../models/edit-command-input';
import { NewCommandInput } from '../../models/new-command-input';
import { NewCommandOutputCommand } from '../../models/new-command-output';
import { EditCommandOutputCommand } from '../../models/edit-command-output';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { CommandParameter } from '../../models/command-parameter';
import { ParameterType } from '../../models/parametertype.enum';
import { Action } from '@veritas-shared/models/action';
import { NavigationService } from '@veritas-shared/services/navigation.service';

@Component({
  selector: 'app-command-detail',
  templateUrl: './command-detail.component.html',
  styleUrls: ['./command-detail.component.scss']
})
export class CommandDetailComponent extends DeactivatedComponent implements OnInit {

  @ViewChild('columnTypeTemplate', { static: true }) columnTypeTemplate: TemplateRef<any>;

  public formCommand: FormGroup;

  public actions: Action[];
  public toolbarActions: Action[] = [];

  public viewMode: ViewMode;

  public ViewMode = ViewMode;

  private commandId: number;

  public command: NewCommandOutputCommand | EditCommandOutputCommand;
  public gridColumns: GridColumn[] = [];
  public gridToolbarActions: Action[] = [];
  public gridData: CommandParameter[];

  constructor(
    private commandService: CommandService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private translate: TranslateService,
    private securityService: SecurityService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private library: FaIconLibrary,
    private navigationService: NavigationService
  ) {
    super();

    this.onEdit = this.onEdit.bind(this);
    this.onView = this.onView.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onAddParameter = this.onAddParameter.bind(this);

    this.library.addIcons(faSave, faChevronLeft, faDumpster);

    //this.actions = [
    //  new Action('edit', 'action.Common.Edit', 'pencil-alt', this.edit),
    //  new Action('delete', 'action.Common.Delete', 'trash-alt', this.delete)
    //];

    this.gridToolbarActions = [
    //  new Action('add', 'action.Common.Add', 'plus', this.onAddParameter)
    ];
  }

  canDeactivate(): boolean {
    return !this.formCommand.dirty;
  }

  ngOnInit() {

    this.gridColumns = [
      {
        field: 'name',
        title: 'page.CommandDetail.Section.Parameters.Name',
        sortable: false,
        resizable: false,
        filterable: false,
      },
      {
        field: 'type',
        title: 'page.CommandDetail.Section.Parameters.Type',
        sortable: false,
        resizable: false,
        filterable: false,
        template: this.columnTypeTemplate
      }
    ];

    this.viewMode = ViewModeHelper.getViewMode(this.route);

    this.commandId = +this.route.snapshot.paramMap.get('id');

    this.formCommand = this.fb.group({
      id: [''],
      name: [{ value: '', disabled: this.viewMode != ViewMode.New }, Validators.required],
      description: [{ value: '', disabled: this.viewMode == ViewMode.View }],
      //parameters: [{ value: '', disabled: this.viewMode == ViewMode.View }],
      template: [{ value: '', disabled: this.viewMode == ViewMode.View }],
    });

    switch (this.viewMode) {
      case ViewMode.Edit:
      case ViewMode.View:
        this.commandService.getEditCommand(this.commandId).subscribe(result => {
          this.command = result.command;
          this.sessionService.setPageTitle(result.command.name);
          this.formCommand.patchValue(result.command);
          this.gridData = result.command.parameters;
        });
        break;
      case ViewMode.New:
        this.commandService.getNewCommand().subscribe(result => {
          this.formCommand.patchValue(result.command);
          this.gridData = result.command.parameters;
        });
        break;
    }

  }

  public onEdit() {
    //this.router.navigate(['commands', 'management', this.commandId, 'edit']);
  }

  public onView() {
    //this.router.navigate(['commands', 'management', this.commandId]);
  }

  public onBack() {
    this.goBack();
  }

  public onCancel() {
    this.formCommand.reset(this.command);
  }

  public onDelete() {
    this.commandService.deleteCommand(this.commandId).subscribe(result => {
      this.toastService.showSuccess(
        this.translate.instant('page.Command.Dialog.Delete.ConfirmationMessage', { '0': 1 })
      );
      this.goBack();
    });
  }

  public goBack() {
    this.navigationService.back('/commands/management');
  }

  public onSave() {
    if (this.formCommand.valid) {
      if (this.viewMode == ViewMode.Edit) {
        let commandInput = <EditCommandInput>this.formCommand.getRawValue();
        commandInput.parameters = this.gridData;
        this.commandService.updateCommand(commandInput).subscribe(result => {
          this.formCommand.reset();
          this.goBack();
        });
      }
      else if (this.viewMode == ViewMode.New) {
        let commandInput = <NewCommandInput>this.formCommand.getRawValue();
        commandInput.parameters = this.gridData;
        this.commandService.addCommand(commandInput).subscribe(result => {
          this.formCommand.reset();
          this.goBack();
        });
      }

    }
  }

  public onAddParameter() {
    let newParameter: CommandParameter;

    newParameter = <CommandParameter>{ name: '', type : ParameterType.Integer };
    this.gridData.push(newParameter);
  }

}
