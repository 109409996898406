import { Injectable, Inject } from '@angular/core';
import { ApiService } from '@veritas-shared/services/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { LabelListOutput } from '../models/label-list-output';
import { ToastService } from '@veritas-shared/services/toast.service';
import { WaitService } from '@veritas-shared/services/wait.service';
import { NewLabelInput } from '../models/new-label-input';
import { EditLabelOutput } from '../models/edit-label-output';
import { NewLabelOutput } from '../models/new-label-output';
import { ToastService as BeanToastService } from "bean-notification";
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { Label } from '@veritas-shared/models/label';

@Injectable({
  providedIn: 'root'
})
export class LabelService extends ApiService {

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, toastService: ToastService, waitService: WaitService, commonHelper: CommonHelper, newToastService: BeanToastService) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  public getLabels(): Observable<LabelListOutput> {
    return this.getBase<LabelListOutput>('api/label');
  }

  public addLabel(label: NewLabelInput): Observable<void> {
    return this.postBase<NewLabelInput, void>('api/label', label);
  }

  public getEditLabel(id: number): Observable<EditLabelOutput> {
    return this.getBase<EditLabelOutput>('api/label/' + id + '/edit');
  }

  public getNewLabel(): Observable<NewLabelOutput> {
    return this.getBase<NewLabelOutput>('api/label/new');
  }

  public deleteLabel(id: number): Observable<void> {
    return this.deleteBase('api/label/' + id);
  }

  public updateLabel(label: Label): Observable<void> {
    return this.putBase<Label, void>('api/label', label);
  }

}
