<h1 class="page-title"><fa-icon icon="user-friends"></fa-icon>{{ constantHelper.LBL_PAGE_COMMANDS_TITLE | translate }}</h1>

<div class="page-content">
  <div class="mt-10">
    <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_COMMANDS_GRID_TITLE | translate }}">

      <div class="grid-container standard-padding">
        <app-custom-grid [columns]="columns" [items]="gridData" [filterable]="true"></app-custom-grid>
      </div>
    </app-group-box>
  </div>
</div>

<ng-template #isSystemColumn let-dataItem>
  <app-info-icon *ngIf="dataItem.isSystem" [icon]="'user-shield'" class="text-primary" tooltip="{{ constantHelper.LBL_COMMON_ISSYSTEM | translate }}"></app-info-icon>
</ng-template>
