import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { ViewModeHelper } from '@veritas-shared/helpers/view-mode-helper';
import { ViewMode } from '@veritas-shared/models/view-mode';
import { ToastService } from '@veritas-shared/services/toast.service';
import { DeactivatedComponent } from '@veritas-shared/components/deactivated.component';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@veritas-shared/services/session.service';
import { LabelService } from '../../services/label.service';
import { NewLabelInput } from '../../models/new-label-input';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { SecurityService } from '@veritas-shared/services/security.service';
import { Label } from '@veritas-shared/models/label';

@Component({
  selector: 'app-label-detail',
  templateUrl: './label-detail.component.html',
  styleUrls: ['./label-detail.component.scss']
})
export class LabelDetailComponent extends DeactivatedComponent implements OnInit, OnDestroy {

  public hasWritePermission: boolean;

  public formLabel: FormGroup;
  public label: any;
  private labelId: number;

  public viewMode: ViewMode;
  public ViewMode = ViewMode;

  private querySubscription: Subscription;

  constructor(
    private labelService: LabelService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private sessionService: SessionService,
    private translate: TranslateService,
    private securityService: SecurityService,
    private route: ActivatedRoute,
    private router: Router,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper
  ) {
    super();

    this.onEdit = this.onEdit.bind(this);
    this.onView = this.onView.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.library.addIcons(faSave, faChevronLeft);


    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_LABEL_MANAGEMENT).subscribe(res => {
      this.hasWritePermission = res;
    });

    // Params
    this.route.params.subscribe(params => {
      if (params) {
        this.labelId = +params.labelId;
      }
    });

    // Query Params
    this.querySubscription = combineLatest(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
      ),
      this.route.queryParams
    ).subscribe(res => {

      // Set ViewMode
      this.viewMode = ViewModeHelper.getViewMode(this.route, 'labelId');

      // Enalbe / Disable the form
      if (this.formLabel) {
        let newForm = this.setFormGroup();
        this.commonHelper.enableValidFormFields(this.formLabel, newForm);
      }

      // Set PageTitle
      if (this.viewMode == ViewMode.View && this.label) {
        this.sessionService.setPageTitle(this.label.name);
      }
    });

  }

  canDeactivate(): boolean {
    return !this.formLabel.dirty;
  }

  ngOnInit() {
    this.formLabel = this.setFormGroup();

    switch (this.viewMode) {
      case ViewMode.Edit:
      case ViewMode.View:
        this.labelService.getEditLabel(this.labelId).subscribe(result => {
          this.label = result.label;
          this.sessionService.setPageTitle(result.label.name);
          this.formLabel.patchValue(result.label);
        });
        break;
      case ViewMode.New:
        this.labelService.getNewLabel().subscribe(result => {
          this.formLabel.patchValue(result.label);
        });
        break;
    }
  }

  ngOnDestroy() {
    if (this.querySubscription != null) {
      this.querySubscription.unsubscribe();
    }
  }

  private setFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      name: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
      //type: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required],
      color: [{ value: '', disabled: this.viewMode == ViewMode.View }, Validators.required]
    });
  }

  public onEdit() {
    this.router.navigate([], { queryParams: { edit: true }, relativeTo: this.route });
  }

  public onView() {
    this.router.navigate([], { relativeTo: this.route });
  }

  public onBack() {
    this.goBack();
  }

  public goBack() {
    this.router.navigate(['dumpsters', 'labels']);
  }

  public onDelete() {
    this.labelService.deleteLabel(this.labelId).subscribe(result => {
      this.toastService.showSuccess(
        this.translate.instant(this.constantHelper.LBL_PAGE_LABEL_DETAIL_DIALOG_DELETE_CONFIRM_MESSAGE, { '0': 1 })
      );
      this.goBack();
    });
  }

  public onCancel() {
    this.formLabel.reset(this.label);
  }

  public onSave() {

    if (this.formLabel.valid) {

      if (this.viewMode == ViewMode.Edit) {
        let labelInput = <Label>this.formLabel.getRawValue();
        this.labelService.updateLabel(labelInput).subscribe(result => {
          this.formLabel.reset();
          this.goBack();
        });
      }
      else if (this.viewMode == ViewMode.New) {
        let labelInput = <NewLabelInput>this.formLabel.getRawValue();
        this.labelService.addLabel(labelInput).subscribe(result => {
          this.formLabel.reset();
          this.goBack();
        });
      }
    }
  }

}
