import { Component, Input } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faDumpster } from '@fortawesome/free-solid-svg-icons';
import { IRelationIcon, relationIcon } from '@veritas-dumpsters/constants/dumpster-relation.const';
import { Dumpster } from '@veritas-shared/models/dumpster';

@Component({
  selector: 'app-relation-icon',
  templateUrl: './relation-icon.component.html',
  styleUrls: ['./relation-icon.component.scss']
})
export class RelationIconComponent {
  @Input() public dumpster: Dumpster;

  public readonly relation: IRelationIcon = relationIcon;

  constructor(
    private library: FaIconLibrary,
  ) {
    this.library.addIcons(faDumpster); 
  }
}
