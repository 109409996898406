<h1 class="d-flex justify-content-between page-title" *ngIf="viewMode != ViewMode.New" [ngClass]="{'has-ticket': hasTicket }">
  <div class="d-flex" [ngClass]="dumpster?.parentDumpsterId || dumpster?.childDumpsterId ? 'icon-wrapper' : ''">
    <fa-icon *ngIf="!dumpster?.parentDumpsterId && !dumpster?.childDumpsterId" icon="dumpster"></fa-icon>
    <app-relation-icon class="icon-large" [dumpster]="dumpster"></app-relation-icon>
    {{ dumpster?.code }}
  </div>
  <!--<sup *ngIf="!hasTicket" class="ml-3"><span class="badge badge-pill no-ticket align-items-center"><fa-icon icon="ticket-alt"></fa-icon>No Ticket</span></sup>-->
  <!--<sup *ngIf="hasTicket" class="ml-3" ><span class="badge badge-pill badge-secondary align-items-center"><fa-icon icon="ticket-alt"></fa-icon>Ticket</span></sup>-->
  <button *ngIf="hasTicket" type="button" class="btn ticket-toolbar-item btn-secondary float-right mr-3" [disabled]="!ticketChecked" (click)="viewTicket(ticket.ticketId, ticket.entityId)" kendoTooltip icon="ticket-alt" title="Ticket aperto">
    <fa-icon class="ticket-button-icon" icon="ticket-alt"></fa-icon>
    <span>Vai al ticket</span>
  </button>
  <button *ngIf="!hasTicket" type="button" class="btn ticket-toolbar-item btn-primary float-right mr-3" [disabled]="!ticketChecked" (click)="newTicket()">
    <fa-icon class="ticket-button-icon" icon="plus"></fa-icon>
    <span>Crea ticket</span>
  </button>
</h1>
<h1 class="page-title" *ngIf="viewMode == ViewMode.New"><fa-icon icon="dumpster"></fa-icon>{{ constantHelper.LBL_PAGE_DEVICEDETAIL_NEW_TITLE | translate }}</h1>
<app-toolbar [formGroup]="formDumpster" [viewMode]="viewMode" [actions]="toolbarActions" (view)="onView()" (back)="onBack()" (save)="onSave()" (cancel)="onCancel()" [hideEdit]="true" [hideOperations]="!hasWritePermission"></app-toolbar>

<div class="page-content">

  <!-- GENERAL -->
  <div class="mt-10">
    <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_GENERAL_TITLE | translate }}">
      <form [formGroup]="formDumpster" class="box-standard-padding" novalidate>
        <!-- -->
        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{constantHelper.LBL_VOCABULARY_DUMPSTER_CODE | translate}}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_CODE | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control k-text-box" formControlName="code">
          </div>

          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_DUMPSTER_SUPPLIER | translate }}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_SUPPLIER | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="supplierName">
          </div>

          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_DUMPSTER | translate }}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER | translate }}</label>
          <div class="col-xl-3 col-md-10 col-12 form-group">
            <input type="text" class="form-control k-text-box" formControlName="binId">
          </div>
        </div>

        <!-- -->
        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_FIRST | translate }}">{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_FIRST | translate }}</label>
          <div class="col-xl-3 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="regionCode">
          </div>

          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_SECOND | translate }}">{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_SECOND | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="regionCodeSecond">
          </div>
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_THIRD | translate }}">{{ constantHelper.LBL_PAGE_DEVICEDETAIL_REGION_CODE_THIRD | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="regionCodeThird">
          </div>
        </div>

        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_COLLECTIONPOINT | translate }}">{{ constantHelper.LBL_VOCABULARY_COLLECTIONPOINT | translate }}</label>
          <div class="col-xl-3 col-md-10 col-12 form-group">
            <kendo-combobox [data]="collectionPoints"
                            [textField]="'name'"
                            [valueField]="'id'"
                            [valuePrimitive]="true"
                            [filterable]="true"
                            (filterChange)="handleCollectionPointsFilter($event)"
                            (valueChange)="onCollectionPointSelected($event)"
                            formControlName="collectionPointId">
            </kendo-combobox>
          </div>

          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_DUMPSTER_TYPE | translate }}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_TYPE | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="binType">
          </div>
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_DUMPSTER_GARBAGE_TYPE | translate }}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_GARBAGE_TYPE | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" [value]="formDumpster.get('garbageType').value | wasteType:entities?.wasteTypes" formControlName="garbageType" />
          </div>
        </div>

        <!-- -->
        <div class="row">
          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_DUMPSTER_USER_TYPE | translate }}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_USER_TYPE | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <kendo-combobox [data]="entities?.userTypes"
                            formControlName="userType"
                            textField="description"
                            valueField="userType"
                            [valuePrimitive]="true">
            </kendo-combobox>
          </div>

          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_DUMPSTER_TAG | translate }}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_TAG | translate }}</label>
          <div class="col-xl-3 col-md-4 col-12 form-group">
            <input type="text" class="form-control" formControlName="tag" />
          </div>

          <label class="col-xl-1 col-md-2 col-12 col-form-label" title="{{ constantHelper.LBL_VOCABULARY_DUMPSTER_GUID | translate }}">{{ constantHelper.LBL_VOCABULARY_DUMPSTER_GUID | translate }}</label>
          <div class="col-xl-3 col-md-10 col-12 form-group">
            <input type="text" class="form-control" formControlName="imei" />
          </div>
        </div>

        <!-- -->
        <div class="row">
          <div class="col-xl-4 col-md-6 col-sm-12">
            <div class="row">
              <label class="col-xl-3 col-md-4 col-12 col-form-label" title="Address">Indirizzo</label>
              <div class="col-xl-9 col-md-8 form-group" style="display: flex;">
                <input type="text" class="form-control k-text-box" formControlName="address" style="flex-grow:4;">
                <div style="align-self: center; margin-left: 5px;">
                  <fa-icon icon="{{addressTypeIcon}}" [title]="addressTooltip"></fa-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 col-sm-12">
            <div class="row">
              <label class="col-xl-3 col-md-4 col-12 col-form-label" title="{{ '$lbl.latLong' | translate }}">{{ '$lbl.latLong' | translate }}</label>
              <div class="col-xl-9 col-md-8 col-12 form-group">
                <input type="string" step="any" class="form-control" formControlName="location" />
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 col-sm-12">
            <app-dumpster-relation
              [dumpster]="dumpster"
              (emitDumpster)="dumpster = $event"
            ></app-dumpster-relation>
          </div>
        </div>

      </form>

      <!-- MAP -->
      <div class="col-12 status-map-container" *ngIf="viewMode == ViewMode.New">
        <div class="new-map-container">
          <app-dumpster-map 
            mode="single"
            [items]="dumpsters"
            [(selectedDumpsters)]="selectedDumpsters"
            [onClickSetMarker]="true"
            [isTitleNavigable]="false"
            (selectedMarkerChange)="onMarkerSet($event)"
          ></app-dumpster-map>
        </div>
      </div>

    </app-group-box>
  </div>

   <!-- STATUS / MAP -->
   <div class="" *ngIf="viewMode != ViewMode.New">
    <div class="row">
      <!-- STATUS -->
      <div class="col-xl-4 col-md-12 col-12 status-map-container">
        <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_STATUS_TITLE | translate }}">
          <div class="row box-standard-padding">
            <div class="col-12 status-data">

              <div class="row">
                <div class="col-md-6 col-12"><span class="bold">Stato:</span></div>
                <div class="col-md-6 col-12 ">{{dumpster?.state | deviceState:entities?.deviceStates}}</div>
              </div>

              <div class="row">
                <div class="col-md-6 col-12"><span class="bold">Livello Batteria:</span></div>
                <div class="col-md-6 col-12 data-field">{{(dumpsterStatusData?.batteryLevelPercentage != null) ? dumpsterStatusData?.batteryLevelPercentage + ' %' : '' }}</div>
              </div>

              <div class="row">
                <div class="col-md-6 col-12"><span class="bold">Latitudine/longitudine:</span></div>
                <div class="col-md-6 col-12 data-field">{{dumpsterStatusData?.latitude && dumpsterStatusData?.longitude ? (dumpsterStatusData?.latitude | number:'1.2-3') + ' / ' + (dumpsterStatusData?.longitude | number:'1.2-3') : ''}}</div>
              </div>

              <div class="row">
                <div class="col-md-6 col-12"><span class="bold">Ultimo Conferimento:</span></div>
                <div class="col-md-6 col-12 data-field">{{ dumpster?.lastBookingTimestamp | date: constantHelper.DATE_TIME_FORMAT}}</div>
              </div>

              <div class="row">
                <div class="col-md-6 col-12"><span class="bold">Ultimo Passaggio Camion:</span></div>
                <div class="col-md-6 col-12 data-field">{{ dumpster?.lastEmptyingTimestamp | date: constantHelper.DATE_TIME_FORMAT}}</div>
              </div>

              <div class="row">
                <div class="col-md-6 col-12"><span class="bold">Conferimenti:</span></div>
                <div class="col-md-6 col-12 data-field" *ngIf="dumpster">{{dumpster?.bookingCount == null ? '-' : dumpster?.bookingCount}}/{{dumpster?.maxBookingCount == null ? '-' : dumpster?.maxBookingCount}} ({{dumpster?.bookingCount == null || !dumpster?.maxBookingCount ? '-' : (dumpster?.bookingCount/dumpster?.maxBookingCount)*100 | number: '1.0-0'}}%)</div>
              </div>

              <div class="row">
                <div class="col-md-6 col-12"><span class="bold">Ultima trasmissione:</span></div>
                <div class="col-md-6 col-12 data-field">{{ dumpsterStatusData?.stateTimestamp | date: constantHelper.DATE_TIME_FORMAT}}</div>
              </div>

            </div>
          </div>
        </app-group-box>
      </div>
      <!-- MAP -->
      <div class="col-xl-8 col-md-12 col-12 status-map-container">
        <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_MAP_TITLE | translate }}">
          <div class="map-container">
            <app-dumpster-map
              *ngIf="dumpster"
              [isTitleNavigable]="false"
              [mode]="'single'"
              [items]="dumpsters"
              [(selectedDumpsters)]="selectedDumpsters"
              [markerCustom]="setIconMap"
            ></app-dumpster-map>
          </div>
        </app-group-box>
      </div>
    </div>
  </div>

  <!-- EVENTS  -->
  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-group-box pageTitle="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_TIMELINE_TITLE | translate }}">

      <div class="box-standard-padding events-data">
        <app-custom-grid [defaultSort]="eventsDefaultSort"
                         [columns]="eventsColumns"
                         [pageable]="true"
                         [pageSizes]="true"
                         [filterable]="true"
                         [items]="events">
        </app-custom-grid>
      </div>
    </app-group-box>
  </div>

  <!-- AUDITING -->
  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-auditing-box [item]="dumpster"></app-auditing-box>
  </div>

  <!-- GROUPS -->
  <div class="" *ngIf="viewMode != ViewMode.New">
    <app-info-sequence title="{{ constantHelper.LBL_PAGE_DEVICEDETAIL_GROUPS_TITLE | translate }}"
                       [items]="groups"
                       (onClick)="goToGroupDetail($event)">
    </app-info-sequence>
  </div>
</div>

<!-- TEMPLATE -->
<ng-template #columnPayloadTemplate
             let-dataItem>
  {{ dataItem.payloadType | payloadType:entities?.payloadTypes }}
</ng-template>


<ng-template windowVisualizer></ng-template>
