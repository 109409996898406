import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faKey, faPencilAlt, faTrashAlt, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { ModalService } from '@veritas-shared/services/modal.service';
import { AuthorizationListOutputItem } from '../../models/authorization-list-output';
import { AuthorizationService } from '../../services/authorization.service';
import { DisableAuthorizationInput } from '../../models/disable-authorization-input';
import { Action } from '@veritas-shared/models/action';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { SecurityService } from '@veritas-shared/services/security.service';
import { NavigationService } from '@veritas-shared/services/navigation.service';

@Component({
  selector: 'app-authorizations',
  templateUrl: './authorizations.component.html',
  styleUrls: ['./authorizations.component.scss']
})
export class AuthorizationsComponent implements OnInit {

  @ViewChild('isSystemColumn', { static: true }) public isSystemColumn: TemplateRef<any>;
  @ViewChild('isEnabledColumn', { static: true }) public isEnabledColumn: TemplateRef<any>;

  public columns: GridColumn[] = [];
  public actions: Action[] = [];
  public toolbarActions: Action[] = [];
  public gridData: AuthorizationListOutputItem[];

  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
    private translate: TranslateService,
    private modalService: ModalService,
    private securityService: SecurityService,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private navigationService: NavigationService
  ) {

    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);
    this.disable = this.disable.bind(this);
    this.enable = this.enable.bind(this);

    this.library.addIcons(faUserShield, faPencilAlt, faTrashAlt, faKey);

    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_AUTHORIZATION_WRITE).subscribe(res => {
      if (res) {

        // Write
        this.actions = [
          new Action('edit', this.constantHelper.LBL_ACTION_COMMON_EDIT, 'pencil-alt', this.edit),
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DELETE, 'trash-alt', this.delete),
          new Action('disable', this.constantHelper.LBL_ACTION_COMMON_DISABLE, 'eye-slash', this.disable, null, false, (authorization: AuthorizationListOutputItem) => authorization.isEnabled),
          new Action('enable', this.constantHelper.LBL_ACTION_COMMON_ENABLE, 'eye', this.enable, null, false, (authorization: AuthorizationListOutputItem) => !authorization.isEnabled),
        ];

        this.toolbarActions = [
          new Action('add', this.constantHelper.LBL_ACTION_COMMON_ADD, 'plus', this.add)
        ];
      } else {

        // Only Read
        this.actions = [
          new Action('view', this.constantHelper.LBL_ACTION_COMMON_VIEW, 'eye', this.view),
        ];
      }
    })
  }

  ngOnInit() {

    this.columns = [
      {
        field: 'isEnabled',
        title: 'common.IsEnabled',
        sortable: true,
        resizable: false,
        filterable: false,
        template: this.isEnabledColumn,
        width: '70px'
      },
      {
        field: 'name',
        title: this.constantHelper.LBL_COMMON_NAME,
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'link',
        callback: this.view
      },
      {
        field: 'groupName',
        title: this.constantHelper.LBL_VOCABULARY_AUTHGROUP,
        sortable: true,
        resizable: true,
        filterable: false
      },
      {
        field: 'roleName',
        title: 'vocabulary.Role',
        sortable: true,
        resizable: true,
        filterable: false
      },
      {
        field: 'usersCount',
        title: this.constantHelper.LBL_VOCABULARY_AUTHORIZATION_USERS_COUNT,
        sortable: true,
        resizable: true,
        filterable: false,
        width: '70px'
      },
      {
        field: 'isSystem',
        title: this.constantHelper.LBL_COMMON_ISSYSTEM,
        sortable: true,
        resizable: false,
        filterable: false,
        template: this.isSystemColumn,
        width: '70px'
      }
    ];

    this.loadData();
  }

  public add() {
    this.router.navigate(['administration', 'authorizations', 'new']);
  }

  public edit(authorization: any) {
    this.router.navigate(['administration', 'authorizations', authorization.id], { queryParams: { edit: true } });
  }

  public view(authorization: any) {
    this.router.navigate(['administration', 'authorizations', authorization.id]);
  }

  public delete(authorization: any) {

    this.modalService.showConfirm(
      this.translate.instant(this.constantHelper.LBL_COMMON_CONFIRM_DELETE),
      () => {
        this.authorizationService.deleteAuthorization(authorization.id).subscribe(result => {
          this.loadData();
        });
      });
  }

  public enable(authorization: any) {
    const input = new DisableAuthorizationInput(authorization.id, true);
    this.authorizationService.disableAuthorization(input).subscribe(result => {
      this.loadData();
    });
  }

  public disable(authorization: any) {
    const input = new DisableAuthorizationInput(authorization.id, false);
    this.authorizationService.disableAuthorization(input).subscribe(result => {
      this.loadData();
    });
  }

  private loadData() {
    this.authorizationService.getAuthorizations().subscribe(result => {
      this.gridData = result.authorizations;
    });
  }

}
