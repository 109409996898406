import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { ToastService as BeanToastService } from "bean-notification";
import { Observable, of } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { CommonHelper } from "../helpers/common.helper";
import { ToastService } from './toast.service';
import { WaitService } from "./wait.service";

const httpOptions = {
  headers: new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
  })
};
const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private toastService: ToastService, private waitService: WaitService, public commonHelper: CommonHelper, private newToastService: BeanToastService) { }

  protected getBase<Out>(apiUrl: string) {
    this.validateRequest();
    const call = this.http.get<Out>(this.baseUrl + apiUrl, httpOptions).pipe(share());
    return this.validateResponse<Out>(call);
  }

  protected postBase<In, Out>(apiUrl: string, body: In) {
    this.validateRequest();
    const call = this.http.post<Out>(this.baseUrl + apiUrl, body, httpOptions).pipe(share());
    return this.validateResponse<Out>(call);
  }

  protected putBase<In, Out>(apiUrl: string, body: In) {
    this.validateRequest();
    const call = this.http.put<Out>(this.baseUrl + apiUrl, body, httpOptions).pipe(share());
    return this.validateResponse<Out>(call);
  }

  protected patchBase<In, Out>(apiUrl: string, body: In){
    this.validateRequest();
    const call = this.http.patch<Out>(this.baseUrl + apiUrl, body, httpOptions).pipe(share());
    return this.validateResponse<Out>(call);
  }

  protected deleteBase<Out>(apiUrl: string) {
    this.validateRequest();
    const call = this.http.delete<Out>(this.baseUrl + apiUrl, httpOptions).pipe(share());
    return this.validateResponse<Out>(call);
  }

  protected downloadBase<Out>(apiUrl: string, data?: any, withBaseUrl: boolean = true): Observable<Out> {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

    let call: Observable<any>;
    if (data == undefined) {
      call = this.http.get<Blob>((withBaseUrl ? this.baseUrl : "") + apiUrl,
        {
          ...httpOptions,
          responseType: 'blob' as 'json',
          observe: 'response'
        });
    } else {
      call = this.http.post<Blob>((withBaseUrl ? this.baseUrl : "") + apiUrl,
        data,
        {
          ...httpOptions,
          responseType: 'blob' as 'json',
          observe: 'response'
        });
    }
    this.waitService.showWait();

    return call.pipe(
      map((response: HttpResponse<Blob>) => {
        let fileName = '';
        for (const key of response.headers.keys()) {
          if (key.toLowerCase() === 'content-disposition') {
            const matches = filenameRegex.exec(response.headers.get(key));
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
        }

        this.waitService.hideWait();

        // the object contains also the name so the when downloading the name is added
        return {
          file: response.body,
          name: fileName
        } as unknown as Out;
      }),
      catchError(val => {
        this.toastService.showError(val);
        this.waitService.hideWait();
        return of(val);
      })
    );
  }

  private validateRequest() {
  }

  private validateResponse<Out>(call: Observable<Out>) {
    this.waitService.showWait();

    call.subscribe({
      next: (result) => {
        // convert date
        if (result) {
          this.commonHelper.convertToDate(result);
        }
      },
      error: (error) => {
        // also log to console
        console.log(error);
        this.waitService.hideWait();

        //this.newToastService.handleError(error);
        this.toastService.showError(error);
      },
      complete: () => this.waitService.hideWait(),
    });

    return call;
  }
}
