import { Injectable } from '@angular/core';
import { minCharts, pageSize, timeDelay } from '@veritas-shared/constants/paginated-filter.model';
import { IPaginationItem } from '@veritas-shared/models/pagination-item.mode.';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaginatedFilterService {
  private _filterItem$ = new Subject<IPaginationItem>();
  public filterItem$ = this._filterItem$.asObservable();

  private _filterReset$ = new Subject<boolean>();
  public filterReset$ = this._filterReset$.asObservable();
  
  private _filterTimer;

  public setResetFilter = () => this._filterReset$.next(true);

  public getPaginatedFilter = (text: string) => {
    clearTimeout(this._filterTimer);
    if(text.length < minCharts) return;

    this._filterTimer = setTimeout (() => {
      this._filterItem$.next({
        offset: 0,
        pageSize: pageSize,
        item: text,
      })
    }, timeDelay);
  }
}
