import { Component, OnInit } from '@angular/core';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { BeanDialogComponentBase } from 'bean-kendo-dialog';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-child-remove-dialog',
  templateUrl: './child-remove-dialog.component.html',
  styleUrls: ['./child-remove-dialog.component.scss']
})
export class ChildRemoveDialogComponent extends BeanDialogComponentBase<any> {
  constructor(
    public constantHelper: ConstantHelper,
  ) {
    super();
  }

  ngAfterViewInitCallback(): void { }
  
  public cancel = (e: any): boolean => true;
  public back = (e: any): boolean => true;
  public save = (e: any): Observable<boolean> => of(true);
}
