
export class MapEntity {

  constructor(
    public id: number,
    public latitude: number,
    public longitude: number,
    public entity: any) { }
}

export enum MapEntityType {
  Dumpster,
  CollectionPoint
}
