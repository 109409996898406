<label title="{{ '$lbl.dumpsterChildRemove' | translate }}">{{ '$lbl.dumpsterChildRemove' | translate }}</label>
<div class="dialog-footer">
  <button type="button" class="btn btn-outline-secondary mt-2" (click)="backButtonClick()">
    <fa-icon icon="chevron-left"></fa-icon>
    {{ 'action.Common.Cancel' | translate }}
  </button>
  <button type="button" class="btn btn-primary float-right mt-2" (click)="saveButtonClick()">
    <fa-icon icon="save"></fa-icon>
    {{ 'common.confirm' | translate }}
  </button>
</div>
