import { Pipe, PipeTransform } from '@angular/core';
import { Action } from '../models/action';

@Pipe({
  name: 'filterBy'
})
export class FilterActionsPipe implements PipeTransform {

  transform(value: Action[], ...args: any[]): any {
    return value.filter((a) => {
      return a.show(args[0]);
    });
  }
}
