import { Injectable } from '@angular/core';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { HttpErrorResponse } from '@angular/common/http';
import { ExceptionOutput } from '@veritas-core/models/exception-output';
import { ToastComponent } from '../components/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private notificationInstance: NotificationRef;

  constructor(private notificationService: NotificationService) { }

  public showInfo(message: string) {
    this.showNotification('info', [message]);
  }

  public showSuccess(message: string) {
    this.showNotification('success', [message]);
  }

  public showError(error: string | HttpErrorResponse) {

    if (error instanceof HttpErrorResponse) {
      let httpErrorResponse: HttpErrorResponse;

      httpErrorResponse = error;

      let messages: string[] = [];

      if (httpErrorResponse.error) {
        let exceptionOutput: ExceptionOutput = httpErrorResponse.error;
        if (exceptionOutput.messages) {
          messages = exceptionOutput.messages;

        } else {
          let dirtyProp;

          for (dirtyProp in exceptionOutput) {
            if (exceptionOutput.hasOwnProperty(dirtyProp)) {
              for (let i = 0, len = exceptionOutput[dirtyProp].length; i < len; i++) {
                messages.push(exceptionOutput[dirtyProp][i]);
              }
            }
          }
        }
      } else if (httpErrorResponse.message) {
        messages = [httpErrorResponse.message];
      }

      this.showNotification('error', messages);
    }
    else {
      let message: string;

      message = error;
      this.showNotification('error', [message]);
    }
  }

  public showWarning(message: string | string[]) {
    this.showNotification('warning', !Array.isArray(message) ? [message] : message);
  }

  private showNotification(style: any, messages: string[]) {

    this.notificationInstance = this.notificationService.show({
      content: ToastComponent,
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'right', vertical: 'bottom' },
      type: { style: style, icon: true },
      closable: true,
      //hideAfter: 5000, // is ignored if closable: true
    });

    // Set custom messages
    let toastComponent = this.notificationInstance.content.instance as ToastComponent;
    toastComponent.messages = messages;

    let that = this;
    setTimeout(() => {
      if (that.notificationInstance) {
        that.notificationInstance.hide();
      }
    }, 5000);
  }
}
