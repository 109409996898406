<!-- BOX-->
<div class="group-box" [ngStyle]="{'border-top-color': borderColor}">

  <!-- HEADER -->
  <div *ngIf="isHeaderVisible" class="group-header" [title]="pageTitle">
    <div class="d-flex flex-column justify-content-center left-title" [ngStyle]="{'max-width': 'calc(100% - ' + headerRightWidth + 'px)' }">
      <span class="title">{{pageTitle}}</span>
    </div>

    <div *ngIf="isCollapsible"
         (click)="collapseClick()"
         class="cursor-pointer right-collapsible"
         #collapsableElement>
      <fa-icon *ngIf="collapsed" icon="angle-down"></fa-icon>
      <fa-icon *ngIf="!collapsed" icon="angle-up"></fa-icon>
    </div>

    <div class="right-action" #rightAction>
      <ng-container *ngTemplateOutlet="headerRightTemplate && headerRightTemplate" select="[button]">
      </ng-container>
    </div>

  </div>

  <!-- BODY -->
  <div class="group-body" [ngClass]="{ 'coll-visible':!collapsed, 'coll-hidden': collapsed }" >
    <ng-container [ngTemplateOutlet]="bodyTemplate && bodyTemplate.elementRef != undefined ? bodyTemplate : defaultContent"></ng-container>
  </div>
</div>

<ng-template #defaultContent>
  <ng-content></ng-content>
</ng-template>
