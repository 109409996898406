import { BannerIcon } from "@veritas-shared/enums/banner.const";
import { ViewMode } from "./view-mode";

export interface IBannerActionType {
  create: BannerAction,
  open: BannerAction,
}

export class BannerAction {
  constructor(
    public icon: BannerIcon,
    public text: string,
    public tooltip?: string,
  ){}
}

export class BannerOptionStatus {
  constructor(
    public present: boolean = false,
    public checked: boolean = false,
  ){}
}

export class BannerOption {
  constructor(
    public icon: BannerIcon,
    public body: string,
    public status: BannerOptionStatus = new BannerOptionStatus(),
    public viewMode?: ViewMode,
  ){}
}