import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTag, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Label } from '@veritas-shared/models/label';

@Component({
  selector: 'app-label-list',
  templateUrl: './label-list.component.html',
  styleUrls: ['./label-list.component.scss']
})
export class LabelListComponent implements OnInit {

  @Input() public labels: Label[];

  @Output() public labelSelect = new EventEmitter();

  public labelSelected: string;

  constructor(private library: FaIconLibrary) {

    this.library.addIcons(faTag, faTimes);

  }

  ngOnInit() {

  }

  public onLabelSelected(labelName: string) {
    this.labelSelected = labelName;
    this.labelSelect.emit(labelName);
  }

  public onLabelUnselected(event) {
    this.labelSelected = null;
    this.labelSelect.emit(null);
    event.stopPropagation();
  }
}
